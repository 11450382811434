import React from "react";

const CircleWallet = ({ width, height, cssClass }) => {
  return (
    <svg
      width={width || "39"}
      height={height || "39"}
      className={`${cssClass ? cssClass : ""}`}
      viewBox="0 0 39 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect opacity="0.4" width="39" height="39" rx="19.5" fill="#C4F5EC" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.3305 16.9793H24.8016C23.1593 16.9822 21.829 18.2793 21.826 19.8805C21.8238 21.4853 23.1556 22.7882 24.8016 22.7903H28.3334V23.0453C28.3334 25.8446 26.6364 27.5 23.7645 27.5H16.2364C13.3638 27.5 11.6667 25.8446 11.6667 23.0453V16.9482C11.6667 14.1489 13.3638 12.5 16.2364 12.5H23.7616C26.6334 12.5 28.3305 14.1489 28.3305 16.9482V16.9793ZM15.6164 16.9728H20.3164H20.3193H20.3253C20.6771 16.9713 20.9616 16.6918 20.9601 16.348C20.9586 16.005 20.6712 15.7277 20.3193 15.7291H15.6164C15.2667 15.7305 14.983 16.0072 14.9816 16.3488C14.9801 16.6918 15.2645 16.9713 15.6164 16.9728Z"
        fill="#0BECB4"
      />
      <path
        opacity="0.4"
        d="M23.3647 20.2472C23.5389 21.0399 24.2339 21.5976 25.0273 21.5831H27.7356C28.0657 21.5831 28.3335 21.3097 28.3335 20.9717V18.8621C28.3328 18.5249 28.0657 18.2507 27.7356 18.25H24.9636C24.061 18.2529 23.3321 19.002 23.3335 19.9252C23.3335 20.0333 23.3441 20.1413 23.3647 20.2472Z"
        fill="#0BECB4"
      />
      <ellipse
        cx="25.0001"
        cy="19.9173"
        rx="0.833334"
        ry="0.833333"
        fill="#0BECB4"
      />
    </svg>
  );
};

export default CircleWallet;
