import { useEffect } from "react";
import useStudent from "../../store/student";

const useUserCourseStatus = (id) => {
  // Store
  const { enrolledCourses, wishlistedCourses, getStudentCoursesInfo } =
    useStudent();
    
  const isEnrolledLoaded = enrolledCourses?.loaded;
  const isEnrolled = enrolledCourses?.data?.includes(id);
  const isInWishlistLoaded = wishlistedCourses?.loaded;
  const isInWishlist = wishlistedCourses?.data?.includes(id);

  //   Effects
  useEffect(() => {
    if (!isEnrolledLoaded && !isInWishlistLoaded) {
      getStudentCoursesInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [isEnrolled, isInWishlist];
};

export default useUserCourseStatus;
