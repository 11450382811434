import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { ReactComponent as ListIcon } from "./../../assets/img/svg-1.svg";

import CourseTable from "../common/Tables/CourseTable";
import DashboardSideNav from "../common/DashboardSideNav";
import DashboardTopNav from "../common/DashboardTopNav";
import WelcomeCard from "../common/WelcomeCard";
import { images } from "./../../utils/images";
import Rating from "./../common/Rating";

import { useDispatch, useSelector } from "react-redux";
// import { getCurrentUserData } from "../../storee/slices/user";
import {
  getInstructorDashboardData,
  getCourses,
} from "../../storee/slices/instructor";
import DashboardLoader from "../common/Loaders/DashboardLoader";
import DashboardMaxWidth from "../common/DashboardMaxWidth";
import CenteredLayout from "../common/CenterLayout";
import { landingPageUrl } from "../../utils/constant";
import { formatCurrency, copyText } from "../../utils/helpers";
import { getPersonalProfile } from "../../services/instructorServices";

const Dashboard = () => {
  // State
  const [userName, setUserNAme] = useState("");
  const [isLoadingUserName, setIsLoadingUserName] = useState(false);
  const [copyBtnText, setCopyBtnText] = useState("Copy link");
  const dispatch = useDispatch();

  // Methods
  const getProfile = async () => {
    setIsLoadingUserName(true);
    try {
      let { data } = await getPersonalProfile();
      let newUserData = data.instructor_personal_profile;
      setUserNAme(newUserData.name);
      setIsLoadingUserName(false);
    } catch (error) {
      setIsLoadingUserName(false);
    }
  };

  const handleTextCopyBtn = async (id) => {
    const isCopied = await copyText(id);
    setCopyBtnText(isCopied ? "Copied!" : "Copy link");
  };

  const handleTextCopyBtnMouseOut = async () => {
    setCopyBtnText("Copy link");
  };

  // Effects
  useEffect(() => {
    // dispatch(getCurrentUserData());
    dispatch(getInstructorDashboardData());
    dispatch(getCourses());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // User data
  // const [user, isLoadingUser] = useSelector((store) => [
  //   store.entities.user?.currentUser,
  //   store.entities.user?.isLoading,
  // ]);

  // Instructor Data
  const [dashboardData, isLoadingDashboardData] = useSelector((store) => [
    store.entities.instructor.dashboardData.data,
    store.entities.instructor.dashboardData.isLoading,
  ]);

  // Instructor Courses
  const [instructorCourses, isLoadingCourses] = useSelector((store) => [
    store.entities.instructor.courses.data,
    store.entities.instructor.courses.isLoading,
  ]);

  // Effects
  useEffect(() => {
    getProfile();
  }, []);

  // Data to display

  return (
    <main className="dashboard-main">
      <DashboardSideNav />
      <main className="dashboard__main">
        <DashboardTopNav>
          <h3 className="head-2">Dashboard</h3>
          <Link to="/create-course">
            <button className="btn btn-1">Create Course</button>
          </Link>
        </DashboardTopNav>
        <CenteredLayout>
          <DashboardMaxWidth>
            {isLoadingUserName || isLoadingDashboardData ? (
              <DashboardLoader />
            ) : (
              <section className="dashboard__main-content">
                <aside className="dashboard__left-side">
                  <WelcomeCard userName={userName || ""} />
                  {/* <div className="warning">
                    <svg className="dashboard-side-icon">
                      <use xlinkHref={`${images.spriteSVG}#icon-info`}></use>
                    </svg>
                    <p className="warning__text">
                      Please make sure you set up your bank and collection
                      details from the settlement section
                    </p>
                  </div> */}

                  <h3 className="head-4 m-b-20px m-t-50px">My Courses</h3>
                  <CourseTable
                    courses={instructorCourses}
                    loading={isLoadingCourses}
                  />
                </aside>
                {/* <!-- Right side --> */}
                <aside className="dashboard__right-side">
                  {/* <!-- Review card --> */}
                  <div className="review__card">
                    <div className="review__rating">
                      <div className="review__image-holder">
                        <img
                          src={
                            dashboardData.instructor_profile_picture_url ||
                            "/assets/images/no-image.jpg"
                          }
                          alt=""
                          className="review__img"
                        />
                      </div>
                      <div className="review__name">
                        <h3 className="head-4">
                          <span className="welcome__username">
                            {userName || ""}
                          </span>
                        </h3>

                        <Rating />
                      </div>
                    </div>

                    {/* <!--  --> */}
                    <div className="review__rating">
                      <div className="review__icon-holder">
                        {/* <svg className="review__icon">
                      <use
                        xlinkHref={`${images.spriteSVG}#icon-course-created`}
                      ></use>
                    </svg> */}

                        <ListIcon className="mmmm" />
                      </div>

                      <div className="review__title">
                        <p className="para-4">Course Created</p>
                        <h2 className="head-2 m-t-5px">
                          {dashboardData.courses_count}
                        </h2>
                      </div>
                    </div>

                    {/* <!--  --> */}
                    <div className="review__rating">
                      <div className="review__icon-holder">
                        <svg className="review__icon">
                          <use
                            xlinkHref={`${images.spriteSVG}#icon-student-enrolled`}
                          ></use>
                        </svg>
                      </div>

                      <div className="review__title">
                        <p className="para-4">Total Student’s Enrolled</p>
                        <h2 className="head-2 m-t-5px">
                          {dashboardData.enrolment_count}
                        </h2>
                      </div>
                    </div>

                    {/* <!--  --> */}
                    <div className="review__rating">
                      <div className="review__icon-holder">
                        <svg className="review__icon">
                          <use
                            xlinkHref={`${images.spriteSVG}#icon-total-icome`}
                          ></use>
                        </svg>
                      </div>

                      <div className="review__title">
                        <p className="para-4">Total Income</p>
                        <h2 className="head-2 m-t-5px">
                          <span>$ </span>
                          {dashboardData.total_instructor_reveue
                            ? formatCurrency(
                                dashboardData.total_instructor_reveue
                              )
                            : 0}
                        </h2>
                      </div>
                    </div>
                  </div>

                  {/* <!--  --> */}
                  <div className="review__card m-t-25px">
                    <h3 className="head-5 m-b-10px">Share Profile</h3>
                    <div className="copy-link">
                      <input
                        type="text"
                        name=""
                        id="profile-link-input"
                        value={`${landingPageUrl}${
                          dashboardData.instructor_profile_url || 9
                        }`}
                        className="copy-link__input"
                        disabled
                      />
                      <button
                        className="btn btn-1 copy-link__btn"
                        onClick={() => handleTextCopyBtn("profile-link-input")}
                        onMouseLeave={() => handleTextCopyBtnMouseOut()}
                      >
                        {copyBtnText}
                      </button>
                    </div>
                  </div>
                </aside>
              </section>
            )}
          </DashboardMaxWidth>
        </CenteredLayout>
      </main>
    </main>
  );
};

export default Dashboard;
