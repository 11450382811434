import http from "./httpServices";

const paypalPaymentApi = "/paypal/make-payment";
const paypalPaymentVerificationApi = "/paypal/verify-payment";
const paypalVerifyTransactionApi = "/paypal/verify-transaction";

const paystackPaymentApi = "/paystack/get-payment-link";
const paystackVerifyTransactionApi = "/paystack/verify-transaction";

const stripePaymentApi = "/stripe/get-payment-link";
const stripeVerifyTransactionApi = "/stripe/verify-transaction";

export function paypalPayment(course_id) {
  return http.post(paypalPaymentApi, { course_id });
}

export function paypalPaymentVerification(data) {
  return http.post(paypalPaymentVerificationApi, data);
}

export function paypalVerifyTransaction(data) {
  return http.post(paypalVerifyTransactionApi, data);
}

export function paystackPayment(course_id) {
  return http.post(paystackPaymentApi, { course_id });
}

export function paystackVerifyTransaction(data) {
  return http.post(paystackVerifyTransactionApi, data);
}

export function stripePayment(course_id) {
  return http.post(stripePaymentApi, { course_id });
}

export function stripeVerifyTransaction(data) {
  return http.post(stripeVerifyTransactionApi, data);
}

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  paypalPayment,
  paypalPaymentVerification,
  paypalVerifyTransaction,
  paystackPayment,
  paystackVerifyTransaction,
  stripePayment,
  stripeVerifyTransaction,
};
