import React from 'react'

const Student = ({studentImg, studentName, studentProfession, courseEnrolled, courseStatus, studentLocation}) => {
  return (
    <div className="student-row">
        <div className="student-info">
            <img src={studentImg} alt="" className="studentImg" />
            <span className="student-text">{studentName}</span>
        </div>
        <div className="student-text">{studentProfession}</div>
        <div className="student-text">{courseEnrolled}</div>
        <div className="student-text">{courseStatus}</div>
        <div className="student-text">{studentLocation}</div>
        <button className="more-btn">&hellip;</button>
    </div>
  )
} 

export default Student