import React ,{ useState }from "react";
import { useParams } from "react-router-dom";
import courseServices from "../services/courseServices";

const withCourseData = (WrappedComponent) => (props) => {
  const [courseData, setCourseData] = useState({});

  // Hooks
  const { id } = useParams();


  //   Handlers
  const getCourseData = async () => {
    try {
      const res = await courseServices.getCourseProfile(id);
      const course = res.data;
      setCourseData(course);
    } catch (error) {}
  };

  //   Wrapped component
  return (
    <WrappedComponent
      {...props}
      getCourse={getCourseData}
      courseData={courseData}
    />
  );
};

export default withCourseData;
