import React, { useState } from "react";
import { Link } from "react-router-dom";
import Rating from "./Rating";
import ProgressBar from "./ProgressBar";
import EnrolledStudent from "./EnrolledStudent";
import moment from "moment";
import ProgressBarWithUser from "./ProgressBarWithUser";
import { removeTags } from "../../utils/helpers";

const AboutCourse = ({ course, allCourseInfo, type, progressBarType }) => {
  // State
  const [readMore, setReadMore] = useState(false);
  // Data to render
  return (
    <div className="about-course">
      <section className="about-course__column">
        <header className="about-course__header">
          <h4 className="head-13">About this course</h4>
          {type !== "student" && (
            <Link to="#" className="link link-3">
              Edit
            </Link>
          )}
        </header>
        <article className="about-course__article">
          {!readMore && course?.about_course ? (
            <>
              {`${removeTags(course?.about_course.substring(0, 150))}....` ||
                "--"}
              {"   "}
              <button
                className="btn link link-3"
                onClick={() => setReadMore(true)}
              >
                Read More
              </button>
            </>
          ) : (
            <>
              {`${removeTags(course?.about_course)}` || "--"}
              {"   "}
              <button
                className="btn link link-3"
                onClick={() => setReadMore(false)}
              >
                Read Less
              </button>
            </>
          )}
        </article>
      </section>

      <section className="about-course__column">
        <header className="about-course__header">
          <h4 className="head-13">Course Rating</h4>
        </header>
        <Rating rate={course?.course_rating} />
      </section>

      <section className="about-course__column">
        <header className="about-course__header">
          <h4 className="head-13">Course Duration</h4>
          {type !== "student" && (
            <Link to={`/edit-course/${course?.id}`} className="link link-3">
              Renew
            </Link>
          )}
        </header>
        <span className="about-course__duration">
          <span>{moment(course?.start_date).format("MMM Do YYYY")}</span>
          <span className="about-course__duration__divider">-</span>
          <span>{moment(course?.end_date).format("MMM Do YYYY")}</span>
        </span>
      </section>

      <section className="about-course__column">
        <header className="about-course__header">
          <h4 className="head-13">Class Size</h4>
        </header>
        {progressBarType === "user" ? (
          <ProgressBarWithUser
            classPercentage={allCourseInfo?.percentage_class_size}
            enrolmentCount={
              allCourseInfo?.enrolment_count ||
              allCourseInfo?.no_of_enrolled_students
            }
            classSize={allCourseInfo?.class_size}
          />
        ) : (
          <ProgressBar classPercentage={allCourseInfo?.percentage_class_size} />
        )}
      </section>

      {type !== "student" && (
        <section className="about-course__column">
          <header className="about-course__header">
            <h4 className="head-13">
              {allCourseInfo?.no_of_enrolled_students} of{" "}
              {course?.class_size || "--"} students enrolled
            </h4>
          </header>

          {allCourseInfo?.no_of_enrolled_students ? <EnrolledStudent /> : ""}
        </section>
      )}
    </div>
  );
};

export default AboutCourse;
