import React, { useEffect } from "react";

import CourseExplore from "../common/CoursesExplore";
import DashboardSideNav from "../common/DashboardSideNav";
import DashboardTopNav from "../common/DashboardTopNav";
import StudentDashboardLoader from "../common/Loaders/StudentDashboardLoader";

import { useDispatch, useSelector } from "react-redux";
import {
  getStudentDashboardData,
  getUpcomingClasses,
} from "./../../storee/slices/student";
import { getCourseCategories } from "./../../storee/slices/helpers";
import {
  getFeaturedCourses,
  getAllCourses,
} from "./../../storee/slices/courses";
import CenteredLayout from "../common/CenterLayout";
import DashboardMaxWidth from "../common/DashboardMaxWidth";

const AllCourses = () => {
  const dispatch = useDispatch();

  let [
    isLoadingDashboardData,
    // dashboardData,
    isLoadingUpcomingClasses,
    // upcomingClasses,
  ] = useSelector((store) => [
    store.entities.student.dashboardData.isLoading,
    store.entities.student.dashboardData.data,
    store.entities.student.upcomingClasses.isLoading,
    // store.entities.student.upcomingClasses.data,
  ]);

  let [isLoadingCategoriesData] = useSelector((store) => [
    store.entities.helpers.courseCategories.isLoading,
  ]);

  let [
    isLoadingfeaturedCourses,
    // featuredCourses,
    isLaodingAllCourses,
    allCourses,
  ] = useSelector((store) => [
    store.entities.courses.featuredCourses.isLoading,
    // store.entities.courses.featuredCourses.data,
    store.entities.courses.allCourses.isLoading,
    store.entities.courses.allCourses.data,
  ]);

  useEffect(() => {
    dispatch(getStudentDashboardData());
    dispatch(getCourseCategories());
    dispatch(getFeaturedCourses());
    dispatch(getAllCourses());
    dispatch(getUpcomingClasses());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderPage =
    isLoadingDashboardData &&
    isLoadingCategoriesData &&
    isLoadingfeaturedCourses &&
    isLaodingAllCourses &&
    isLoadingUpcomingClasses;
  return (
    <main className="dashboard-main">
      <DashboardSideNav />
      <main className="dashboard__main">
        <DashboardTopNav>
          <h3 className="head-2">Courses</h3>
        </DashboardTopNav>

        <CenteredLayout>
          <DashboardMaxWidth>
            {renderPage ? (
              <StudentDashboardLoader />
            ) : (
              <CourseExplore
                cssClass="m-t-50px"
                courses={allCourses?.courses}
                noLink={true}
              />
            )}
          </DashboardMaxWidth>
        </CenteredLayout>
      </main>
    </main>
  );
};

export default AllCourses;
