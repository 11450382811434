import React from "react";

const Select = ({
  label,
  name,
  options,
  optionId,
  optionName,
  placeholder,
  error,
  value,
  ...rest
}) => {
  return (
    <div className="input__wrapper">
      <div className="input-top">
        {label ? (
          <label htmlFor="" className="input__label">
            {label}
          </label>
        ) : (
          <label htmlFor=""></label>
        )}

        {error ? <span className="input__error"> {error} </span> : ""}
      </div>
      <div className={`input__holder`}>
        {/* <label for="cars">Choose a car:</label> */}
        <select
          name={name}
          id={name}
          value={value}
          className={`input__item input__item--no-icon`}
          {...rest}
        >
          <option value="">{placeholder ? placeholder : ""}</option>
          {/* Was getting errors here
              TODO: Resolve error.
          */}
          {options?.map((option, id) => (
            <option key={id} value={optionId ? option.optionId : id}>
              {optionName ? option.optionName : option}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default Select;
