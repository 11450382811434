import React from "react";

const Circle = ({ width, height, cssClass, pathCssClass1 }) => {
  return (
    <svg
      width={width || "13"}
      height={height || "13"}
      className={`${cssClass ? cssClass : ""}`}
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="6.5"
        cy="6.5"
        r="5.5"
        fill="white"
        stroke="#121212"
        strokeWidth="2"
      />
    </svg>
  );
};

export default Circle;
