import React from "react";

const LinkIcon = ({ width, height, cssClass }) => {
  return (
    <svg
      width={width || "61"}
      height={height || "30"}
      className={`${cssClass ? cssClass : ""}`}
      viewBox="0 0 61 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M45.75 18H15.25V12H45.75V18Z" fill="#5C4FFF" />
      <path
        d="M45.75 30H36.6V24H45.75C50.8034 24 54.9 19.9706 54.9 15C54.9 10.0294 50.8034 6 45.75 6H36.6V0H45.75C54.1723 0 61 6.71573 61 15C61 23.2843 54.1723 30 45.75 30ZM24.4 30H15.25C6.82766 30 0 23.2843 0 15C0 6.71573 6.82766 0 15.25 0H24.4V6H15.25C10.1966 6 6.1 10.0294 6.1 15C6.1 19.9706 10.1966 24 15.25 24H24.4V30Z"
        fill="#647382"
        fillOpacity="0.2"
      />
    </svg>
  );
};

export default LinkIcon;
