import React from 'react'
import DashboardTopNav from '../common/DashboardTopNav'
import DashboardSideNav from '../common/DashboardSideNav'
import InputSearch from '../common/InputSearch'
import CenteredLayout from '../common/CenterLayout'
import DashboardMaxWidth from '../common/DashboardMaxWidth'
import { Link, useHistory } from "react-router-dom";
import { images } from '../../../src/utils/images'
import ContentCard from '../common/ContentCard'
import StudentDetail from '../common/StudentDetail'
import StudentCourses from '../common/StudentCourses'

const ViewStudent = () => {
    const history = useHistory()

    const goBack = (e) => {
        e.preventDefault();
        history.goBack();
      };
  return (
    <main className="dashboard-main">
      <DashboardSideNav />
      <main className="dashboard__main">
        <DashboardTopNav>
            <button onClick={(e) => goBack(e)} className="btn btn-3">
                <i className="fas fa-long-arrow-alt-left m-r-6px"></i>
                Back
            </button>
            <InputSearch placeholder="Search Courses" />

            <Link to="/create-course">
                <button className="btn btn-1">Create Course</button>
            </Link>
        </DashboardTopNav>

        <CenteredLayout>
          <DashboardMaxWidth>
            <section className="dashboard__main-content students-main view-student-main">
                <ContentCard cssClass="student-detail-card" cssClass2="student-detail-content">
                    <StudentDetail 
                        studentImage={images.pic19}
                        studentName="John Doe" 
                        studentRole="Recruitment Officer" 
                        studentJob="Ux Designer" 
                        studentQualification="BCs Degree"
                        studentSocialLink="#"
                        studentSocialIcon={images.pic16}
                        studentSocialName="Linkedin"
                    />
                    <button className="btn btn-1 student-send-msg">Send Message</button>
                    <div className="student-detail-about">
                        <h4 className="detail-about-label">About John</h4>
                        <p className="detail-about-paragraph">
                            Lauren Laitin helps professionals find fulfillment, stimulation, boundaries, and happiness at work and at home. After navigating her own career transition—from attorney to founder and principal of ParachuteCoaching.com—Lauren found her true passion and loves working with others to find theirs, too. She works with clients to clarify exactly what they want, craft effective strategies for getting there, and ultimately carve a path to success. Work with Lauren if you're still trying to figure out what you want to be when you grow up or you're ready for a change and want support to make it happen.
                        </p>
                    </div>
                </ContentCard>
                <div className='student-courses-grid'>
                    <h4 className="student-courses-label">Jonh Doe's courses</h4>
                    <div className="student-courses-info">
                        <StudentCourses 
                            courseImg={images.pic12}
                            title="Creative & Design"
                            label="The fundamental of Product design"
                            status="Completed"
                            info="A course for anyone who’s ready to find their dream job. Covering everything from resumes to job applications."
                            teach={images.pic12}
                            teachName="Temitope Niyi"
                            teachRole="Senior product designer @ Elan Haos"
                            students="65 Students"
                        />
                        <StudentCourses 
                            courseImg={images.pic12}
                            title="Creative & Design"
                            label="The fundamental of Product design"
                            status="Completed"
                            info="A course for anyone who’s ready to find their dream job. Covering everything from resumes to job applications."
                            teach={images.pic12}
                            teachName="Temitope Niyi"
                            teachRole="Senior product designer @ Elan Haos"
                            students="65 Students"
                        />
                        <StudentCourses 
                            courseImg={images.pic12}
                            title="Creative & Design"
                            label="The fundamental of Product design"
                            status="Ongoing"
                            info="A course for anyone who’s ready to find their dream job. Covering everything from resumes to job applications."
                            teach={images.pic12}
                            teachName="Temitope Niyi"
                            teachRole="Senior product designer @ Elan Haos"
                            students="65 Students"
                        />
                    </div>
                </div>
            </section>
          </DashboardMaxWidth>
        </CenteredLayout>
      </main>
    </main>
  )
}

export default ViewStudent