import React from 'react'

const StudentDetail = ({
    studentImage,
    studentName, 
    studentRole, 
    studentJob, 
    studentQualification,
    studentSocialLink,
    studentSocialIcon,
    studentSocialName
}) => {
  return (
    <div className="student-detail-info">
        <img src={studentImage} alt="" className="student-detail-image" />
        <div className="student-detail">
            <h4 className="student-detail-name">{studentName}</h4>
            <span className="student-detail-role">{studentRole}</span>
            <div className="student-qualifications">
                <div className="student-qual-box">
                    <span className="student-qual-label">Job title</span>
                    <span className="student-qual-info">{studentJob}</span>
                </div>
                <span className="student-qual-line"></span>
                <div className="student-qual-box">
                    <span className="student-qual-label">Qualification</span>
                    <span className="student-qual-info">{studentQualification}</span>
                </div>
            </div>
            <a href={studentSocialLink} className="student-detail-socials">
                <img src={studentSocialIcon} alt="" className="student-social-icon" />
                <span className="student-social-title">{studentSocialName}</span>
            </a>
        </div>
    </div>
  )
}

export default StudentDetail