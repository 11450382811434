import React from "react";
import { images } from "./../../utils/images";

const EnrolledStudent = (props) => {
  const { size } = props;
  return (
    <div className="enrolled-student">
      <div
        className={`enrolled-student__item enrolled-student__item--1 ${
          size === "sm" ? "sm" : ""
        }`}
      >
        <img
          src={images.pic4}
          alt="enrolled-student"
          className="enrolled-student__img"
        />
      </div>
      <div
        className={`enrolled-student__item enrolled-student__item--1 ${
          size === "sm" ? "sm" : ""
        }`}
      >
        <img
          src={images.pic7}
          alt="enrolled-student"
          className="enrolled-student__img"
        />
      </div>
      <div
        className={`enrolled-student__item enrolled-student__item--1 ${
          size === "sm" ? "sm" : ""
        }`}
      >
        <img
          src={images.pic8}
          alt="enrolled-student"
          className="enrolled-student__img"
        />
      </div>
      <div
        className={`enrolled-student__item enrolled-student__item--last ${
          size === "sm" ? "sm" : ""
        }`}
      >
        <span className="enrolled-student__others">+26</span>
      </div>
    </div>
  );
};

export default EnrolledStudent;
