import React, { useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import useOnClickOutside from "../../../utils/Hooks/useOnClickOutside";
import CoursePublish from "../CoursePublish";
// import Svg from "../Svg";
import Dots from "../SvgIcons/Dots";
import EditIcon from "./../SvgIcons/EditIcon";
import TrashIcon from "./../SvgIcons/TrashIcon";
// import { motion } from "framer-motion/dist/framer-motion";
import ConfirmActionModal from "./../Modals/ConfirmActionModal";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteCourse,
  removeInstructorCourse,
} from "../../../storee/slices/instructor";

const TableRows = ({ course }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const dropdownRef = useRef();
  useOnClickOutside(dropdownRef, () => setShowDropdown(false));
  const history = useHistory();
  const dispatch = useDispatch();

  const handleCourseDelete = async () => {
    await dispatch(deleteCourse({ course_id: course?.id }));
    await dispatch(removeInstructorCourse({ id: course?.id }));
    setShowDeleteModal(false);
  };

  const [isLoading] = useSelector((store) => [
    store.entities.instructor.deleteCourse.isLoading,
  ]);

  return (
    <>
      <tr className="table__main__row">
        <td className="table__main__data data__course-name">
          <div className="data__course-icon">
            {course?.thumbnail_file_url ? (
              // <Svg
              //   iconId="icon-course-placeholer"
              //   cssClassName="data__course-img"
              // />
              <div className="table__avatar">
                <img src={course?.thumbnail_file_url} alt="course avatar" />
              </div>
            ) : (
              <div className="table__avatar">
                <img src={course?.thumbnail_file_url} alt="course avatar" />
              </div>
              // <Svg
              //   iconId="icon-course-placeholer"
              //   cssClassName="data__course-img"
              // />
            )}
          </div>
          <p className="data__course-description">{course?.course_name}</p>
        </td>
        <td className="table__main__data text-center course-publish-action">
          <CoursePublish status={course?.is_published} courseId={course?.id} />
        </td>
        <td className="table__main__data text-center course-view-button">
          <Link to={`/course-view/${course?.id}`}>
            <button className="btn btn-2">View Course</button>
          </Link>
        </td>
        <td className="table__main__data table__actions">
          <button
            className="btn btn-naked"
            onClick={() => setShowDropdown((prevState) => !prevState)}
          >
            <Dots cssClass="table__actions-dots" />
          </button>
          {showDropdown && (
            <div ref={dropdownRef} className="dropdown">
              <button
                className="btn dropdown-btn"
                onClick={() => history.push(`/edit-course/${course?.id}`)}
              >
                <EditIcon pathCssClass="dropdown-btn-icon" />
                Edit
              </button>
              <button
                className="btn dropdown-btn"
                onClick={() => setShowDeleteModal(true)}
              >
                <TrashIcon pathCssClass="dropdown-btn-icon" />
                Delete
              </button>
            </div>
          )}
        </td>
      </tr>

      {showDeleteModal && (
        <ConfirmActionModal
          setShowModal={setShowDeleteModal}
          handleYesAction={handleCourseDelete}
          title="Are you sure you wan to delete the course ?"
          isLoading={isLoading}
        />
      )}
    </>
  );
};

export default TableRows;
