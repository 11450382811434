import React from 'react'
import DashboardTopNav from '../common/DashboardTopNav'
import DashboardSideNav from '../common/DashboardSideNav'
import InputSearch from '../common/InputSearch'
import CenteredLayout from '../common/CenterLayout'
import DashboardMaxWidth from '../common/DashboardMaxWidth'
import { Link } from 'react-router-dom'
import { images } from '../../../src/utils/images'
import Student from '../common/Student'
import { useHistory } from 'react-router-dom'

const Students = () => {
  const history = useHistory()
  function goBack(e) {
    e.preventDefault();
    history.goBack();
  };
  return (
    <main className="dashboard-main">
      <DashboardSideNav />
      <main className="dashboard__main">
        <DashboardTopNav>
              <button onClick={(e) => goBack(e)} className="btn btn-3">
                <i className="fas fa-long-arrow-alt-left m-r-6px"></i>
                Back
              </button>
          <InputSearch placeholder="Search Courses" />
          <Link to="/create-course">
            <button className="btn btn-1">Create Course</button>
          </Link>
        </DashboardTopNav>
        <h3 className="head-2">Students</h3>

        <CenteredLayout>
          <DashboardMaxWidth>
            <section className="dashboard__main-content students-main">
              <div className="student-nav-bar">
                <span className='student-bar student-bar-active'>All Student</span>
                <span className='student-bar'>Current Students</span>
                <span className='student-bar'>Past Students</span>
              </div>
              <div className="studentsInfo">
                <div className="studentsInfoTabBar">
                  <div className="studentInfoTab">
                    <span className="infoTabLabel">Recipient Name</span>
                    <img src={images.arrowDown} alt="" className="infoTabIcon" />
                  </div>
                  <div className="studentInfoTab">
                    <span className="infoTabLabel">Profession</span>
                    <img src={images.arrowDown} alt="" className="infoTabIcon" />
                  </div>
                  <div className="studentInfoTab">
                    <span className="infoTabLabel">Course Enrolled</span>
                    <img src={images.arrowDown} alt="" className="infoTabIcon" />
                  </div>
                  <div className="studentInfoTab">
                    <span className="infoTabLabel">Recipient Name</span>
                    <img src={images.arrowDown} alt="" className="infoTabIcon" />
                  </div>
                  <div className="studentInfoTab">
                    <span className="infoTabLabel">Location</span>
                    <img src={images.arrowDown} alt="" className="infoTabIcon" />
                  </div>
                </div>
                <Link to="/view-student">
                  <Student 
                    studentImg={images.pic12}
                    studentName="John Doe"
                    studentProfession="UX DESIGN"
                    courseEnrolled="Product Design Introduction"
                    courseStatus="Completed"
                    studentLocation="London"
                  />
                </Link>
                <Link to="/view-student">
                  <Student 
                    studentImg={images.pic12}
                    studentName="John Doe"
                    studentProfession="UX DESIGN"
                    courseEnrolled="Product Design Introduction"
                    courseStatus="Completed"
                    studentLocation="London"
                  />
                </Link>
                <Link to="/view-student">
                  <Student 
                    studentImg={images.pic12}
                    studentName="John Doe"
                    studentProfession="UX DESIGN"
                    courseEnrolled="Product Design Introduction"
                    courseStatus="Completed"
                    studentLocation="London"
                  />
                </Link>
              </div>
            </section>
          </DashboardMaxWidth>
        </CenteredLayout>
      </main>
    </main>
  )
}

export default Students