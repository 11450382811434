import React from "react";
import { Link } from "react-router-dom";
import { getFirstLine } from "../../../utils/helpers";

const DocumentView = (props) => {
  const { link, title, description } = props;
  return (
    <div className="all-course-content__doc-ele">
      <h3 className="head-33 m-b-20px text-center">{title || "--"}</h3>
      <p className="para-17 text-center">{getFirstLine(description) || "--"}</p>

      <Link
        to={{ pathname: `${link}` }}
        target="_blank"
        className="text-docorate-none m-t-10px display-block"
      >
        <button className="btn btn-7 btn-md m-x-auto">GO TO RESOURCE</button>
      </Link>

      <p className="para-17 m-t-20px text-center">
        Thie link will open a new tab.
      </p>
    </div>
  );
};

export default DocumentView;
