import Skeleton from "react-loading-skeleton";

const LiveClassesLoader = () => {
  const style = {
    main: {
      display: "grid",
      gridTemplateColumns: "repeat(3, 1fr)",
      gridGap: "3rem",
      marginTop: "50px",
    },

    card: {
      backgroundColor: "white",
      borderRadius: "4px",
      padding: "20px",
    },
  };

  return (
    <div className="m-t-50px">
      <div className="flex-r-jcbetween-aicenter m-b-20px">
        <div className="flex-c">
          <Skeleton height={"10px"} width={"200px"} />
          <Skeleton height={"10px"} width={"400px"} />
        </div>
        <div className="flex-r-aicenter m-t-20px">
          <Skeleton height={"20px"} width={"120px"} />
          <div className="m-l-20px">
            <Skeleton height={"20px"} width={"120px"} />
          </div>
        </div>
      </div>

      <div className="flex-r-jcbetween-aicenter m-b-30px" style={style.card}>
        <div className="flex-r-jcbetween-aicenter flex-gap-10px">
          <Skeleton height={"25px"} width={"25px"} />
          <div className="">
            <Skeleton height={"40px"} width={"40px"} />
          </div>
          <div>
            <div className="m-b-">
              <Skeleton height={"10px"} width={"200px"} />
            </div>
            <Skeleton height={"10px"} width={"400px"} />
          </div>
        </div>
        <div className="flex-r-aicenter">
          <Skeleton height={"30px"} width={"70px"} />
          <div className="m-l-20px">
            <Skeleton height={"30px"} width={"70px"} />
          </div>
          <div className="m-l-20px">
            <Skeleton height={"20px"} width={"10px"} />
          </div>
        </div>
      </div>
      <div className="flex-r-jcbetween-aicenter m-b-30px" style={style.card}>
        <div className="flex-r-jcbetween-aicenter flex-gap-10px">
          <Skeleton height={"25px"} width={"25px"} />
          <div className="">
            <Skeleton height={"40px"} width={"40px"} />
          </div>
          <div>
            <div className="m-b-">
              <Skeleton height={"10px"} width={"200px"} />
            </div>
            <Skeleton height={"10px"} width={"400px"} />
          </div>
        </div>
        <div className="flex-r-aicenter">
          <Skeleton height={"30px"} width={"70px"} />
          <div className="m-l-20px">
            <Skeleton height={"30px"} width={"70px"} />
          </div>
          <div className="m-l-20px">
            <Skeleton height={"20px"} width={"10px"} />
          </div>
        </div>
      </div>
      <div className="flex-r-jcbetween-aicenter m-b-30px" style={style.card}>
        <div className="flex-r-jcbetween-aicenter flex-gap-10px">
          <Skeleton height={"25px"} width={"25px"} />
          <div className="">
            <Skeleton height={"40px"} width={"40px"} />
          </div>
          <div>
            <div className="m-b-">
              <Skeleton height={"10px"} width={"200px"} />
            </div>
            <Skeleton height={"10px"} width={"400px"} />
          </div>
        </div>
        <div className="flex-r-aicenter">
          <Skeleton height={"30px"} width={"70px"} />
          <div className="m-l-20px">
            <Skeleton height={"30px"} width={"70px"} />
          </div>
          <div className="m-l-20px">
            <Skeleton height={"20px"} width={"10px"} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LiveClassesLoader;
