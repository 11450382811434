import React from "react";
import Form from "./Form";
import Joi from "joi";
import { connect } from "react-redux";
import { uploadPromoVideo } from "../../../storee/slices/singleCourse";
import { addANotification } from "../../../storee/slices/notification";

class PromoVideoForm extends Form {
  constructor(props) {
    super(props);

    this.state = {
      data: { link: "" },
      error: { link: "" },
    };
  }

  schema = {
    link: Joi.string().required().label("Link"),
  };

  doSubmit = async () => {
    // this.setState({ isLoading: true });
    const { link } = this.state.data;

    await this.props.uploadPromoVideo({
      course_id: this.props.courseId,
      promo_video_url: link,
    });

    await this.props.notifyActions({
      type: "ok",
      title: "Success",
      description: "Video link Saved Successfully!",
    });

    window.location.reload();
  };

  render() {
    const { isLoading } = this.props;
    return (
      <form onSubmit={this.handleSubmit}>
        {this.renderInput(
          "Link",
          "link",
          "text",
          "noIcon",
          null,
          "icon-link-small"
        )}

        <button className="btn btn-5 m-t-25px">
          {isLoading ? (
            <i className="fas fa-spinner animate-rotate"></i>
          ) : (
            "Save Link"
          )}
        </button>
      </form>
    );
  }
}

// Store
const mapStateToProps = (state) => ({
  isLoading: state.entities.singleCourse.promoVideo.isLoading,
  isError: state.entities.singleCourse.promoVideo.error,
  courseId: state.entities.singleCourse.data.courses.id,
});

const mapDispatchToProps = (dispatch) => ({
  uploadPromoVideo: (data) => dispatch(uploadPromoVideo(data)),
  notifyActions: (data) => dispatch(addANotification(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PromoVideoForm);
