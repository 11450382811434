import React from "react";
import Form from "./../common/Forms/Form";

import DashboardSideNav from "./../common/DashboardSideNav";
import DashboardTopNav from "./../common/DashboardTopNav";
import CenteredLayout from "../common/CenterLayout";
import DashboardMaxWidth from "../common/DashboardMaxWidth";
import Check from "../common/SvgIcons/Check";

class CourseCreated extends Form {
  constructor(props) {
    super(props);
    this.state = {};
  }

  goBack = (e) => {
    e.preventDefault();
    this.props.history.goBack();
  };

  render() {
    return (
      <>
        <main className="dashboard-main">
          <DashboardSideNav />
          <main className="dashboard__main">
            <DashboardTopNav>
              <button onClick={(e) => this.goBack(e)} className="btn btn-3">
                <i className="fas fa-long-arrow-alt-left m-r-6px"></i>
                Back
              </button>
            </DashboardTopNav>

            <CenteredLayout>
              <DashboardMaxWidth>
                <div
                  className="confirm-card"
                  style={{ display: "grid", placeItems: "center" }}
                >
                  <div
                    className="card"
                    style={{
                      display: "grid",
                      placeItems: "center",
                      marginTop: "6rem",
                    }}
                  >
                    <Check />
                    <h5
                      head="head-34"
                      style={{
                        marginTop: "2rem",
                        marginBottom: "3rem",
                      }}
                    >
                      Congratulations! Your course has been created successfully
                    </h5>
                    <button
                      onClick={() => this.props.history.push("/courses")}
                      className="btn btn-7 btn-sm m-t-20px"
                    >
                      Back to Courses
                    </button>
                  </div>
                </div>
              </DashboardMaxWidth>
            </CenteredLayout>
          </main>
        </main>
      </>
    );
  }
}

export default CourseCreated;
