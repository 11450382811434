import moment from "moment";
import React from "react";
import { handleTimeDifference } from "../../utils/helpers";

const JoinEvent = (props) => {
  // Props
  const { link, date } = props;

  // Handlers
 

  // Data to render
  return (
    <div className="joinEventGrid">
      <div className="eventCalBlock eventCal1 joinEventContent">
        {handleTimeDifference(date) ? (
          <span className="eventCal1Title">
            Starts:{" "}
            <span className="text-bold m-t-10px">
              {moment(date).endOf("day").fromNow()}
            </span>
          </span>
        ) : (
          <h4>Class already ended</h4>
        )}

        {/* <div class="eventCalSubBlock">
          <div class="eventBox">
            <h4 class="eventBoxTitle">{moment(date).endOf("day").fromNow()}</h4>
            <h4 class="eventBoxTitle">17</h4>
            <span class="eventBoxTimeText">Hours</span>
          </div>
          <div class="eventBox">
            <h4 class="eventBoxTitle">45</h4>
            <span class="eventBoxTimeText">Minutes</span>
          </div>
        </div> */}
        <button
          disabled={!handleTimeDifference(date)}
          className="btn btn-1 btn-sm m-t-20px"
          onClick={() => window.open(`${link}`, "_blank")}
        >
          {handleTimeDifference(date) ? "Join Event" : "Can't join"}
        </button>
      </div>
    </div>
  );
};

export default JoinEvent;
