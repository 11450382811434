import React from "react";
import { Link } from "react-router-dom";

const ContentCard = (props) => {
  const { title, children, cssClass, cssClass2, titleLink } = props;
  return (
    <div className={`card-content ${cssClass ? cssClass : ""}`}>
      {title && (
        <div className="card-content__top">
          <h4 className="head-9">{title}</h4>
          {titleLink && (
            <Link className="link link-3" to={titleLink?.link}>
                {titleLink?.label}
            </Link>
          )}
        </div>
      )}
      <div className={`card-content__content ${cssClass2}`}>{children}</div>
    </div>
  );
};

export default ContentCard;
