import React, { useEffect, useState } from "react";
import {
  useParams,
  useHistory,
  Switch,
  BrowserRouter,
  Route,
  Redirect,
} from "react-router-dom";
// import { useDispatch } from "react-redux";

import DashboardSideNav from "../common/DashboardSideNav";
import DashboardTopNav from "../common/DashboardTopNav";

// import { getCourse } from "../../storee/slices/singleCourse";
import ContentCard from "../common/ContentCard";
import CourseContentDropdown from "../common/CourseContentDropdown/Wapper";
import CenteredLayout from "../common/CenterLayout";
import DashboardMaxWidth from "../common/DashboardMaxWidth";
import FixedTop from "../common/FixedTop";

// import useSWR from "swr";
import SpinnerBox from "./../common/SpinnerBox";
import Content from "./CoursePreview/Content";
// import EmptyActivityBox from "../common/EmptyActivityBox";
// import EmptyActivityState from "../common/EmptyActivityState";
// import { fetcher } from "../../services/fetcher";
// import { log } from "../../utils/helpers";
import useSingleCourse from "../../store/singleCourse";
import useUserCourseStatus from "../../utils/Hooks/useUserCourseStatus";
// import FirstContent from "./CoursePreview/FirstContent";
// import path from "app-root-path";
// import path from "path";
// import(`${path}/node_modules/plyr-react/dist/plyr.css`);
// import "plyr-react/dist/plyr.css";

// const fetcher = (url, id) =>
//   axios.post(url, { course_id: id }).then((res) => res.data);

const AllCourseContent = () => {
  // State
  const [reRender, setReRender] = useState("");
  // const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();

  // const { data } = useSWR(
  //   "/get-all-course-curriculums-and-course-contents",
  //   (url) => fetcher(url, "post", { course_id: id })
  // );

  // let courseData, curriculumId, contentId;
  // if (data) {
  //   courseData = data?.all_course_curriculums_and_course_contents;
  //   courseData.about_instructor = data?.about_instructor;

  //   curriculumId = courseData[0].curriculum_id;
  //   contentId = courseData[0].course_contents[0].id;
  // }

  // Store
  const {
    fecthCourse,
    isLoading,
    courseData,
    curriculumId,
    contentId,
    setCourseInfo,
  } = useSingleCourse();

  // Hooks
  const [isEnrolled] = useUserCourseStatus(id);

  // Methods
  const goBack = (e) => {
    e.preventDefault();
    history.goBack();
  };

  const getCourseInfo = async () => {
    await fecthCourse(id);
  };

  const updateRender = (val) => {
    if (val) setReRender(val);
  };

  // Effects
  useEffect(() => {
    if (!isEnrolled) {
      history.goBack();
    }
    getCourseInfo();

    if ((curriculumId, contentId)) {
      setCourseInfo(curriculumId, contentId);
    }

    // dispatch(getCourse(id));
    // window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    updateRender();
  }, [reRender]);

  // Data to render
  return (
    <main className="dashboard-main">
      <DashboardSideNav />
      <main className="dashboard__main">
        <DashboardTopNav>
          <button
            onClick={(e) => goBack(e)}
            className="btn btn-3 dashboard__top-nav--back-btn"
          >
            <i className="fas fa-long-arrow-alt-left m-r-6px"></i>
            Back
          </button>
        </DashboardTopNav>
        <>
          <CenteredLayout>
            <DashboardMaxWidth>
              {isLoading ? (
                <SpinnerBox />
              ) : (
                <div className="all-course-content m-t-50px">
                  <BrowserRouter>
                    <div className="all-course-content__left">
                      <FixedTop cssClass="top-74px">
                        <ContentCard title="Course Content">
                          <CourseContentDropdown
                            courseContents={courseData || []}
                          />
                        </ContentCard>
                      </FixedTop>
                    </div>
                    <Switch>
                      {/* The course content */}
                      <Route path="/user-course/:id/contents/:curriculumId/:contentId">
                        <Content
                          updateRender={updateRender}
                          contents={courseData || []}
                        />
                      </Route>
                      <Route path="/user-course/:id/contents/" exact>
                        {curriculumId && contentId && (
                          <Redirect
                            to={`/user-course/${id}/contents/${curriculumId}/${contentId}`}
                          />
                        )}
                      </Route>
                    </Switch>
                  </BrowserRouter>
                </div>
              )}
            </DashboardMaxWidth>
          </CenteredLayout>
        </>
      </main>
    </main>
  );
};

export default AllCourseContent;
