import React from "react";
// import EnrolledStudent from "./EnrolledStudent";

const ProgressBarWithUser = ({
  classPercentage,
  enrolmentCount,
  classSize,
  className,
}) => {
  return (
    <>
      <div className={`progress-bar-with-user ${className ? className : ""}`}>
        <div
          className="progress-bar-with-user__status"
          style={{ width: `${classPercentage || 0}%` }}
        ></div>
      </div>
      <div className="progress-bar-with-user__bottom">
        <span className="progress-bar-with-user__percent">
          {`${enrolmentCount} 0f ${classSize ? classSize : "--"} enrolled`}
        </span>

        {/* {enrolmentCount > 0 && <EnrolledStudent size={"sm"} />} */}
      </div>
    </>
  );
};

export default ProgressBarWithUser;
