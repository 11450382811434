import React from "react";
import StudentSettings from "./StudentSettings";
import InstructorSettings from "./InstructorSettings";

const MainSettings = (props) => {
  const { userType } = props;

  return userType && userType === "1" ? (
    <StudentSettings />
  ) : (
    <InstructorSettings />
  );
};

export default MainSettings;
