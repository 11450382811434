import React from "react";
import "react-quill/dist/quill.snow.css";

import Form from "./../Forms/Form";
import Joi from "joi";
import ContentCard from "../ContentCard";

import ModalLayout from "./../ModalLayout";
import ModalChild from "./../ModalChild";
import { images } from "./../../../utils/images";
import EmptyActivityBox from "./../EmptyActivityBox";
import EmptyActivityState from "./../EmptyActivityState";
import { connect } from "react-redux";
import {
  addCourseCurriculum,
  deleteCourseCurriculum,
  updateCourseCurriculum,
} from "../../../storee/slices/createCourse";
import { addANotification } from "../../../storee/slices/notification";
import ContentCardWithActions from "../ContentCardWithActions";
import ConfirmActionModal from "../Modals/ConfirmActionModal";
import Notiflix from "notiflix";

class Curriculum extends Form {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        topic: "",
        description: "",
      },
      error: {
        topic: "",
        description: "",
      },
      weekId: 1,
      isLoading: false,
      showModal: false,
      showDeleteModal: false,
      isEditing: false,
    };
  }

  schema = {
    topic: Joi.string().required().label("Topic"),
    description: Joi.string().required().label("Description"),
  };

  doSubmit = async () => {
    const { isEditing, editingWeekId, editingCurriculumId } = this.state;
    const data = {
      course_id: this.props.courseId,
      topic: this.state.data.topic,
      description: this.state.data.description,
      week_number: isEditing ? editingWeekId : this.props.weekNumber,
    };

    // Check if course has been created
    if (!this.props.courseId) {
      Notiflix.Notify.failure(
        "Error while creating curriculum, Please fill the About this course form first"
      );
      return;
    }

    // || this.props.isEditingCourseId

    // Post to database
    if (isEditing) {
      delete data.course_id;
      data.curriculum_id = editingCurriculumId;
      await this.props.updateCurriculum(data);
    } else {
      await this.props.saveCurriculum(data);
    }

    // await this.props.addCurriculumToStore(data);

    Notiflix.Notify.success(
      `Week ${
        isEditing ? editingWeekId : this.props.weekNumber - 1
      } curriculum ${isEditing ? "updated" : "created"} successfully`
    );

    // reset Data
    if (!isEditing) this.resetData();
  };

  resetData = () => {
    this.setState({
      data: {
        topic: "",
        description: "",
      },
      error: {
        topic: "",
        description: "",
      },
    });
  };

  handleActionModalOpen = (id) => {
    this.setState({
      showDeleteModal: true,
      deletingCurriculumId: id,
    });
  };

  handleModalOpen = () => {
    this.setState({
      showModal: true,
    });
  };

  handleModalClose = () => {
    this.setState(
      {
        showModal: false,
        isEditing: false,
        showDeleteModal: false,
      },
      () => this.resetData()
    );
  };

  handleCurriculumEdit = async (id) => {
    this.setState({ isEditing: true });
    const curriculum = this.props?.curriculums.filter(
      (curr) => curr.curriculum_id === id
    );

    await this.setState(
      {
        data: {
          topic: curriculum[0].topic,
          description: curriculum[0].description,
        },
        editingWeekId: curriculum[0].week_number,
        editingCurriculumId: curriculum[0].curriculum_id,
      },
      this.setState({ showModal: true })
    );
  };

  handleCurriculumDelete = async () => {
    const { deletingCurriculumId } = this.state;
    await this.props.deleteCurriculum({ curriculum_id: deletingCurriculumId });
    this.props.notifyActions({
      type: "ok",
      title: "Success",
      description: `Curriculum deleted successfully`,
    });
    this.setState({ showDeleteModal: false });
  };

  render() {
    const { curriculums, isLoadingCurriculum, isDeleting } = this.props;
    const { showModal, showDeleteModal } = this.state;

    return (
      <>
        <div className="about-this-course animate-slideFromLeft">
          <h3 className="head-5 m-b-10px">Curriculum</h3>
          {curriculums.length ? (
            curriculums.map((data, index) => (
              <ContentCardWithActions
                key={index}
                data={data}
                handleEdit={this.handleCurriculumEdit}
                handleDelete={this.handleActionModalOpen}
              />
            ))
          ) : (
            <EmptyActivityBox cssClass="p-y-50px">
              <EmptyActivityState
                title="No Curriculum"
                handleClick={this.handleModalOpen}
                btnText={"Add Curriculum"}
              />
            </EmptyActivityBox>
          )}

          {curriculums.length > 0 && curriculums.length < 8 && (
            <button
              className="btn btn-1 btn-md m-t-30px"
              onClick={() => this.handleModalOpen()}
            >
              Add Curriculum
            </button>
          )}
        </div>

        {showModal && (
          <ModalLayout>
            <ModalChild>
              <ContentCard
                title={`Week ${
                  this.state.isEditing
                    ? this.state.editingWeekId
                    : this.props.weekNumber
                }`}
              >
                <button
                  className="btn-close-modals"
                  onClick={() => this.handleModalClose()}
                >
                  <img src={images.pic9} alt="times" />
                </button>

                <form onSubmit={this.handleSubmit} id="curriculumForm">
                  <ContentCard>
                    {this.renderInput("Topic", "topic", "text", "noIcon", null)}
                    {this.renderTextArea("description", 5, null, "Description")}

                    <button
                      className="btn btn-5 m-t-25px"
                      form="curriculumForm"
                    >
                      {isLoadingCurriculum ? (
                        <i className="fas fa-spinner animate-rotate"></i>
                      ) : (
                        "Save Curriculum"
                      )}
                    </button>
                  </ContentCard>

                  {/* {this.renderButton("Save")} */}
                </form>
              </ContentCard>
            </ModalChild>
          </ModalLayout>
        )}

        {showDeleteModal && (
          <ConfirmActionModal
            setShowModal={this.handleModalClose}
            handleYesAction={this.handleCurriculumDelete}
            title="Are you sure you wan to delete curriculum ?"
            isLoading={isDeleting}
          />
        )}
      </>
    );
  }
}

// Store
const mapStateToProps = (state) => ({
  isLoadingCurriculum: state.entities.createCourse.curriculum.isLoading,
  curriculums: state.entities.createCourse.curriculum.curriculums,
  isDeleting: state.entities.createCourse.curriculum.isDeleting,
  courseId: state.entities.createCourse.courseId,
  weekNumber: state.entities.createCourse.curriculum.weekNumber,
});

const mapDispatchToProps = (dispatch) => ({
  saveCurriculum: (data) => dispatch(addCourseCurriculum(data)),
  notifyActions: (data) => dispatch(addANotification(data)),
  updateCurriculum: (data) => dispatch(updateCourseCurriculum(data)),
  deleteCurriculum: (data) => dispatch(deleteCourseCurriculum(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Curriculum);
