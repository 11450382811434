import React from "react";

const ProgressBarSingle = (props) => {
  // Props
  const {percentage} = props;

  // Data to render
  return (
    <>
      <div className="progress-bar">
        <div
          className="progress-bar__status"
          style={{ width: `${percentage}%` }}
        ></div>
      </div>
    </>
  );
};

export default ProgressBarSingle;
