import React from "react";

const ProgressBar = ({ classPercentage }) => {
  return (
    <>
      <div className="progress-bar">
        <div
          className="progress-bar__status"
          style={{ width: `${Math.floor(classPercentage) || 0}%` }}
        ></div>
      </div>
      <span className="progress-bar__percent">
        {`${Math.floor(classPercentage)}% Booked`}
      </span>
    </>
  );
};

export default ProgressBar;
