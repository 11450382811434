import React, { Component } from "react";
import Joi from "joi";
import Input from "../Input/Input";
import TextArea from "../Input/TextArea";
import Select from "./../Input/Select";
import Radio from "./../Input/Radio1";
import Spinner from "../Spinner";
import CustomFile from "../Input/CustomFile";
// import Checkbox from "../Input/Checkbox";

class Form extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.renderInput = this.renderInput.bind(this);
    this.renderButton = this.renderButton.bind(this);
    this.thumbnailRef = React.createRef();
  }

  // Validate the full form data
  validate = () => {
    const schema = this.schema;
    const data = this.state.data;
    const schemaData = Joi.object({ ...schema });

    const { error } = schemaData.validate(data, { abortEarly: false });

    if (error) {
      this.setErrors(error);
      return error;
    }
  };

  // validate a single input onChange
  validateInput = ({ name, value, id }) => {
    const data = { [name || id]: value };
    const schemaObj = { [name || id]: this.schema[name || id] };
    const schema = Joi.object(schemaObj);
    const { error } = schema.validate(data);

    if (error) {
      this.setErrors(error);
      return error;
    }
  };

  //   Set errors
  setErrors = (error) => {
    const dataError = this.state.error;
    for (let err of error.details) {
      const { path, message } = err;
      dataError[path[0]] = message;
    }

    this.setState({ error: dataError });
  };

  //   Handle change
  handleChange = ({ currentTarget: input }) => {
    const { data, error } = this.state;
    const errorCheck = this.validateInput(input);
    if (!errorCheck) error[input.name || input.id] = "";

    if (input.files) {
      data[input.name || input.id] = input.files[0];
    } else {
      data[input.name || input.id] = input.value;
    }
    this.setState({ data, error });
  };

  handleCourseThumbnail = ()=>{
    
  }

  //   Handle submit
  handleSubmit = (e) => {
    e.preventDefault();

    const error = this.validate();
    if (error) return;
    this.doSubmit();
  };

  // Render input
  renderInput = (
    label,
    name,
    type = "text",
    noicon,
    placeholder,
    labelIcon,
    id
  ) => {
    const { data, error } = this.state;

    return (
      <Input
        label={label}
        onChange={this.handleChange}
        value={data[name]}
        name={name}
        error={error[name]}
        type={type}
        noicon={noicon}
        placeholder={placeholder}
        labelIcon={labelIcon}
        id={id}
      />
    );
  };
  // Render select
  renderSelect = (label, name, options, optionId, optionName, placeholder) => {
    const { data, error } = this.state;

    return (
      <Select
        label={label}
        name={name}
        value={data[name]}
        options={options}
        optionId={optionId}
        optionName={optionName}
        placeholder={placeholder}
        error={error[name]}
        onChange={this.handleChange}
      />
    );
  };

  // Render file input
  renderFile = (label, name, type = "file", noicon) => {
    const { data, error } = this.state;

    return (
      <Input
        label={label}
        onChange={this.handleChange}
        value={data[name]}
        name={name}
        error={error[name]}
        type={type}
        noicon={noicon}
      />
    );
  };

  // Render custom input
  renderCustomFile = (label, name, type = "file", noicon) => {
    const { data, error } = this.state;

    return (
      <CustomFile
        label={label}
        onChange={this.handleChange}
        // value={data[name] ? data[name] : ""}
        editName={data[name] || ""}
        name={name}
        error={error[name]}
        type={type}
        noicon={noicon}
        ref={this.thumbnailRef}
      />
    );
  };

  renderRadio = (label, name, value, itemKey, selectedValue) => {
    const { data, error } = this.state;

    return (
      <Radio
        label={label}
        onChange={this.handleChange}
        values={data[name]}
        value={value}
        key={itemKey}
        name={name}
        error={error[name]}
        checked={selectedValue === value}
      />
    );
  };

  // renderCheckbox = (label, name) => {
  //   const { data, error } = this.state;

  //   return (
  //     <Checkbox
  //       label={label}
  //       onChange={this.handleChange}
  //       // checked={data[name]}
  //       value={data[name]}
  //       name={name}
  //       error={error[name]}
  //     />
  //   );
  // };

  // Render text area
  renderTextArea = (name, rows, placeholder, label) => {
    const { data, error } = this.state;

    return (
      <TextArea
        name={name}
        onChange={this.handleChange}
        value={data[name]}
        error={error[name]}
        rows={rows}
        placeholder={placeholder}
        label={label}
      />
    );
  };

  // Render Button
  renderButton = (label, isLoading, cssClass) => {
    return (
      <button
        type="submit"
        className={`btn btn-1 btn--full ${cssClass ? cssClass : ""}`}
      >
        {isLoading ? <Spinner color={"color"} /> : label}
      </button>
    );
  };

  renderTextEditor = () => {};
}

export default Form;
