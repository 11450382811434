import React from "react";
import { Link, useLocation } from "react-router-dom";
import { formatCurrency } from "../../utils/helpers";

import { images } from "../../utils/images";
import ProgressBar from "./ProgressBar";

const CourseWithPrice = (props) => {
  // Hooks
  const location = useLocation();

  // Props
  const { course } = props;

  // Data to render
  return (
    <div className="course-with-price">
      <div className="course-with-price__thumbnail">
        <img
          src={course.thumbnail_file_url || images.pic19}
          alt=""
          className="course-with-price__thumbnail-img"
        />
      </div>
      <p className="para-4 m-y-10px">{course?.instructor_name || "--"}</p>
      <h5
        className="head-19 m-b-20px text-truncate width-100-percent"
        title={course?.course_name}
      >
        {course?.course_name}
      </h5>

      <ProgressBar classPercentage={course?.percentage_booked || 0} />

      <h4 className="head-19 m-t-20px">{`$ ${
        course?.price ? formatCurrency(course?.price) : "0.00"
      }`}</h4>

      <Link
        to={{
          pathname: `/course/${course.id}`,
          state: { fromUrl: location.pathname },
        }}
        className="link-decoration-none"
      >
        <button className="btn btn-6 m-t-20px">Enrol now</button>
      </Link>
    </div>
  );
};

export default CourseWithPrice;
