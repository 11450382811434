import Form from "./../Forms/Form";
import Joi from "joi";
import ContentCard from "../ContentCard";
import GridView from "../GridView";
import { connect } from "react-redux";
import { updatePassword } from "./../../../storee/slices/instructor";

class Password extends Form {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        currentPassword: "",
        newPassword: "",
        confirmNewPassword: "",
      },
      error: {
        currentPassword: "",
        newPassword: "",
        confirmNewPassword: "",
      },
    };
  }

  schema = {
    currentPassword: Joi.string().required().label("Current Password"),
    newPassword: Joi.string().required().label("New Password"),
    confirmNewPassword: Joi.string().required().label("New Password"),
  };

  doSubmit = () => {
    const { currentPassword, newPassword, confirmNewPassword } =
      this.state.data;

    if (newPassword !== confirmNewPassword) {
      this.setState({
        error: {
          ...this.state.error,
          confirmNewPassword: "Password do not match",
        },
      });
      return;
    }

    const passwordData = {
      current_password: currentPassword,
      new_password: newPassword,
    };

    this.props.updatePassword(passwordData);
  };

  render() {
    const { isLoading } = this.props;
    return (
      <div className="animate-slideFromLeft">
        <h3 className="head-5 m-b-10px">Password &amp; Security</h3>
        <form onSubmit={this.handleSubmit}>
          <ContentCard>
            <GridView grid={2}>
              {this.renderInput(
                "Current Password",
                "currentPassword",
                "password",
                "noicon",
                null
              )}
            </GridView>
            <div className="m-b-20px"></div>
            {this.renderInput(
              "New Password",
              "newPassword",
              "password",
              "noicon",
              null
            )}
            {this.renderInput(
              "Confirm New Password",
              "confirmNewPassword",
              "password",
              "noicon",
              null
            )}
            {/* <GridView grid={2}>
              {this.renderInput(
                "New Password",
                "newPassword",
                "password",
                "noicon",
                null
              )}
              {this.renderInput(
                "Confirm New Password",
                "confirmNewPassword",
                "password",
                "noicon",
                null
              )}
            </GridView> */}

            {this.renderButton(
              "Save Changes",
              isLoading,
              "btn btn-5 m-t-25px m-l-auto"
            )}
            {/* <button type="submit" className="btn btn-5 m-t-25px m-l-auto">
              Save Changes
            </button> */}
          </ContentCard>
        </form>
      </div>
    );
  }
}

// Store
const mapStateToProps = (state) => ({
  isLoading: state.entities.instructor.passwordChange.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  updatePassword: (data) => dispatch(updatePassword(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Password);
