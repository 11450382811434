import React from "react";

const ExitIcon = ({ width, height, cssClass, pathCssClass }) => {
  return (
    <svg
      width={width || "16"}
      height={height || "16"}
      className={`${cssClass ? cssClass : ""}`}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={pathCssClass}
        d="M16 1.77778L16 14.2222C16 15.2041 15.2041 16 14.2222 16L10.6667 16L10.6667 14.2222L14.2222 14.2222L14.2222 1.77778L1.77778 1.77778L1.77778 14.2222L5.33333 14.2222L5.33333 16L1.77778 16C0.795938 16 -3.47916e-08 15.2041 -7.77091e-08 14.2222L-6.21673e-07 1.77778C-6.64591e-07 0.795938 0.795937 -3.47916e-08 1.77778 -7.77091e-08L14.2222 -6.21673e-07C15.2041 -6.64591e-07 16 0.795938 16 1.77778ZM11.5556 8.88889L8.88889 8.88889L8.88889 16L7.11111 16L7.11111 8.88889L4.44444 8.88889L8 4.44444L11.5556 8.88889Z"
        fill="#5C4FFF"
      />
    </svg>
  );
};

export default ExitIcon;
