import React from "react";
import Form from "./../common/Forms/Form";

import DashboardSideNav from "./../common/DashboardSideNav";
import DashboardTopNav from "./../common/DashboardTopNav";
import Activities from "./../common/Activities";
import EmptyActivityBox from "./../common/EmptyActivityBox";
import EmptyActivityState from "./../common/EmptyActivityState";
import AboutThisCourseForm from "./../common/CreateCourse/AboutThisCourse";
import CourseContent from "./../common/CreateCourse/CourseContent";
import Price from "./../common/CreateCourse/Price";
import LiveClass from "./../common/CreateCourse/LIveClass";
import { CreateCourseProvider } from "../../utils/contexts/createCourseContext";
import CenteredLayout from "../common/CenterLayout";
import DashboardMaxWidth from "../common/DashboardMaxWidth";
import WhoIsThisCourseFor from "../common/CreateCourse/WhoIsThisCourseFor";
// import UserTick from "../common/SvgIcons/UserTick";
// import IconLiveClass from "../common/SvgIcons/IconLiveClass";
import Curriculum from "../common/CreateCourse/Curriculum";
import ConfirmActionModal from "../common/Modals/ConfirmActionModal";
import { connect } from "react-redux";
import { resetData, updateCourses } from "../../storee/slices/createCourse";
import { getCourseData } from "./../../storee/slices/createCourse";
import withCourseCreation from "../../hoc/withCourseCreation";
import {
  getCourseCategories,
  getLevelOfCompetence,
} from "../../storee/slices/helpers";
import AboutCourseIcon from "../common/SvgIcons/AboutCourseIcon";
import CurriculumIcon from "../common/SvgIcons/CurriculumIcon";
import CourseContentIcon from "../common/SvgIcons/CourseContentIcon";
import WhoCourseForIcon from "../common/SvgIcons/WhoCourseForIcon";
import LiveClassesIcon from "../common/SvgIcons/LiveClassesIcon";
import PriceIcon from "../common/SvgIcons/PriceIcon";

class CreateCourse extends Form {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: -1,
      newCourseId: null,
      isLoading: false,
      currentFormIsValid: false,
      courseCompleted: false,
      courseEditId: "",
    };
  }

  async componentDidMount() {
    const { id: courseEditId } = this.props.match.params;
    if (courseEditId) {
      this.setState(
        { courseEditId },
        async () =>
          await this.props.getCourseDataForEdit({ course_id: courseEditId })
      );
      // this.setState({ activeTab: 0 });
    } else {
      this.props.resetData();
    }

    await this.props.getLevelOfCompetence();
    await this.props.getAllCourseCategories();
  }

  goBack = (e) => {
    e.preventDefault();
    this.props.history.goBack();
  };

  getNewCourseId = () => {
    const { newCourseId } = this.state;
    return newCourseId;
  };

  setNewCourseId = (id) => {
    this.setState({ newCourseId: id });
    return this.state.newCourseId;
  };

  setIsLoading = (loadingState) => {
    this.setState({ isLoading: loadingState });
  };

  handleActionClicked = (id) => this.setState({ activeTab: id });

  setActiveTab = (tab) => {
    this.setState({ activeTab: tab });
  };

  goToFirstForm = () => {
    let { activeTab } = this.state;

    if (activeTab === 0 || activeTab === 4) {
      const currentForm = document.getElementById("course-submit-btn");
      currentForm.setAttribute("form", this.createCourseFormIds[activeTab]);

      currentForm.click();
      return;
    } else {
      const currentForm = document.getElementById("course-submit-btn");
      currentForm.removeAttribute("form");
    }

    if (activeTab === 5) {
      this.setState({ courseCompleted: true });
      return;
    }

    this.setState(({ activeTab }) => ({ activeTab: activeTab + 1 }));
  };

  handleConfirmClose = () => {
    this.setState({ courseCompleted: false });
  };

  goToThirdForm = () => {
    // let { activeTab } = this.state;
    // if (activeTab >= 0) return;
    this.setState(({ activeTab }, props) => ({ activeTab: 2 }));
    return;
  };
  handleContinueStep = () => {
    // let { activeTab } = this.state;
    // if (activeTab >= this.createCourseForms.length - 1) return;
    // this.setState(({ activeTab }) => ({ activeTab: activeTab + 1 }));
  };
  handleBackStep = () => {
    let activeTab = this.state.activeTab;
    if (activeTab === 0) return;
    this.setState({ activeTab: activeTab - 1 });
  };

  createCourseActions = [
    {
      iconClass: "activities__icon",
      // iconId: "icon-info-circle",
      icon: <AboutCourseIcon />,
      iconType: "stroke",
      name: "About this course",
      link: "/create-course/about",
      handleActionClicked: this.handleActionClicked,
    },
    {
      iconClass: "activities__icon",
      // iconId: "icon-edit",
      icon: <CurriculumIcon />,
      iconType: "",
      name: "Curriculum",
      link: "/create-course/content",
      handleActionClicked: this.handleActionClicked,
    },
    {
      iconClass: "activities__icon",
      // iconId: "icon-edit",
      icon: <CourseContentIcon />,
      iconType: "",
      name: "Course Content",
      link: "/create-course/content",
      handleActionClicked: this.handleActionClicked,
    },
    {
      iconClass: "activities__icon",
      icon: <WhoCourseForIcon />,
      iconType: "stroke",
      name: "Who is this course for",
      link: "/create-course/course-user",
      handleActionClicked: this.handleActionClicked,
    },
    {
      iconClass: "activities__icon",
      iconId: "icon-tag",
      icon: <PriceIcon />,
      iconType: "stroke",
      name: "Price",
      link: "/create-course/price",
      handleActionClicked: this.handleActionClicked,
    },
    {
      iconClass: "activities__icon-2",
      icon: <LiveClassesIcon />,
      iconType: "",
      name: "Live Classes",
      link: "/create-course/live-class",
      handleActionClicked: this.handleActionClicked,
    },
    // {
    //   iconClass: "activities__icon",
    //   iconId: "icon-time-circle",
    //   name: "Office Hours",
    //   link: "",
    //   handleActionClicked: this.handleActionClicked,
    // },
  ];

  createCourseForms = [
    <AboutThisCourseForm
      key={0}
      setCourseId={(id) => this.setNewCourseId(id)}
      // nextStep={() => this.handleContinueStep()}
      setIsLoading={(state) => this.setIsLoading(state)}
      setActiveTab={this.setActiveTab}
    />,
    <Curriculum
      key={1}
      getCourseId={() => this.getNewCourseId()}
      setIsLoading={(state) => this.setIsLoading(state)}
    />,
    <CourseContent
      getCourseId={() => this.getNewCourseId()}
      setIsLoading={(state) => this.setIsLoading(state)}
      key={2}
    />,
    <WhoIsThisCourseFor
      key={3}
      getCourseId={() => this.getNewCourseId()}
      setIsLoading={(state) => this.setIsLoading(state)}
    />,
    <Price
      key={4}
      getCourseId={() => this.getNewCourseId()}
      // nextStep={() => this.handleContinueStep()}
      setIsLoading={(state) => this.setIsLoading(state)}
      setActiveTab={this.setActiveTab}
    />,
    <LiveClass
      key={5}
      getCourseId={() => this.getNewCourseId()}
      nextStep={() => this.handleContinueStep()}
      setIsLoading={(state) => this.setIsLoading(state)}
    />,
    // <h2 key={6} id="OfficeHoursForm">
    //   Office Hours
    // </h2>,
  ];

  createCourseFormIds = [
    "aboutCourseForm",
    "curriculum",
    "courseContentForm",
    "WhoIsThisCourseFor",
    "coursePriceForm",
    "courseLiveClassesForm",
    // "OfficeHoursForm",
  ];

  handleNo = () => {
    this.props.history.push("/courses");
  };

  handleYes = async () => {
    // const data = {
    //   course_id: this.props.courseId,
    //   is_published: 1,
    // };
    // await this.props.updateCourse(data);
    this.props.history.push("/course-created");
  };

  render() {
    const { activeTab, isLoading, courseCompleted, courseEditId } = this.state;
    const { isPublishing } = this.props;
    return (
      <>
        <main className="dashboard-main">
          <DashboardSideNav />
          {/* <div className="create-course__layout"> */}
          {/* <div className="create-course__top"> */}
          <main className="dashboard__main">
            <DashboardTopNav>
              <button onClick={(e) => this.goBack(e)} className="btn btn-3">
                <i className="fas fa-long-arrow-alt-left m-r-6px"></i>
                Back
              </button>
            </DashboardTopNav>

            <CenteredLayout>
              <DashboardMaxWidth>
                <div className="course-title">
                  <h2 className="head-8">
                    {" "}
                    {courseEditId ? "Edit Course" : "Create Course"}
                  </h2>
                  <p className="para-8 m-t-8px">
                    Carefully fill the form below to{" "}
                    {courseEditId ? "edit" : "create"} a course
                  </p>
                </div>
                <CreateCourseProvider value={this.setNewCourseId}>
                  <Activities
                    activeTab={activeTab}
                    activities={this.createCourseActions}
                  >
                    {activeTab !== -1 ? (
                      this.createCourseForms.map((courseForm, id) =>
                        id === activeTab ? courseForm : null
                      )
                    ) : (
                      <EmptyActivityBox cssClass="p-y-100px">
                        <EmptyActivityState />
                      </EmptyActivityBox>
                    )}
                  </Activities>
                </CreateCourseProvider>
              </DashboardMaxWidth>
            </CenteredLayout>
          </main>
          {/* </div> */}
          <div className="create-course__bottom">
            {activeTab > 0 ? (
              <button
                className="btn btn__onboard-nav mq-phone"
                onClick={() => this.handleBackStep()}
              >
                Back
              </button>
            ) : (
              <span></span>
            )}

            <button
              id="course-submit-btn"
              type="submit"
              className="btn btn-1 fixed-width display-none"
              // form={this.createCourseFormIds[activeTab]}
              // disabled={activeTab >= 0 && !this.state.currentFormIsValid}
            >
              {isLoading ? (
                <i className="fas fa-spinner animate-rotate"></i>
              ) : activeTab === -1 ? (
                "Create course"
              ) : (
                "Save and Continue"
              )}
            </button>
            <button
              // id="course-submit-btn"
              disabled={isLoading}
              type="submit"
              className="btn btn-1 fixed-width"
              // form={this.createCourseFormIds[activeTab]}
              onClick={() => this.goToFirstForm()}
              // disabled={activeTab >= 0 && !this.state.currentFormIsValid}
            >
              {isLoading ? (
                <i className="fas fa-spinner animate-rotate"></i>
              ) : activeTab === -1 ? (
                `${courseEditId ? "Edit" : "Creat"} course`
              ) : (
                "Save and Continue"
              )}
            </button>
          </div>
          {/* </div> */}
        </main>

        {courseCompleted && (
          <ConfirmActionModal
            setShowModal={this.handleConfirmClose}
            handleNoAction={this.handleNo}
            handleYesAction={this.handleYes}
            title="Do you want to create your course now?"
            type="warn"
            noBtnText={"No"}
            yesBtnText={"Create course"}
            isLoading={isPublishing}
          />
        )}
      </>
    );
  }
}

// Store
const mapStateToProps = (state) => ({
  isPublishing: state.entities.createCourse.publishCourse.isLoading,
  courseId: state.entities.createCourse.courseId,
});

const mapDispatchToProps = (dispatch) => ({
  updateCourse: (data) => dispatch(updateCourses(data)),
  getCourseDataForEdit: (data) => dispatch(getCourseData(data)),
  resetData: (data) => dispatch(resetData(data)),
  getLevelOfCompetence: () => dispatch(getLevelOfCompetence()),
  getAllCourseCategories: () => dispatch(getCourseCategories()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withCourseCreation(CreateCourse));
