import React from "react";
import EmptyActivityBox from "./EmptyActivityBox";
import EmptyActivityState from "./EmptyActivityState";
import Review from "./Review";

const CourseReviewByUser = (props) => {
  // const {
  //   reviews: [],
  // } = props;
  return (
    <>
      {![].length ? (
        <EmptyActivityBox cssClass="p-y-50px">
          <EmptyActivityState
            title="No Feedback"
            subTitle="No feedback for this course "
          />
        </EmptyActivityBox>
      ) : (
        [1, 2].map((review, index) => <Review key={index} />)
      )}
    </>
  );
};

export default CourseReviewByUser;
