import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "./../actions/api";

const course = createSlice({
  name: "course",
  initialState: {
    data: {},
    error: {},
    fetchedCourses: [],
    isLoading: false,

    courseById: {
      data: {},
      error: {},
      fetchedCourses: [],
      isLoading: false,
    },

    coursePublished: {
      data: {},
      error: {},
      isLoading: false,
    },

    publishCourse: {
      data: {},
      error: {},
      isLoading: false,
      hasError: false,
    },

    promoVideo: {
      data: {},
      error: {},
      isLoading: false,
    },

    deleteVideo: {
      data: {},
      error: {},
      isLoading: false,
    },
  },
  reducers: {
    fetchingCourse: (course, action) => {
      course.isLoading = true;
      course.data = { }
    },
    fetchingCourseFailed: (course, action) => {
      course.error = action.payload;
      course.isLoading = false;
    },
    fetchingCourseSuccess: (course, action) => {
      course.data = { ...action.payload };
      course.fetchedCourses.push({ ...action.payload });
      course.isLoading = false;
    },

    // Get Course By category
    fetchingCourseById: (course, action) => {
      course.courseById.isLoading = true;
    },
    fetchingCourseByIdFail: (course, action) => {
      course.courseById.isLoading = false;
      course.courseById.error = action?.payload;
    },
    fetchingCourseByIdSuccess: (course, action) => {
      course.courseById.isLoading = false;
      const coursecourse = action.payload.course;
      delete action.payload.message;
      delete action.payload.course;
      course.courseById.data = { ...coursecourse, ...action.payload };
    },

    // Publish Course By button toggle
    publishingCourse: (course, action) => {
      course.publishCourse.hasError = false;
      course.publishCourse.isLoading = true;
    },
    publishingCourseFail: (course, action) => {
      course.publishCourse.error = action?.payload;
      course.publishCourse.hasError = true;
      course.publishCourse.isLoading = false;
    },
    publishingCourseSuccess: (course, action) => {
      course.publishCourse.data = { ...action.payload };
      course.publishCourse.error = {};
      course.publishCourse.hasError = false;
      course.publishCourse.isLoading = false;
    },

    // Upload course promo video
    uploadingingPromoVideo: (course, action) => {
      course.promoVideo.isLoading = true;
    },
    uploadingingPromoVideoFailed: (course, action) => {
      course.promoVideo.error = action.payload;
      course.promoVideo.isLoading = false;
    },
    uploadingingPromoVideoSuccess: (course, action) => {
      // course.promoVideo.data = { ...action.payload };
      // course.promoVideo.fetchedCourses.push({ ...action.payload });
      course.promoVideo.isLoading = false;
    },

    // Upload course promo video
    deletingCourse: (course, action) => {
      course.deleteVideo.isLoading = true;
    },
    deletingCourseFailed: (course, action) => {
      course.deleteVideo.error = action.payload;
      course.deleteVideo.isLoading = false;
    },
    deletingCourseSuccess: (course, action) => {
      course.deleteVideo.isLoading = false;
    },

    // Edit course live class
    editingCourse: (course, action) => {
      course.deleteVideo.isLoading = true;
    },
    editingCourseFailed: (course, action) => {
      course.deleteVideo.error = action.payload;
      course.deleteVideo.isLoading = false;
    },
    editingCourseSuccess: (course, action) => {
      course.deleteVideo.isLoading = false;
    },
  },
});

// Action Types
const {
  fetchingCourse,
  fetchingCourseFailed,
  fetchingCourseSuccess,
  fetchingCourseById,
  fetchingCourseByIdFail,
  fetchingCourseByIdSuccess,
  publishingCourse,
  publishingCourseFail,
  publishingCourseSuccess,
  uploadingingPromoVideo,
  uploadingingPromoVideoFailed,
  uploadingingPromoVideoSuccess,
  deletingCourse,
  deletingCourseFailed,
  deletingCourseSuccess,
  editingCourse,
  editingCourseFailed,
  editingCourseSuccess,
} = course.actions;

//   Action Creators
const service = "courseServices";
export const getCourse = (id) =>
  apiCallBegan({
    service,
    endpoint: "getCourseProfile",
    data: id,
    onStart: fetchingCourse.type,
    onSuccess: fetchingCourseSuccess.type,
    onError: fetchingCourseFailed.type,
  });

export const getCoursesById = (courseId) =>
  apiCallBegan({
    service,
    endpoint: "getCourseById",
    data: courseId,
    onStart: fetchingCourseById.type,
    onSuccess: fetchingCourseByIdSuccess.type,
    onError: fetchingCourseByIdFail.type,
  });

export const publishCourse = (courseId) =>
  apiCallBegan({
    service,
    endpoint: "publishCourse",
    data: courseId,
    onStart: publishingCourse.type,
    onSuccess: publishingCourseSuccess.type,
    onError: publishingCourseFail.type,
  });

export const uploadPromoVideo = (data) =>
  apiCallBegan({
    service,
    endpoint: "uploadPromoVideo",
    data,
    onStart: uploadingingPromoVideo.type,
    onSuccess: uploadingingPromoVideoSuccess.type,
    onError: uploadingingPromoVideoFailed.type,
  });

export const deleteCourse = (data) =>
  apiCallBegan({
    service,
    endpoint: "deleteCourse",
    data,
    onStart: deletingCourse.type,
    onSuccess: deletingCourseSuccess.type,
    onError: deletingCourseFailed.type,
  });
export const editSingleCourseLiveClass = (data) =>
  apiCallBegan({
    service,
    endpoint: "deleteCourse",
    data,
    onStart: editingCourse.type,
    onSuccess: editingCourseSuccess.type,
    onError: editingCourseFailed.type,
  });
// *********************** End of Action Creators *****************************

export default course.reducer;
