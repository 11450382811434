import React from "react";
import { Link } from "react-router-dom";
import Svg from "./Svg";
import PlusCircle from "./SvgIcons/PlusCircle";

const CourseContentToggler = ({
  handleClick,
  contentName,
  handleModalOpen,
  estimatedTime,
  id,
}) => {
  return (
    <button
      className="course-contents__toggler"
      onClick={() => handleClick()}
      title={contentName || "Course Content 1"}
    >
      <Svg cssClassName="dropdown-toggler" iconId="icon-toggle-down" />

      <h5 className="head-11 m-l-15px m-r-15px course-contents__heading text-truncate">
        {contentName || "Course Content 1"}
      </h5>

      {/* <span className="course-duration">
        {estimatedTime ? `${estimatedTime} Min` : "10 Min"}
      </span> */}
      {handleModalOpen && (
        <Link
          to="#"
          className="btn btn-naked m-l-auto"
          onClick={(e) => {
            e.stopPropagation();
            handleModalOpen(id);
          }}
        >
          <PlusCircle />
        </Link>
      )}
    </button>
  );
};

export default CourseContentToggler;
