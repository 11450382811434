import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./rootReducer";
import api from "./middleware/api";

const setStore = () => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api),
  });
};

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default setStore;
