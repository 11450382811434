import Form from "./../Forms/Form";
import Joi from "joi";
import GridView from "../GridView";
import { connect } from "react-redux";
import { getAllCountries } from "./../../../storee/slices/helpers";

class PaypalForm extends Form {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        nameOnCard: "",
        cardNumber: "",
        cardExpireMonth: "",
        cardExpireYear: "",
        cvv: "",
      },
      error: {
        nameOnCard: "",
        cardNumber: "",
        cardExpireMonth: "",
        cardExpireYear: "",
        cvv: "",
      },
      isLoading: false,
    };
  }

  schema = {
    nameOnCard: Joi.string().required().label("Name On Card"),
    cardNumber: Joi.number().required().label("Card Number"),
    cardExpireMonth: Joi.number().required().label("Month"),
    cardExpireYear: Joi.number().required().label("Year"),
    cvv: Joi.number().required().label("CVV"),
  };

  async componentDidMount() {}

  doSubmit = () => {
    // const { currentPassword, newPassword, confirmNewPassword } =
    //   this.state.data;
    // this.props.updatePassword(passwordData);
  };

  render() {
    // const { isLoading } = this.props;
    return (
      //   <div className="animate-slideFromLeft">
      <form onSubmit={this.handleSubmit}>
        {/* <ContentCard> */}
        {this.renderInput("Name on Card", "nameOnCard", "text", "noicon", null)}
        {this.renderInput("Card Number", "cardNumber", "text", "noicon", null)}

        <GridView grid={3}>
          {this.renderInput("MM", "cardExpireMonth", "text", "noicon", null)}
          {this.renderInput("YY", "cardExpireYear", "text", "noicon", null)}
          {this.renderInput("CVV", "cvv", "text", "noicon", null)}
        </GridView>

        {/* {this.renderButton(
              "Save Changes",
              isLoading,
              "btn btn-5 m-t-40px m-l-auto"
            )} */}
        {/* <button type="submit" className="btn btn-5 m-t-25px m-l-auto">
              Save Changes
            </button> */}
        {/* </ContentCard> */}
      </form>
      //   {/* </div> */}
    );
  }
}

// Store
const mapStateToProps = (state) => ({
  countryData: state.entities.helpers.countries.data,
});

const mapDispatchToProps = (dispatch) => ({
  getAllCountries: () => dispatch(getAllCountries()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaypalForm);
