import React from "react";
import useSWR from "swr";
import { fetcher } from "../../services/fetcher";
import EmptyActivityBox from "./EmptyActivityBox";
import EmptyActivityState from "./EmptyActivityState";
import FeaturedCourse from "./FeaturedCourse";
import SpinnerBox from "./SpinnerBox";

const UserWishlist = () => {
  // Props

  // Api
  const { data } = useSWR("/student/get-student-course-wishlist", (url) =>
    fetcher(url, "post")
  );

  let wishLists = [];
  if (data) {
    wishLists = data.wishlist_courses;
  }

  return (
    <>
      {!data ? (
        <SpinnerBox />
      ) : (
        <>
          {wishLists?.length ? (
            <div className="featured-courses__wrapper">
              <div className="featured-courses">
                {wishLists?.map((wishList) => (
                  <FeaturedCourse
                    key={wishList.id}
                    course={wishList}
                    wishlist
                  />
                ))}
              </div>
            </div>
          ) : (
            <EmptyActivityBox cssClass="p-y-50px">
              <EmptyActivityState
                title="No Activity"
                subTitle="You don't have any course in your wishlist"
              />
            </EmptyActivityBox>
          )}
        </>
      )}
    </>
  );
};

export default UserWishlist;
