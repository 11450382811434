import React, { useEffect } from "react";

import ContentCard from "../common/ContentCard";
import CourseExplore from "../common/CoursesExplore";
import DashboardSideNav from "../common/DashboardSideNav";
import DashboardTopNav from "../common/DashboardTopNav";
import EmptyActivityBox from "../common/EmptyActivityBox";
import EmptyActivityState from "../common/EmptyActivityState";
import StudentDashboardLoader from "../common/Loaders/StudentDashboardLoader";
import UpcomingClasses from "../common/UpcomingClasses";

import { useDispatch, useSelector } from "react-redux";
import {
  getStudentDashboardData,
  getUpcomingClasses,
} from "./../../storee/slices/student";
import { getCourseCategories } from "./../../storee/slices/helpers";
import {
  getFeaturedCourses,
  getAllCourses,
} from "./../../storee/slices/courses";
import MyLearnings from "../common/MyLearnings";
import OngoingCourse from "../common/OngoingCourse";
import FixedTop from "../common/FixedTop";
import CenteredLayout from "../common/CenterLayout";
import DashboardMaxWidth from "../common/DashboardMaxWidth";

const StudentCourses = () => {
  // Hooks
  const dispatch = useDispatch();

  // Store
  let [
    isLoadingDashboardData,
    // dashboardData,
    isLoadingUpcomingClasses,
    upcomingClasses,
  ] = useSelector((store) => [
    store.entities.student.dashboardData.isLoading,
    // store.entities.student.dashboardData.data,
    store.entities.student.upcomingClasses.isLoading,
    store.entities.student.upcomingClasses.data,
  ]);

  let [isLoadingCategoriesData] = useSelector((store) => [
    store.entities.helpers.courseCategories.isLoading,
  ]);

  let [
    isLoadingfeaturedCourses,
    // featuredCourses,
    isLaodingAllCourses,
    allCourses,
  ] = useSelector((store) => [
    store.entities.courses.featuredCourses.isLoading,
    // store.entities.courses.featuredCourses.data,
    store.entities.courses.allCourses.isLoading,
    store.entities.courses.allCourses.data,
  ]);

  // Effects
  useEffect(() => {
    dispatch(getStudentDashboardData());
    dispatch(getCourseCategories());
    dispatch(getFeaturedCourses());
    dispatch(getAllCourses());
    dispatch(getUpcomingClasses());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderPage =
    isLoadingDashboardData &&
    isLoadingCategoriesData &&
    isLoadingfeaturedCourses &&
    isLaodingAllCourses &&
    isLoadingUpcomingClasses;

    // Data to render
  return (
    <main className="dashboard-main">
      <DashboardSideNav />
      <main className="dashboard__main">
        <DashboardTopNav>
          <h3 className="head-2">Courses</h3>
        </DashboardTopNav>

        <CenteredLayout>
          <DashboardMaxWidth>
            {renderPage ? (
              <StudentDashboardLoader />
            ) : (
              <section className="student-dashboard__layout">
                <section className="student-dashboard__left">
                  <OngoingCourse />
                  <MyLearnings cssClass="m-t-50px" />
                  <CourseExplore
                    cssClass="m-t-50px"
                    courses={allCourses?.courses}
                  />
                </section>
                <aside className="student-dashboard__right">
                  <FixedTop cssClass="top-74px">
                    {/* <div className="student-dashboard__fix-to-top"> */}
                    {upcomingClasses?.all_student_live_classes?.length > 0 ? (
                      <UpcomingClasses
                        classes={upcomingClasses?.all_student_live_classes?.slice(
                          0,
                          5
                        )}
                      />
                    ) : (
                      <ContentCard
                        title="Upcoming Classess"
                        // cssClass="student-dashboard__fix-to-top"
                      >
                        <EmptyActivityBox cssClass="p-y-50px">
                          <EmptyActivityState
                            title="No upcoming classes"
                            subTitle="You do not have any upcoming classes"
                          />
                        </EmptyActivityBox>
                      </ContentCard>
                    )}
                    {/* </div> */}
                  </FixedTop>
                </aside>
              </section>
            )}
          </DashboardMaxWidth>
        </CenteredLayout>
      </main>
    </main>
  );
};

export default StudentCourses;
