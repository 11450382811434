import React from "react";
import Dashboard from "./Dasboard";
import StudentDashboard from "./StudentDashboard";

const Maindashboard = (props) => {
  const { userType } = props;

  return userType && userType === "1" ? <StudentDashboard /> : <Dashboard />;
};

export default Maindashboard;
