import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useTabs from "../../utils/Hooks/useTab";
import CourseWithPrice from "./CourseWithPrice";
// import Pagination from "./Pagination";
import TabNavigator from "./TabNavigator";
import { useDispatch, useSelector } from "react-redux";
import { getCourseCategories } from "./../../storee/slices/helpers";
// import Pagination from "./Pagination";
import InputSearch from "./InputSearch";
import Filter from "./Filter/Index";
import {
  getAllCourses,
  getAllCoursesBySearch,
  getCoursesByCategory,
} from "../../storee/slices/courses";
import EmptyActivityBox from "./EmptyActivityBox";
import EmptyActivityState from "./EmptyActivityState";
import SpinnerBox from "./SpinnerBox";
import { debounceFunc, _getQueryString } from "../../utils/helpers";

let debounceHandler = null;

const CourseExplore = (props) => {
  // States
  const [searchVal, setSearchVal] = useState("");
  const [queryObj, setQueryObj] = useState({
    level_of_competence: "",
    price: "",
    duration: "",
    page_number: 1,
  });

  // Hooks
  const [activeTabId, setTabId] = useTabs(0);
  // const [categoryId, setCategoryId] = useState(0);
  const dispatch = useDispatch();
  const { cssClass, noLink } = props;

  // Store Selectors
  let [courseCategoryList, courseCategories] = useSelector((store) => [
    store.entities.helpers.courseCategories.list,
    store.entities.helpers.courseCategories.data,
  ]);

  let [isLaodingAllCourses, allCourses] = useSelector((store) => [
    store.entities.courses.allCourses.isLoading,
    store.entities.courses.allCourses.data,
  ]);

  // Methods
  const handleSearch = (value) => {
    debounceHandler = debounceFunc(
      () => {
        setSearchVal(value);
      },
      debounceHandler,
      1000
    );
  };
  const handleQueryObjChange = (name, value) => {
    setQueryObj((prevState) => ({ ...prevState, [name]: value }));
  };

  const searchCourse = () => {
    const str = _getQueryString({ ...queryObj, search_keyword: searchVal });
    dispatch(getAllCoursesBySearch(str));
  };

  const resetFilter = () => {
    setQueryObj({
      level_of_competence: "",
      price: "",
      duration: "",
      page_number: 1,
    });
  };

  useEffect(() => {
    if (courseCategoryList.length < 1) dispatch(getCourseCategories());
    // if (!allCourses.length) dispatch(getAllCourses());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (activeTabId === 0) {
      dispatch(getAllCourses());
    } else {
      // Get tab name by activeId
      // filter by name in the cats array and get the right Id
      const tabName = courseCategoryList[activeTabId - 1];
      const tabId = courseCategories.find(
        (category) => category.name === tabName
      )?.id;
      dispatch(getCoursesByCategory(`${tabId}`));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTabId]);

  useEffect(() => {
    if (searchVal) {
      searchCourse();
    } else if (activeTabId === 0) {
      dispatch(getAllCourses());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchVal]);

  return (
    <>
      <section className={`course-explore ${cssClass}`}>
        <h4 className="head-18 m-b-5px">Explore</h4>
        <div className="course-explore__top__down m-b-15px">
          <p className="para-9">
            Find courses for your next growth in career and life See more
          </p>

          {noLink ? (
            ""
          ) : (
            <Link to="/course-list" className="link link-4">
              See more
            </Link>
          )}
        </div>
        <div className="course-explore__body m-t-30px">
          <div className="course-explore__search">
            <InputSearch
              placeholder={"Search Course"}
              cssClass=" course-explore__search-input flex-grow-1"
              getSearchValue={handleSearch}
            />
            <Filter
              inputChange={handleQueryObjChange}
              searchCourse={searchCourse}
              resetFilter={resetFilter}
            />
          </div>
          <TabNavigator
            tabTitles={["All", ...courseCategoryList]}
            withLine={true}
            activeId={activeTabId}
            handleClick={setTabId}
          />
          {isLaodingAllCourses ? (
            <SpinnerBox />
          ) : allCourses?.courses?.length ? (
            <div className="course-explore__lists">
              {allCourses?.courses?.map((course, index) => (
                <CourseWithPrice key={index} course={course} />
              ))}
            </div>
          ) : (
            <EmptyActivityBox cssClass="p-y-50px">
              <EmptyActivityState title="No Courses In this Category" />
            </EmptyActivityBox>
          )}

          {/* {isLaodingAllCourses === false && allCourses?.courses?.length > 0 ? (
            <Pagination cssClass={"m-t-60px"} />
          ) : (
            ""
          )} */}
        </div>
      </section>
    </>
  );
};

export default CourseExplore;
