import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { removeANotification } from "../../../storee/slices/notification";
// import NotificationIcon from "./NotificationIcon";

const NotificationItem = ({ type, title, description, id }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    let NotificationTimer = setTimeout(
      () => dispatch(removeANotification(id)),
      5000
    );

    return () => clearTimeout(NotificationTimer);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="alert-notification__item animate-slideFromLeftBounce">
      {/* <NotificationIcon type={type} /> */}
      <div className="alert-notification__content">
        <div>
          {title && (
            <h4
              className={`alert-notification__title m-b-5px ${
                type === "bad" ? "bad" : ""
              }`}
            >
              {title}
            </h4>
          )}
          <p
            className={`alert-notification__description m-b-10px ${
              type === "bad" ? "bad" : ""
            }`}
          >
            {description}
          </p>
        </div>
        <div>
          <button
            onClick={() => {
              dispatch(removeANotification(id));
            }}
            className={`btn alert-notification__close-btn ${
              type === "bad" ? "bad" : ""
            }`}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default NotificationItem;
