import React, { useEffect, useState } from "react";
import { useParams, useHistory, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import DashboardSideNav from "../common/DashboardSideNav";
import DashboardTopNav from "../common/DashboardTopNav";

import { getCourse } from "../../storee/slices/singleCourse";
import Payments from "./../common/Payments/Index";
// import usePayment from "../../store/payment";

const Payment = () => {
  // State
  const [selectedOption, setSelectedOption] = useState("");

  // Hooks
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();

  // Data
  const paymentOptions = [
    {
      id: "paystack",
      type: "paystack",
      label:
        "Amount will be converted and paid in USD. Bank might apply charges",
      title: "Paystack",
      name: "payment",
    },
    {
      id: "paypal",
      type: "paypal",
      label:
        "Amount will be converted and paid in USD. Bank might apply charges",
      title: "PayPal",
      name: "payment",
    },
    {
      id: "Stripe",
      type: "stripe",
      label:
        "Amount will be converted and paid in USD. Bank might apply charges",
      title: "Stripe",
      name: "payment",
    },
  ];

  // Store
  const [course] = useSelector((store) => [
    store.entities.singleCourse.courseById.data,
  ]);

  // Methods
  const goBack = (e) => {
    e.preventDefault();
    history.goBack();
  };

  useEffect(() => {
    dispatch(getCourse(id));
    // window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main className="dashboard-main">
      <DashboardSideNav />
      <main className="dashboard__main">
        <DashboardTopNav>
          <button onClick={(e) => goBack(e)} className="btn btn-3">
            <i className="fas fa-long-arrow-alt-left m-r-6px"></i>
            Back
          </button>
        </DashboardTopNav>
        <>
          <div className="course-title m-b-30px">
            <h2 className="head-8">Payment</h2>
          </div>
          <div className="payment-card__wrapper">
            <div className="payment-card">
              <h4 className="head-26">Choose a payment method</h4>

              <Payments
                paymentOptions={paymentOptions}
                setSelectedOption={setSelectedOption}
              />

              <p className="text-center para-16 m-t-50px">
                We Accept all major Credit/Debit cards
              </p>

              <Link to={selectedOption ? `/user-course/${id}/checkout` : "#"}>
                <button
                  disabled={!selectedOption}
                  className="btn btn-7 btn--full m-t-20px"
                >
                  {`PAY NOW: $ ${course?.price}`}
                </button>
              </Link>
            </div>
          </div>
        </>
      </main>
    </main>
  );
};

export default Payment;
