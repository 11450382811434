import moment from "moment";

export const convertNameToAvatar = (name) => {
  const splitNames = name.split(" ");
  const firstName = splitNames[0];
  const lastName = splitNames[1];
  const firstChar = firstName.charAt(0);
  const lastChar = lastName.charAt(0);

  return `${firstChar}${lastChar ? lastChar : null}`;
};

export const formatCurrency = (value) => {
  if (isNaN(value)) {
    return "0.00";
  }
  return Number(value)
    .toFixed(2)
    .replace(/\d(?=(\d{3})+\.)/g, "$&,");
};

export const removeTags = (value) => {
  return String(value).replace(/(<([^>]+)>)/gi, "");
};

export const getContentForCurriculum = (allContents, curriculumId) => {
  const contents = allContents.filter(
    (content) => content.curriculum_id === curriculumId
  );

  return contents;
};

export const rgbaToHex = (r, g, b) => {
  r = r.toString(16);
  g = g.toString(16);
  b = b.toString(16);

  if (r.length === 1) r = "0" + r;
  if (g.length === 1) g = "0" + g;
  if (b.length === 1) b = "0" + b;

  return "#" + r + g + b;
};

export const _isVideoLink = (link) => {
  const isYoutubeLink = /youtube.com/;
  const isVimeoLink = /vimeo.com/;
  return isYoutubeLink.test(link) || isVimeoLink.test(link);
};

export const getFirstLine = (text) => {
  const textArr = text.split(" ");

  if (textArr.length < 12) {
    return textArr.join(" ");
  }

  return textArr.splice(0, 12).join(" ") + "....";
};

export const handleTimeDifference = (time) => {
  const startTime = moment(time);
  const end = moment(new Date());

  var duration = moment.duration(startTime.diff(end));
  var seconds = duration.asSeconds();

  return seconds > 0;
};

export const formatLiveClassesCalenderData = (classes) => {
  const data = [];
  classes.forEach((classItem) =>
    data.push({
      name: classItem.curriculum_name,
      description: classItem.live_class_type_name,
      startDate: classItem.date,
      startTime: classItem.start_time,
      endTime: classItem.end_time,
      location: classItem.link_to_live_class,
      // timeZone: classItem.timezone_name_abbr,
    })
  );

  return data;
};

export const formatDateTime = (time) => {
  // time = 10:30 @type => string
  const timeArr = time.split(":");
  const hour = timeArr[0];
  const minute = timeArr[1];

  if (hour < 12) return `${hour}:${minute} AM`;
  if (hour > 12) return `${hour - 12}:${minute} PM`;
  return `${hour}:${minute} PM`;
};

export const log = console.log.bind(document);

export const getCourseContents = (data) => {
  const contents = [];

  if (data.length) {
    data.forEach((childData) => {
      contents.push(...childData.course_contents);
    });
  }

  return contents;
};

export const _getQueryString = (data) => {
  // Create a cloned object
  const clonedObj = JSON.parse(JSON.stringify(data));

  //   Delete invalid properties
  for (const [key, value] of Object.entries(clonedObj)) {
    if ([null, undefined, ""].includes(value) || typeof value === "object") {
      delete clonedObj[key];
    }
  }

  const queryString = Object.keys(clonedObj)
    .map((key) => key + "=" + clonedObj[key])
    .join("&");

  return queryString;
};

export const debounceFunc = (func, handler, delay) => {
  clearTimeout(handler);
  return setTimeout(func, delay);
};

export const convertJSON = (data) => {
  return typeof data === "string" ? JSON.parse(data) : data;
};

export const isValidJson = (data) => {};

export const copyText = async (id) => {
  const inputElemet = document.getElementById(id);
  const value = inputElemet.value;

  let status = false;

  try {
    await navigator.clipboard.writeText(value);
    status = true;
  } catch (error) {
    status = false;
  }

  return status;
};
