import React from "react";

const IconEmail = ({
  width,
  height,
  cssClass,
  pathCssClass1,
  pathCssClass2,
}) => {
  return (
    <svg
      width={width || "20"}
      height={height || "18"}
      className={`${cssClass ? cssClass : ""}`}
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={pathCssClass1}
        opacity="0.4"
        d="M20 12.94C20 15.73 17.76 17.99 14.97 18H14.96H5.05C2.27 18 0 15.75 0 12.96V12.95C0 12.95 0.006 8.524 0.014 6.298C0.015 5.88 0.495 5.646 0.822 5.906C3.198 7.791 7.447 11.228 7.5 11.273C8.21 11.842 9.11 12.163 10.03 12.163C10.95 12.163 11.85 11.842 12.56 11.262C12.613 11.227 16.767 7.893 19.179 5.977C19.507 5.716 19.989 5.95 19.99 6.367C20 8.576 20 12.94 20 12.94"
        fill="#272D33"
      />
      <path
        className={pathCssClass2}
        d="M19.4769 2.674C18.6109 1.042 16.9069 0 15.0309 0H5.05086C3.17486 0 1.47086 1.042 0.60486 2.674C0.41086 3.039 0.50286 3.494 0.82586 3.752L8.25086 9.691C8.77086 10.111 9.40086 10.32 10.0309 10.32C10.0349 10.32 10.0379 10.32 10.0409 10.32C10.0439 10.32 10.0479 10.32 10.0509 10.32C10.6809 10.32 11.3109 10.111 11.8309 9.691L19.2559 3.752C19.5789 3.494 19.6709 3.039 19.4769 2.674"
        fill="#272D33"
      />
    </svg>
  );
};

export default IconEmail;
