import Form from "./../Forms/Form";
import Joi from "joi";
import ContentCard from "../ContentCard";
import { connect } from "react-redux";
import { createCoursePrice } from "../../../storee/slices/createCourse";
import { formatCurrency } from "../../../utils/helpers";
import { addANotification } from "../../../storee/slices/notification";
import Notiflix from "notiflix";

class Price extends Form {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        coursePrice: this.props.price || "",
      },
      error: {
        coursePrice: "",
      },
    };
  }

  schema = {
    coursePrice: Joi.number().required().label("Course Price"),
  };

  doSubmit = async () => {
    // Check if course has been created
    // if (!this.props.courseId) {
    //   Notiflix.Notify.failure(
    //     "Error while creating price, Please fill the About this course form first"
    //   );
    //   return;
    // }

    this.props.setIsLoading(true);

    const { coursePrice } = this.state.data;

    const data = {
      course_id: this.props.courseId,
      price: coursePrice,
    };

    try {
      await this.props.saveCoursePrice(data);
      Notiflix.Notify.success("Course price updated");
      await this.props.setActiveTab(5);
    } catch (error) {
      Notiflix.Notify.failure("An Error occured");
    }
    // this.props.notifyActions({
      //   type: "ok",
      //   title: "Success",
      //   description: `Price Created successfully`,
      // });
      await this.props.setIsLoading(false);
    // if (this.props.isEditingCourseId) {
    // if (this.props.nextStep) this.props.nextStep();
    // }
    // this.resetData();
  };

  resetData = () => {
    this.setState({
      data: {
        coursePrice: "",
      },
    });
  };

  render() {
    const { coursePrice } = this.state.data;
    return (
      <div className="about-this-course animate-slideFromLeft">
        <h3 className="head-5 m-b-10px">Price</h3>

        <form onSubmit={this.handleSubmit} id="coursePriceForm">
          <ContentCard title="Set amount for your course">
            {this.renderInput(
              null,
              "coursePrice",
              "number",
              "num",
              "noIcon",
              "0.00"
            )}
            <h1 className="text-center p-y-50px">
              $ {formatCurrency(coursePrice) || "0.00"}
            </h1>
          </ContentCard>

          {/* {this.renderInput("Confirm Password", "password", "password")}

        {this.renderButton("Send Reset Link")} */}
        </form>
      </div>
    );
  }
}

// Store
const mapStateToProps = (state) => ({
  courseId: state.entities.createCourse.courseId,
  isLoading: state.entities.createCourse.price.isLoading,
  price: state.entities.createCourse.price.data,
  isEditingCourseId: state.entities.createCourse.courseId,
});

const mapDispatchToProps = (dispatch) => ({
  notifyActions: (data) => dispatch(addANotification(data)),
  saveCoursePrice: (data) => dispatch(createCoursePrice(data)),
  // saveCourseContent: (data) => dispatch(createCourseContent(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Price);
