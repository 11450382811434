import React from "react";

const ListIcon = ({
  width,
  height,
  cssClass,
  pathCssClass1,
  pathCssClass2,
}) => {
  return (
    <svg
      id="icon-course-created"
      width={width || "52"}
      height={height || "52"}
      className={`icon-course-created ${cssClass ? cssClass : ""}`}
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect opacity="0.1" width="52" height="52" rx="8" fill="#EC0B43" />
      <path
        className={pathCssClass1}
        opacity="0.4"
        d="M31.588 12.666H20.4133C16.36 12.666 14 15.0393 14 19.106V32.8793C14 37.0127 16.36 39.3327 20.4133 39.3327H31.588C35.7067 39.3327 38 37.0127 38 32.8793V19.106C38 15.0393 35.7067 12.666 31.588 12.666Z"
        fill="#EC0B43"
      />
      <path
        className={pathCssClass2}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.7733 18.8652V18.8786C20.1986 18.8786 19.7333 19.3452 19.7333 19.9186C19.7333 20.4919 20.1986 20.9586 20.7733 20.9586H24.7586C25.3333 20.9586 25.7999 20.4919 25.7999 19.9039C25.7999 19.3319 25.3333 18.8652 24.7586 18.8652H20.7733ZM31.2266 26.9852H20.7733C20.1986 26.9852 19.7333 26.5186 19.7333 25.9452C19.7333 25.3719 20.1986 24.9039 20.7733 24.9039H31.2266C31.7999 24.9039 32.2666 25.3719 32.2666 25.9452C32.2666 26.5186 31.7999 26.9852 31.2266 26.9852ZM31.2266 33.0786H20.7733C20.3733 33.1319 19.9866 32.9319 19.7733 32.5986C19.5599 32.2519 19.5599 31.8119 19.7733 31.4786C19.9866 31.1319 20.3733 30.9452 20.7733 30.9852H31.2266C31.7586 31.0386 32.1599 31.4919 32.1599 32.0386C32.1599 32.5706 31.7586 33.0252 31.2266 33.0786Z"
        fill="#EC0B43"
      />
    </svg>
  );
};

export default ListIcon;
