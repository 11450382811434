import React from "react";
import ReactStars from "react-rating-stars-component";

const Rating = ({ cssClass, rate }) => {
  const ratingChanged = (newRating) => {
  };
  return (
    <div className={`rating__stars ${cssClass ? cssClass : ""}`}>
      {/* <Svg cssClassName="rating__star" iconId="icon-star-rating" />
      <Svg cssClassName="rating__star" iconId="icon-star-rating" />
      <Svg cssClassName="rating__star" iconId="icon-star-rating" />
      <Svg cssClassName="rating__star" iconId="icon-star-rating" />
      <Svg cssClassName="rating__star" iconId="icon-star-rating" /> */}

      <ReactStars
        count={5}
        onChange={ratingChanged}
        size={24}
        activeColor="#ffd700"
        value={rate || 0}
        isHalf={true}
        emptyIcon={<i className="far fa-star"></i>}
        halfIcon={<i className="fa fa-star-half-alt"></i>}
        fullIcon={<i className="fa fa-star"></i>}
      />
    </div>
  );
};

export default Rating;
