import React from "react";
import CourseContentDropdown from "./Index";

const Wapper = (props) => {
  const { courseContents } = props;


  return (
    <div className="course-content-dropdown__wrapper">
      {courseContents.map((content) => (
        <CourseContentDropdown key={content?.curriculum_id} data={content} />
      ))}
    </div>
  );
};

export default Wapper;
