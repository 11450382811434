import { createSlice } from "@reduxjs/toolkit";
import { getCurrentUser } from "../../services/authServices";
// import { getPersonalProfile } from "../../services/instructorServices";

const userSlice = createSlice({
  name: "user",
  initialState: {
    currentUser: {},
    isLoading: false,
    lastFetch: null,

    // Newly registered user
    newUser: {
      email: "",
    },
  },
  reducers: {
    dataRequested: (user, action) => {
      user.isLoading = true;
    },
    dataRequestFailed: (user, action) => {
      user.isLoading = false;
    },
    dataRequestSuccess: (user, action) => {
      user.currentUser = action.payload;
      user.isLoading = false;
    },

    // New user
    addNewUserData: (user, action) => {
      user.newUser.email = action.payload.email;
    },
  },
});

const { dataRequestFailed, dataRequestSuccess, addNewUserData } =
  userSlice.actions;

const userData = getCurrentUser();
// let newUserData;
// (async () => {
//   let { data } = await getPersonalProfile();
//   newUserData = data.instructor_personal_profile;
// })();
// getPersonalProfile().then(({ data }) => {
//   newUserData = data.instructor_personal_profile;
// });
export const getCurrentUserData = () => {
  if (userData)
    return {
      type: dataRequestSuccess.type,
      payload: userData,
    };
  else
    return {
      type: dataRequestFailed.type,
    };
};

export const saveNewUserData = (data) => addNewUserData({ email: data });
export default userSlice.reducer;
