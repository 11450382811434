import { Link, useParams } from "react-router-dom";
import ResetPasswordForm from "./../common/Forms/ResetPasswordForm";
import Onboarding from "../common/Onboarding";

const ResetPassword = ({ location }) => {
  // Hooks
  const { token } = useParams();

  // Data to display
  return (
    <Onboarding location={location}>
      <Link to="/login" class="link link-1 m-b-40px">
        Back to Login
      </Link>

      <h2 className="head-2">Reset Password</h2>
      <p className="para-2 m-t-20px m-b-40px">
        Please choose your new password
      </p>

      <ResetPasswordForm resetToken={token} />
    </Onboarding>
  );
};

export default ResetPassword;
