import { images } from "../../utils/images";
const Svg = ({ cssClassName, iconId }) => {
  return (
    <svg className={cssClassName}>
      <use xlinkHref={`${images.spriteSVG}#${iconId}`}></use>
    </svg>
  );
};

export default Svg;
