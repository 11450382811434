import React from "react";
import ModalLayout from "./../ModalLayout";
import ModalChild from "./../ModalChild";
import ContentCard from "./../ContentCard";
import { images } from "../../../utils/images";
import LiveClassForm from "../Forms/LiveClassForm";

const LiveClassModal = (props) => {
  const { setShowModal, title, classId, handleReloadClasses } = props;

  return (
    <ModalLayout>
      <ModalChild>
        <ContentCard title={title ? title : "--"}>
          <button
            className="btn-close-modals"
            onClick={() => setShowModal(false)}
          >
            <img src={images?.pic9} alt="times" />
          </button>

          <LiveClassForm
            closeModal={() => setShowModal(false)}
            classId={classId}
            handleReloadClasses={handleReloadClasses}
          />
        </ContentCard>
      </ModalChild>
    </ModalLayout>
  );
};

export default LiveClassModal;
