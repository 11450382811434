import React, { useState } from "react";
import CourseContentToggler from "./CourseContentToggler";
import CourseContentFiles from "./CourseContentFiles";
import EmptyActivityBox from "./EmptyActivityBox";
import EmptyActivityState from "./EmptyActivityState";

const CourseContentSingle = ({
  opened,
  contentName,
  estimatedTime,
  contents,
  id,
  handleModalOpen,
  handleEdit,
  handleDelete,
  hideActions,
}) => {
  const initialState = opened || false;
  const [contentShow, setContentShow] = useState(initialState);

  const handleClick = () => {
    setContentShow(!contentShow);
  };

  return (
    <div className="course-contents">
      <CourseContentToggler
        contentName={contentName || ""}
        // estimatedTime={estimatedTime}
        handleClick={handleClick}
        id={id}
        handleModalOpen={handleModalOpen}
      />
      {contentShow && contents.length ? (
        <CourseContentFiles
          contents={contents}
          handleEdit={handleEdit}
          handleDelete={handleDelete}
          hideActions={hideActions}
        />
      ) : contentShow && !contents.length ? (
        <EmptyActivityBox cssClass="p-y-20px">
          <EmptyActivityState
            title="No resource"
            noImage={true}
            handleClick={() => handleModalOpen(id)}
            btnText="Upload Resource"
          />
        </EmptyActivityBox>
      ) : (
        ""
      )}
    </div>
  );
};

export default React.memo(CourseContentSingle);
