import React from "react";

const CircleComment = ({ width, height, cssClass }) => {
  return (
    <svg
      width={width || "39"}
      height={height || "39"}
      className={`${cssClass ? cssClass : ""}`}
      viewBox="0 0 39 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect opacity="0.13" width="39" height="39" rx="19.5" fill="#5C4FFF" />
      <path
        opacity="0.4"
        d="M20.0168 11.666C15.1751 11.666 11.6667 15.616 11.6667 19.9993C11.6667 21.3994 12.0751 22.841 12.7917 24.1577C12.9251 24.3744 12.9417 24.6494 12.8501 24.9077L12.2917 26.7744C12.1667 27.2243 12.5501 27.5577 12.9751 27.4244L14.6584 26.9244C15.1167 26.7744 15.4751 26.966 15.9009 27.2244C17.1176 27.941 18.6334 28.3077 20.0001 28.3077C24.1334 28.3077 28.3334 25.116 28.3334 19.9743C28.3334 15.541 24.7501 11.666 20.0168 11.666Z"
        fill="#5C4FFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.9839 21.0748C19.3922 21.0665 18.9172 20.5915 18.9172 19.9998C18.9172 19.4165 19.4005 18.9332 19.9839 18.9415C20.5755 18.9415 21.0505 19.4165 21.0505 20.0082C21.0505 20.5915 20.5755 21.0748 19.9839 21.0748ZM16.1419 21.0749C15.5585 21.0749 15.0752 20.5915 15.0752 20.0082C15.0752 19.4165 15.5502 18.9415 16.1419 18.9415C16.7335 18.9415 17.2085 19.4165 17.2085 20.0082C17.2085 20.5915 16.7335 21.0665 16.1419 21.0749ZM22.7587 20.0082C22.7587 20.5915 23.2337 21.0748 23.8254 21.0748C24.417 21.0748 24.892 20.5915 24.892 20.0082C24.892 19.4165 24.417 18.9415 23.8254 18.9415C23.2337 18.9415 22.7587 19.4165 22.7587 20.0082Z"
        fill="#5C4FFF"
      />
    </svg>
  );
};

export default CircleComment;
