import React, { useState as useEffect } from "react";
import useTabs from "../../utils/Hooks/useTab";
import TabNavigator from "./TabNavigator";
// import UserAllCourses from "./UserAllCourses";
import UserCompletedCourses from "./UserCompletedCourses";
import UserCoursesEnrolled from "./UserCoursesEnrolled";
import UserWishlist from "./UserWishlist";

const MyLearnings = (props) => {
  // Props
  const { cssClass } = props;

  // Hooks
  const [activeTabId, setTabId, renderTabComponent, setAllTabContent] =
    useTabs(0);

  // Variables
  const tabLabels = ["Ongoing Course", "Completed", "Wishlist"];

  useEffect(() => {
    setAllTabContent([
      <UserCoursesEnrolled courses={[]} />,
      <UserCompletedCourses courses={[]} />,
      <UserWishlist courses={[]} />,
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // // UserAllCourses
  // const ff = useCallback(() => {}, [activeTabId]);
  return (
    <div className={cssClass ? cssClass : ""}>
      <h3 className="head-18 m-b-12px">My Learnings</h3>
      <div className="my-learnings__card">
        <TabNavigator
          tabTitles={tabLabels}
          withLine={true}
          activeId={activeTabId}
          handleClick={setTabId}
        />
        {renderTabComponent(activeTabId)}
      </div>
    </div>
  );
};

export default React.memo(MyLearnings);
