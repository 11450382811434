import React from "react";
import { images } from "../../../utils/images";

const ChatTextOther = () => {
  return (
    <div className="chat-texts__other">
      <div className="chat-texts__other-image">
        <img
          src={images.pic10}
          alt="chat"
          className="chat-texts__other-img"
        />
      </div>
      <div className="chat-texts__other-text">
        <article>
          <span className="chat-texts__message">
            Sure! I’ll schedule a meeting for 2pm.
          </span>
        </article>

        <span className="chat-texts__time">12:24</span>
      </div>
    </div>
  );
};

export default ChatTextOther;
