import React from 'react';
import OnboardingNav from "./OnboardingNav";

const Onboarding = (props) => {
  return (
    <>
      <OnboardingNav location={props.location} />
      <main className="onboard-main">
        <div className="center">
          <div className="onboard-card">{props.children}</div>
        </div>
      </main>
    </>
  );
};

export default Onboarding;
