import React from "react";

const CaretCircleIcon = ({
  width,
  height,
  cssClass,
  pathCssClass1,
  pathCssClass2,
}) => {
  return (
    <svg
      id="icon-arrow-right-circle"
      width={width || "20"}
      height={height || "20"}
      className={`icon-arrow-right-circle ${cssClass ? cssClass : ""}`}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={pathCssClass1}
        opacity="0.2"
        d="M10 0C15.515 0 20 4.486 20 10C20 15.514 15.515 20 10 20C4.486 20 0 15.514 0 10C0 4.486 4.486 0 10 0Z"
        fill="#272D33"
      />
      <path
        className={pathCssClass2}
        d="M8.55797 5.77991C8.74897 5.77991 8.94097 5.85291 9.08697 5.99891L12.574 9.46891C12.715 9.60991 12.794 9.80091 12.794 10.0009C12.794 10.1999 12.715 10.3909 12.574 10.5319L9.08697 14.0039C8.79397 14.2959 8.31997 14.2959 8.02697 14.0019C7.73497 13.7079 7.73597 13.2329 8.02897 12.9409L10.982 10.0009L8.02897 7.06091C7.73597 6.76891 7.73497 6.29491 8.02697 6.00091C8.17297 5.85291 8.36597 5.77991 8.55797 5.77991Z"
        fill="#272D33"
      />
    </svg>
  );
};

export default CaretCircleIcon;
