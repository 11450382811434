import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { formatCurrency } from "../../utils/helpers";
import { images } from "../../utils/images";
// import AboutInstructor from "./AboutInstructor";
import { useDispatch, useSelector } from "react-redux";
import { addCourseToWishList } from "../../storee/slices/student";
import Spinner from "./Spinner";
// import { addANotification } from "../../storee/slices/notification";
import useUserCourseStatus from "../../utils/Hooks/useUserCourseStatus";
// import Notiflix from "notiflix";

const CourseEnrollCard = (props) => {
  // States
  const [isJustAddedToWishlist, setIsJustAddedToWishlist] = useState(false);

  //
  const dispatch = useDispatch();
  const wishlistRef = useRef(false);
  // const { pic, price, id, instructor } = props;
  const { pic, price, id } = props;

  // Store selector
  const [isLoading, data] = useSelector((store) => [
    store.entities.student.wishLists.isLoading,
    store.entities.student.wishLists.data,
  ]);

  // Hooks
  const [isEnrolled, isInWishlist] = useUserCourseStatus(id);

  // Effects
  useEffect(() => {
    if (data && data.message === "Course saved to wishlist") {
      // dispatch(
      //   addANotification({
      //     type: "ok",
      //     title: "Success!",
      //     description: "Course added to wishlist",
      //   })
      // );
      // Notiflix.Notify.success("Course added to wishlist");
      setIsJustAddedToWishlist(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <div className="course-enroll-card">
      <div className="course-enroll-card__avatar">
        <img
          src={pic || images["pic22"]}
          alt=""
          className="course-enroll-card__ele"
        />
      </div>
      <div className="course-enroll-card__actions">
        <p className="para-8 m-b-10px">Price</p>
        <h2 className="head-24 m-b-20px">
          {`$ ${price ? formatCurrency(price) : " 0.00"}`}
        </h2>
        {isEnrolled ? (
          <Link to={`/user-course/${id}`}>
            <button className="btn btn-7 btn--full m-b-20px">
              Continue Watching
            </button>
          </Link>
        ) : (
          <>
            <Link to={`/user-course/${id}/payment`}>
              <button className="btn btn-7 btn--full m-b-20px">
                Proceed to Pay
              </button>
            </Link>
            <button
              ref={wishlistRef}
              className="btn btn-8 btn--full"
              onClick={() => dispatch(addCourseToWishList({ course_id: id }))}
              disabled={isInWishlist || isJustAddedToWishlist}
            >
              {isInWishlist || isJustAddedToWishlist ? (
                "Added to Wishlist already"
              ) : isLoading ? (
                <Spinner />
              ) : (
                " Add to Wishlist"
              )}
            </button>
          </>
        )}
      </div>

      {/* <AboutInstructor data={instructor} /> */}
    </div>
  );
};

export default CourseEnrollCard;
