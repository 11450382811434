import Form from "./../Forms/Form";
import Joi from "joi";
import GridView from "../GridView";

import courseServices from "../../../services/courseServices";
import PlatformsLoader from "../Loaders/PlatformsLoader";
import {
  createLiveClass,
  editLiveClass,
} from "../../../storee/slices/createCourse";
// import Checkbox from "./../Input/Checkbox";
import { connect } from "react-redux";
import {
  getLiveClassTypes,
  getPreferedPlatform,
  getTimeZones,
} from "../../../storee/slices/helpers";
import Spinner from "../Spinner";
import { addANotification } from "../../../storee/slices/notification";
import withRouter from "../../../hoc/withRouter";
import withCourseData from "../../../hoc/withCourseData";
import SpinnerBox from "../SpinnerBox";

class LiveClassForm extends Form {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        liveClassName: "",
        startTime: "",
        endTime: "",
        preferredPlatform: "",
        date: "",
        timeZone: "",
        linkToLIveClass: "",
        liveClassType: "",
      },
      error: {
        liveClassName: "",
        startTime: "",
        endTime: "",
        preferredPlatform: "",
        date: "",
        timeZone: "",
        linkToLIveClass: "",
        liveClassType: "",
      },

      preferredLiveClassPlatform: this.props.preferedPlatforms,
      courseId: "",
      // liveClasses: [],
      timeZonesList: [],
      curriculums: [],
      liveClassTypes: [],
      isLoadingData: false,
      isLoading: false,
      isLoadingPlatforms: false,
      showModal: false,
      isEditing: false,
    };
  }

  schema = {
    liveClassName: Joi.string().required().label("*"),
    startTime: Joi.string().required().label("*"),
    endTime: Joi.string().required().label("*"),
    preferredPlatform: Joi.string().label("*"),
    date: Joi.string().label("*"),
    timeZone: Joi.string().label("*"),
    linkToLIveClass: Joi.string().label("*"),
    liveClassType: Joi.string().label("*"),
  };

  async componentDidMount() {
    this.setState({ isLoadingPlatforms: true, isLoadingData: true });

    await this.props.getCourse();

    // Get course id
    const courseIdFromProps = this.props.params.id;
    const liveClassesResponse = await courseServices.getLiveClassesById({
      course_id: courseIdFromProps,
    });
    const liveClasses = liveClassesResponse.data.courses;

    await this.setState({ liveClasses });

    // Load timezones
    await this.props.getAllTimeZones();
    // eslint-disable-next-line no-unused-vars
    const timeZonesData = this.props?.timeZones?.map((timeZone) => ({
      optionName: timeZone?.text,
      optionId: timeZone?.id,
    }));
    this.setState({
      timeZonesList: [...timeZonesData],
    });

    // Live class types
    await this.props.getLiveClassTypes();
    // eslint-disable-next-line no-unused-vars
    const liveClassTypesData = this.props?.liveClassTypes?.map(
      (liveClassType) => ({
        optionName: liveClassType?.name,
        optionId: liveClassType?.live_class_type_id,
      })
    );
    this.setState({
      liveClassTypes: [...liveClassTypesData],
    });

    // Fetch preffered live classes
    if (this.props.preferedPlatforms.length < 1) {
      this.setState({ isLoadingPlatforms: true });
      await this.props.getPreferedPlatforms();
      this.setState({
        preferredLiveClassPlatform: this.props.preferedPlatforms,
        isLoadingPlatforms: false,
      });
    } else {
      this.setState({ isLoadingPlatforms: false });
    }
    // Load curriculums
    const curriculumsData = this.props?.courseData?.curriculums?.map(
      (curriculum) => ({
        optionName: curriculum?.topic,
        optionId: curriculum?.curriculum_id,
      })
    );
    if (curriculumsData && curriculumsData.length) {
      this.setState({
        curriculums: [...curriculumsData],
      });
    }

    // testttttt
    if (this.props.classId) {
      await this.handleLiveClassEdit(this.props.classId);
    }

    this.setState({ isLoadingData: false });
  }

  doSubmit = async () => {
    this.setState({ isLoading: true });
    // const courseIdFromProps = this.props.getCourseId();
    const { isEditing, editingClassId } = this.state;

    const {
      liveClassName: live_class_name,
      startTime,
      endTime,
      preferredPlatform: preferred_platform,
      date,
      timeZone,
      linkToLIveClass: link_to_live_class,
      liveClassType,
      // curriculumId,
    } = this.state.data;
    const liveClassData = {
      live_class_id: editingClassId,
      live_class_name: "",
      date,
      start_time: startTime,
      end_time: endTime,
      time_zone_id: timeZone || "",
      preferred_platform,
      link_to_live_class,
      live_class_type: liveClassType,
      curriculum_id: live_class_name,
    };

    if (Date.parse(`${date} ${startTime}`) > Date.parse(`${date} ${endTime}`)) {
      this.setState({ isLoading: false });

      const errors = this.state.error;
      this.setState({
        error: {
          ...errors,
          endTime: "End time must be greater then start time",
        },
      });
      return;
    }

    if (isEditing) {
      // delete liveClassData.course_id;
      liveClassData.live_class_id = editingClassId;
      liveClassData.course_id = this.props.courseData.courses.id;
      await this.props.editLiveClass(liveClassData);
    } else {
      delete liveClassData.live_class_id;
      liveClassData.course_id = this.props.courseData.courses.id;
      await this.props.saveLiveClass(liveClassData);
    }

    // Notification
    this.props.notifyActions({
      type: "ok",
      title: "Success",
      description: `${isEditing ? "Updated" : "Created"} successfully`,
    });

    this.setState(
      {
        isLoading: false,
      },
      () => {
        if (!isEditing) this.resetData();
      }
    );

    // Reload live classes
    this.props.handleReloadClasses();

    // Close modal
    this.props.closeModal();
  };

  resetData = () => {
    this.setState({
      data: {
        liveClassName: "",
        startTime: "",
        endTime: "",
        liveClassType: "",
        preferredPlatform: "",
        date: "",
        timeZone: "",
        linkToLIveClass: "",
      },
    });
  };

  handleLiveClassEdit = async (id) => {
    this.setState({ isEditing: true });
    const { liveClasses } = this.state;
    if (liveClasses.length) {
      const liveClass = liveClasses.filter((liveClass) => liveClass.id === id);
     
      await this.setState(
        {
          data: {
            liveClassName: liveClass[0]?.curriculum_id,
            startTime: liveClass[0]?.start_time,
            endTime: liveClass[0]?.end_time,
            preferredPlatform: liveClass[0]?.preferred_platform,
            date: liveClass[0]?.date,
            timeZone: liveClass[0]?.time_zone,
            linkToLIveClass: liveClass[0]?.link_to_live_class,
            liveClassType: liveClass[0]?.live_class_type,
          },
          editingClassId: liveClass[0]?.id,
        },
        () => this.setState({ showModal: true })
      );

      // await this.setState({

      // })
    }
  };

  handleModalOpen = () => {
    this.setState({
      showModal: true,
    });
  };

  handleModalClose = () => {
    this.setState(
      {
        showModal: false,
      isEditing: false,
      },
      () => this.resetData()
    );
  };

  render() {
    const {
      preferredLiveClassPlatform,
      isLoading,
      isLoadingPlatforms,
      timeZonesList,
      curriculums,
      liveClassTypes,
      //   showModal,
      isLoadingData,
    } = this.state;
    // const { curriculums } = this.props.courseData;
    const { preferredPlatform } = this.state.data;
    return (
      <>
        {isLoadingData && isLoadingPlatforms ? (
          <SpinnerBox />
        ) : (
          <form onSubmit={this.handleSubmit} id="courseLiveClassesForm">
            {this.renderSelect(
              "Select Live Class Type",
              "liveClassType",
              liveClassTypes,
              "name",
              "live_class_type_id",
              "Select"
            )}
            {this.renderSelect(
              "Select Course Content",
              "liveClassName",
              curriculums,
              "curriculum_id",
              "topic",
              "Select"
            )}
            {/* {this.renderInput(
                    "Course Content Name",
                    "liveClassName",
                    "text",
                    "noIcon",
                    null
                  )} */}
            {this.renderInput("Date", "date", "date", "noIcon")}
            <GridView grid={2}>
              {this.renderInput("Start Time", "startTime", "time", "noIcon")}
              {this.renderInput("End Time", "endTime", "time", "noIcon")}
            </GridView>
            {this.renderSelect(
              "Time Zone",
              "timeZone",
              timeZonesList,
              "text",
              "id",
              "Select"
            )}
            <p className="input__label m-b-7px">Preferred Platform</p>
            {isLoadingPlatforms ? (
              <PlatformsLoader />
            ) : (
              <GridView grid={4}>
                {preferredLiveClassPlatform.map((platform, index) =>
                  this.renderRadio(
                    platform["name"],
                    "preferredPlatform",
                    platform["id"] || "",
                    index,
                    preferredPlatform
                  )
                )}
              </GridView>
              // <GridView grid={2}>
              //   {preferredLiveClassPlatform?.map((platform, index) => (
              //     <Checkbox
              //       key={index}
              //       label={platform?.name}
              //       // cssClass="m-t-20px"
              //     />
              //   ))}
              // </GridView>
            )}

            <div className="m-t-20px"></div>

            {this.renderInput(
              "Link to Live class",
              "linkToLIveClass",
              "text",
              "noIcon",
              "Paste link to Live Class"
            )}

            <button
              type="submit"
              form="courseLiveClassesForm"
              className="btn btn-2 btn-md m-t-25px"
            >
              {isLoading ? <Spinner /> : "Set"}
            </button>

            {/* {this.renderInput("Confirm Password", "password", "password")}

        {this.renderButton("Send Reset Link")} */}
          </form>
        )}
      </>
    );
  }
}

// Store
const mapStateToProps = (state) => ({
  courseId: state.entities.createCourse.courseId,
  liveClasses: state.entities.createCourse.liveClasses.lists,
  curriculums: state.entities.createCourse.curriculum.curriculums,
  timeZones: state.entities.helpers.timeZones.data,
  liveClassTypes: state.entities.helpers.liveClassTypes.data,
  preferedPlatforms: state.entities.helpers.preferedPlatform.data,
});

const mapDispatchToProps = (dispatch) => ({
  notifyActions: (data) => dispatch(addANotification(data)),
  saveLiveClass: (data) => dispatch(createLiveClass(data)),
  editLiveClass: (data) => dispatch(editLiveClass(data)),
  getAllTimeZones: () => dispatch(getTimeZones()),
  getPreferedPlatforms: () => dispatch(getPreferedPlatform()),
  getLiveClassTypes: () => dispatch(getLiveClassTypes()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withCourseData(LiveClassForm)));
