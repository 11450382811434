import React from "react";
import moment from "moment";

import Svg from "./Svg";

const TransactionTable = ({ tableData }) => {
  return (
    <div className="table-wrapper">
      <div className="table-container">
        <div className="table">
          <table className="table__main">
            <thead>
              <tr className="">
                <th className="table__main__head">Transction ID</th>
                <th className="table__main__head">Date</th>
                <th className="table__main__head">Details</th>
                <th className="table__main__head">Amount</th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((data, index) => (
                <tr className="table__main__row" key={index}>
                  <td className="table__main__data">
                    <p className="data__details data__details-ref">
                      {data.request_reference}
                    </p>
                  </td>
                  <td className="table__main__data">
                    <p className="data__details">
                      {moment(data.date_created).format(
                        "Do MMM, YYYY h:mm:ss a"
                      )}
                    </p>
                  </td>
                  <td className="table__main__data">
                    <p className="data__details">{data.request_detail}</p>
                  </td>
                  <td className="table__main__data">
                    <p className="data__details data__dot">
                      {data.status === "approved" ? (
                        <Svg
                          cssClassName="data__dot-icon"
                          iconId="icon-green-dot"
                        />
                      ) : (
                        <Svg
                          cssClassName="data__dot-icon"
                          iconId="icon-red-dot"
                        />
                      )}

                      <span>$ {data.request_amount} </span>
                    </p>
                  </td>
                </tr>
              ))}

              {/* <tr className="table__main__row">
            <td className="table__main__data">
              <p className="data__details">26FBBDVY64U4B</p>
            </td>
            <td className="table__main__data">
              <p className="data__details">28th Jan, 2021 10:25 am</p>
            </td>
            <td className="table__main__data">
              <p className="data__details">Design Strategy Course Payment</p>
            </td>
            <td className="table__main__data">
              <p className="data__details data__dot">
                <Svg cssClassName="data__dot-icon" iconId="icon-green-dot" />
                <span>$ 50,650.00</span>
              </p>
            </td>
          </tr>
          <tr className="table__main__row">
            <td className="table__main__data">
              <p className="data__details">26FBBDVY64U4B</p>
            </td>
            <td className="table__main__data">
              <p className="data__details">28th Jan, 2021 10:25 am</p>
            </td>
            <td className="table__main__data">
              <p className="data__details">Payout withdrawal request</p>
            </td>
            <td className="table__main__data">
              <p className="data__details data__dot">
                <Svg cssClassName="data__dot-icon" iconId="icon-red-dot" />
                <span>$ 50,650.00</span>
              </p>
            </td>
          </tr>
         */}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default TransactionTable;
