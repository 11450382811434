import React from "react";
import ModalLayout from "./../ModalLayout";
import ModalChild from "./../ModalChild";
import ContentCard from "./../ContentCard";
import { images } from "../../../utils/images";
import Spinner from "../Spinner";
import WarnIcon from "../SvgIcons/WarnIcon";
import CheckIcon from "../SvgIcons/CheckIcon";

const ConfirmActionModal = (props) => {
  const {
    setShowModal,
    handleNoAction,
    handleYesAction,
    isLoading,
    title,
    noBtnText,
    yesBtnText,
    type = "warn",
  } = props;
  return (
    <ModalLayout>
      <ModalChild>
        <ContentCard>
          <button
            className="btn-close-modals"
            onClick={() => setShowModal(false)}
          >
            <img src={images?.pic9} alt="times" />
          </button>
          <div className="action-modal">
            {/* <div className="action-modal-icon"> */}
            {type === "warn" ? <WarnIcon /> : <CheckIcon />}
            {/* </div> */}
            <h3 className="action-modal__title-2 text-center">{title}</h3>
            <div className="action-modal__actions">
              <button
                className="btn btn-6 btn-md m-t-30px"
                onClick={() => {
                  if (handleNoAction) {
                    handleNoAction();
                  } else {
                    setShowModal(false);
                  }
                }}
              >
                {noBtnText ? noBtnText : " No please!"}
              </button>
              <button
                className="btn btn-1 btn-md m-t-30px"
                onClick={() => handleYesAction()}
              >
                {isLoading ? (
                  <Spinner color={"white"} />
                ) : yesBtnText ? (
                  yesBtnText
                ) : (
                  "Yes I am sure"
                )}
              </button>
            </div>
          </div>
        </ContentCard>
      </ModalChild>
    </ModalLayout>
  );
};

export default ConfirmActionModal;
