import React from "react";
import Plyr from "plyr-react";

const VideoAvatar = (props) => {
  const { cssClass, url } = props;
  const videoSrc = {
    type: "video",
    sources: [
      {
        src: url || "https://www.youtube.com",
        provider: "youtube",
      },
    ],
  };
  return (
    <div
      className={`featured-course__video-avatar m-b-12px ${
        cssClass ? cssClass : ""
      }`}
    >
      <Plyr
        source={videoSrc ? videoSrc : "https://www.youtube.com"}
        options={{
          controls: [
            "play",
            "progress",
            "mute",
            "volume",
            "settings",
            "fullscreen",
          ],
        }}
      />
    </div>
  );
};

export default React.memo(VideoAvatar);
