import React from "react";

const ClockIcon = ({ width, height, cssClass, pathCssClass1 }) => {
  return (
    <svg
      width={width || "16"}
      height={height || "16"}
      className={`${cssClass ? cssClass : ""}`}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={pathCssClass1}
        d="M8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C15.9952 12.4163 12.4163 15.9952 8 16ZM8 1.6C4.46538 1.6 1.6 4.46538 1.6 8C1.6 11.5346 4.46538 14.4 8 14.4C11.5346 14.4 14.4 11.5346 14.4 8C14.396 4.46702 11.533 1.60397 8 1.6ZM12 8.8H7.2V4H8.8V7.2H12V8.8Z"
        fill="#647382"
      />
    </svg>
  );
};

export default ClockIcon;
