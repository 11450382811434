import React from "react";
import Form from "./../common/Forms/Form";
import DashboardSideNav from "../common/DashboardSideNav";
import DashboardTopNav from "../common/DashboardTopNav";
import Activities from "../common/Activities";
import Password from "../common/settings/Password";
// import Notifications from "./../common/settings/Notifications";
import CenteredLayout from "../common/CenterLayout";
import DashboardMaxWidth from "../common/DashboardMaxWidth";
import BillingAddress from "./../common/settings/BillingAddress";
import AboutStudent from "../common/settings/AboutStudent";

class StudentSettings extends Form {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: 0,
    };
  }

  settingsForms = [
    <AboutStudent key={1} />,
    <Password key={2} />,
    // <Notifications key={3} />,
    <BillingAddress key={3} />,
  ];

  handleActionClicked = (id) => this.setState({ activeTab: id });

  InstructorSettingsActivities = [
    {
      name: "About me",
      handleActionClicked: this.handleActionClicked,
    },
    {
      name: "Password & Security",
      handleActionClicked: this.handleActionClicked,
    },
    // {
    //   name: "Notifications",
    //   handleActionClicked: this.handleActionClicked,
    // },
    {
      name: "Billing Address",
      handleActionClicked: this.handleActionClicked,
    },
  ];

  render() {
    const { activeTab } = this.state;

    return (
      <>
        <main className="dashboard-main">
          <DashboardSideNav />
          <main className="dashboard__main">
            <DashboardTopNav>
              <h3 className="head-2">Settings</h3>
            </DashboardTopNav>
            <CenteredLayout>
              <DashboardMaxWidth>
                <Activities activities={this.InstructorSettingsActivities}>
                  {this.settingsForms.map((form, index) =>
                    activeTab === index ? form : null
                  )}
                </Activities>
              </DashboardMaxWidth>
            </CenteredLayout>
          </main>
        </main>
      </>
    );
  }
}

export default StudentSettings;
