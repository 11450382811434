import create from "zustand";

const usePayment = create((set) => ({
  paymentOption: "",
  newPaidCourseId: "",
  isLoading: false,

  // Methods
  setPaymentOption: (paymentOption) => {
    set(() => ({ paymentOption }));
  },
  setNewPaidCourseId: (newPaidCourseId) => {
    set(() => ({ newPaidCourseId }));
  },
  makePayment: async () => {
    set(() => ({ isLoading: true }));
  },
}));

export default usePayment;
