import React from "react";

const EditIcon = ({ width, height, cssClass, pathCssClass }) => {
  return (
    <svg
      width={width || "19"}
      height={height || "19"}
      className={`${cssClass ? cssClass : ""}`}
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={pathCssClass}
        d="M8.70837 1.58331H7.12504C3.16671 1.58331 1.58337 3.16665 1.58337 7.12498V11.875C1.58337 15.8333 3.16671 17.4166 7.12504 17.4166H11.875C15.8334 17.4166 17.4167 15.8333 17.4167 11.875V10.2916"
        stroke="#292D32"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className={pathCssClass}
        d="M12.6982 2.39085L6.4599 8.62918C6.2224 8.86668 5.9849 9.33377 5.9374 9.67418L5.59699 12.0571C5.47032 12.92 6.0799 13.5217 6.94282 13.4029L9.32574 13.0625C9.65824 13.015 10.1253 12.7775 10.3707 12.54L16.6091 6.30168C17.6857 5.22502 18.1924 3.97418 16.6091 2.39085C15.0257 0.807517 13.7749 1.31418 12.6982 2.39085Z"
        stroke="#292D32"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className={pathCssClass}
        d="M11.8037 3.28546C12.3341 5.17754 13.8145 6.65796 15.7145 7.19629"
        stroke="#292D32"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EditIcon;
