import React, { useEffect } from "react";
import Plyr from "plyr-react";

const VideoPlayer = (props) => {
  const { link } = props;
  const videoSrc = {
    type: "video",
    sources: [
      {
        src: link,
        provider: "youtube",
      },
    ],
  };

  // Effects
  useEffect(() => {
  }, [link]);

  // Data to render
  return (
    <div className="all-course-content__video-ele">
      <Plyr
        source={videoSrc ? videoSrc : ""}
        src={videoSrc ? videoSrc : ""}
        options={{
          controls: [
            "play",
            "progress",
            "current-time",
            "duration",
            "mute",
            "volume",
            "captions",
            "settings",
            "airplay",
            "fullscreen",
          ],
        }}
      />
    </div>
  );
};

export default VideoPlayer;
