import actions from "../actions/api";
import * as services from "./../../services";

const apiMiddleware =
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type !== actions.apiCallBegan.type) return next(action);

    // const { url, method, onStart, onSuccess, onError, data } = action.payload;
    const { service, endpoint, onStart, onSuccess, onError, data } =
      action.payload;

    if (onStart) dispatch({ type: onStart });

    next(action);

    try {
      let response;
      // Incase of a get endpoint that don't need a data
      if (data) {
        response = await services[service][endpoint](data);
      } else {
        response = await services[service][endpoint]();
      }

      // General Case
      dispatch(actions.apiCallSuccess(response.data));

      // Specific Cases
      if (onSuccess) dispatch({ type: onSuccess, payload: response.data });
    } catch (error) {
      // General case
      dispatch(actions.apiCallFailed(error.message));

      // Specific Cases
      if (onError) dispatch({ type: onError, payload: error.message });
    }
  };

export default apiMiddleware;
