import React from "react";

const Dots = ({ width, height, cssClass }) => {
  return (
    <svg
      width={width || "26"}
      height={height || "24"}
      className={`${cssClass ? cssClass : ""}`}
      viewBox="0 0 26 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.984 3C12.1474 3 11.4629 3.675 11.4629 4.5C11.4629 5.325 12.1474 6 12.984 6C13.8207 6 14.5052 5.325 14.5052 4.5C14.5052 3.675 13.8207 3 12.984 3ZM12.984 18C12.1474 18 11.4629 18.675 11.4629 19.5C11.4629 20.325 12.1474 21 12.984 21C13.8207 21 14.5052 20.325 14.5052 19.5C14.5052 18.675 13.8207 18 12.984 18Z"
        fill="#272D33"
      />
      <path
        opacity="0.5"
        d="M11.4629 12C11.4629 11.175 12.1474 10.5 12.984 10.5C13.8207 10.5 14.5052 11.175 14.5052 12C14.5052 12.825 13.8207 13.5 12.984 13.5C12.1474 13.5 11.4629 12.825 11.4629 12Z"
        fill="#5C4FFF"
      />
    </svg>
  );
};

export default Dots;
