import React, { useEffect, useState } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// import InputSearch from "../common/InputSearch";
import DashboardSideNav from "../common/DashboardSideNav";
import DashboardTopNav from "../common/DashboardTopNav";
import { images } from "./../../utils/images";
import CourseContentSingle from "./../common/CourseContentSingle";
import ContentCard from "../common/ContentCard";
import TabNavigator from "../common/TabNavigator";
import AboutCourse from "./../common/AboutCourse";

import moment from "moment";

import { getCourse } from "./../../storee/slices/singleCourse";
import CourseViewLoader from "./../common/Loaders/CourseViewLoader";
import EmptyActivityBox from "./../common/EmptyActivityBox";
import EmptyActivityState from "./../common/EmptyActivityState";
import CenteredLayout from "../common/CenterLayout";
import DashboardMaxWidth from "../common/DashboardMaxWidth";
import MyStudents from "../common/MyStudents";
import MyDiscussions from "../common/MyDiscussions";
import { useCallback } from "react";
import useTabs2 from "../../utils/Hooks/useTab2";
import CourseReviewByUser from "./../common/CourseReviewByUser";
import UploadPromoVideoModal from "./../common/Modals/UploadPromoVideoModal";
import VideoAvatar from "../common/VideoAvatar";
import { getContentForCurriculum } from "../../utils/helpers";

const InstructorCourseView = ({ getCourseData }) => {
  const [showVideoUploadModal, setShowVideoUploadModal] = useState(false);
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();

  const {
    tab1_activeTabId,
    tab1_setTabId,
    tab1_renderTabComponent,
    tab1_setAllTabContent,
  } = useTabs2(0, "tab1");

  const { activeTabId, setTabId, renderTabComponent, setAllTabContent } =
    useTabs2(0);

  const [course, courseData, isLoading] = useSelector((store) => [
    store.entities.singleCourse.data.courses,
    store.entities.singleCourse.data,
    store.entities.singleCourse.isLoading,
  ]);

  const getACourse = useCallback(() => {
    dispatch(getCourse(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // Effects
  useEffect(() => {
    getACourse();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setAllTabContent([
      <MyStudents students={[]} />,
      <MyDiscussions course={course} />,
    ]);
    tab1_setAllTabContent([
      <AboutCourse course={course} allCourseInfo={courseData} />,
      <CourseReviewByUser />,
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseData, course]);

  const goBack = (e) => {
    e.preventDefault();
    history.goBack();
  };

  // Data to render
  return (
    <>
      <main className="dashboard-main">
        <DashboardSideNav />
        <main className="dashboard__main">
          <DashboardTopNav>
            <button onClick={(e) => goBack(e)} className="btn btn-3">
              <i className="fas fa-long-arrow-alt-left m-r-6px"></i>
              Back
            </button>
            {/* <InputSearch placeholder="Search Courses" /> */}

            <Link to="/create-course">
              <button className="btn btn-1">Create Course</button>
            </Link>
          </DashboardTopNav>

          <CenteredLayout>
            <DashboardMaxWidth>
              {isLoading ? (
                <CourseViewLoader />
              ) : (
                <>
                  <div className="course-title max-width-650px">
                    <h2 className="head-8">
                      {courseData?.courses?.course_name}
                    </h2>
                  </div>

                  <div className="course__info-n-action">
                    <div className="flex-r-aicenter m-t-25px">
                      <span className="course__infos course__created-date">
                        Created{" "}
                        {moment(courseData?.courses?.created_at).format("LL")}
                      </span>
                      {courseData?.courses?.is_published === "1" ? (
                        <span className="course__infos course__is-published published">
                          Published
                        </span>
                      ) : (
                        <span className="course__infos course__is-published ">
                          <i className="fas fa-times-circle not-published-icon"></i>
                          Not Published
                        </span>
                      )}
                      {/* <i className="fas fa-share-alt course__infos m-l-auto"></i> */}
                    </div>

                    <div className="m-t-25px">
                      <button
                        className="btn btn-4"
                        onClick={() =>
                          history.push(`/edit-course/${course?.id}`)
                        }
                      >
                        Edit Course
                      </button>
                      <button
                        className="btn btn-4 editLiveBtn"
                        onClick={() =>
                          history.push(`/edit-live-class/${course?.id}`)
                        }
                      >
                        Edit Live Class
                      </button>
                    </div>
                  </div>

                  <div className="course-view__blocks">
                    <div className=" course-view__block-1">
                      {course?.promo_video_url ? (
                        <>
                          <VideoAvatar url={course?.promo_video_url} />
                          <button
                            className="btn btn-sm m-b-30px display-block"
                            onClick={() => setShowVideoUploadModal(true)}
                          >
                            Change Video
                          </button>
                        </>
                      ) : (
                        <ContentCard title="Promo Video">
                          <img
                            src={images.pic1}
                            alt="promo"
                            className="promo__img"
                          />
                          <h3 className="head-10 m-y-10px text-center">
                            Upload your promo video
                          </h3>
                          <p className="para-4 text-center">
                            Upload your 1min video{" "}
                          </p>
                          <button
                            className="btn btn-1 m-y-30px-15px btn-upload-vid"
                            onClick={() => setShowVideoUploadModal(true)}
                          >
                            Upload Video
                          </button>
                          <p className="para-6 text-center">
                            Learn more about{" "}
                            <Link to="#" className="link link-3">
                              uploading videos
                            </Link>
                          </p>
                        </ContentCard>
                      )}

                      <ContentCard>
                        <TabNavigator
                          tabTitles={["About", "Feedback"]}
                          withLine={true}
                          activeId={tab1_activeTabId}
                          handleClick={tab1_setTabId}
                        />
                        {tab1_renderTabComponent(tab1_activeTabId)}
                      </ContentCard>
                    </div>

                    <div className=" course-view__block-2">
                      <ContentCard title="Course Content">
                        {courseData?.course_contents?.length < 1 ||
                        !courseData ? (
                          <EmptyActivityBox cssClass="p-y-50px">
                            <EmptyActivityState
                              title="No Contents Available"
                              subTitle="Contents for this course are not currently availbale!"
                            />
                          </EmptyActivityBox>
                        ) : (
                          <>
                            {courseData?.curriculums?.map(
                              (curriculum, index) => (
                                <CourseContentSingle
                                  key={curriculum?.curriculum_id}
                                  opened={index === 0 ? true : false}
                                  contentName={curriculum?.topic}
                                  contents={getContentForCurriculum(
                                    courseData?.course_contents,
                                    curriculum?.curriculum_id
                                  )}
                                  hideActions={true}
                                />
                              )
                            )}
                          </>
                        )}
                      </ContentCard>
                    </div>

                    <div className=" course-view__block-3">
                      <ContentCard>
                        <TabNavigator
                          tabTitles={["My students", "Discussions"]}
                          withLine={true}
                          activeId={activeTabId}
                          handleClick={setTabId}
                        />
                        {renderTabComponent(activeTabId)}
                      </ContentCard>
                    </div>
                  </div>
                </>
              )}
            </DashboardMaxWidth>
          </CenteredLayout>
        </main>
      </main>

      {showVideoUploadModal && (
        <UploadPromoVideoModal setShowModal={setShowVideoUploadModal} />
      )}
    </>
  );
};

// Store
// const mapStateToProps = (state) => ({
//   isLoading: state.entities.singleCourse.isLoading,
//   data: state.entities.singleCourse.data,
//   isDataFetched: state.entities.instructor.isDataFetched,
// });

// const mapDispatchToProps = (dispatch) => ({
//   getCourseData: (id) => dispatch(getCourse(id)),
//   // getInstructorProfile: (data) => dispatch(getInstructorProfile()),
// });

export default InstructorCourseView;
