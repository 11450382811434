import React from "react";

const PaypalIcon = ({ width, height, cssClass, pathCssClass1 }) => {
  return (
    <svg
      width={width || "42"}
      height={height || "16"}
      className={`${cssClass ? cssClass : ""}`}
      viewBox="0 0 42 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.29305 3.38574C8.14503 3.08064 7.90307 2.83377 7.60705 2.64575C7.29252 2.45288 6.92987 2.31852 6.509 2.24276C6.08321 2.16602 5.59435 2.13053 5.02058 2.12598H2.38954C2.11632 2.13053 1.84668 2.34162 1.78486 2.60544L0.0141514 10.3197C-0.0489869 10.5793 0.120792 10.7995 0.395339 10.7995H1.6541C1.92832 10.7995 2.20123 10.5884 2.25974 10.3197L2.69461 8.44617C2.75219 8.18564 3.02183 7.97097 3.29473 7.97097H3.6587C5.19947 7.96642 6.3919 7.65189 7.24767 7.02089C8.11285 6.38857 8.53537 5.55456 8.53537 4.52414C8.53537 4.07073 8.46283 3.68987 8.29305 3.38574L8.29305 3.38574ZM5.57577 5.90455C5.19491 6.18656 4.66603 6.32546 3.98104 6.32546H3.67561C3.40275 6.32546 3.23164 6.11108 3.29016 5.84603L3.67106 4.20602C3.72503 3.94585 3.99828 3.72662 4.27244 3.72662H4.68424C5.16858 3.72662 5.53579 3.80694 5.78233 3.97217C6.02465 4.12929 6.14501 4.3853 6.14501 4.72124C6.14957 5.2319 5.9534 5.62681 5.57577 5.90456L5.57577 5.90455Z"
        fill="#0288D1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.9037 4.90088L17.8836 13.3942C17.7216 13.6592 17.3638 13.8694 17.0951 13.8694L15.786 13.8739C15.5176 13.8739 15.4269 13.6592 15.5931 13.3942L16.7097 11.4494C16.7097 11.4494 16.7991 11.3368 16.7178 10.7013C16.548 9.55379 15.6919 4.6771 15.6919 4.6771C15.6288 4.41201 15.7951 4.19279 16.0683 4.19279H17.1973C17.5427 4.19279 17.8205 4.42112 17.8836 4.6862L18.4433 8.07025L18.6544 8.0748C18.8291 7.76024 20.635 4.66343 20.635 4.66343C20.8061 4.4029 21.156 4.18368 21.4293 4.18368C21.4293 4.18368 22.6395 4.18823 22.9391 4.18368C23.388 4.17457 22.9037 4.9009 22.9037 4.9009V4.90088Z"
        fill="#0288D1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.3702 4.48892C13.8952 4.21928 13.1426 4.08984 12.12 4.08984C11.6096 4.08984 11.1035 4.12527 10.5916 4.20656C10.2152 4.26417 10.1798 4.27328 9.94689 4.32725C9.46713 4.43037 9.39134 4.91016 9.39134 4.91016L9.2388 5.54244C9.1539 5.94514 9.38678 5.93148 9.48112 5.89566C10.3715 5.63339 11.6708 5.29393 12.5643 5.68132C12.8154 5.78802 12.9406 5.97734 12.9406 6.25021L12.9188 6.42942L12.7396 6.54197C12.0227 6.58654 11.5062 6.65323 10.8784 6.74362C10.2647 6.82853 9.72763 6.98142 9.28368 7.1821C8.8039 7.39319 8.4448 7.68007 8.19922 8.0382C7.95723 8.40182 7.83557 8.84123 7.83557 9.35645C7.83557 9.84535 8.0099 10.2435 8.35079 10.5534C8.69135 10.8572 9.14022 11.0055 9.67821 11.0055C10.0178 11.0055 10.2829 10.9789 10.4666 10.9343L11.0804 10.7232L11.5956 10.4364L12.0718 10.1231L12.0754 10.1322L12.0305 10.3329L12.026 10.342C11.9778 10.5889 12.1245 10.7909 12.3623 10.8127L12.3668 10.8218H13.5417L13.5543 10.8127C13.8058 10.7818 14.0432 10.5798 14.0972 10.342L15.0164 6.36178L15.0655 6.03912L15.0873 5.73499C15.0919 5.17391 14.8463 4.75759 14.3701 4.48892L14.3702 4.48892ZM12.3405 8.96193C12.0588 9.33323 11.6409 9.53931 11.1755 9.60487C10.4339 9.70941 9.87032 9.32115 10.0724 8.7699C10.3135 8.11242 10.964 7.93273 11.5784 7.8453C11.8777 7.80832 12.2689 7.84995 12.5961 7.89474C12.5779 7.98062 12.4163 8.65654 12.3405 8.96192L12.3405 8.96193Z"
        fill="#0288D1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.1797 3.41667C30.5928 2.26358 29.0452 2.15332 27.9073 2.15332H25.2713C24.9985 2.15694 24.7343 2.37258 24.6712 2.63178L22.9008 10.3509C22.8377 10.6115 23.0134 10.8304 23.2817 10.8304L24.5418 10.8258C24.8101 10.8304 25.0879 10.6115 25.1464 10.3509L25.5767 8.47713C25.6398 8.21786 25.9082 7.99734 26.1814 7.99734H26.5405C28.0826 7.99734 29.2795 7.67955 30.1447 7.05178C30.9914 6.41494 31.4217 5.5819 31.4217 4.55054C31.4172 4.0984 31.3404 3.71754 31.1797 3.41667L31.1797 3.41667ZM28.4638 5.94007C28.0826 6.21422 27.5537 6.35313 26.872 6.35313H26.5587C26.2891 6.35313 26.1102 6.13294 26.1733 5.87337L26.5542 4.2373C26.6117 3.97674 26.8859 3.75755 27.1588 3.75755H27.5664C28.0507 3.75755 28.4143 3.83787 28.6645 3.99951C28.9064 4.1615 29.0281 4.41619 29.0326 4.75251C29.0326 5.26318 28.8401 5.6577 28.4638 5.94007Z"
        fill="#25B1F2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.2639 4.49286C36.7842 4.22778 36.0347 4.09375 35.0138 4.09375C34.5063 4.09375 33.9957 4.12921 33.4899 4.21412C33.1087 4.26812 33.0723 4.28178 32.839 4.33119C32.3596 4.43463 32.2884 4.9141 32.2884 4.9141L32.1313 5.54638C32.0464 5.94908 32.2747 5.93087 32.3824 5.8996C32.5879 5.83653 32.705 5.7793 33.1259 5.6807C33.5345 5.58637 33.9694 5.51874 34.3138 5.51874C34.8118 5.51874 35.1972 5.57726 35.4564 5.68526C35.7124 5.79196 35.8373 5.98483 35.8373 6.25447L35.8159 6.43336L35.6367 6.54591C34.9148 6.59048 34.3951 6.65717 33.7768 6.74659C33.1663 6.83282 32.6247 6.9808 32.1758 7.18604C31.6964 7.39713 31.3383 7.68401 31.0917 8.04214C30.6017 8.77745 30.5481 9.92437 31.2443 10.5574C31.5884 10.8566 32.0324 11.0095 32.5707 11.0095C33.5924 11.0095 34.1604 10.6702 34.9643 10.1271C35.0113 10.174 34.7078 10.7673 35.2639 10.8166L36.4342 10.8212L36.4524 10.8166C36.698 10.7857 36.9367 10.5838 36.9894 10.3414L37.9086 6.37028L37.958 6.04306L37.9857 5.73926C37.9857 5.17786 37.7434 4.76153 37.2639 4.49286L37.2639 4.49286ZM35.2375 8.96587C34.9227 9.36795 34.4049 9.62454 33.8834 9.62454C33.2815 9.62454 32.9576 9.48305 32.9497 8.80545C32.9459 8.48291 33.1435 8.23075 33.6411 8.03759C33.8662 7.96179 34.1486 7.89868 34.4709 7.85379C34.7578 7.81381 35.327 7.75067 35.3992 7.74712L35.4886 7.90324C35.475 7.98814 35.313 8.66048 35.2375 8.96586L35.2375 8.96587Z"
        fill="#25B1F2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.9894 2.69471L40.3036 10.3413L40.2405 10.4356C40.1419 10.6467 39.9318 10.821 39.6989 10.821H38.5286L38.5068 10.7947C38.2817 10.7625 38.1383 10.5836 38.1923 10.3462L38.2372 10.1224L39.8196 2.95065C39.8912 2.61578 40.0614 2.2198 40.4779 2.2198H41.6082C41.8814 2.21524 42.0431 2.43088 41.9894 2.69471V2.69471Z"
        fill="#25B1F2"
      />
    </svg>
  );
};

export default PaypalIcon;
