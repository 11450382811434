import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import useCreateCourse from "../store/createCourse";

const withCourseCreation = (WrappedComponent) => (props) => {
  // Hooks
  const { id } = useParams();

  //   Store
  const { setEditingCourseId, getCourseDataForEdit, data } = useCreateCourse();

  //   Handlers

  //   Effects
  useEffect(
    () => {
      // Save course id and fetch course to edit
      if (id) {
        setEditingCourseId(id);
        getCourseDataForEdit(id);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  //   Wrapped component
  return <WrappedComponent {...props} data={data} />;
};

export default withCourseCreation;
