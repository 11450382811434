import React, { useState } from "react";
import { Link } from "react-router-dom";
import useSingleCourse from "../../../store/singleCourse";
import CaretDown from "../SvgIcons/CaretDown";
import Circle from "./../SvgIcons/Circle";
import CircleCheck from "./../SvgIcons/CircleCheck";

const CourseContentDropdown = (props) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const { data } = props;

  // Store
  const { setCourseInfo } = useSingleCourse();

  return (
    <div className="course-content-dropdown">
      <button
        className="course-content-dropdown__toggler"
        onClick={() => setShowDropdown((prevState) => !prevState)}
      >
        {/* <CaretDown cssClass={showDropdown ? "rotate-180" : "rotate--180"} /> */}
        <CaretDown cssClass={showDropdown ? "rotate--180" : "rotate-0"} />
        <div className="text-left">
          <h6 className="head-28 mnm">{data?.topic}</h6>
          <p className="para-16 text-left m-t-8px">
            {data?.total_estimated_time} Min
          </p>
        </div>
      </button>
      {/*  */}

      {showDropdown && (
        <div className="course-content-dropdown__lists">
          {data?.course_contents?.map((content) => (
            <div
              key={content.id}
              className="course-content-dropdown__list-item"
            >
              {content?.is_completed ? (
                <CircleCheck cssClass="course-content-dropdown__icon animate-zoomInOut" />
              ) : (
                <Circle cssClass="course-content-dropdown__icon" />
              )}
              <Link
                to={`/user-course/${data.course_id}/contents/${data?.curriculum_id}/${content.id}`}
                onClick={() => {
                  setCourseInfo(data?.curriculum_id, content.id);
                }}
              >
                <div>
                  <h6 className="head-28 head-28--2">
                    {" "}
                    {content?.resource_name}{" "}
                  </h6>
                  <p className="para-16 text-left m-t-5px">
                    {content?.estimated_time} Min
                  </p>
                </div>
              </Link>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CourseContentDropdown;
