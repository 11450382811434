import { Link } from "react-router-dom";

import auth from "../../../services/authServices";
import Form from "./Form";
import Joi from "joi";

class LoginForm extends Form {
  constructor(props) {
    super(props);

    this.state = {
      data: { email: "", password: "" },
      error: { email: "", password: "" },
      authError: "",
      isLoading: false,
    };
  }

  schema = {
    email: Joi.string().required().label("Email"),
    password: Joi.string().required().label("Password"),
  };

  doSubmit = async () => {
    this.setState({ isLoading: true });
    const { email, password } = this.state.data;

    try {
      let res = await auth.userLogin(email, password);
      // .then(({ data }) => {
      const { data } = res;
      auth.storeUserData(data);

      this.props.history.push("/dashboard");
      window.location = "/dashboard";
      this.setState({ isLoading: false });
      // });
    } catch (ex) {
      if (ex.response && ex.response.data.message === "Unauthorised") {
        const error = { ...this.state.error };
        error.email = "Invalid email or password";
        this.setState({ error });
      } else {
        this.setState({
          error: { ...this.state.error, email: ex.response.data.message },
        });
      }
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { isLoading } = this.state;
    return (
      <form onSubmit={this.handleSubmit}>
        {this.renderInput("Email address", "email")}
        {this.renderInput("Password", "password", "password")}

        <div className="text-right">
          <Link to="/forget-password" className="onboard__link-reset">
            Forgot password?
          </Link>
        </div>

        {this.renderButton("Log in", isLoading)}
      </form>
    );
  }
}

export default LoginForm;
