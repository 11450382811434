import React from "react";

const VerifyEmail = () => {
  return (
    <svg
      width="233"
      height="233"
      viewBox="0 0 333 333"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="166" cy="141" r="119" fill="#E4EAF1" />
      <g filter="url(#filter0_f_1324_3779)">
        <circle
          cx="166.5"
          cy="166.5"
          r="93.5"
          fill="black"
          fill-opacity="0.3"
        />
      </g>
      <path
        d="M267.222 129.856C270.043 128.182 271.831 125.201 271.98 121.923C272.128 118.646 270.616 115.515 267.958 113.593L172.029 44.2352C168.604 41.7587 163.974 41.7749 160.567 44.2737L65.989 113.631C63.3531 115.564 61.8629 118.691 62.0223 121.956C62.1809 125.221 63.9676 128.188 66.7786 129.856L162.032 186.378C163.563 187.287 165.282 187.741 167 187.741C168.718 187.741 170.437 187.287 171.968 186.378L267.222 129.856Z"
        fill="#ADB2BD"
      />
      <path
        d="M267.222 129.856C270.043 128.182 271.831 125.2 271.98 121.923C272.128 118.646 270.616 115.515 267.958 113.593L172.029 44.2351C170.518 43.1424 168.772 42.5366 167 42.4141V187.741C168.719 187.741 170.437 187.287 171.968 186.378L267.222 129.856Z"
        fill="#A4ADBE"
      />
      <path
        d="M226 50.2303C226 45.6847 222.062 42 217.204 42H115.796C110.938 42 107 45.6847 107 50.2303V183H226V50.2303Z"
        fill="#DCDFEE"
      />
      <path
        d="M226 50.2303C226 45.6847 222.029 42 217.13 42H166V183H226V50.2303Z"
        fill="#EEF0F9"
      />
      <path
        d="M225.525 118.961L167 153.689L107.084 118.136V182.987H225.525V118.961Z"
        fill="#DCDFEE"
      />
      <path
        d="M167 153.69V182.988H225.525V118.962L167 153.69Z"
        fill="#CFD2DE"
      />
      <path
        d="M272.015 121.672C272.015 119.172 270.673 116.865 268.501 115.628C266.329 114.391 263.661 114.415 261.511 115.691L167 171.772L72.4894 115.691C70.3396 114.416 67.671 114.391 65.4992 115.628C63.327 116.865 61.9859 119.172 61.9859 121.672V238.046C61.9855 241.886 65.0992 245 68.9401 245H265.06C268.901 245 272.015 241.886 272.015 238.046V121.672Z"
        fill="#EEF0F9"
      />
      <path
        d="M272.015 121.672C272.015 119.172 270.673 116.865 268.501 115.628C266.329 114.391 263.661 114.415 261.511 115.691L167 171.772V245H265.06C268.901 245 272.015 241.886 272.015 238.046V121.672Z"
        fill="#DCDFEE"
      />
      <path
        d="M67.5099 239.65L167 171.772V245.001H69.1608C66.2785 245.001 65.1282 241.275 67.5099 239.65Z"
        fill="#CFD2DE"
      />
      <path
        d="M266.491 239.649L167 171.771V245H264.839C267.722 245 268.872 241.274 266.491 239.649Z"
        fill="#D3D6E4"
      />
      <path
        d="M207.567 60.0068H124.575V70.6706H207.567V60.0068Z"
        fill="#ADB2BD"
      />
      <path
        d="M207.567 81.334H124.575V91.9977H207.567V81.334Z"
        fill="#ADB2BD"
      />
      <path
        d="M207.567 102.661H124.575V113.325H207.567V102.661Z"
        fill="#ADB2BD"
      />
      <path
        d="M207.566 60.0068H166.07V70.6706H207.566V60.0068Z"
        fill="#A4ADBE"
      />
      <path d="M207.566 81.334H166.07V91.9977H207.566V81.334Z" fill="#A4ADBE" />
      <path
        d="M207.566 102.661H166.07V113.325H207.566V102.661Z"
        fill="#A4ADBE"
      />
      <circle cx="297" cy="58" r="7" fill="#DEE3EB" />
      <circle cx="47" cy="69" r="4" fill="#DEE3EB" />
      <circle cx="316" cy="90" r="12" fill="#DEE3EB" />
      <circle cx="15.5" cy="197.5" r="14.5" fill="#DEE3EB" />
      <defs>
        <filter
          id="filter0_f_1324_3779"
          x="0"
          y="0"
          width="333"
          height="333"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="36.5"
            result="effect1_foregroundBlur_1324_3779"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default VerifyEmail;
