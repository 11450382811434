import React from "react";

const Book = () => {
//   const { width, height, cssClass, pathCssClass1, pathCssClass2 } = props;
  return (
    <svg
      width="37"
      height="40"
      viewBox="0 0 37 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_773_2900)">
        <path
          d="M29.6794 24.798C25.4089 24.798 21.1219 24.798 16.8514 24.798C15.9115 24.798 15.4004 25.2102 15.4169 25.9027C15.4334 26.5622 15.9115 26.925 16.8184 26.925C18.9619 26.925 21.0889 26.925 23.2324 26.925C25.3924 26.925 27.5688 26.925 29.7288 26.925C30.5697 26.925 30.9984 26.5622 31.0149 25.8862C31.0314 25.1772 30.5862 24.798 29.6794 24.798Z"
          fill="#5C4FFF"
        />
        <path
          d="M29.6298 29.2003C27.5193 29.2003 25.3923 29.2003 23.2818 29.2003C21.0559 29.2003 18.8299 29.1838 16.604 29.2003C15.6147 29.2003 15.1035 30.0577 15.5817 30.8161C15.8785 31.2613 16.3402 31.3273 16.8183 31.3273C21.1053 31.3273 25.3923 31.3438 29.6793 31.3273C30.5862 31.3273 31.0314 30.9151 31.0149 30.2226C31.0149 29.5301 30.5862 29.2003 29.6298 29.2003Z"
          fill="#5C4FFF"
        />
        <path
          d="M29.6788 20.4617C27.5353 20.4617 25.3918 20.4617 23.2483 20.4617C21.1048 20.4617 18.9613 20.4617 16.8178 20.4617C15.878 20.4617 15.4328 20.7914 15.3998 21.4674C15.3833 22.127 15.8615 22.5722 16.7519 22.5722C21.0554 22.5886 25.3753 22.5886 29.6788 22.5722C30.5857 22.5722 31.0144 22.1435 30.9979 21.4345C30.9979 20.7914 30.5857 20.4617 29.6788 20.4617Z"
          fill="#5C4FFF"
        />
        <path
          d="M11.1138 7.94724C13.0265 7.96373 14.9556 7.96373 16.8683 7.94724C17.6927 7.94724 18.1544 7.51855 18.1379 6.85903C18.1214 6.216 17.6597 5.83677 16.8188 5.82029C15.879 5.8038 14.9556 5.82029 14.0158 5.82029C13.0595 5.82029 12.0866 5.8038 11.1303 5.82029C10.2894 5.83677 9.84422 6.23249 9.8772 6.9085C9.92666 7.66695 10.4378 7.93076 11.1138 7.94724Z"
          fill="#5C4FFF"
        />
        <path
          d="M11.0806 12.2671C12.0699 12.2836 13.0592 12.2671 14.0485 12.2671C15.0378 12.2671 16.0271 12.2671 17.0164 12.2671C17.6925 12.2671 18.1541 11.9538 18.1376 11.2448C18.1212 10.5359 17.6595 10.2391 16.9835 10.2391C15.0048 10.2391 13.0262 10.2391 11.0476 10.2391C10.3881 10.2391 9.92641 10.4864 9.89344 11.2284C9.86046 11.8714 10.2727 12.2506 11.0806 12.2671Z"
          fill="#5C4FFF"
        />
        <path
          d="M12.0697 19.4724C11.9049 18.9613 11.6245 18.5161 11.2288 18.1533C8.73908 15.0701 3.99041 15.0701 1.48417 18.1698C1.08845 18.5326 0.808148 18.9613 0.643264 19.4889C0.494868 19.7692 0.346472 20.0495 0.198076 20.3298C0.115634 20.8244 -0.0327615 21.3026 0.0167037 21.7972C0.0167037 22.2918 0.0331922 22.7865 0.0331922 23.2811C0.148611 23.9242 0.26403 24.5672 0.659752 25.1113C0.98952 25.8862 1.48417 26.5623 2.14371 27.0899C4.79834 29.2168 8.14549 29.2168 10.5528 27.0734C11.2618 26.5787 11.7235 25.9027 12.0532 25.1278C12.4655 24.5342 12.5974 23.8417 12.6798 23.1492C12.6798 22.704 12.6963 22.2589 12.6963 21.8302C12.7293 21.3685 12.6468 20.9398 12.4984 20.5111C12.4655 20.0989 12.3006 19.7857 12.0697 19.4724Z"
          fill="#5C4FFF"
        />
        <path
          d="M36.9513 33.3388V30.5359C36.9513 29.4312 36.9513 28.343 36.9513 27.2383V23.2811V19.1591V16.0264C36.9513 15.4493 36.9678 14.8887 36.9678 14.3281C36.9678 13.817 37.0503 13.2894 36.5721 12.9101C35.4344 11.6406 34.2142 10.4369 32.9446 9.28277C32.1532 8.26051 31.1144 7.48558 30.1911 6.59522C29.0864 5.49052 27.9981 4.40231 26.8934 3.29761C26.0195 2.42374 25.1456 1.56636 24.3047 0.659522C23.8595 0.181369 23.3484 0 22.7053 0C18.204 0.0164881 13.7027 0.0164881 9.20132 0.0164881C7.12378 0.0164881 5.57387 0.939819 4.50213 2.68755C3.66122 3.95713 3.74366 5.3751 3.79312 6.79308C3.79312 8.22754 3.79312 9.64551 3.79312 11.08C3.79312 11.5251 3.77664 11.9538 3.77664 12.399C3.77664 14.3941 3.77664 14.3776 5.68929 14.0808C6.15097 14.0148 6.29936 13.817 6.29936 13.3883C6.28287 12.6134 6.28287 11.8549 6.26639 11.08V6.79308C6.26639 6.49629 6.28287 6.19951 6.29936 5.88623C6.31585 4.81451 6.54669 3.84172 7.43706 3.11624C8.19553 2.6216 9.03644 2.47321 9.9433 2.4897C13.4388 2.52267 16.9179 2.52267 20.397 2.4897C21.1719 2.4897 21.5347 2.70404 21.4687 3.49547V6.79308V9.43117C21.3863 10.8821 21.7655 12.1682 22.7548 13.2399C23.8265 14.5754 25.2116 15.2185 26.9099 15.235C29.1688 15.2514 31.4112 15.2514 33.6701 15.235C34.2637 15.235 34.544 15.4328 34.511 16.0264V19.1591C34.511 20.5276 34.511 21.9126 34.511 23.2811V27.2383V30.5359V33.3388C34.511 34.4435 34.4946 35.5318 33.7691 36.4551C32.9611 37.2135 32.0213 37.4938 30.9166 37.4773C25.5084 37.4444 20.1002 37.4444 14.6755 37.4773C12.7958 37.4773 13.0266 37.0816 12.9772 39.1921C12.9607 39.7857 13.1585 40.0165 13.7851 40.0165C15.3845 39.9835 16.9674 40 18.5667 40.0165C22.8537 40.0165 27.1407 40.0165 31.4277 40.0165C33.7196 40.0165 35.6652 38.7799 36.4732 36.8343C37.1327 35.7296 37.0008 34.526 36.9513 33.3388Z"
          fill="#5C4FFF"
        />
      </g>
      <defs>
        <clipPath id="clip0_773_2900">
          <rect width="37" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Book;
