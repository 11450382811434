import React from "react";
import EditIcon from "./SvgIcons/EditIcon";
// import TrashIcon from "./SvgIcons/TrashIcon";

const ContentCardWithActions = (props) => {
  // const { data, handleEdit, handleDelete } = props;
  const { data, handleEdit } = props;
  return (
    <div className="who-is-course-for__card m-b-10px">
      <div className="top-2">
        <div>{`Week ${data?.week_number}`}</div>
        <div className="top-2__left">
          {/* <button
            className="btn btn-naked"
            onClick={() => handleDelete(data.curriculum_id)}
          >
            <TrashIcon />
          </button> */}
          <button
            className="btn btn-naked"
            onClick={() => handleEdit(data.curriculum_id)}
          >
            <EditIcon />
          </button>
        </div>
      </div>
      <div className="body">
        <div className="data m-b-10px">
          <h5 className="head-32 body-head">Topic: </h5>
          <p className="para-19"> {data?.topic} </p>
        </div>
        <div className="data">
          <h5 className="head-32">Description: </h5>
          <p className="para-19">{data?.description}</p>
        </div>
      </div>
    </div>
  );
};

export default ContentCardWithActions;
