import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import Form from "./../Forms/Form";
import Joi from "joi";
import ContentCard from "../ContentCard";
import GridView from "../GridView";

import { connect } from "react-redux";
// import {
//   getCourseCategories,
//   getLevelOfCompetence,
// } from "../../../storee/slices/helpers";
import {
  createAboutCourse,
  saveAboutcourseData,
  updateAboutCourse,
} from "../../../storee/slices/createCourse";
import { addANotification } from "../../../storee/slices/notification";
import Input from "../Input/Input";
import SpinnerBox from "./../SpinnerBox";
import Notiflix from "notiflix";
import { convertJSON } from "../../../utils/helpers";
import _ from "lodash";
import moment from "moment";

class AboutThisCourseForm extends Form {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        courseName: "",
        classSize: "",
        courseCategory: "",
        thumbnailFileUrl: "",
        startDate: "",
        endDate: "",
        levelOfCompetence: "",

        sub_title: "",
      },
      error: {
        courseName: "",
        classSize: "",
        courseCategory: "",
        thumbnailFileUrl: "",
        endDate: "",
        startDate: "",
        levelOfCompetence: "",

        sub_title: "",
      },
      // whatToLearn: this.props.persistedData.whatToLearn || "",
      aboutCourse: "",
      competenceList: [],
      courseCategoryList: [],
      whatToLearnArr: [],
      studentNeedsArr: [],

      whatStudentWillLearn: "",
      studentNeeds: "",
      whatStudentWillLearnError: "",
      studentNeedsError: "",

      isLoadingApi: false,
      // Text Editor
      // editorState: EditorState.createEmpty(),
    };
    // this.onChange = (editorState) => this.setState({ editorState });
  }

  schema = {
    courseName: Joi.string().required().label("Course name"),
    sub_title: Joi.string().required().label("Course sub title"),
    classSize: Joi.number().required().label("Class size"),
    courseCategory: Joi.string().required().label("Course category"),
    levelOfCompetence: Joi.string().required().label("Course category"),
    // whatToLearn: Joi.string().label("What to learn"),
    // studentNeeds: Joi.string().label("What student needs"),
    thumbnailFileUrl: Joi.any().label("Thumbnail"),
    startDate: Joi.string().required().label("Start date"),
    endDate: Joi.string().required().label("End date"),
  };

  async componentDidMount() {
    this.setState({ isLoadingApi: true });
    // await this.props.getLevelOfCompetence();
    // await this.props.getAllCourseCategories();

    const competenceData = this.props?.allLevelOfCompetence?.map(
      (competence) => ({
        optionName: competence?.name,
        optionId: competence?.level_of_competence_id,
      })
    );

    const courseCategoriesData = this.props?.allCourseCategories?.map(
      (category) => ({
        optionName: category?.name,
        optionId: category?.id,
      })
    );

    this.setState(
      {
        competenceList: [...competenceData],
        courseCategoryList: [...courseCategoriesData],
        aboutCourse:
          this.props.persistedData?.aboutCourse ||
          this.props.persistedData?.about_course ||
          "",
        whatToLearnArr: this.props?.persistedData?.outcomes
          ? _.compact(convertJSON(this.props?.persistedData?.outcomes))
          : [],
        studentNeedsArr:
          _.compact(
            convertJSON(this.props.persistedData?.course_requirements)
          ) || [],
        data: {
          courseName:
            this.props.persistedData.courseName ||
            this.props.persistedData.course_name ||
            "",
          classSize:
            this.props.persistedData.classSize ||
            this.props.persistedData.class_size ||
            "",
          courseCategory:
            this.props.persistedData.courseCategory ||
            this.props.persistedData.course_category ||
            "",
          thumbnailFileUrl: this.props.persistedData.thumbnailFileUrl || "",
          startDate:
            this.props.persistedData.startDate ||
            this.props.persistedData.start_date ||
            "",
          endDate:
            this.props.persistedData.endDate ||
            this.props.persistedData.end_date ||
            "",
          levelOfCompetence:
            this.props.persistedData.levelOfCompetence ||
            this.props.persistedData.level_of_competence ||
            "",

          sub_title: this.props.persistedData.sub_title || "",
        },
      },
      () => this.setState({ isLoadingApi: false })
    );
  }

  // handleEditorChange1 = (val) => {
  //   // const { data } = this.state;
  //   this.setState({ whatToLearn: val });
  // };
  handleEditorChange2 = (val) => {
    // const { data } = this.state;
    this.setState({ aboutCourse: val });
  };

  doSubmit = async () => {
    this.props.setIsLoading(true);
    const {
      whatToLearn: outcome,
      aboutCourse: about_course,
      whatToLearnArr,
      studentNeedsArr,
      whatStudentWillLearn,
      studentNeeds,
    } = this.state;

    if (!whatToLearnArr.length && !whatStudentWillLearn) {
      this.setState({
        whatStudentWillLearnError: "This field cannot be empty",
      });
      return;
    }

    if (!studentNeedsArr.length && !studentNeeds) {
      this.setState({ studentNeedsError: "This field cannot be empty" });
      return;
    }

    const {
      courseName: course_name,
      classSize: class_size,
      courseCategory: course_category,
      thumbnailFileUrl: thumbnail_file,
      startDate: start_date,
      endDate: end_date,
      levelOfCompetence: level_of_competence,
      sub_title,
    } = this.state.data;

    const { aboutCourse } = this.state;
    // Data to persit
    const dataToPersist = {
      ...this.state.data,
      aboutCourse,
      thumbnailFileUrl:
        this.state.data.thumbnailFileUrl.name ||
        this.state.data.thumbnailFileUrl,
    };
    let course_id = this.props.isEditingCourseId ? "course_id" : null;
    const data = {
      course_name,
      class_size,
      outcome,
      course_category,
      thumbnail_file,
      about_course,
      start_date,
      end_date,
      level_of_competence,
      sub_title,
      course_requirements: !studentNeedsArr.length
        ? JSON.stringify([studentNeeds])
        : JSON.stringify([...studentNeedsArr, studentNeeds]),
      outcomes: !whatToLearnArr.length
        ? JSON.stringify([whatStudentWillLearn])
        : JSON.stringify([...whatToLearnArr, whatStudentWillLearn]),
      course_overview: "",
      ...(course_id && { course_id: this.props.isEditingCourseId }),
    };

    if (!this.state.data.thumbnailFileUrl.name) {
      delete data.thumbnail_file;
    }

    // Check file size
    if (data.thumbnail_file) {
      const files = data.thumbnail_file;
      const supportedFileTypes = ["jpg", "jpeg", "png", "webp"];

      let fileType = files.name;
      fileType = fileType.split(".");
      fileType = fileType.at(-1);
      if (supportedFileTypes.includes(fileType)) {
        const fileSize = files.size / 1000000;
        if (fileSize > 1) {
          this.setState(
            {
              error: {
                ...this.state.error,
                thumbnailFileUrl: "File too large, should not be more than 1MB",
              },
            },
            () => {
              Notiflix.Notify.failure(
                "File too larg, should not be more than 1MB"
              );
              this.props.setIsLoading(false);
            }
          );
          return;
        } else {
          this.setState({
            error: {
              ...this.state.error,
              thumbnailFileUrl: "",
            },
          });
        }
      } else {
        this.setState(
          {
            error: {
              ...this.state.error,
              thumbnailFileUrl: "Unsupported file type",
            },
          },
          () => {
            Notiflix.Notify.failure("Unsupported file type");
            this.props.setIsLoading(false);
          }
        );
      }
    }

    let formData = new FormData();
    for (let key in data) {
      formData.append(key, data[key]);
    }

    // Post to database
    await this.props.persistAboutcourseData(dataToPersist);

    // Check duration difference
    const startDate = moment(start_date);
    const endDate = moment(end_date);

    const diff = endDate.diff(startDate);

    // await this.props.handleIsDurationOk(true);
    if (diff < 1) {
      Notiflix.Notify.failure(
        "An error occured! End date should be greater then start date"
      );
      await this.props.setIsLoading(false);
      await this.props.setActiveTab(0);

      return;
    }
    // await this.props.handleIsDurationOk(true);

    if (this.props.isEditingCourse) {
      try {
        await this.props.updateAboutCourse(formData);
        // Notiflix.Notify.success("About course updated");
      } catch (error) {
        Notiflix.Notify.failure("An Error occured");
      }
    } else {
      try {
        await this.props.postAboutCourse(formData);
        Notiflix.Notify.success("About course updated");
      } catch (error) {
        Notiflix.Notify.failure("An Error occured");
      }
    }
    await this.props.setIsLoading(false);

    // Do a check if course creation was successful
    if (this.props.response) {
      if (this.props.response.status === false) {
        Notiflix.Notify.failure(this.props.response.message);
        return;
      }
    }

    // if (!course_id) {
    if (this.props.nextStep) this.props.nextStep();
    // }
    Notiflix.Notify.success(
      `Course ${
        this.props.isEditingCourse ? "Updated" : "Created"
      } Successfully`
    );
    await this.props.setActiveTab(1);
  };

  handleAddWhatToLearn = () => {
    const { whatStudentWillLearn } = this.state;
    if (whatStudentWillLearn) {
      const { whatToLearnArr } = this.state;
      this.setState({
        whatToLearnArr: [...whatToLearnArr, whatStudentWillLearn],
        whatStudentWillLearn: "",
      });
    }
  };

  handleWhatToLearnRemove = (index) => {
    const whatToLearnArr = [...this.state.whatToLearnArr];
    whatToLearnArr.splice(index, 1);
    this.setState({ whatToLearnArr });
  };

  handleAddStudentNeeds = () => {
    const { studentNeeds } = this.state;
    if (studentNeeds) {
      const { studentNeedsArr } = this.state;
      this.setState({
        studentNeedsArr: [...studentNeedsArr, studentNeeds],
        studentNeeds: "",
      });
    }
  };

  handleStudentNeedsRemove = (index) => {
    const studentNeedsArr = [...this.state.studentNeedsArr];
    studentNeedsArr.splice(index, 1);
    this.setState({ studentNeedsArr });
  };

  handleArrayContentChange = (dataName, index, value) => {
    const arr = this.state[dataName];
    arr.splice(index, 1, value);

    this.setState({ [dataName]: arr });
  };

  render() {
    const {
      competenceList,
      courseCategoryList,
      whatToLearnArr,
      studentNeedsArr,
      isLoadingApi,
    } = this.state;

    return (
      <div className="about-this-course animate-slideFromLeft">
        <h3 className="head-5 m-b-10px">About this course</h3>
        {isLoadingApi ? (
          <ContentCard>
            <SpinnerBox />
          </ContentCard>
        ) : (
          <form onSubmit={this.handleSubmit} id="aboutCourseForm">
            <ContentCard title="Course Name">
              {this.renderInput(
                "Main title",
                "courseName",
                "text",
                "noIcon",
                ""
              )}
              {this.renderInput("Sub title", "sub_title", "text", "noIcon", "")}
            </ContentCard>

            <ContentCard title="What your student will learn">
              {whatToLearnArr?.length
                ? whatToLearnArr?.map((value, index) => (
                    <div key={index} className="what-to-learn-item-layout ">
                      <Input
                        value={value}
                        noicon={true}
                        onChange={(e) =>
                          // this.setState({
                          //   whatStudentWillLearn: e.target.value,
                          // })
                          this.handleArrayContentChange(
                            "whatToLearnArr",
                            index,
                            e.target.value
                          )
                        }
                        // className="input__wrapper"
                      />
                      <button
                        className="btn btn-sm btn-warn btn--remove-item"
                        onClick={(e) => {
                          e.preventDefault();
                          this.handleWhatToLearnRemove(index);
                        }}
                      >
                        Remove
                      </button>
                    </div>
                  ))
                : ""}

              {/* {this.renderInput(null, "whatStudentWillLearn", "text", "noIcon", "")} */}
              <Input
                onChange={(e) =>
                  this.setState({ whatStudentWillLearn: e.target.value })
                }
                value={this.state.whatStudentWillLearn}
                noicon={true}
                error={this.state.whatStudentWillLearnError}
              />

              <div>
                <button
                  type="button"
                  className="btn btn-1 btn-md m-t-10px"
                  onClick={() => this.handleAddWhatToLearn()}
                >
                  Add More
                </button>
              </div>
            </ContentCard>

            <ContentCard title="What your students will need to take this course">
              {studentNeedsArr.length
                ? studentNeedsArr?.map((value, index) => (
                    <div key={index} className="what-to-learn-item-layout ">
                      <Input
                        value={value}
                        noicon={true}
                        onChange={(e) =>
                          this.handleArrayContentChange(
                            "studentNeedsArr",
                            index,
                            e.target.value
                          )
                        }
                        className="input__wrapper"
                      />
                      <button
                        type="button"
                        className="btn btn-sm btn-warn btn--remove-item"
                        onClick={(e) => {
                          e.preventDefault();
                          this.handleStudentNeedsRemove(index);
                        }}
                      >
                        Remove
                      </button>
                    </div>
                  ))
                : ""}

              {/* {this.renderInput(null, "studentNeeds", "text", "noIcon", "")} */}
              <Input
                onChange={(e) =>
                  this.setState({ studentNeeds: e.target.value })
                }
                value={this.state.studentNeeds}
                noicon={true}
                error={this.state.studentNeedsError}
              />

              <div>
                <button
                  type="button"
                  className="btn btn-1 btn-md m-t-10px"
                  onClick={() => this.handleAddStudentNeeds()}
                >
                  Add More
                </button>
              </div>
            </ContentCard>

            <ContentCard title="Class Details">
              <GridView grid={3}>
                {this.renderInput(
                  "Class Size",
                  "classSize",
                  "number",
                  "noIcon",
                  null
                )}
                {this.renderSelect(
                  "Course Category",
                  "courseCategory",
                  courseCategoryList,
                  "name",
                  "id",
                  "Select Category"
                )}
                {this.renderSelect(
                  "Level of competence",
                  "levelOfCompetence",
                  competenceList,
                  "name",
                  "level_of_competence_id",
                  "Select Level"
                )}
              </GridView>
              {this.renderCustomFile(
                "Thumbnail File",
                "thumbnailFileUrl",
                "file",
                "noIcon"
              )}

              {/* <CustomFile /> */}
            </ContentCard>

            {/* <ContentCard title="What your students will learn">
            {/* {this.renderTextArea("whatToLearn", "5")} */}
            {/* <p className="text-right">Max 300 words</p> */}
            {/* <ReactQuill
              value={this.state.whatToLearn}
              onChange={this.handleEditorChange1}
            /> */}
            {/* <div style={{ height: "300px" }}></div> */}
            {/* </ReactQuill> */}
            {/* </ContentCard>  */}

            <ContentCard title="About the course">
              {/* {this.renderTextArea("aboutCourse", "5")} */}
              {/* <p className="text-right">Max 300 words</p> */}
              <ReactQuill
                value={this.state.aboutCourse}
                onChange={this.handleEditorChange2}
              />
            </ContentCard>

            <ContentCard title="Course Duration">
              <GridView grid={2}>
                {this.renderInput("Start date", "startDate", "date", "noIcon")}
                {this.renderInput("End date", "endDate", "date", "noIcon")}
                {/* <div className="course-date-selected">
                <div className="course-date-selected__title">
                  <Svg
                    cssClassName="icon-info-circle-blue"
                    iconId="icon-info-circle-blue"
                  />
                  <h5 className="m-l-5px">Date Selected</h5>
                </div>
              </div> */}
              </GridView>
            </ContentCard>

            {/* {this.renderInput("Confirm Password", "password", "password")}
             */}

            {/* {this.renderButton("Send Reset Link")} */}
          </form>
        )}
      </div>
    );
  }
}

// Store
const mapStateToProps = (state) => ({
  allLevelOfCompetence: state.entities.helpers.levelOfCompetence.data,
  allCourseCategories: state.entities.helpers.courseCategories.data,
  isLoadingCreateCourse: state.entities.createCourse.aboutCourse.isLoading,
  persistedData: state.entities.createCourse.aboutCourse.data,
  response: state.entities.createCourse.aboutCourse.apiResponse,
  isEditingCourse: state.entities.createCourse.isEditing,
  isEditingCourseId: state.entities.createCourse.courseId,
});

const mapDispatchToProps = (dispatch) => ({
  // getLevelOfCompetence: () => dispatch(getLevelOfCompetence()),
  // getAllCourseCategories: () => dispatch(getCourseCategories()),
  postAboutCourse: (data) => dispatch(createAboutCourse(data)),
  persistAboutcourseData: (data) => dispatch(saveAboutcourseData(data)),
  notifyActions: (data) => dispatch(addANotification(data)),
  updateAboutCourse: (data) => dispatch(updateAboutCourse(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AboutThisCourseForm);
