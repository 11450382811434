import create from "zustand";
import {
  getCompletedCourses,
  getOngoingCourses,
  getStudentBillingInfo,
  getStudentRecentCourse,
  getWishlistCourses,
  updateStudentBillingInfo,
} from "../services/studentServices";
import Notiflix from "notiflix";

const useStudent = create((set, get) => ({
  billingInfo: {
    isLoading: false,
    data: {},
    error: {},
  },
  recentCourse: {
    isLoading: false,
    data: {},
    error: {},
  },
  completedCourses: {
    isLoading: false,
    data: [],
    error: {},
  },
  ongoingCourses: {
    isLoading: false,
    data: [],
    error: {},
  },
  wishlistCourses: {
    isLoading: false,
    data: [],
    error: {},
  },
  enrolledCourses: { loaded: false, data: [] },
  wishlistedCourses: { loaded: false, data: [] },

  // Methods
  getBillingInfo: async () => {
    try {
      const res = await getStudentBillingInfo();
      set((prev) => ({
        ...prev,
        billingInfo: {
          ...prev.billingInfo,
          data: { ...res.data.student_bililng_info },
        },
      }));
    } catch (error) {
      // Notiflix.Notify.failure("An Error Occured");
    }
  },
  updateBillingInfo: async (data) => {
    set((prev) => ({
      ...prev,
      billingInfo: { ...prev.billingInfo, isLoading: true },
    }));
    try {
      await updateStudentBillingInfo(data);
      Notiflix.Notify.success("Billing Info updated");
      set((prev) => ({
        ...prev,
        billingInfo: { ...prev.billingInfo, isLoading: false },
      }));
    } catch (error) {
      Notiflix.Notify.failure("An Error Occured");
    }
  },
  getRecentCourses: async () => {
    try {
      const res = await getStudentRecentCourse();
      set((prev) => ({
        ...prev,
        recentCourse: { ...prev.recentCourse, data: res.data.recent_course },
      }));
    } catch (error) {}
  },
  getCompletedCourses: async () => {
    try {
      let res = await getCompletedCourses();
      // console.log(res);
      let completedCourses = {
        isLoading: false,
        data: [...res.data.completed_courses],
        loaded: true,
      };
      set({ completedCourses });
    } catch (error) {}
  },
  getOngoingCourses: async () => {
    try {
      let res = await getOngoingCourses();
      // console.log(res);
      let ongoingCourses = {
        isLoading: false,
        data: [...res.data.ongoing_courses],
        loaded: true,
      };
      set({ ongoingCourses });
    } catch (error) {}
  },
  getWishlistCourses: async () => {
    try {
      let res = await getWishlistCourses();
      // console.log(res);
      let wishlistCourses = {
        isLoading: false,
        data: [...res.data.wishlist_courses],
        loaded: true,
      };
      set({ wishlistCourses });
    } catch (error) {}
  },
  updateEnrolledCourses: () => {
    let c1 = get().completedCourses;
    let c2 = get().ongoingCourses;
    let c3 = get().wishlistCourses;

    c1 = c1.data;
    c2 = c2.data;
    c3 = c3.data;

    let concatted = c1.concat(c2);
    // concatted = concatted.concat(c3);

    let mapped = concatted.map((c) => c.id);
    let mappedWished = c3.map((c) => c.id);

    mapped = [...new Set(mapped)];
    // mappedWished = [...new Set(mappedWished)];

    let enrolledCourses = { loaded: true, data: mapped };
    let wishlistedCourses = { loaded: true, data: mappedWished };

    set({ enrolledCourses, wishlistedCourses });
  },
  getStudentCoursesInfo: async () => {
    let c1 = get().completedCourses;
    let c2 = get().ongoingCourses;
    let c3 = get().wishlistCourses;

    if (!c1.loaded) await get().getCompletedCourses();
    if (!c2.loaded) await get().getOngoingCourses();
    if (!c3.loaded) await get().getWishlistCourses();

    get().updateEnrolledCourses();
  },
}));

export default useStudent;
