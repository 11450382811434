import React from "react";

const GridView = ({ grid = null, cssClass, children }) => {
  // const styles = {
  //   gridTemplateColumns: `repeat(${grid}, 1fr)`,
  // };
  return (
    <div
      className={`grid-view ${cssClass ? cssClass : ""} ${
        grid ? `grid-view__equal--${grid}` : ""
      }`}
      
    >
      {children}
    </div>
  );
};

export default GridView;
