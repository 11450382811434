import { Link } from "react-router-dom";
import { images } from "../../utils/images";

const OnboardingNav = ({ location }) => {
  return (
    <nav className="nav bg-1 fixed-top">
      <div className="row">
        <div className="center">
          <div className="onboard__nav">
            <Link to="/">
              <svg className="site-logo">
                <use xlinkHref={`${images.spriteSVG}#site-logo`}></use>
              </svg>
            </Link>

            {location.pathname === "/register" ? (
              <>
                <p className="para-1 m-l-auto m-r-25px mq-phone">
                  Already have an account?
                </p>
                <Link to="/login">
                  <button className="btn btn__onboard-nav mq-phone">
                    Log in
                  </button>
                </Link>
              </>
            ) : (
              <>
                <p className="para-1 m-l-auto m-r-25px mq-phone">
                  Don`t have an account?
                </p>
                <Link to="/register">
                  <button className="btn btn__onboard-nav mq-phone">
                    Sign Up
                  </button>
                </Link>
              </>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default OnboardingNav;
