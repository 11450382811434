import React from "react";

const TabNavigator = (props) => {
  // Props
  const { tabTitles, activeId, withLine, handleClick } = props;

  // Data to display
  return (
    <div className={`navigator ${withLine ? "with-line" : ""}`}>
      {tabTitles.map((title, id) => (
        <span
          key={id}
          onClick={() => {
            if (handleClick) handleClick(id);
          }}
          className={`navigator__toggler ${id === activeId ? "active" : ""}`}
        >
          {title}
        </span>
      ))}
    </div>
  );
};

export default TabNavigator;
