import React from "react";

const PlusCircle = ({ width, height, cssClass }) => {
  return (
    <>
      <svg
        width={width || "20"}
        height={height || "20"}
        className={`${cssClass ? cssClass : ""}`}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 0C0.895431 0 0 0.895431 0 2V18C0 19.1046 0.895431 20 2 20H18C19.1046 20 20 19.1046 20 18V2C20 0.895431 19.1046 0 18 0H2ZM9.00051 5.26316V9H5.26367V11H9.00051V14.7368H11.0005V11H14.7374V9H11.0005V5.26316H9.00051Z"
          fill="black"
          fillOpacity="0.2"
        />
      </svg>
    </>
  );
};

export default PlusCircle;
