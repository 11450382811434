import React from "react";

const FilterIcon = ({
  width,
  height,
  cssClass,
  pathCssClass1,
  pathCssClass2,
}) => {
  return (
    <svg
      width={width || "17"}
      height={height || "14"}
      className={`${cssClass ? cssClass : ""}`}
      viewBox="0 0 17 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={pathCssClass1}
        d="M10.3944 8.23986L15.6539 3.67122C15.983 3.38476 16.169 2.99349 16.169 2.58515V1.24522C16.169 0.404463 15.3986 0 14.4483 0H1.72071C0.770413 0 0 0.404463 0 1.24522V2.60921C0 2.99582 0.165841 3.36845 0.465055 3.65103L5.29725 8.2158C5.3885 8.30197 5.51573 8.35087 5.64999 8.35165L10.0531 8.36252C10.1803 8.3633 10.3032 8.31982 10.3944 8.23986Z"
        fill="#272D33"
      />
      <path
        className={pathCssClass2}
        opacity="0.4"
        d="M5.44043 8.31152V13.4484C5.44043 13.6355 5.54924 13.8118 5.72736 13.9127C5.82915 13.9709 5.94673 14.0004 6.06431 14.0004C6.15293 14.0004 6.24155 13.9841 6.32404 13.9515L9.88654 12.5137C10.1094 12.4245 10.2524 12.2281 10.2524 12.0115V8.31152H5.44043Z"
        fill="#272D33"
      />
    </svg>
  );
};

export default FilterIcon;
