import React from "react";
import "react-quill/dist/quill.snow.css";

import Form from "./../Forms/Form";
import Joi from "joi";
import ContentCard from "../ContentCard";

import EditIcon from "./../SvgIcons/EditIcon";
import TrashIcon from "../SvgIcons/TrashIcon";
import ModalLayout from "./../ModalLayout";
import ModalChild from "../ModalChild";
import { images } from "../../../utils/images";
import { connect } from "react-redux";
import EmptyActivityBox from "../EmptyActivityBox";
import EmptyActivityState from "../EmptyActivityState";
import { getWhoIsCourseFor } from "../../../storee/slices/helpers";
import {
  createWhoIsCourseFor,
  deleteWhoIsCourseFor,
  updateWhoIsCourseFor,
} from "../../../storee/slices/createCourse";
import { addANotification } from "../../../storee/slices/notification";
import ConfirmActionModal from "../Modals/ConfirmActionModal";
import Notiflix from "notiflix";

class WhoIsThisCourseFor extends Form {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        courseCategory: "",
        description: "",
      },
      error: {
        courseCategory: "",
        description: "",
      },
      showModal: false,
      courseLevels: [],
    };
  }

  async componentDidMount() {
    await this.props.getWhoIsThisCourseForList();
    const categoryData = this.props?.whoIsThisCourseForList?.map(
      (category) => ({
        optionName: category?.name,
        optionId: category?.category_id,
      })
    );
    this.setState({
      courseLevels: [...categoryData],
    });
  }

  schema = {
    courseCategory: Joi.string().required().label("Category"),
    description: Joi.string().required().label("Description"),
  };

  handleModalOpen = () => {
    this.setState({
      showModal: true,
    });
  };

  handleModalClose = () => {
    this.setState(
      {
        showModal: false,
        isEditing: false,
        showDeleteModal: false,
      },
      () => this.resetData()
    );
  };

  handleActionModalOpen = (id) => {
    console.log({ id });
    this.setState({
      showDeleteModal: true,
      deletingId: id,
    });
  };

  handleDelete = async () => {
    const { deletingId } = this.state;

    try {
      await this.props.deleteWhoCourseFor({
        who_is_this_course_for_id: deletingId,
      });
      Notiflix.Notify.success(`Deleted successfully`);
    } catch (error) {}
    // this.props.notifyActions({
    //   type: "ok",
    //   title: "Success",
    //   description: `Deleted successfully`,
    // });
    this.setState({ showDeleteModal: false });
  };

  doSubmit = async () => {
    const { isEditing, editingWhoId } = this.state;
    const { courseCategory, description } = this.state.data;

    const data = {
      course_id: this.props.courseId,
      category: courseCategory,
      description: description,
    };

    // Check if course has been created
    if (!this.props.courseId) {
      Notiflix.Notify.failure(
        "Error while creating data, Please fill the About this course form first"
      );

      return;
    }

    // Post to database
    if (isEditing) {
      data.who_is_this_course_for_id = editingWhoId;
      try {
        await this.props.updateWhoCourseFor(data);
        Notiflix.Notify.success(
          `${isEditing ? "Updated" : "Created"} successfully`
        );
        this.handleModalClose();
      } catch (error) {}
    } else {
      try {
        await this.props.saveWhoIsCourseFor(data);
        Notiflix.Notify.success(
          `${isEditing ? "Updated" : "Created"} successfully`
        );
      } catch (error) {}
    }

    // this.props.notifyActions({
    //   type: "ok",
    //   title: "Success",
    //   description: `${isEditing ? "Updated" : "Created"} successfully`,
    // });

    // Notiflix.Notify.success(
    //   `${isEditing ? "Updated" : "Created"} successfully`
    // );

    // reset Data
    if (!isEditing) this.resetData();
  };

  resetData = () => {
    this.setState({
      data: {
        courseCategory: "",
        description: "",
      },
    });
  };

  handleEdit = (data) => {
    this.setState({ isEditing: true });

    this.setState(
      {
        data: {
          courseCategory: data.category_id,
          description: data.description,
        },
        editingWhoId: data.id,
      },
      () => this.setState({ showModal: true })
    );
  };

  render() {
    const { showModal, courseLevels, showDeleteModal } = this.state;
    const { levels, isLoadingSave, isDeleting } = this.props;

    return (
      <>
        <div className="about-this-course animate-slideFromLeft">
          <h3 className="head-5 m-b-10px">Who is this course for ?</h3>
          {levels?.length ? (
            levels?.map((data, index) => (
              <div key={index} className="who-is-course-for__card m-b-10px">
                <div className="top-2">
                  <div></div>
                  <div className="top-2__left">
                    <button
                      className="btn btn-naked"
                      onClick={() => this.handleActionModalOpen(data.id)}
                    >
                      <TrashIcon />
                    </button>
                    <button
                      className="btn btn-naked"
                      onClick={() => this.handleEdit(data)}
                    >
                      <EditIcon />
                    </button>
                  </div>
                </div>
                <div className="body">
                  <div className="data m-b-10px">
                    <h5 className="head-32 body-head">Category: </h5>
                    <p className="para-19"> {data?.category_name || "--"} </p>
                  </div>
                  <div className="data">
                    <h5 className="head-32">Description: </h5>
                    <p className="para-19">{data?.description || "--"}</p>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <EmptyActivityBox cssClass="p-y-50px">
              <EmptyActivityState
                title="No User Level Added"
                handleClick={this.handleModalOpen}
                btnText={"Add Level"}
              />
            </EmptyActivityBox>
          )}
          {levels?.length > 0 && (
            <button
              className="btn btn-1 btn-md m-t-30px"
              onClick={() => this.handleModalOpen()}
            >
              Add Category
            </button>
          )}
        </div>

        {showModal && (
          <ModalLayout>
            <ModalChild>
              <ContentCard title={"Who is this course for ?"}>
                <button
                  className="btn-close-modals"
                  onClick={() => this.handleModalClose()}
                >
                  <img src={images.pic9} alt="times" />
                </button>

                <form onSubmit={this.handleSubmit} id="whoIsThisCourseFor">
                  <ContentCard>
                    {this.renderSelect(
                      "Course Category",
                      "courseCategory",
                      courseLevels,
                      "category_id",
                      "name",
                      "Select level"
                    )}
                    {this.renderTextArea("description", 5, null, "Description")}
                  </ContentCard>

                  {/* {this.renderButton("Save")} */}

                  <button
                    className="btn btn-5 m-t-25px"
                    form="whoIsThisCourseFor"
                  >
                    {isLoadingSave ? (
                      <i className="fas fa-spinner animate-rotate"></i>
                    ) : (
                      "Save Category"
                    )}
                  </button>
                </form>
              </ContentCard>
            </ModalChild>
          </ModalLayout>
        )}

        {showDeleteModal && (
          <ConfirmActionModal
            setShowModal={this.handleModalClose}
            handleYesAction={this.handleDelete}
            title="Are you sure you wan to delete ?"
            isLoading={isDeleting}
          />
        )}
      </>
    );
  }
}

// Store
const mapStateToProps = (state) => ({
  courseId: state.entities.createCourse.courseId,
  isLoadingCourseUser: state.entities.createCourse.whoIsCourseFor.isLoading,
  levels: state.entities.createCourse.whoIsCourseFor.lists,
  isLoadingSave: state.entities.createCourse.whoIsCourseFor.isLoading,
  isDeleting: state.entities.createCourse.whoIsCourseFor.isDeleting,
  whoIsThisCourseForList: state.entities.helpers.whoIsThisCourseFor.data,
});

const mapDispatchToProps = (dispatch) => ({
  notifyActions: (data) => dispatch(addANotification(data)),
  getWhoIsThisCourseForList: () => dispatch(getWhoIsCourseFor()),
  saveWhoIsCourseFor: (data) => dispatch(createWhoIsCourseFor(data)),
  updateWhoCourseFor: (data) => dispatch(updateWhoIsCourseFor(data)),
  deleteWhoCourseFor: (data) => dispatch(deleteWhoIsCourseFor(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WhoIsThisCourseFor);
