import create from "zustand";
import { getCourseDataForEdit } from "../services/courseServices";
import { storage } from "../services/storage";
import { log } from "../utils/helpers";

const useCreateCourse = create((set, get) => ({
  data: {
    activeTab: -1,
    newCourseId: "",
    isLoading: false,
    currentFormIsValid: false,
    courseCompleted: false,
    courseEditId: "",
    //
    aboutCourse: {
      data: {},
    },
  },

  //   Methods
  loadDataFromLocal: () => {
    set({ data: storage.fetch() });
  },
  saveDataToLocal: () => {
    storage.save(get().data);
  },
  setEditingCourseId: (id) => {
    set((prev) => ({ ...prev, data: { ...prev.data, courseEditId: id } }));
  },
  getCourseDataForEdit: async (id) => {
    try {
      const res = await getCourseDataForEdit({ course_id: id });
      const { data } = res;
      //
      const {
        course_name,
        class_size,
        outcome,
        course_category,
        thumbnail_file_url,
        about_course,
        start_date,
        end_date,
        level_of_competence,
        sub_title,
        course_requirements,
        outcomes,
        course_overview,
      } = data.courses;

      const aboutCourse = {
        course_name,
        class_size,
        outcome: JSON.parse(outcome),
        course_category,
        thumbnail_file_url,
        about_course,
        start_date,
        end_date,
        level_of_competence,
        sub_title,
        course_requirements: JSON.parse(course_requirements),
        outcomes,
        course_overview,
      };
      log({ aboutCourse });
      set((prev) => ({
        ...prev,
        data: {
          ...prev.data,
          aboutCourse: { ...prev.data.aboutCourse, data: aboutCourse },
        },
      }));
    } catch (error) {}
  },
}));

export default useCreateCourse;
