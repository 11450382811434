import React from "react";
import FileContent from "./FileContent";

const CourseContentFiles = ({
  links,
  contents,
  handleEdit,
  handleDelete,
  hideActions,
}) => {
  return (
    <div className="course-content">
      {contents?.map((content, index) => (
        <FileContent
          key={index}
          type={content?.resource_type_id}
          label={content?.resource_name}
          link={content?.attachment_link}
          estimatedTime={content?.estimated_time}
          id={content?.course_content_id || content?.id}
          allContent={content}
          handleEdit={handleEdit}
          handleDelete={handleDelete}
          hideActions={hideActions}
        />
      ))}
      {/* <FileContent type="video" />
      <FileContent type="link" links={links} /> */}
    </div>
  );
};

export default React.memo(CourseContentFiles);
