import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../actions/api";

const coursesSlice = createSlice({
  name: "courses",
  initialState: {
    featuredCourses: {
      isLoading: false,
      data: {},
      error: {},
    },
    allCourses: {
      isLoading: false,
      data: {},
      error: {},
    },
    courseCategories: {
      isLoading: false,
      data: {},
      error: {},
    },
  },
  reducers: {
    // Featured Courses
    featuredCoursesRequested: (course, action) => {
      course.featuredCourses.isLoading = true;
    },
    featuredCoursesRequestFail: (course, action) => {
      course.featuredCourses.isLoading = false;
      course.featuredCourses.error = action?.payload;
    },
    featuredCoursesRequestSuccess: (course, action) => {
      course.featuredCourses.isLoading = false;
      course.featuredCourses.data = action.payload;
    },
    // Get All Courses
    allCoursesRequested: (course, action) => {
      course.allCourses.isLoading = true;
    },
    allCoursesRequestFail: (course, action) => {
      course.allCourses.isLoading = false;
      course.allCourses.error = action?.payload;
    },
    allCoursesRequestSuccess: (course, action) => {
      course.allCourses.isLoading = false;
      course.allCourses.data = action.payload;
    },
    // Get Course By category
    coursesByCategoryRequested: (course, action) => {
      course.allCourses.isLoading = true;
    },
    coursesByCategoryRequestFail: (course, action) => {
      course.allCourses.isLoading = false;
      course.allCourses.error = action?.payload;
    },
    coursesByCategoryRequestSuccess: (course, action) => {
      course.allCourses.isLoading = false;
      course.allCourses.data = action.payload;
    },
    // Get Course By Search
    coursesBySearchRequested: (course, action) => {
      course.allCourses.isLoading = true;
    },
    coursesBySearchRequestFail: (course, action) => {
      course.allCourses.isLoading = false;
      course.allCourses.error = action?.payload;
    },
    coursesBySearchRequestSuccess: (course, action) => {
      course.allCourses.isLoading = false;
      course.allCourses.data = { courses: [...action.payload.courses.data] };
    },
  },
});

// Action Types
const {
  //   Course categories
  featuredCoursesRequested,
  featuredCoursesRequestFail,
  featuredCoursesRequestSuccess,
  allCoursesRequested,
  allCoursesRequestFail,
  allCoursesRequestSuccess,
  coursesByCategoryRequested,
  coursesByCategoryRequestFail,
  coursesByCategoryRequestSuccess,
  coursesBySearchRequested,
  coursesBySearchRequestFail,
  coursesBySearchRequestSuccess,
} = coursesSlice.actions;

//   Action Creators
const service = "courseServices";

export const getFeaturedCourses = () =>
  apiCallBegan({
    service,
    endpoint: "getFeaturedCourses",
    onStart: featuredCoursesRequested.type,
    onSuccess: featuredCoursesRequestSuccess.type,
    onError: featuredCoursesRequestFail.type,
  });

export const getAllCourses = () =>
  apiCallBegan({
    service,
    endpoint: "getAllCourses",
    onStart: allCoursesRequested.type,
    onSuccess: allCoursesRequestSuccess.type,
    onError: allCoursesRequestFail.type,
  });

export const getCoursesByCategory = (categoryId) =>
  apiCallBegan({
    service,
    endpoint: "getCourseByCategory",
    data: categoryId,
    onStart: coursesByCategoryRequested.type,
    onSuccess: coursesByCategoryRequestSuccess.type,
    onError: coursesByCategoryRequestFail.type,
  });

export const getAllCoursesBySearch = (data) =>
  apiCallBegan({
    service,
    data,
    endpoint: "getAllCoursesOnSearch",
    onStart: coursesBySearchRequested.type,
    onSuccess: coursesBySearchRequestSuccess.type,
    onError: coursesBySearchRequestFail.type,
  });

// getDashboardData

// *********************** End of Action Creators *****************************

export default coursesSlice.reducer;
