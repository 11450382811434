import { React, useState } from "react";
import ContentCard from "./common/ContentCard";
import JoinEvent from "./common/JoinEvent";
import moment from "moment";
import { formatDateTime, handleTimeDifference } from "../utils/helpers";
import RowActions from "./common/RowActions";
import LiveClassModal from "./common/Modals/LiveClassModal";
import { deleteLiveClasses } from "../services/courseServices";
import LiveClassVideoModal from "./common/Modals/LiveClassVideoModal";

const LiveClasses = (props) => {
  // States
  const [joinEvent, setJoinEvent] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  // Hooks

  // Props
  const { handleReloadClasses, data } = props;

  //   Handlers
  const handleJoinEvent = (e) => {
    e.stopPropagation();
    setJoinEvent((prevState) => !prevState);
  };

  const handleUploadModal = (e) => {
    e.stopPropagation();
    setShowModal((prevState) => !prevState);
  };

  const handleEditLiveClass = async () => {
    setShowEdit(true);
  };

  const handleDeleteLiveClass = async () => {
    setIsDeleting(true);
    try {
      await deleteLiveClasses({ live_class_id: data.id });
      setIsDeleting(false);
      handleReloadClasses();
    } catch (error) {
      setIsDeleting(false);
    }
  };

  // Data to render
  return (
    <>
      <ContentCard cssClass2="liveClassGrid">
        <div className="liveClassBlock" onClick={handleJoinEvent}>
          <div className="liveClassBlock1">
            <button
              onClick={handleJoinEvent}
              className={joinEvent ? "dropdownBtnFlip" : "dropdownBtn"}
            />
            <div className="eventCardDate liveClassDate">
              <span className="dateTitle liveClassMonth">
                {moment(data?.date).format("MMM")}
              </span>
              <span className="dateNum liveClassDay m-t-5px">
                {moment(data?.date).format("Do")}
              </span>
            </div>
            <div className="liveClassInfo">
              <span className="modalInfoTitle liveClassTitle max-width-500px">
                {data?.curriculum_name || "--"}
              </span>
              <div className="eventsModalInfo1Time liveClassDateInfo">
                <p className="para-5">{moment(data?.date).format("LL")}</p>

                <p className="para-5">
                  {formatDateTime(data?.start_time)} -{" "}
                  {formatDateTime(data?.end_time)}
                  {/* {moment(data?.start_time, "hh").format("LT")} -{" "}
                  {moment(data?.end_time, "hh").format("LT")} */}
                </p>
                <p className="para-5">{data?.timezone_name_abbr || "--"}</p>
              </div>
            </div>
          </div>
          <div className="liveClassBlock2">
            <div className="meetupTyp">
              {" "}
              Meetup: {data?.live_class_type_name}{" "}
            </div>

            <>
              {data?.live_class_type_name !== "Live Session" ? null : (
                <>
                  {data?.link_to_recording &&
                  !handleTimeDifference(data?.date) ? (
                    <button
                      onClick={() =>
                        window.open(data?.link_to_recording, "_blank")
                      }
                      className="btn btn-4"
                    >
                      Watch Recording
                    </button>
                  ) : (
                    <>
                      {handleTimeDifference(data?.date) ? null : (
                        <button
                          onClick={handleUploadModal}
                          className="btn btn-4"
                        >
                          Upload Video
                        </button>
                      )}
                    </>
                  )}
                </>
              )}
            </>

            <RowActions
              handleEdit={handleEditLiveClass}
              handleDelete={handleDeleteLiveClass}
              isDeleting={isDeleting}
            />
          </div>
        </div>
        {joinEvent && (
          <JoinEvent date={data?.date} link={data?.link_to_live_class} />
        )}
        {showModal && (
          <LiveClassVideoModal
            title="Upload recording"
            setShowModal={() => setShowModal(false)}
            handleReloadClasses={handleReloadClasses}
            classId={data?.id}
            data={data}
          />
        )}
        {/* {showModal && (
          <ModalLayout>
            <ModalChild>
              <ContentCard title="Upload recording">
                <button
                  className="btn-close-modals"
                  onClick={() => setShowModal(false)}
                >
                  <img src={images?.pic9} alt="times" />
                </button>
                <div className="eventsModal">
                  <div className="eventsModalInfo uploadModalInfo">
                    <div className="eventsModalInfo2 uploadModalContent">
                      <div className="eventsModalInfo1">
                        <h4 className="modalInfoTitle">
                          {data?.curriculum_name}
                        </h4>
                        <div className="eventsModalInfo1Time">
                          <p className="para-5">
                            {moment(data?.date).format("LL")}
                          </p>
                          <p className="para-5">
                            {moment(data?.start_time, "hh").format("LT")}
                          </p>
                          <p className="para-5">
                            {data?.timezone_name_abbr || "--"}
                          </p>
                        </div>
                      </div>
                      <div className="uploadLinkColumn">
                        <h3 className="linkTitle">Link</h3>
                        <input type="text" className="linkUploadBox" />
                        <button className="btn btn-1">Upload</button>
                      </div>
                    </div>
                  </div>
                </div>
              </ContentCard>
            </ModalChild>
          </ModalLayout>
        )} */}
      </ContentCard>

      {showEdit && (
        <LiveClassModal
          title="Edit live class"
          setShowModal={() => setShowEdit(false)}
          handleReloadClasses={handleReloadClasses}
          classId={data?.id}
        />
      )}
    </>
  );
};

export default LiveClasses;
