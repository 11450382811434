import Skeleton from "react-loading-skeleton";

const StudentDashboardLoader = () => {
  const loaderBgColor = "#ffffff6e";
  const style = {
    main: {
      display: "grid",
      gridTemplateColumns: "73% 25%",
      gridGap: "2.5rem",
      gridColumnGap: "2%",
      marginTop: "4rem",
    },

    studentCardsLayout: {
      display: "grid",
      gridTemplateColumns: "repeat(3, 1fr)",
      gridGap: "2rem",
      marginBottom: "3rem",
    },

    studentCards: {
      borderRadius: "4px",
      overflow: "hidden",
      backgroundColor: loaderBgColor,
      padding: "2rem",
    },
    studentCard: {
      borderRadius: "4px",
      overflow: "hidden",
    },
    whatToLearn: {
      borderRadius: "4px",
      overflow: "hidden",
      backgroundColor: loaderBgColor,
      padding: "2rem",
    },
    whatToLearnTagsWrapper: {
      display: "flex",
      flexFlow: "row wrap",
      marginTop: "30px",
      gap: "15px",
    },
    whatToLearnTag: {
      borderRadius: " 0.4rem",
      overflow: "hidden",
      display: "inline-block",
    },
    featuredCoursesSection: {
      marginTop: "45px",
    },
    featuredCourses: {
      marginTop: "15px",
      borderRadius: "4px",
      overflow: "hidden",
      backgroundColor: loaderBgColor,
      display: "flex",
      flexFlow: "row wrap",
      gap: "2rem",
      padding: "1.5rem 2rem",
    },
    featuredCourse: {
      flexShrink: 0,
      flexBasis: "20rem",
      overflow: "hidden",
      borderRadius: "0.4rem",
    },
  };

  return (
    <div className="" style={style.main}>
      {/* Left side of the dashboard main content */}
      <div>
        {/* Student Cards */}
        <div className="m-b-20px" style={style.studentCardsLayout}>
          <div style={style.studentCard}>
            <Skeleton height={"86px"} width={"100%"} />
          </div>
          <div style={style.studentCard}>
            <Skeleton height={"86px"} width={"100%"} />
          </div>
          <div style={style.studentCard}>
            <Skeleton height={"86px"} width={"100%"} />
          </div>
        </div>

        {/* What to learn */}
        {/* <div className="m-b-20px" style={style.whatToLearn}>
          <div className="flex-r-jcbetween">
            <div>
              <div className="m-b-2px">
                <Skeleton height={"13px"} width={"130px"} />
              </div>
              <div>
                <Skeleton height={"8px"} width={"200px"} />
              </div>
            </div>
            <Skeleton height={"18px"} width={"18px"} />
          </div>
          <div className="flex-rowwrap" style={style.whatToLearnTagsWrapper}>
            <div className="flex-rowwrap" style={style.whatToLearnTag}>
              <Skeleton height={"32px"} width={"130px"} />
            </div>
            <div className="flex-rowwrap" style={style.whatToLearnTag}>
              <Skeleton height={"32px"} width={"110px"} />
            </div>
            <div className="flex-rowwrap" style={style.whatToLearnTag}>
              <Skeleton height={"32px"} width={"150px"} />
            </div>
            <div className="flex-rowwrap" style={style.whatToLearnTag}>
              <Skeleton height={"32px"} width={"120px"} />
            </div>
            <div className="flex-rowwrap" style={style.whatToLearnTag}>
              <Skeleton height={"32px"} width={"120px"} />
            </div>
            <div className="flex-rowwrap" style={style.whatToLearnTag}>
              <Skeleton height={"32px"} width={"100px"} />
            </div>
            <div className="flex-rowwrap" style={style.whatToLearnTag}>
              <Skeleton height={"32px"} width={"170px"} />
            </div>
            <div className="flex-rowwrap" style={style.whatToLearnTag}>
              <Skeleton height={"32px"} width={"130px"} />
            </div>
          </div>
        </div> */}

        {/* Featured Courses */}
        <div style={style.featuredCoursesSection}>
          <div>
            <div className="m-b-2px">
              <Skeleton height={"13px"} width={"130px"} />
            </div>
            <div>
              <Skeleton height={"8px"} width={"200px"} />
            </div>
          </div>

          <div style={style.featuredCourses}>
            <div style={style.featuredCourse}>
              <Skeleton height={"207px"} width={"100%"} />
            </div>
            <div style={style.featuredCourse}>
              <Skeleton height={"207px"} width={"100%"} />
            </div>
            <div style={style.featuredCourse}>
              <Skeleton height={"207px"} width={"100%"} />
            </div>
          </div>
        </div>

        {/* Explore Courses */}
        <div style={style.featuredCoursesSection}>
          <div>
            <div className="m-b-2px">
              <Skeleton height={"13px"} width={"130px"} />
            </div>
            <div>
              <Skeleton height={"8px"} width={"200px"} />
            </div>
          </div>

          <div style={style.featuredCourses}>
            <div style={style.featuredCourse}>
              <Skeleton height={"207px"} width={"100%"} />
            </div>
            <div style={style.featuredCourse}>
              <Skeleton height={"207px"} width={"100%"} />
            </div>
            <div style={style.featuredCourse}>
              <Skeleton height={"207px"} width={"100%"} />
            </div>
          </div>
        </div>
      </div>

      {/* Right side of the dashboard main content */}
      <div>
        <div style={style.studentCards}>
          <div style={style.studentCard} className="m-b-10px">
            <Skeleton height={"59px"} width={"100%"} />
          </div>
          <div style={style.studentCard} className="m-b-10px">
            <Skeleton height={"59px"} width={"100%"} />
          </div>
          <div style={style.studentCard} className="">
            <Skeleton height={"59px"} width={"100%"} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentDashboardLoader;
