import Form from "./../Forms/Form";
import Joi from "joi";

class ComposeMessage extends Form {
  constructor(props) {
    super(props);

    this.state = {
      data: { message: "" },
      error: { message: "" },
    };
  }

  schema = {
    message: Joi.any().label("Message"),
  };

  doSubmit = () => {
  };

  render() {
    const placeholder = "Send Message";
    return (
      <div className="compose-message">
        <form onSubmit={this.handleSubmit}>
          {this.renderTextArea("message", "3", placeholder)}
          <div className="compose-message__actions">
            <button type="submit" className="btn btn-sm m-l-auto">
              Post
            </button>
          </div>
        </form>
      </div>
    );
  }
}

export default ComposeMessage;
