import React from "react";
import Svg from "./Svg";
import LinkIcon from "./SvgIcons/LinkIcon";

const EmptyActivityState = ({
  title,
  subTitle,
  handleClick,
  btnText,
  noImage,
  iconType,
}) => {
  return (
    <div className="empty-activity-state">
      {!noImage && (
        <>
          {iconType === "link" ? (
            <LinkIcon />
          ) : (
            <Svg
              cssClassName="empty-activity-state__icon"
              iconId="icon-no-document"
            />
          )}
        </>
      )}

      <h3 className="empty-activity-state__heading m-t-25px">
        {title ? title : "No Activity"}
      </h3>
      {subTitle && (
        <p className="para-8 m-t-8px text-center">
          {subTitle ? subTitle : ""}
          {/* {subTitle || ""} */}
        </p>
      )}

      {handleClick && (
        <div>
          <button
            className="btn btn-1 btn-md m-t-20px"
            onClick={() => handleClick()}
          >
            {btnText}
          </button>
        </div>
      )}
    </div>
  );
};

export default EmptyActivityState;
