import { useState } from "react";

const useTabs2 = (activeTab, name) => {
  const [allTabContent, setAllTabContent] = useState([]);
  const [activeTabId, setActiveTabId] = useState(activeTab);

  const setTabId = (id) => setActiveTabId(id);

  const renderTabComponent = (index) => {
    return allTabContent[index];
  };

  const renamedTabNames = {
    [`${name ? `${name}_` : ""}activeTabId`]: activeTabId,
    [`${name ? `${name}_` : ""}setTabId`]: setTabId,
    [`${name ? `${name}_` : ""}renderTabComponent`]: renderTabComponent,
    [`${name ? `${name}_` : ""}setAllTabContent`]: setAllTabContent,
  };

  return renamedTabNames;
};

export default useTabs2;
