import React from "react";
import authServices from "../../services/authServices";
import UserAvatar from "./UserAvatar/Index";
import { useHistory } from "react-router-dom";
import CenteredLayout from "./CenterLayout";
import DashboardMaxWidth from "./DashboardMaxWidth";

const DashboardTopNav = (props) => {
  // Hooks
  const history = useHistory();

  const user = authServices.getCurrentUser();

  // Props
  const { children, back, url } = props;

  // Handler
  const goBack = (e) => {
    e.preventDefault();
    history.goBack();
  };
  const goBackToUrl = (e) => {
    e.preventDefault();
    history.replace(url);
  };

  return (
    <nav className="dashboard__top-nav-wrapper">
      <CenteredLayout>
        <DashboardMaxWidth>
          <nav className="dashboard__top-nav">
            <div className="dashboard__top-nav__left-side">
              {back && (
                <button
                  onClick={(e) => (url ? goBackToUrl(e) : goBack(e))}
                  className="btn btn-3 m-r-25px dashboard__top-nav--back-btn"
                >
                  <i className="fas fa-long-arrow-alt-left m-r-6px"></i>
                  Back
                </button>
              )}
              <button className="btn dashboard__top-nav--toggler">
                <span className="dashboard__top-nav--bars"></span>
                <span className="dashboard__top-nav--bars"></span>
                <span className="dashboard__top-nav--bars"></span>
              </button>

              <div className="dashboard__top-nav__right">{children}</div>
            </div>

            {user?.role_id === "1" && <UserAvatar user={user && user} />}
          </nav>
        </DashboardMaxWidth>
      </CenteredLayout>
    </nav>
  );
};

export default DashboardTopNav;
