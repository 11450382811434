import React from "react";

const AboutCourseIcon = ({ width, height, cssClass, pathCssClass1 }) => {
  return (
    <svg
      width={width || "22"}
      height={height || "22"}
      className={cssClass ? cssClass : ""}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={pathCssClass1 ? pathCssClass1 : ""}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 1C16.5232 1 21 5.47784 21 11C21 16.5222 16.5232 21 11 21C5.47784 21 1 16.5222 1 11C1 5.47784 5.47784 1 11 1Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className={pathCssClass1 ? pathCssClass1 : ""}
        d="M10.9946 6.89648V11.6738"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className={pathCssClass1 ? pathCssClass1 : ""}
        d="M10.9951 15.1035H11.0059"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default AboutCourseIcon;
