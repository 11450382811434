import { useState } from "react";

const useTabs = (activeTab) => {
  const [allTabContent, setAllTabContent] = useState([]);
  const [activeTabId, setActiveTabId] = useState(activeTab);

  const setTabId = (id) => setActiveTabId(id);

  const renderTabComponent = (index) => {
    return allTabContent[index];
  };

  return [activeTabId, setTabId, renderTabComponent, setAllTabContent];
};

export default useTabs;
