import React from "react";

const LiveClassesIcon = ({ width, height, cssClass, pathCssClass1 }) => {
  return (
    <svg
      width={width || "31"}
      height={height || "20"}
      className={cssClass ? cssClass : ""}
      viewBox="0 0 31 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2_1991)">
        <path
          className={pathCssClass1 ? pathCssClass1 : ""}
          d="M14.7861 2H15.7401V3H14.7861V2Z"
          fill="black"
        />
        <path
          className={pathCssClass1 ? pathCssClass1 : ""}
          d="M14.7862 0H3.33882C2.54585 0 1.90789 0.66875 1.90789 1.5V16H0V17.5C0 18.8813 1.06723 20 2.38487 20H28.1414C29.4591 20 30.5263 18.8813 30.5263 17.5V16H28.6184V1.5C28.6184 0.66875 27.9805 0 27.1875 0H15.7401H14.7862ZM2.86184 1.5C2.86184 1.225 3.07648 1 3.33882 1H27.1875C27.4498 1 27.6645 1.225 27.6645 1.5V16H26.7105V2H16.6941V3H25.7566V16H20.9868V14.6938C20.9868 14.0688 20.6172 13.5125 20.0567 13.2875L17.1711 12.1562V10.6125L17.648 10.2312V6C17.648 5.16875 17.0101 4.5 16.2171 4.5H14.8458C13.4685 4.5 12.2701 5.48125 11.9422 6.88125L11.7932 7.5H12.8783V10.2312L13.3553 10.6125V12.1562L10.4696 13.2937C9.90913 13.5125 9.53947 14.075 9.53947 14.7V16H4.76974V3H13.8322V2H3.81579V16H2.86184V1.5ZM10.4934 16V14.6938C10.4934 14.4875 10.6186 14.3 10.8035 14.225L13.5341 13.15C13.8084 13.6125 14.2734 13.9062 14.7862 13.9563V15.5H15.7401V13.9563C16.2529 13.9062 16.7179 13.6062 16.9922 13.15L19.7229 14.225C19.9077 14.3 20.0329 14.4875 20.0329 14.6938V16H17.1711V16.5C17.1711 16.775 16.9564 17 16.6941 17H13.8322C13.5699 17 13.3553 16.775 13.3553 16.5V16H10.4934ZM15.7401 10.5H14.7862L13.8322 9.7375V7.5H15.7401V6.5H13.1168C13.4924 5.875 14.1423 5.5 14.8458 5.5H16.2171C16.4794 5.5 16.6941 5.725 16.6941 6V9.7375L15.7401 10.5ZM16.2171 11.375V12.4875C16.2111 12.575 16.1396 13 15.2632 13C14.3867 13 14.3152 12.5688 14.3092 12.5V11.375L14.4642 11.5H16.0561L16.2171 11.375ZM29.5724 17.5C29.5724 18.3312 28.9344 19 28.1414 19H2.38487C1.5919 19 0.953947 18.3312 0.953947 17.5V17H12.4848C12.6875 17.6 13.2301 18 13.8322 18H16.6941C17.3022 18 17.8388 17.6 18.0415 17H29.5724V17.5Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_2_1991">
          <rect width="30.5263" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LiveClassesIcon;
