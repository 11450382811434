import React from "react";

const WhoCourseForIcon = ({ width, height, cssClass, pathCssClass1 }) => {
  return (
    <svg
      width={width || "19"}
      height={height || "19"}
      className={cssClass ? cssClass : ""}
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={pathCssClass1 ? pathCssClass1 : ""}
        d="M9.49984 9.50016C11.686 9.50016 13.4582 7.72796 13.4582 5.54183C13.4582 3.3557 11.686 1.5835 9.49984 1.5835C7.31371 1.5835 5.5415 3.3557 5.5415 5.54183C5.5415 7.72796 7.31371 9.50016 9.49984 9.50016Z"
        stroke="#292D32"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className={pathCssClass1 ? pathCssClass1 : ""}
        d="M2.69971 17.4167C2.69971 14.3529 5.74762 11.875 9.50012 11.875C10.2601 11.875 10.9964 11.9779 11.6851 12.1679"
        stroke="#292D32"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className={pathCssClass1 ? pathCssClass1 : ""}
        d="M17.4168 14.2502C17.4168 14.8439 17.2506 15.406 16.9577 15.881C16.7914 16.166 16.5777 16.4193 16.3323 16.6252C15.7781 17.1239 15.0497 17.4168 14.2502 17.4168C13.0943 17.4168 12.0889 16.7993 11.5427 15.881C11.2497 15.406 11.0835 14.8439 11.0835 14.2502C11.0835 13.2527 11.5427 12.3581 12.271 11.7802C12.8172 11.3448 13.506 11.0835 14.2502 11.0835C15.9997 11.0835 17.4168 12.5006 17.4168 14.2502Z"
        stroke="#292D32"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className={pathCssClass1 ? pathCssClass1 : ""}
        d="M13.0151 14.2499L13.7989 15.0337L15.4851 13.4741"
        stroke="#292D32"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default WhoCourseForIcon;
