import React from "react";
import { convertNameToAvatar } from "../../../utils/helpers";
import { AvatarStyle, AvatarName } from "./Style";

const UserAvatar = (props) => {
  const { user } = props;

  return (
    <AvatarStyle>
      <AvatarName>{convertNameToAvatar(user?.name)}</AvatarName>
      <h5>Welcome! {user?.name}</h5>
    </AvatarStyle>
  );
};

export default UserAvatar;
