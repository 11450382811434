import React from "react";

const WarnIcon = ({ width, height, cssClass }) => {
  return (
    <svg
      width={width || "69"}
      height={height || "69"}
      className={`${cssClass ? cssClass : ""}`}
      viewBox="0 0 69 69"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M34.5 69C15.4462 69 0 53.5538 0 34.5C0 15.4462 15.4462 0 34.5 0C53.5538 0 69 15.4462 69 34.5C68.9791 53.5452 53.5452 68.9791 34.5 69ZM31.05 51.75V58.65H37.95V51.75H31.05ZM34.5 17.25C38.3108 17.25 41.4 20.3392 41.4 24.15C41.4121 25.981 40.677 27.7378 39.3645 29.0145L35.0865 33.3615C32.504 35.9549 31.0528 39.465 31.05 43.125V44.85H37.95C37.6819 41.1413 39.1777 37.5231 41.9865 35.0865L45.0915 31.9125C47.1549 29.8574 48.3102 27.0622 48.3001 24.15C48.3001 16.5285 42.1215 10.35 34.5 10.35C26.8785 10.35 20.7 16.5285 20.7 24.15H27.6C27.6 20.3392 30.6892 17.25 34.5 17.25Z"
        fill="#F5D4DF"
      />
    </svg>
  );
};

export default WarnIcon;
