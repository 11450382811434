import React from "react";

const SettlementIcon = ({
  width,
  height,
  cssClass,
  pathCssClass1,
  pathCssClass2,
  title = "Settlements",
}) => {
  return (
    <>
      <svg
        width={width || "18"}
        height={height || "16"}
        className={`${cssClass ? cssClass : ""}`}
        viewBox="0 0 18 16"
        fill="none"
      >
        <path
          className={pathCssClass1}
          opacity="0.4"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.3297 4.97927H13.8008C12.1586 4.98216 10.8282 6.27929 10.8253 7.88047C10.8231 9.48527 12.1549 10.7882 13.8008 10.7903H17.3327V11.0453C17.3327 13.8446 15.6356 15.5 12.7638 15.5H5.23565C2.36305 15.5 0.666016 13.8446 0.666016 11.0453V4.94822C0.666016 2.14885 2.36305 0.5 5.23565 0.5H12.7608C15.6327 0.5 17.3297 2.14885 17.3297 4.94822V4.97927ZM4.61564 4.97278H9.31564H9.31861H9.32453C9.67638 4.97133 9.96083 4.69183 9.95935 4.34805C9.95787 4.00499 9.67046 3.72765 9.31861 3.7291H4.61564C4.26602 3.73054 3.98231 4.00715 3.98083 4.34877C3.97935 4.69183 4.26379 4.97133 4.61564 4.97278Z"
          fill="#272D33"
        />
        <path
          className={pathCssClass2}
          opacity="0.4"
          d="M12.3632 8.24722C12.5375 9.03987 13.2324 9.59756 14.0259 9.58306H16.7341C17.0642 9.58306 17.332 9.30965 17.332 8.97171V6.86208C17.3313 6.52485 17.0642 6.25073 16.7341 6.25H13.9621C13.0596 6.2529 12.3306 7.00204 12.332 7.92523C12.332 8.03329 12.3427 8.14134 12.3632 8.24722Z"
          fill="#272D33"
        />
        <ellipse
          cx="13.9993"
          cy="7.91732"
          rx="0.833334"
          ry="0.833333"
          fill="#272D33"
        />
      </svg>
      <span>{title}</span>
    </>
  );
};

export default SettlementIcon;
