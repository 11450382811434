import Form from "./Form";
import Joi from "joi";

class DiscussionForm extends Form {
  constructor(props) {
    super(props);

    this.state = {
      data: { discussion: "" },
      error: { email: "" },
    };
  }

  schema = {
    discussion: Joi.string().required().label("Discussion"),
  };

  doSubmit = () => {
  };

  render() {
    const placeholder =
      this.props.placeholder ||
      "Add to the discussions. Direct a comment to a person by using their names";
    return (
      <form onSubmit={this.handleSubmit}>
        {this.renderTextArea("discussion", "5", placeholder)}
        <button type="submit" className="btn btn-sm m-l-auto">
          Post
        </button>
      </form>
    );
  }
}

export default DiscussionForm;
