import Form from "./../Forms/Form";
import Joi from "joi";
import ContentCard from "../ContentCard";
import GridView from "../GridView";
import { connect } from "react-redux";
import { getAllCountries } from "./../../../storee/slices/helpers";
import SpinnerBox from "../SpinnerBox";
import { updateStudentBilling } from "../../../storee/slices/student";
import withStudentStore from "../../../hoc/withStudentStore";

class BillingAddress extends Form {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        billing_country: "",
        billing_address: "",
        billing_postal_code: "",
        billing_state: "",
        billing_city: "",
        // billing_organization: "",
        // billing_tax_id: "",
      },
      error: {
        billing_country: "",
        billing_address: "",
        billing_postal_code: "",
        billing_state: "",
        billing_city: "",
        // billing_organization: "",
        // billing_tax_id: "",
      },
      isLoading: false,
      countries: [],
    };
  }

  schema = {
    billing_country: Joi.string().required().label("Country"),
    billing_address: Joi.string().required().label("Address"),
    billing_postal_code: Joi.string().required().label("Postal Code"),
    billing_state: Joi.string().required().label("Province"),
    billing_city: Joi.string().required().label("City"),
    // billing_organization: Joi.string().required().label("Billing Organization"),
    // billing_tax_id: Joi.string().required().label("Tax Id"),
  };

  async componentDidMount() {
    await this.props.getAllCountries();
    const countryData = this.props.countryData.countries.map((country) => ({
      optionName: country?.country_name,
      optionId: country?.country_id,
    }));
    this.setState({ countries: countryData });
    await this.props.store.getBillingInfo();
    const {
      billing_country,
      billing_address,
      billing_postal_code,
      billing_state,
      billing_city,
    } = this.props.store.billingInfo.data;

    this.setState({
      data: {
        billing_country,
        billing_address,
        billing_postal_code,
        billing_state,
        billing_city,
      },
    });
  }

  doSubmit = async () => {
    // this.props.updatePassword(passwordData);
    const {
      billing_country,
      billing_address,
      billing_postal_code,
      billing_state,
      billing_city,
      // billing_organization,
      // billing_tax_id,
    } = this.state.data;

    const billingData = {
      billing_country,
      billing_address,
      billing_postal_code,
      billing_state,
      billing_city,
      billing_organization: "",
      billing_tax_id: "",
    };
    let formData = new FormData();
    for (let key in billingData) {
      formData.append(key, billingData[key]);
    }
    await this.props.store.updateBillingInfo(formData);
    if (this.props.close) {
      this.props.close();
    }
    // this.setState({ data: { ...this.props.store.billingInfo.data } });
  };

  render() {
    const { isLoadingCountryData } = this.props;
    const { countries } = this.state;
    const { isLoading } = this.props.store.billingInfo;

    return (
      <div className="animate-slideFromLeft">
        {this.props?.type === "edit" ? (
          ""
        ) : (
          <h3 className="head-5 m-b-10px">Billing Address</h3>
        )}

        <form onSubmit={this.handleSubmit}>
          <ContentCard>
            {isLoadingCountryData ? (
              <SpinnerBox />
            ) : (
              <>
                {this.renderSelect(
                  "Country / Region",
                  "billing_country",
                  countries,
                  "country_id",
                  "country_name",
                  "Select Country"
                )}
                {/* <div className="m-b-20px"></div> */}
                {this.renderInput(
                  "Address",
                  "billing_address",
                  "text",
                  "noicon",
                  null
                )}

                <GridView grid={2}>
                  {this.renderInput(
                    "Postal Code",
                    "billing_postal_code",
                    "text",
                    "noicon",
                    null
                  )}
                  {this.renderInput(
                    "Province",
                    "billing_state",
                    "text",
                    "noicon",
                    null
                  )}
                </GridView>

                {this.renderInput(
                  "City",
                  "billing_city",
                  "text",
                  "noicon",
                  null
                )}
                {/* {this.renderInput(
                  "Organization",
                  "billing_organization",
                  "text",
                  "noicon",
                  null
                )}
                {this.renderInput(
                  "Tax Id",
                  "billing_tax_id",
                  "text",
                  "noicon",
                  null
                )} */}

                {this.renderButton(
                  "Save Changes",
                  isLoading,
                  "btn btn-5 m-t-40px m-l-auto"
                )}
                {/* <button type="submit" className="btn btn-5 m-t-25px m-l-auto">
              Save Changes
            </button> */}
              </>
            )}
          </ContentCard>
        </form>
      </div>
    );
  }
}

// Store
const mapStateToProps = (state) => ({
  countryData: state.entities.helpers.countries.data,
  isLoadingCountryData: state.entities.helpers.countries.isLoading,
  isLoading: state.entities.student.studentBilling.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  getAllCountries: () => dispatch(getAllCountries()),
  updateBilling: (data) => dispatch(updateStudentBilling(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStudentStore(BillingAddress));
