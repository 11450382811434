import http from "./httpServices";

const getAllCoursesCategoryApi = "/helper/get-all-course-categories";
const getAllCountriesApi = "/helper/get-all-countries";
const getCompetenceLevelsApi = "helper/get-all-level-of-competences";
const getWhoIsThisCourseForApi =
  "/helper/get-all-who_is_this_course_for_categories";
const getResourceTypesApi = "/helper/get-all-resource-types";
const getTimeZonesApi = "/helper/get-all-timezones";
const getPreferedPlatformsApi = "/helper/get-all-live-class-platforms";
const getLiveClassTypesApi = "/helper/get-all-live-class-types";
const getDurationsApi = "/helper/get-all-durations";

export function getAllCoursesCategory() {
  return http.get(getAllCoursesCategoryApi);
}
export function getAllCountries() {
  return http.get(getAllCountriesApi);
}
export function getCompetenceLevels() {
  return http.get(getCompetenceLevelsApi);
}
export function getWhoIsThisCourseFor() {
  return http.get(getWhoIsThisCourseForApi);
}
export function getResourceTypes() {
  return http.get(getResourceTypesApi);
}
export function getTimeZones() {
  return http.get(getTimeZonesApi);
}
export function getPreferedPlatforms() {
  return http.get(getPreferedPlatformsApi);
}
export function getLiveClassTypes() {
  return http.get(getLiveClassTypesApi);
}
export function getDurations() {
  return http.get(getDurationsApi);
}

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  getAllCoursesCategory,
  getAllCountries,
  getCompetenceLevels,
  getWhoIsThisCourseFor,
  getResourceTypes,
  getTimeZones,
  getPreferedPlatforms,
  getLiveClassTypes,
  getDurations,
};
