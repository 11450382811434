import React from "react";

const CheckIcon = ({ width, height, cssClass }) => {
  return (
    <svg
      width={width || "69"}
      height={height || "69"}
      className={`${cssClass ? cssClass : ""}`}
      viewBox="0 0 69 69"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M34.5034 69C15.4564 68.9791 0.0209173 53.5452 0 34.5V33.81C0.379301 14.8507 15.9909 -0.248488 34.9543 0.00309885C53.9177 0.254686 69.1232 15.7627 68.9993 34.7254C68.8753 53.6882 53.4685 68.9962 34.5034 69ZM21.101 35.5105C19.7556 34.1704 17.5793 34.1725 16.2365 35.5152C14.8916 36.8599 14.8916 39.0402 16.2365 40.3849L24.1385 48.2861C26.0518 50.1992 29.1537 50.1992 31.067 48.2861L52.7728 26.5826C54.1177 25.2378 54.1215 23.0585 52.7814 21.709C51.4347 20.3529 49.2425 20.349 47.891 21.7003L27.6028 41.9865L21.101 35.5105Z"
        fill="#51CF66"
      />
    </svg>
  );
};

export default CheckIcon;
