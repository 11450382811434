import React from "react";
import CourseDocument from "./CourseDocument";
import CourseLink from "./CourseLink";
import CourseVideo from "./CourseVideo";

const FileContent = (props) => {
  const { type } = props;
  return (
    <>
      {type === "2" ? (
        <CourseVideo {...props} />
      ) : type === "3" ? (
        <CourseLink {...props} />
      ) : (
        <CourseDocument {...props} />
      )}
    </>
  );
};

export default FileContent;
