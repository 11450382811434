import React from "react";
import { images } from "./../../utils/images";

const Discussion = () => {
  return (
    <article className="discussion">
      <div className="discussion__image">
        <img src={images.pic3} className="discussion__img" alt="discusser" />
      </div>
      <div className="discussion__details">
        <h4 className="head-12">Emmanuella James</h4>
        <span className="discussion__age">2 weeks ago</span>
        <article className='para-7'>
          How do I submit the assignment and work on the project you recommended
          for the 2nd outline?
        </article>
      </div>
    </article>
  );
};

export default Discussion;
