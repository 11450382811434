import { Component } from "react";
import { Switch, Route, BrowserRouter, Redirect } from "react-router-dom";

import InstructorCourseView from "./components/pages/InstructorCourseView";
// import InstructorSettings from "./components/pages/InstructorSettings";
import CourseEnroll from "./components/pages/CourseEnroll";
// import InstructorCourses from "./components/pages/InstructorCourses";
// import StudentDashboard from "./components/pages/StudentDashboard";
import ProtectedRoute from "./components/common/ProtectedRoute";
import ForgetPassword from "./components/pages/ForgetPassword";
import ResetPassword from "./components/pages/ResetPassword";
import CreateCourse from "./components/pages/CreateCourse";
import CreateLiveClass from "./components/pages/CreateLiveClass";
import Settlement from "./components/pages/Settlements";
// import Dashboard from "./components/pages/Dasboard";
import Messages from "./components/pages/Messages";
import NotFound from "./components/pages/NotFound";
import Register from "./components/pages/Register";
import Logout from "./components/pages/Logout";
import Login from "./components/pages/Login";
import Students from "./components/pages/Students";
import ViewStudent from "./components/pages/ViewStudent";

import auth from "./services/authServices";

import "./App.scss";
import CourseEnrolled from "./components/pages/CourseEnrolled";
// import StudentSettings from "./components/pages/StudentSettings";
import Payment from "./components/pages/Payment";
import AllCourseContent from "./components/pages/AllCourseContent";
import Maindashboard from "./components/pages/MainDashboard";
import MainSettings from "./components/pages/MainSettings";
import MainCoursesPage from "./components/pages/MainCoursesPage";
import Checkout from "./components/pages/Checkout";
import Notification from "./components/pages/Notification";
import AllCourses from "./components/pages/AllCourses";
import AccountSuccess from "./components/pages/AccountSuccess";
import CourseCreated from "./components/pages/CourseCreated";
import ConfirmPayment from "./components/pages/ConfirmPayment";
import PaymentSuccesful from "./components/pages/PaymentSuccessful";
import AccountVerification from "./components/pages/AccountVerification";

class App extends Component {
  state = {
    user: {},
  };

  componentDidMount() {
    const user = auth.getCurrentUser();
    this.setState({ user });
  }

  render() {
    return (
      <BrowserRouter>
        <Switch>
          {/* Auths pages */}
          <Route path="/forget-password" component={ForgetPassword} />
          <Route path="/reset-password" component={ResetPassword} />
          <Route path="/set-password/:token" component={ResetPassword} />{" "}
          {/* To be implemented */}
          <Route path="/login" component={Login} />
          <Route path="/logout" component={Logout} />
          <Route path="/register" component={Register} />
          <Route path="/account-success" component={AccountSuccess} />
          <Route
            path="/verify-account/:token"
            component={AccountVerification}
          />
         
          {/* User pages */}
          <ProtectedRoute path="/dashboard" component={Maindashboard} />
          <ProtectedRoute path="/courses" component={MainCoursesPage} />
          <ProtectedRoute path="/course-created" component={CourseCreated} />
          <ProtectedRoute path="/course-list" component={AllCourses} />
          <ProtectedRoute
            path="/course-view/:id"
            component={InstructorCourseView}
          />
          <ProtectedRoute path="/create-course" component={CreateCourse} />
          <ProtectedRoute path="/edit-course/:id" component={CreateCourse} />
          <ProtectedRoute
            path="/edit-live-class/:id"
            component={CreateLiveClass}
          />
          <ProtectedRoute path="/messages" component={Messages} />
          <ProtectedRoute path="/settlement" component={Settlement} />
          <ProtectedRoute path="/notification" component={Notification} />
          <ProtectedRoute path="/user-course/:id/payment" component={Payment} />
          <ProtectedRoute
            path="/user-course/:id/checkout"
            component={Checkout}
          />
          <ProtectedRoute
            path="/user-course/confirm-payment"
            component={ConfirmPayment}
          />
          <ProtectedRoute
            path="/user-course/payment-successful"
            component={PaymentSuccesful}
          />
          <ProtectedRoute
            path="/user-course/payment-failed"
            component={PaymentSuccesful}
          />
          <ProtectedRoute path="/students" component={Students} />
          <ProtectedRoute path="/view-student/" component={ViewStudent} />
          <ProtectedRoute
            path="/user-course/:id/contents"
            component={AllCourseContent}
          />
          <ProtectedRoute path="/course/:id" component={CourseEnroll} />
          <ProtectedRoute path="/user-course/:id" component={CourseEnrolled} />
          <ProtectedRoute path="/settings" component={MainSettings} />
          {/* <Route path="/instructor-settings" component={InstructorSettings} />
          <Route path="/student-settings" component={StudentSettings} /> */}
          <Route path="/not-found" component={NotFound} />
          <Redirect from="/" to="/login" exact />
          <Redirect to="/not-found" />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default App;
