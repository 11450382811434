import http from "./httpServices";

const courseApi = "/create-course";
const updateCourseApi = "/update-course";
const coursePriceApi = "/update-course-price";
const getLiveClassesByIdApi = "/get-live-classes-by-course-id";
const prefferedLiveClassesPlatformApi = "/helper/get-all-live-class-platforms";
const courseLiveClassesApi = "/create-live-class";
const editLiveClassesApi = "/update-live-class";
const deleteLiveClassesApi = "/delete-live-class";
const getCourseByIdApi = "/get-course-by-id";
const getCoursesApi = "/get-instructor-courses";
const getCourseProfileApi = "/get-course-profile";
const getFeaturedCoursesApi = "/get-all-featured-courses";
const getAllCoursesApi = "/get-all-courses";
const getCourseByCategoryApi = "/get-all-courses-by-course-category";
const publishCourseApi = "/change-publish-status";
const createCurriculumApi = "/create-curriculum";
const updateCurriculumApi = "/update-curriculum";
const deleteCurriculumApi = "/delete-curriculum";
const createCourseContentApi = "/create-course-content";
const updateCourseContentApi = "/update-course-content";
const deleteCourseContentApi = "/delete-course-content";
const createWhoIsCourseForApi = "/create-who-is-this-course-for";
const updateWhoIsCourseForApi = "/update-who-is-this-course-for";
const deleteWhoIsCourseForApi = "/delete-who-is-this-course-for";
const getCourseDataForEditApi = "/get-course-by-id-for-edit";
const uploadPromoVideoApi = "/upload-promo-video";
const deleteCourseApi = "/delete-course";
const markContentApi = "/mark-course-content-as-complete";
const uploadLiveClassVideoApi = "/upload-live-class-recording";
const getCourseContentsApi = "/get-all-course-curriculums-and-course-contents";
const getCourseContentTrackerApi = "/get-course-content-tracking-info";
const courseSearchApi = "/course/course-search-new";

export function createCourse(data) {
  return http.post(courseApi, data);
}

export function updateCourse(data) {
  return http.post(updateCourseApi, data);
}

export function createCoursePrice(data) {
  return http.post(coursePriceApi, data);
}

export function getLiveClassesById(data) {
  return http.post(getLiveClassesByIdApi, data);
}

export function prefferedLiveClassesPlatform() {
  return http.get(prefferedLiveClassesPlatformApi);
}

export function createCourseLiveClasses(data) {
  return http.post(courseLiveClassesApi, data);
}

export function editLiveClasses(data) {
  return http.post(editLiveClassesApi, data);
}

export function deleteLiveClasses(data) {
  return http.post(deleteLiveClassesApi, data);
}

export function getCourseById(id) {
  return http.get(`${getCourseByIdApi}/${id}`);
}

export function getCourses() {
  return http.get(getCoursesApi);
}

export function getCourseProfile(id) {
  return http.post(getCourseProfileApi, { course_id: id });
}

export function getFeaturedCourses() {
  return http.post(getFeaturedCoursesApi);
}

export function getAllCourses() {
  return http.post(getAllCoursesApi);
}

export function getCourseByCategory(id) {
  return http.post(getCourseByCategoryApi, { course_category_id: id });
}

export const createCurriculum = (data) => {
  return http.post(createCurriculumApi, data);
};

export const updateCurriculum = (data) => {
  return http.post(updateCurriculumApi, data);
};

export const deleteCurriculum = (data) => {
  return http.post(deleteCurriculumApi, data);
};

export const createCourseContent = (data) => {
  return http.post(createCourseContentApi, data);
};

export const updateCourseContent = (data) => {
  return http.post(updateCourseContentApi, data);
};

export const deleteCourseContent = (data) => {
  return http.post(deleteCourseContentApi, data);
};

export const createWhoIsCourseFor = (data) => {
  return http.post(createWhoIsCourseForApi, data);
};

export const updateWhoIsCourseFor = (data) => {
  return http.post(updateWhoIsCourseForApi, data);
};

export const deleteWhoIsCourseFor = (data) => {
  return http.post(deleteWhoIsCourseForApi, data);
};

export function publishCourse(data) {
  return http.post(publishCourseApi, data);
}

export function getCourseDataForEdit(data) {
  return http.post(getCourseDataForEditApi, data);
}

export function uploadPromoVideo(data) {
  return http.post(uploadPromoVideoApi, data);
}

export function deleteCourse(data) {
  return http.post(deleteCourseApi, data);
}

export function markContent(data) {
  return http.post(markContentApi, { course_content_id: data });
}

export function updateLiveClass(data) {
  return http.post(markContentApi, { course_content_id: data });
}

export function uploadLiveClassVideo(data) {
  return http.post(uploadLiveClassVideoApi, data);
}

export function getCourseContents(id) {
  return http.post(getCourseContentsApi, { course_id: id });
}

export function getCourseContentTracker(data) {
  return http.post(getCourseContentTrackerApi, data);
}

export const getAllCoursesOnSearch = (queryString) => {
  return http.get(`${courseSearchApi}?${queryString}`);
};
/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  createCourse,
  updateCourse,
  createCoursePrice,
  getLiveClassesById,
  prefferedLiveClassesPlatform,
  createCourseLiveClasses,
  editLiveClasses,
  deleteLiveClasses,
  getCourseById,
  getCourses,
  getCourseProfile,
  getFeaturedCourses,
  getAllCourses,
  getCourseByCategory,
  publishCourse,
  createCurriculum,
  updateCurriculum,
  deleteCurriculum,
  createCourseContent,
  updateCourseContent,
  deleteCourseContent,
  createWhoIsCourseFor,
  updateWhoIsCourseFor,
  deleteWhoIsCourseFor,
  getCourseDataForEdit,
  uploadPromoVideo,
  deleteCourse,
  markContent,
  uploadLiveClassVideo,
  getCourseContents,
  getCourseContentTracker,
  getAllCoursesOnSearch,
};
