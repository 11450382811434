import { combineReducers } from "redux";
import userReducer from "./slices/user";
import instructorReducer from "./slices/instructor";
import singleCourseReducer from "./slices/singleCourse";
import studentReducer from "./slices/student";
import helpersReducer from "./slices/helpers";
import coursesReducer from "./slices/courses";
import notificationReducer from "./slices/notification";
import createCourseSliceReducer from "./slices/createCourse";

export default combineReducers({
  user: userReducer,
  instructor: instructorReducer,
  singleCourse: singleCourseReducer,
  student: studentReducer,
  courses: coursesReducer,
  helpers: helpersReducer,
  notification: notificationReducer,
  createCourse: createCourseSliceReducer,
});
