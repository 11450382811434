import React from "react";

const CircleInfo = ({ width, height, cssClass}) => {
  return (
    <svg
      width={width || "39"}
      height={height || "39"}
      className={`${cssClass ? cssClass : ""}`}
      viewBox="0 0 39 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        opacity="0.4"
        width="39"
        height="39"
        rx="19.5"
        fill="#F7B50C"
        fillOpacity="0.3"
      />
      <rect
        opacity="0.6"
        x="20.0796"
        y="6"
        width="18.4975"
        height="18.4975"
        rx="9.24874"
        transform="rotate(45 20.0796 6)"
        fill="#F7B50C"
      />
      <path
        d="M21.3516 13.8281C21.5703 13.8281 21.7552 13.9036 21.9062 14.0547C22.0573 14.2057 22.1328 14.3906 22.1328 14.6094C22.1328 14.8229 22.0547 15.0078 21.8984 15.1641C21.7474 15.3151 21.5651 15.3906 21.3516 15.3906C21.138 15.3906 20.9531 15.3151 20.7969 15.1641C20.6458 15.0078 20.5703 14.8229 20.5703 14.6094C20.5703 14.3906 20.6458 14.2057 20.7969 14.0547C20.9479 13.9036 21.1328 13.8281 21.3516 13.8281ZM21.4531 16.9297L19.9062 22.4062C19.8021 22.776 19.75 22.9974 19.75 23.0703C19.75 23.1536 19.7734 23.2214 19.8203 23.2734C19.8724 23.3255 19.9323 23.3516 20 23.3516C20.0781 23.3516 20.1719 23.3099 20.2812 23.2266C20.5781 22.9922 20.8776 22.6589 21.1797 22.2266L21.4531 22.4062C21.099 22.9479 20.6823 23.4036 20.2031 23.7734C19.849 24.0495 19.5104 24.1875 19.1875 24.1875C18.974 24.1875 18.7995 24.125 18.6641 24C18.5286 23.8698 18.4609 23.7083 18.4609 23.5156C18.4609 23.3229 18.526 23.0026 18.6562 22.5547L19.6719 19.0547C19.8385 18.4818 19.9219 18.1224 19.9219 17.9766C19.9219 17.862 19.8802 17.7682 19.7969 17.6953C19.7188 17.6224 19.6094 17.5859 19.4688 17.5859C19.3542 17.5859 19.1172 17.6146 18.7578 17.6719V17.3672L21.4531 16.9297Z"
        fill="white"
      />
    </svg>
  );
};

export default CircleInfo;
