import Form from "./Form";
import Joi from "joi";
import { setPassword } from "../../../services/authServices";
import Notiflix from "notiflix";

class ResetPasswordForm extends Form {
  constructor(props) {
    super(props);

    this.state = {
      data: { password: "", confirmPassword: "" },
      error: { password: "", confirmPassword: "" },
      isLoading: false,
    };
  }

  schema = {
    password: Joi.string().required().label("Password"),
    confirmPassword: Joi.string().min(6).required().label("Password"),
  };

  doSubmit = async () => {
    const token = this.props.resetToken;
    this.setState({ isLoading: true });

    const data = { ...this.state.data };

    const { password, confirmPassword } = data;

    if (password !== confirmPassword) {
      this.setState(
        {
          error: {
            ...this.state.error,
            confirmPassword: "Password does not match",
          },
        },
        () => {
          this.setState({ isLoading: false });
        }
      );
      return;
    } else {
      this.setState({
        error: {
          ...this.state.error,
          confirmPassword: "",
        },
      });
    }

    try {
      await setPassword(token, password);
      Notiflix.Notify.success("Password set successfully");

      this.setState({ isLoading: false });
      this.props.history.push("/login");
    } catch (error) {
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { isLoading } = this.state;

    // Data to display
    return (
      <form onSubmit={this.handleSubmit}>
        {this.renderInput("Password", "password", "password")}
        {this.renderInput("Confirm Password", "password", "password")}

        {this.renderButton("Send Reset Link", isLoading)}
      </form>
    );
  }
}

export default ResetPasswordForm;
