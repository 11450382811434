import React, { useState } from "react";
import CalenderIcon from "./SvgIcons/CalenderIcon";
import CameraIcon from "./SvgIcons/CameraIcon";
import CaretCircleIcon from "./SvgIcons/CaretCircleIcon";
import ClockIcon from "./SvgIcons/ClockIcon";
import ListIcon from "./SvgIcons/ListIcon";
import moment from "moment";

const CustomDropdown = (props) => {
  const { data, hostName } = props;
  const [showContent, setShowContent] = useState(false);

  return (
    <div className="custom-dropdown">
      <button
        className="custom-dropdown__toggler"
        onClick={() => setShowContent((prevState) => !prevState)}
      >
        <div className="custom-dropdown__icon-holder ">
          <ListIcon />
        </div>
        <div className="custom-dropdown__title">
          <p className="para-4"> {data?.live_class_type_name || "--"} </p>
          <h2 className="head-20">
            {" "}
            {data?.curriculum_name || data?.curriculum_topic || "--"}{" "}
          </h2>
        </div>
        <CaretCircleIcon
          cssClass={`review__link-arrow m-l-auto ${
            showContent ? "rotate-270" : "rotate--270"
          }`}
        />
      </button>
      {/* Dropdown Content */}
      {showContent && (
        <div className="custom-dropdown__content">
          <h5 className="m-b-5px">
            <span className="head-22">Hosted by: </span>
            <span className="head-23">
              {hostName || data?.instructor_name || "--"}
            </span>
          </h5>
          <p className="upcoming-classes__dropdown-content m-b-5px">
            <CalenderIcon />
            <span> {moment(data?.date).format("LL")}</span>
          </p>
          <p className="upcoming-classes__dropdown-content m-b-5px">
            <ClockIcon />
            <span>
              {moment(data?.start_time, "HH:mm").format("LT")}
              {" - "}
              {moment(data?.end_time, "HH:mm").format("LT")}
            </span>
          </p>
          <p className="upcoming-classes__dropdown-content">
            <CameraIcon />
            <span>{data?.name || data?.platform_name || "--"}</span>
          </p>
        </div>
      )}
    </div>
  );
};

export default CustomDropdown;
