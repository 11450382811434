import React from "react";

const CoursesIcon = ({
  width,
  height,
  cssClass,
  pathCssClass1,
  pathCssClass2,
  title = "Courses",
}) => {
  return (
    <>
      <svg
        width={width || "16"}
        height={height || "18"}
        className={`${cssClass ? cssClass : ""}`}
        viewBox="0 0 16 18"
        fill="none"
      >
        <path
          className={pathCssClass1}
          opacity="0.4"
          d="M11.4925 0.666016H4.50833C1.975 0.666016 0.5 2.14935 0.5 4.69102V13.2993C0.5 15.8827 1.975 17.3327 4.50833 17.3327H11.4925C14.0667 17.3327 15.5 15.8827 15.5 13.2993V4.69102C15.5 2.14935 14.0667 0.666016 11.4925 0.666016Z"
          fill="#272D33"
        />
        <path
          className={pathCssClass2}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.73281 4.54102V4.54935C4.37365 4.54935 4.08281 4.84102 4.08281 5.19935C4.08281 5.55768 4.37365 5.84935 4.73281 5.84935H7.22365C7.58281 5.84935 7.87448 5.55768 7.87448 5.19018C7.87448 4.83268 7.58281 4.54102 7.22365 4.54102H4.73281ZM11.2661 9.61602H4.73281C4.37365 9.61602 4.08281 9.32435 4.08281 8.96602C4.08281 8.60768 4.37365 8.31518 4.73281 8.31518H11.2661C11.6245 8.31518 11.9161 8.60768 11.9161 8.96602C11.9161 9.32435 11.6245 9.61602 11.2661 9.61602ZM11.2661 13.4243H4.73281C4.48281 13.4577 4.24115 13.3327 4.10781 13.1243C3.97448 12.9077 3.97448 12.6327 4.10781 12.4243C4.24115 12.2077 4.48281 12.091 4.73281 12.116H11.2661C11.5986 12.1493 11.8495 12.4327 11.8495 12.7743C11.8495 13.1068 11.5986 13.391 11.2661 13.4243Z"
          fill="#272D33"
        />
      </svg>
      <span>{title}</span>
    </>
  );
};

export default CoursesIcon;
