import Skeleton from "react-loading-skeleton";

const CourseViewLoader = () => {
  const style = {
    main: {
      display: "grid",
      gridTemplateColumns: "repeat(3, 1fr)",
      gridGap: "3rem",
      marginTop: "50px",
    },

    gap10: {
      gap: "10px",
    },
  };

  return (
    <div className=" m-t-50px">
      <Skeleton height={"30px"} width={"500px"} />

      <div className="flex-r-jcbetween-aicenter">
        <div className="flex-r-aicenter m-t-20px" style={style.gap10}>
          <Skeleton height={"20px"} width={"120px"} />
          <Skeleton height={"20px"} width={"120px"} />
        </div>
        <div className="flex-r-aicenter m-t-20px" style={style.gap10}>
          <Skeleton height={"30px"} width={"30px"} />
          <Skeleton height={"30px"} width={"70px"} />
        </div>
      </div>

      <div className="" style={style.main}>
        <div className="flex-c flex-gap-30px">
          <Skeleton height={"300px"} width={"100%"} />
          <Skeleton height={"300px"} width={"100%"} />
        </div>
        <Skeleton height={"450px"} width={"100%"} />
        <Skeleton height={"600px"} width={"100%"} />
      </div>
    </div>
  );
};

export default CourseViewLoader;
