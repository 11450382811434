import React, { useState, useRef, forwardRef } from "react";
import Svg from "./../Svg";
import FileIcon from "../SvgIcons/FileIcon";

const CustomFile = forwardRef(
  (
    {
      label,
      error,
      labelIcon,
      type = "text",
      noicon,
      placeholder,
      editName,
      ...rest
    },
    ref
  ) => {
    // Focus holder div when input is focused ✔
    const [, setFocused] = useState(false);
    const handleFocus = () => {
      setFocused(true);
    };
    const handleBlur = () => {
      setFocused(false);
    };

    // Focus holder div when holder div is focused ✔
    let inputHolderRef = useRef(null);
    const handleInputHolerClick = () => {
      const classExist = inputHolderRef.className
        .split(" ")
        .includes("focused");
      if (!classExist) inputHolderRef.className += " focused";
      // Focus input when holder div is focused ✔
      inputHolderRef.children[0].focus();
    };

    return (
      <div className="input__wrapper">
        <div className="input-top">
          {label ? (
            <label htmlFor="" className="input__label">
              {labelIcon ? (
                <Svg
                  iconId={labelIcon}
                  cssClassName={`${labelIcon} m-r-10px`}
                />
              ) : (
                ""
              )}
              {label}
            </label>
          ) : (
            <label htmlFor=""></label>
          )}

          {error ? <span className="input__error"> {error} </span> : ""}
        </div>
        <div
          className="custom-file"
          ref={(ele) => (inputHolderRef = ele)}
          onClick={handleInputHolerClick}
        >
          <div className="custom-file__placeholder">
            <FileIcon />
            <span className="text-truncate max-width-300px">
              {ref?.current?.files[0]?.name
                ? `${ref.current.files[0].name}`
                : editName
                ? editName
                : "Drop file here or click to upload"}
            </span>
          </div>
          <input
            onFocus={handleFocus}
            onBlur={handleBlur}
            type="file"
            ref={ref}
            accept="image/*"
            {...rest}
            className="custom-file__input"
          />
        </div>
      </div>
    );
  }
);

export default CustomFile;
