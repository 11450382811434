import React from "react";

const SpinnerBox = () => {
  return (
    <div className="spinner-box">
      <p className="text-center">
        <i className="fas fa-spinner animate-rotate-2 spinner-box__text color-primary"></i>
      </p>
    </div>
  );
};

export default SpinnerBox;
