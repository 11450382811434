import styled from "styled-components";

export const AvatarStyle = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-left: 1.5rem;
`;

export const AvatarName = styled.div`
  width: 4rem;
  height: 4rem;
  flex-shrink: 0;
  border-radius: 50%;
  background-color: #5c4fff;
  color: #ffffff;

  display: grid;
  /* place-items: center; */
  place-content: center;
  font-size: 1.7rem;
`;
