import React from "react";

const LogoutIcon = ({
  width,
  height,
  cssClass,
  pathCssClass1,
  pathCssClass2,
  title = "Logout",
}) => {
  return (
    <>
      <svg
        width={width || "18"}
        height={height || "18"}
        className={`${cssClass ? cssClass : ""}`}
        viewBox="0 0 18 18"
        fill="none"
      >
        <path
          className={pathCssClass1}
          opacity="0.4"
          d="M0.666016 4.37185C0.666016 2.32935 2.35788 0.666016 4.43646 0.666016H8.57072C10.6451 0.666016 12.3327 2.32435 12.3327 4.36352V13.6269C12.3327 15.6702 10.6408 17.3327 8.56139 17.3327H4.42883C2.35364 17.3327 0.666016 15.6743 0.666016 13.6352V12.8518V4.37185Z"
          fill="#272D33"
        />
        <path
          className={pathCssClass2}
          d="M17.149 8.54505L14.7775 6.12089C14.5324 5.87089 14.138 5.87089 13.8937 6.12255C13.6502 6.37422 13.651 6.78005 13.8953 7.03005L15.1947 8.35755H13.9489H6.95696C6.61203 8.35755 6.33203 8.64505 6.33203 8.99922C6.33203 9.35422 6.61203 9.64089 6.95696 9.64089H15.1947L13.8953 10.9684C13.651 11.2184 13.6502 11.6242 13.8937 11.8759C14.0162 12.0017 14.1761 12.0651 14.3368 12.0651C14.4959 12.0651 14.6558 12.0017 14.7775 11.8776L17.149 9.45422C17.2667 9.33339 17.3333 9.17005 17.3333 8.99922C17.3333 8.82922 17.2667 8.66589 17.149 8.54505Z"
          fill="#272D33"
        />
      </svg>
      <span>{title}</span>
    </>
  );
};

export default LogoutIcon;
