import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import CourseTable from "../common/Tables/CourseTable";
import DashboardSideNav from "../common/DashboardSideNav";
import DashboardTopNav from "../common/DashboardTopNav";
import Schedule from "../common/Schedule";
// import { images } from "./../../utils/images";
// import InputSearch from "./../common/InputSearch";
import MeetingCard from "./../common/MeetingCard";
import TabNavigator from "./../common/TabNavigator";

import DashboardMaxWidth from "../common/DashboardMaxWidth";
import CenteredLayout from "../common/CenterLayout";
import { useDispatch, useSelector } from "react-redux";
import useTabs from "../../utils/Hooks/useTab";
import { getCourses, getUpcomingClasses } from "../../storee/slices/instructor";
import SpinnerBox from "../common/SpinnerBox";

const InstructorCourses = () => {
  const [activeTabId, setTabId] = useTabs(0);
  
  const tabLabels = ["All Courses", "Completed"];
  const dispatch = useDispatch();

  const [courses, isLoadingCourses, upcomingClasses] = useSelector((store) => [
    store.entities.instructor.courses.data,
    store.entities.instructor.courses.isLoading,
    store.entities.instructor.upcomingClasses.data,
  ]);

  useEffect(() => {
    dispatch(getCourses());
    dispatch(getUpcomingClasses());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   setAllTabContent([
  //     <CourseTable courses={courses} loading={isLoadingCourses} />,
  //     <CourseTable courses={[]} loading={isLoadingCourses} />,
  //   ]);

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [courses, isLoadingCourses]);

  return (
    <main className="dashboard-main">
      <DashboardSideNav />
      <main className="dashboard__main">
        <DashboardTopNav>
          <h3 className="head-2">Course Overview</h3>
          {/* <InputSearch placeholder="Search Courses" /> */}
          <Link to="/create-course">
            <button className="btn btn-1">Create Course</button>
          </Link>
        </DashboardTopNav>

        <CenteredLayout>
          <DashboardMaxWidth>
            {isLoadingCourses ? (
              <SpinnerBox />
            ) : (
              <>
                <section className="dashboard__main-content">
                  <aside className="dashboard__left-side">
                    <MeetingCard details={upcomingClasses[0]} />
                    <h3 className="head-4 m-y-40px">Courses</h3>
                    <div className="table-top">
                      <TabNavigator
                        tabTitles={tabLabels}
                        activeId={activeTabId}
                        handleClick={setTabId}
                      />
                    </div>
                    {/* {renderTabComponent(activeTabId)} */}
                    {activeTabId === 0 && (
                      <CourseTable
                        courses={courses}
                        loading={isLoadingCourses}
                      />
                    )}
                    {activeTabId === 1 && (
                      <CourseTable courses={[]} loading={isLoadingCourses} />
                    )}
                  </aside>
                  <aside className="dashboard__right-side">
                    <Schedule events={upcomingClasses} />
                  </aside>
                </section>
              </>
            )}
          </DashboardMaxWidth>
        </CenteredLayout>
      </main>
    </main>
  );
};

export default InstructorCourses;
