import React from "react";

const EmptyActivityBox = ({ children, cssClass }) => {
  return (
    <div className={`empty-activity-box ${cssClass ? cssClass : ""}`}>
      {children}
    </div>
  );
};

export default EmptyActivityBox;
