import React from "react";
import { images } from "./../../utils/images";
import EmptyActivityBox from "./EmptyActivityBox";
import EmptyActivityState from "./EmptyActivityState";
import InputSearch from "./InputSearch";
import { Link } from "react-router-dom";

const MyStudents = (props) => {
  // Props
  const { students } = props;

  // DAta to render
  return (
    <>
      {!students.length ? (
        <EmptyActivityBox cssClass="p-y-50px">
          <EmptyActivityState
            title="No Students"
            subTitle="No students has enrolled for this course"
          />
        </EmptyActivityBox>
      ) : (
        <>
          <div className="my-students">
            <InputSearch
              cssClass="width-fluid m-b-30px"
              placeholder="Search Student"
            />

            {students?.map((val, index) => (
              <div key={index} className="my-student">
                <div className="image">
                  <img src={images.pic3} alt="" className="my-student__img" />
                </div>
                <div>
                  <h6 className="head-32">Emmanuella James</h6>
                  {/* <p className="para-23 m-t-1px">UX Designer</p> */}
                </div>
              </div>
            ))}
            <Link to="/students" className="viewAllStudentsBtn">
              View all
            </Link>
          </div>
        </>
      )}
    </>
  );
};

export default React.memo(MyStudents);
