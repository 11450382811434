import React from "react";

const CaretDown = ({ width, height, cssClass, pathCssClass1 }) => {
  return (
    <svg
      width={width || "9"}
      height={height || "5"}
      className={`${cssClass ? cssClass : ""}`}
      viewBox="0 0 9 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={pathCssClass1}
        d="M8.44275 0.749376C8.44275 0.940376 8.36975 1.13238 8.22375 1.27838L4.75375 4.76538C4.61275 4.90638 4.42175 4.98538 4.22175 4.98538C4.02275 4.98538 3.83175 4.90638 3.69075 4.76538L0.218751 1.27838C-0.0732494 0.985376 -0.0732494 0.511376 0.220751 0.218376C0.514751 -0.0736236 0.989751 -0.0726236 1.28175 0.220376L4.22175 3.17338L7.16175 0.220376C7.45375 -0.0726236 7.92775 -0.0736236 8.22175 0.218376C8.36975 0.364376 8.44275 0.557376 8.44275 0.749376Z"
        fill="#272D33"
      />
    </svg>
  );
};

export default CaretDown;
