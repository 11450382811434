import React from "react";
import { images } from "../../../utils/images";
import InputSearch from "../InputSearch";
import ComposeMessage from "./ComposeMessage";
import ChatTextMe from "./ChatTextMe";
import ChatTextOther from "./ChatTextOther";

const MessagesContainer = () => {
  return (
    <div className="messages">
      <div className="contact-list">
        {/* <div className="messages__contacts">
          <div className="messages__contacts-top">
            <h4 className="head-14">Active Messages</h4>
          </div>

          <div className="chat-card__wrapper active">
            <div className="chat-card ">
              <div className="chat-image">
                <img
                  src={images.pic10}
                  alt="user-icon"
                  className="chat-image__img"
                />
              </div>
              <div>
                <h3 className="head-15">Tosin Steven</h3>
                <span className="last-message">Hi...</span>
              </div>
              <div className="m-l-auto text-right">
                <span className="last-message last-message__time m-l-auto">
                  25 mins ago
                </span>
                <span className="unread-messages m-l-auto"> 27</span>
              </div>
            </div>
          </div>
          <div className="chat-card__wrapper">
            <div className="chat-card ">
              <div className="chat-image">
                <img
                  src={images.pic10}
                  alt="user-icon"
                  className="chat-image__img"
                />
              </div>
              <div>
                <h3 className="head-15">Tosin Steven</h3>
                <span className="last-message">Hi...</span>
              </div>
              <div className="m-l-auto text-right">
                <span className="last-message last-message__time m-l-auto">
                  25 mins ago
                </span>
                <span className="unread-messages m-l-auto"> 7</span>
              </div>
            </div>
          </div>

          <div className="chat-card__wrapper">
            <div className="chat-card ">
              <div className="chat-image">
                <img
                  src={images.pic10}
                  alt="user-icon"
                  className="chat-image__img"
                />
              </div>
              <div>
                <h3 className="head-15">Tosin Steven</h3>
                <span className="last-message">Hi...</span>
              </div>
              <div className="m-l-auto text-right">
                <span className="last-message last-message__time m-l-auto">
                  25 mins ago
                </span>
                <span className="unread-messages m-l-auto"> 3</span>
              </div>
            </div>
          </div>
        </div> */}

        <div className="messages__contacts">
          <div className="messages__contacts-top">
            <div className="chat-card ">
                <InputSearch
                  cssClass="input__search-course--no-margin"
                  placeholder="Search Students"
                />
              </div>
            <h4 className="head-14">Messages</h4>
          </div>
          <div className="chat-tab-list">
              <span className="chat-tab active">Current</span>
              <span className="chat-tab">Class</span>
              <span className="chat-tab">Archive</span>
            </div>

          <div className="chat-card__wrapper active">
            <div className="chat-card ">
              <div className="chat-image">
                <img
                  src={images.pic10}
                  alt="user-icon"
                  className="chat-image__img"
                />
              </div>
              <div>
                <h3 className="head-15">Tosin Steven</h3>
                <span className="last-message">Hi...</span>
              </div>
              <div className="m-l-auto text-right">
                <span className="last-message last-message__time m-l-auto">
                  25 mins ago
                </span>
                <span className="unread-messages m-l-auto"> 27</span>
              </div>
            </div>
          </div>
          <div className="chat-card__wrapper">
            <div className="chat-card ">
              <div className="chat-image">
                <img
                  src={images.pic10}
                  alt="user-icon"
                  className="chat-image__img"
                />
              </div>
              <div>
                <h3 className="head-15">Tosin Steven</h3>
                <span className="last-message">Hi...</span>
              </div>
              <div className="m-l-auto text-right">
                <span className="last-message last-message__time m-l-auto">
                  25 mins ago
                </span>
                <span className="unread-messages m-l-auto"> 7</span>
              </div>
            </div>
          </div>
          <div className="chat-card__wrapper">
            <div className="chat-card ">
              <div className="chat-image">
                <img
                  src={images.pic10}
                  alt="user-icon"
                  className="chat-image__img"
                />
              </div>
              <div>
                <h3 className="head-15">Tosin Steven</h3>
                <span className="last-message">Hi...</span>
              </div>
              <div className="m-l-auto text-right">
                <span className="last-message last-message__time m-l-auto">
                  25 mins ago
                </span>
                <span className="unread-messages m-l-auto"> 3</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="chats chats-empty">
        <EmptyActivityBox cssClass="h-100">
          <EmptyActivityState />
        </EmptyActivityBox>
      </div> */}
      <div className="chats chats-active">
        <div className="chats-active__top">
          <div className="chats-active__user">
            <img src={images.pic10} alt="" className="chats-active__user-img" />
          </div>
          <h4 className="chats-active__user-name">Tosin Steven</h4>
        </div>

        <div className="chat-texts">
          <ChatTextMe />
          <ChatTextMe />
          <ChatTextOther />
        </div>

        <ComposeMessage />
      </div>
    </div>
  );
};

export default MessagesContainer;
