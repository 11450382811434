import Joi from "joi";
import Form from "./../common/Forms/Form";
import DashboardSideNav from "../common/DashboardSideNav";
import DashboardTopNav from "../common/DashboardTopNav";
import Activities from "../common/Activities";
import AboutMe from "../common/settings/AboutMe";
import Password from "../common/settings/Password";
// import BankAccount from "../common/settings/BankAccount";
// import Notifications from "./../common/settings/Notifications";
import CenteredLayout from "../common/CenterLayout";
import DashboardMaxWidth from "../common/DashboardMaxWidth";

class InstructorSettings extends Form {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        courseName: "",
      },
      error: {
        courseName: "",
      },
      activeTab: 0,
    };
  }

  schema = {
    courseName: Joi.string().required().label("Course Name"),
  };

  settingsForms = [
    <AboutMe key={1} />,
    <Password key={2} />,
    // <BankAccount key={3} />,
    // <Notifications key={4} />,
  ];

  handleActionClicked = (id) => this.setState({ activeTab: id });

  doSubmit = () => {
  };

  render() {
    const InstructorSettingsActivities = [
      {
        name: "About me",
        handleActionClicked: this.handleActionClicked,
      },
      {
        name: "Password & Security",
        handleActionClicked: this.handleActionClicked,
      },
      // {
      //   name: "Bank Account",
      //   handleActionClicked: this.handleActionClicked,
      // },
      // {
      //   name: "Notifications",
      //   handleActionClicked: this.handleActionClicked,
      // },
    ];

    const { activeTab } = this.state;

    return (
      <>
        <main className="dashboard-main">
          <DashboardSideNav />
          <main className="dashboard__main">
            <DashboardTopNav>
              <h3 className="head-2">Settings</h3>
            </DashboardTopNav>

            <CenteredLayout>
              <DashboardMaxWidth>
                <Activities activities={InstructorSettingsActivities}>
                  {this.settingsForms.map((form, index) =>
                    activeTab === index ? form : null
                  )}
                </Activities>
              </DashboardMaxWidth>
            </CenteredLayout>
          </main>
        </main>
      </>
    );
  }
}

export default InstructorSettings;
