import React, { useEffect } from "react";

import EmptyActivityBox from "../EmptyActivityBox";
import EmptyActivityState from "../EmptyActivityState";
import CourseTableLoader from "./../Loaders/CourseTableLoader";
import TableRows from "./TableRows";

const CourseTable = (props) => {
  // Props
  const { courses, loading } = props;
  // Effects
  useEffect(() => {}, [loading]);
  // Data to render
  return (
    <>
      {loading ? (
        <CourseTableLoader />
      ) : !courses?.length ? (
        <EmptyActivityBox cssClass="p-y-50px">
          <EmptyActivityState
            title="Empty Courses"
            subTitle="Your course lists are empty, please create a course"
          />
        </EmptyActivityBox>
      ) : (
        <div className="table-wrapper">
          <div className="table-container">
            <div className="table">
              <table className="table__main">
                <thead>
                  <tr className="">
                    <th className="table__main__head">Course Name</th>
                    <th className="table__main__head table__main__head--center">
                      Action
                    </th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {courses?.length &&
                    courses?.map((course, id) => {
                      return <TableRows key={id} course={course} />;
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CourseTable;
