const WelcomeCard = ({ userName }) => {
  return (
    <div className="welcome__card">
      <h1 className="head-3">
        Hello <span className="welcome__username">{userName && userName}</span>
      </h1>
      <p className="para-3 m-t-8px">Check out what is happening.</p>
    </div>
  );
};

export default WelcomeCard;
