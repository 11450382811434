import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "./../actions/api";

const helperSlice = createSlice({
  name: "helpers",
  initialState: {
    courseCategories: {
      isLoading: false,
      list: [],
      data: [],
      error: {},
    },
    countries: {
      isLoading: false,
      data: {},
      error: {},
    },
    preferedPlatform: {
      isLoading: false,
      data: [],
      error: null,
    },
    levelOfCompetence: {
      data: [],
      isLoading: false,
      error: null,
    },
    durations: {
      data: [],
      isLoading: false,
      error: null,
    },
    whoIsThisCourseFor: {
      data: [],
      isLoading: false,
      error: null,
    },
    resourceTypes: {
      data: [],
      isLoading: false,
      error: null,
    },
    timeZones: {
      data: [],
      isLoading: false,
      error: null,
    },
    liveClassTypes: {
      data: [],
      isLoading: false,
      error: null,
    },
  },
  reducers: {
    //   Student Dashboard Data
    //   Fetching All Course Categories
    fetchingCourseCategories: (helpers, action) => {
      helpers.courseCategories.isLoading = true;
    },
    fetchingCourseCategoryFailed: (helpers, action) => {
      helpers.courseCategories.error = action.payload;
      helpers.courseCategories.isLoading = false;
    },
    fetchingCourseCategorySuccess: (helpers, action) => {
      helpers.courseCategories.data = [...action.payload.course_categories];
      helpers.courseCategories.isLoading = false;
      // Set course categories list
      const categoryList = action.payload?.course_categories.map(
        (category) => category.name
      );
      helpers.courseCategories.list = [...categoryList];
    },

    // Countries
    countriesRequested: (helpers, action) => {
      helpers.countries.isLoading = true;
    },
    countriesRequestFailed: (helpers, action) => {
      helpers.countries.error = action.payload;
      helpers.countries.isLoading = false;
    },
    countriesRequestSuccess: (helpers, action) => {
      helpers.countries.data = action.payload;
      helpers.countries.isLoading = false;
    },

    // Prefered Platforms
    preferedPlatformRequested: (helpers, action) => {
      helpers.preferedPlatform.isLoading = true;
    },
    preferedPlatformRequestFailed: (helpers, action) => {
      helpers.preferedPlatform.error = action.payload;
      helpers.preferedPlatform.isLoading = false;
    },
    preferedPlatformRequestSuccess: (helpers, action) => {
      helpers.preferedPlatform.data = [...action.payload.live_class_platforms];
      helpers.preferedPlatform.isLoading = false;
    },
    preferedPlatformReset: (helpers, action) => {
      helpers.preferedPlatform.data = [...action.payload.live_class_platforms];
      helpers.preferedPlatform.isLoading = false;
    },

    // Competence level
    competenceLevelRequested: (helpers, action) => {
      helpers.levelOfCompetence.isLoading = true;
    },
    competenceLevelFailed: (helpers, action) => {
      helpers.levelOfCompetence.error = action.payload;
      helpers.levelOfCompetence.isLoading = false;
    },
    competenceLevelSuccess: (helpers, action) => {
      helpers.levelOfCompetence.data = action.payload.level_of_competences;
      helpers.levelOfCompetence.isLoading = false;
    },

    // Durations
    durationsRequested: (helpers, action) => {
      helpers.durations.isLoading = true;
    },
    durationsFailed: (helpers, action) => {
      helpers.durations.error = action.payload;
      helpers.durations.isLoading = false;
    },
    durationsSuccess: (helpers, action) => {
      helpers.durations.data = action.payload.duration_result;
      helpers.durations.isLoading = false;
    },

    // Who is this course for
    whoIsCourseForRequested: (helpers, action) => {
      helpers.whoIsThisCourseFor.isLoading = true;
    },
    whoIsCourseForFailed: (helpers, action) => {
      helpers.whoIsThisCourseFor.error = action.payload;
      helpers.whoIsThisCourseFor.isLoading = false;
    },
    whoIsCourseForSuccess: (helpers, action) => {
      helpers.whoIsThisCourseFor.data =
        action.payload.who_is_this_course_for_categories_result;
      helpers.whoIsThisCourseFor.isLoading = false;
    },

    // Course resource types
    resourceTypesRequested: (helpers, action) => {
      helpers.resourceTypes.isLoading = true;
    },
    resourceTypesFailed: (helpers, action) => {
      helpers.resourceTypes.error = action.payload;
      helpers.resourceTypes.isLoading = false;
    },
    resourceTypesSuccess: (helpers, action) => {
      helpers.resourceTypes.data = action.payload.resource_types;
      helpers.resourceTypes.isLoading = false;
    },
    // Time zones
    timeZonesRequested: (helpers, action) => {
      helpers.timeZones.isLoading = true;
    },
    timeZonesFailed: (helpers, action) => {
      helpers.timeZones.error = action.payload;
      helpers.timeZones.isLoading = false;
    },
    timeZonesSuccess: (helpers, action) => {
      helpers.timeZones.data = action.payload.timezones_result;
      helpers.timeZones.isLoading = false;
    },
    // Live classes
    liveClassTypeRequested: (helpers, action) => {
      helpers.liveClassTypes.isLoading = true;
    },
    liveClassTypeFailed: (helpers, action) => {
      helpers.liveClassTypes.error = action.payload;
      helpers.liveClassTypes.isLoading = false;
    },
    liveClassTypeSuccess: (helpers, action) => {
      delete action.payload.message;
      helpers.liveClassTypes.data = [...action.payload.live_class_types_result];
      helpers.liveClassTypes.isLoading = false;
    },
  },
});

// Action Types
const {
  //   Course categories
  fetchingCourseCategories,
  fetchingCourseCategoryFailed,
  fetchingCourseCategorySuccess,
  countriesRequested,
  countriesRequestFailed,
  countriesRequestSuccess,
  competenceLevelRequested,
  competenceLevelFailed,
  competenceLevelSuccess,
  whoIsCourseForRequested,
  whoIsCourseForFailed,
  whoIsCourseForSuccess,
  resourceTypesRequested,
  resourceTypesFailed,
  resourceTypesSuccess,
  timeZonesRequested,
  timeZonesFailed,
  timeZonesSuccess,
  preferedPlatformRequested,
  preferedPlatformRequestFailed,
  preferedPlatformRequestSuccess,
  liveClassTypeRequested,
  liveClassTypeFailed,
  liveClassTypeSuccess,
  durationsRequested,
  durationsFailed,
  durationsSuccess,
} = helperSlice.actions;

//   Action Creators
const service = "helperServices";
export const getCourseCategories = () =>
  apiCallBegan({
    service,
    endpoint: "getAllCoursesCategory",
    onStart: fetchingCourseCategories.type,
    onSuccess: fetchingCourseCategorySuccess.type,
    onError: fetchingCourseCategoryFailed.type,
  });

export const getAllCountries = () =>
  apiCallBegan({
    service,
    endpoint: "getAllCountries",
    onStart: countriesRequested.type,
    onSuccess: countriesRequestSuccess.type,
    onError: countriesRequestFailed.type,
  });

export const getLevelOfCompetence = () =>
  apiCallBegan({
    service,
    endpoint: "getCompetenceLevels",
    onStart: competenceLevelRequested.type,
    onSuccess: competenceLevelSuccess.type,
    onError: competenceLevelFailed.type,
  });

export const getWhoIsCourseFor = () =>
  apiCallBegan({
    service,
    endpoint: "getWhoIsThisCourseFor",
    onStart: whoIsCourseForRequested.type,
    onSuccess: whoIsCourseForSuccess.type,
    onError: whoIsCourseForFailed.type,
  });

export const getResourceTypes = () =>
  apiCallBegan({
    service,
    endpoint: "getResourceTypes",
    onStart: resourceTypesRequested.type,
    onSuccess: resourceTypesSuccess.type,
    onError: resourceTypesFailed.type,
  });

export const getTimeZones = () =>
  apiCallBegan({
    service,
    endpoint: "getTimeZones",
    onStart: timeZonesRequested.type,
    onSuccess: timeZonesSuccess.type,
    onError: timeZonesFailed.type,
  });

export const getPreferedPlatform = () =>
  apiCallBegan({
    service,
    endpoint: "getPreferedPlatforms",
    onStart: preferedPlatformRequested.type,
    onSuccess: preferedPlatformRequestSuccess.type,
    onError: preferedPlatformRequestFailed.type,
  });

export const getLiveClassTypes = () =>
  apiCallBegan({
    service,
    endpoint: "getLiveClassTypes",
    onStart: liveClassTypeRequested.type,
    onSuccess: liveClassTypeSuccess.type,
    onError: liveClassTypeFailed.type,
  });

export const getAllDurations = () =>
  apiCallBegan({
    service,
    endpoint: "getDurations",
    onStart: durationsRequested.type,
    onSuccess: durationsSuccess.type,
    onError: durationsFailed.type,
  });

// getDashboardData

// *********************** End of Action Creators *****************************

export default helperSlice.reducer;
