import Joi from "joi";

import Form from "./../common/Forms/Form";
import DashboardSideNav from "../common/DashboardSideNav";
import DashboardTopNav from "../common/DashboardTopNav";
// import InputSearch from "./../common/InputSearch";
import TransactionTable from "./../common/TransactionTable";
import Svg from "./../common/Svg";
import ModalLayout from "./../common/ModalLayout";
import ModalChild from "./../common/ModalChild";
import ContentCard from "./../common/ContentCard";
import { images } from "../../utils/images";

// Http Services
import userServices from "../../services/userServices";
import CenteredLayout from "../common/CenterLayout";
import DashboardMaxWidth from "../common/DashboardMaxWidth";
import { formatCurrency } from "../../utils/helpers";
import SpinnerBox from "./../common/SpinnerBox";
import EmptyActivityBox from "../common/EmptyActivityBox";
import EmptyActivityState from "../common/EmptyActivityState";

class Settlement extends Form {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      isLoading: false,
      isLoadingData: false,

      instructorSettlements: {},
      settlementTransactions: [],

      data: {
        amountToBePaid: "",
        remark: "",
      },
      error: {
        amountToBePaid: "",
        remark: "",
      },
    };
  }

  schema = {
    amountToBePaid: Joi.number().required().label("Amount to be paid"),
    remark: Joi.string().required().label("Remark"),
  };

  async componentDidMount() {
    this.getData();
  }

  getData = async () => {
    // fix: Don't mutate 🧨
    // Get Instructor Settlement data
    this.setState({ isLoadingData: true });
    try {
      await userServices.getSettlementData().then(({ data }) => {
        let { instructorSettlements } = this.state;
        const {
          available_funds_for_withdrawal,
          total_amount_earned,
          total_courses_booked,
        } = data;
        instructorSettlements = {
          available_funds_for_withdrawal,
          total_amount_earned,
          total_courses_booked,
        };

        this.setState({ instructorSettlements });
      });
    } catch (error) {}

    // Get Instructor all transactions
    try {
      await userServices.getAllTransactions().then(({ data }) => {
        const { settlement_transactions } = data;
        this.setState({
          settlementTransactions: [...settlement_transactions],
        });
      });
    } catch (error) {}
    this.setState({ isLoadingData: false });
  };

  doSubmit = async () => {
    this.setState({ isLoading: true });
    const { amountToBePaid, remark } = this.state.data;
    const payoutData = {
      request_amount: amountToBePaid,
      request_detail: remark,
    };

    try {
      await userServices.createPayoutRequest(payoutData).then(({ data }) => {
        this.setState({ isLoading: false, showModal: false }, () =>
          this.getData()
        );
      });
    } catch (error) {
      this.setState({ isLoading: false, showModal: false });
    }
  };

  handleModalOpen = () => {
    this.setState({
      showModal: true,
    });
  };

  handleModalClose = () => {
    this.setState({
      showModal: false,
    });
  };

  render() {
    const {
      showModal,
      instructorSettlements,
      settlementTransactions,
      isLoading,
      isLoadingData,
    } = this.state;
    const {
      available_funds_for_withdrawal,
      total_amount_earned,
      total_courses_booked,
    } = instructorSettlements;

    return (
      <>
        <main className="dashboard-main">
          <DashboardSideNav />
          <main className="dashboard__main">
            <DashboardTopNav>
              <h3 className="head-2">Settlements</h3>
              <button
                className="btn btn-1"
                onClick={() => this.handleModalOpen()}
              >
                Request Payout
              </button>
            </DashboardTopNav>

            <CenteredLayout>
              <DashboardMaxWidth>
                {isLoadingData ? (
                  <SpinnerBox />
                ) : (
                  <>
                    <section className="settlement">
                      <div className="settlement-card settlement-card--1">
                        <Svg
                          cssClassName="settlement__icon"
                          iconId="icon-withdrawer"
                        />
                        <div className="settlement__details">
                          <h6 className="settlement-title settlement-title--white">
                            Available Fund for Withdrawal
                          </h6>
                          <h4 className="settlement-value settlement-value--white">
                            $ {formatCurrency(available_funds_for_withdrawal)}
                          </h4>
                        </div>
                      </div>
                      <div className="settlement-card">
                        <Svg
                          cssClassName="settlement__icon"
                          iconId="icon-earned"
                        />
                        <div className="settlement__details">
                          <h6 className="settlement-title">
                            Total Amount Earned
                          </h6>
                          <h4 className="settlement-value">
                            $ {formatCurrency(total_amount_earned)}{" "}
                          </h4>
                        </div>
                      </div>
                      <div className="settlement-card">
                        <Svg
                          cssClassName="settlement__icon"
                          iconId="icon-booked"
                        />
                        <div className="settlement__details">
                          <h6 className="settlement-title">
                            Total Course Booked
                          </h6>
                          <h4 className="settlement-value">
                            {" "}
                            {total_courses_booked}{" "}
                          </h4>
                        </div>
                      </div>
                    </section>

                    <section>
                      <div className="settlement-table">
                        <div className="settlement-table__top">
                          <h4 className="head-16">Transactions</h4>
                          {/* <InputSearch
                            placeholder="Search Transactions"
                            cssClass="input__search-course--no-margin"
                          /> */}
                        </div>
                        {settlementTransactions &&
                        settlementTransactions.length ? (
                          <TransactionTable
                            tableData={settlementTransactions}
                          />
                        ) : (
                          <EmptyActivityBox cssClass="p-y-50px">
                            <EmptyActivityState
                              title="No transactions Available"
                              subTitle="No transaction has been processed yet!"
                            />
                          </EmptyActivityBox>
                        )}
                      </div>
                    </section>
                  </>
                )}
              </DashboardMaxWidth>
            </CenteredLayout>
          </main>
        </main>

        {showModal && (
          <ModalLayout>
            <ModalChild>
              <ContentCard>
                <button
                  className="btn-close-modals"
                  onClick={() => this.handleModalClose()}
                >
                  <img src={images.pic9} alt="times" />
                </button>

                <form onSubmit={this.handleSubmit}>
                  {this.renderInput(
                    "Amount to be paid",
                    "amountToBePaid",
                    "text",
                    "noIcon",
                    null
                  )}
                  {this.renderInput("Remark", "remark", "text", "noIcon", null)}

                  {this.renderButton("Payout", isLoading, "btn btn-5 m-t-25px")}

                  {/* <button className="btn btn-5 m-t-25px">Payout</button> */}
                </form>
              </ContentCard>
            </ModalChild>
          </ModalLayout>
        )}
      </>
    );
  }
}

export default Settlement;
