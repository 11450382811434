import React, { useState, useRef, useCallback } from "react";
import useOnClickOutside from "../../utils/Hooks/useOnClickOutside";
import Dots from "./SvgIcons/Dots";
import EditIcon from "./SvgIcons/EditIcon";
import TrashIcon from "./SvgIcons/TrashIcon";

const RowActions = (props) => {
  // Refs
  const dropdownRef = useRef();

  // States
  const [showDropdown, setShowDropdown] = useState(false);

  //   Props
  const { handleEdit, handleDelete, isDeleting } = props;

  //   Hooks
  useOnClickOutside(dropdownRef, () => setShowDropdown(false)); // Custom hook

  //   Handlers
  const handleDropdownShow = (e) => {
    e.stopPropagation();
    setShowDropdown((prevState) => !prevState);
  };

  const handleEditing = useCallback((e) => {
    e.stopPropagation();
    handleEdit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDeleting = useCallback((e) => {
    e.stopPropagation();
    handleDelete();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //   Data to render
  return (
    <div className="row-action">
      <button
        className="btn btn-naked row-action__button"
        onClick={handleDropdownShow}
      >
        <Dots />
      </button>
      {showDropdown && (
        <div ref={dropdownRef} className="dropdown">
          <button className="btn dropdown-btn" onClick={handleEditing}>
            <EditIcon pathCssClass="dropdown-btn-icon" />
            Edit
          </button>
          <button className="btn dropdown-btn" onClick={handleDeleting}>
            <TrashIcon pathCssClass="dropdown-btn-icon" />
            {isDeleting ? "Deleting" : "Delete"}
          </button>
        </div>
      )}
    </div>
  );
};

export default RowActions;
