import http from "./httpServices";

const studentRegisterApi = "/student-register";
const instructorSettlementApi = "/payout/get-settlement-dashboard-stats";
const instructorAllTransactionsApi = "/payout/get-all-settlement-transactions";
const createPayoutRequestApi = "/payout/create-payout-request";

export async function registerInstructor(instructorData) {
  const { email, password, name, phone_number, country_id } = instructorData;
  return await http.post(
    `${studentRegisterApi}?name=${name}&email=${email}&password=${password}&country_id=${country_id}&phone_number=${phone_number}`
  );
}

export const getSettlementData = () => {
  return http.get(instructorSettlementApi);
};

export const getAllTransactions = () => {
  return http.get(instructorAllTransactionsApi);
};

export const createPayoutRequest = (data) => {
  return http.post(createPayoutRequestApi, data);
};

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  registerInstructor,
  getSettlementData,
  getAllTransactions,
  createPayoutRequest,
};
