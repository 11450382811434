import ContentCard from "./ContentCard";
import EmptyActivityBox from "./EmptyActivityBox";
import EmptyActivityState from "./EmptyActivityState";
import EventCard from "./EventCard";

const Schedule = (props) => {
  const { events } = props;

  // Data to render
  return (
    <ContentCard title="Schedule">
      {events && events.length ? (
        <EmptyActivityBox cssClass="p-y-50px">
          <EmptyActivityState title="No schedules found" />
        </EmptyActivityBox>
      ) : (
        <>
          {events.length ? (
            <>
              <p className="para-1 para-1--light">Upcoming Events</p>
              {events?.map((event) => (
                <EventCard
                  key={event?.live_class_id}
                  id={event?.live_class_id}
                  name={event?.topic}
                  date={event?.date}
                  time={event?.start_time}
                  timeZone={event?.timezone_name}
                  type={event?.live_class_type_name}
                />
              ))}
            </>
          ) : (
            <EmptyActivityBox cssClass="p-y-20px">
              <EmptyActivityState title="No Upcoming Schedule" />
            </EmptyActivityBox>
          )}
        </>
      )}
    </ContentCard>
  );
};

export default Schedule;
