import React from "react";

const CircleCheck = ({ width, height, cssClass, pathCssClass1 }) => {
  return (
    <svg
      width={width || "13"}
      height={height || "13"}
      className={`${cssClass ? cssClass : ""}`}
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="6.5"
        cy="6.5"
        r="5.5"
        fill="#121212"
        stroke="#121212"
        strokeWidth="2"
      />
      <path
        className={pathCssClass1}
        d="M4 7L5.66471 9L9 5"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CircleCheck;
