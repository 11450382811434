import React, { useState } from "react";
// import { Link } from "react-router-dom";

import OnboardingNav from "../common/OnboardingNav";
import VerifyEmail from "../common/SvgIcons/VerifyEmail";
import { useSelector } from "react-redux";
import { resendVerificationLink } from "../../services/authServices";

const AccountSuccess = ({ location }) => {
  // State
  const [isSending, setIsSending] = useState(false);

  // User data
  const [userEmail] = useSelector((store) => [
    store.entities.user?.newUser?.email,
  ]);

  // Methods
  const handleCodeResend = async () => {
    if (userEmail && !isSending) {
      setIsSending(true);
      try {
        await resendVerificationLink({ email: userEmail });
        setIsSending(false);
      } catch (error) {
        setIsSending(false);
      }
    }
  };

  // Data to display
  return (
    <>
      <OnboardingNav location={location} />
      <div className="verify-email__container">
        <div className="verify-email__details">
          <VerifyEmail />
          <h3 className="m-b-20px">Thank you</h3>
          {userEmail && (
            <p className="m-b-5px">We sent an email to {userEmail}</p>
          )}
          <p className="m-b-30px">
            click the confirmation link sent to your email to verify your
            account
          </p>
          <botton onClick={() => handleCodeResend()} className="link link-3">
            {isSending ? "Resending..." : "Resend email"}
          </botton>
        </div>
      </div>
    </>
  );
};

export default AccountSuccess;
