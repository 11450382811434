import Skeleton from "react-loading-skeleton";

const PlatformsLoader = () => {
  return (
    <div className="flex-r-jcbetween-aicenter flex-gap-30px">
      <div className="flex-c">
        <Skeleton height={"50px"} width={"50px"} />
        <p className="m-t-5px">
          <Skeleton height={"4px"} />
        </p>
      </div>
      <div className="flex-c">
        <Skeleton height={"50px"} width={"50px"} />
        <p className="m-t-5px">
          <Skeleton height={"4px"} />
        </p>
      </div>
      <div className="flex-c">
        <Skeleton height={"50px"} width={"50px"} />
        <p className="m-t-5px">
          <Skeleton height={"4px"} />
        </p>
      </div>
      <div className="flex-c">
        <Skeleton height={"50px"} width={"50px"} />
        <p className="m-t-5px">
          <Skeleton height={"4px"} />
        </p>
      </div>
    </div>
  );
};

export default PlatformsLoader;
