import React from "react";

const VerifyAcc = () => {
  return (
    <svg
      width="333"
      height="333"
      viewBox="0 0 333 333"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse cx="162" cy="157" rx="89" ry="72" fill="#E4EAF1" />
      <g filter="url(#filter0_f_1334_3802)">
        <circle
          cx="166.5"
          cy="166.5"
          r="93.5"
          fill="black"
          fill-opacity="0.3"
        />
      </g>
      <circle cx="297" cy="58" r="7" fill="#DEE3EB" />
      <circle cx="47" cy="69" r="4" fill="#DEE3EB" />
      <circle cx="316" cy="90" r="12" fill="#DEE3EB" />
      <circle cx="15.5" cy="197.5" r="14.5" fill="#DEE3EB" />
      <rect x="71" y="51" width="191" height="191" rx="20" fill="white" />
      <path
        d="M166.5 218.125C183.071 218.125 199.129 212.379 211.938 201.867C224.748 191.354 233.516 176.726 236.749 160.473C239.982 144.221 237.479 127.35 229.668 112.736C221.856 98.1221 209.219 86.6685 193.91 80.3271C178.6 73.9858 161.566 73.1489 145.708 77.9591C129.851 82.7694 116.152 92.9291 106.946 106.707C97.7397 120.485 93.5957 137.029 95.2199 153.52C96.8441 170.011 104.136 185.429 115.853 197.147"
        stroke="#CCD2E3"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M198.333 130.583L159.944 176.651C159.288 177.437 158.96 177.831 158.52 177.851C158.079 177.871 157.717 177.509 156.993 176.784L134.667 154.458"
        stroke="#CCD2E3"
        stroke-width="2"
        stroke-linecap="round"
      />
      <defs>
        <filter
          id="filter0_f_1334_3802"
          x="0"
          y="0"
          width="333"
          height="333"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="36.5"
            result="effect1_foregroundBlur_1334_3802"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default VerifyAcc;
