import React, { useEffect } from "react";

import ContentCard from "../common/ContentCard";
import CourseExplore from "../common/CoursesExplore";
import DashboardSideNav from "../common/DashboardSideNav";
import DashboardTopNav from "../common/DashboardTopNav";
import EmptyActivityBox from "../common/EmptyActivityBox";
import EmptyActivityState from "../common/EmptyActivityState";
// import WhatToLearn from "../common/WhatToLearn";
import StudentDashboardLoader from "../common/Loaders/StudentDashboardLoader";
import Svg from "./../common/Svg";
import UpcomingClasses from "../common/UpcomingClasses";

import { useDispatch, useSelector } from "react-redux";
import {
  getOngoingClass,
  getStudentDashboardData,
  getUpcomingClasses,
} from "./../../storee/slices/student";
// import { getCourseCategories } from "./../../storee/slices/helpers";
// import { getFeaturedCourses } from "./../../storee/slices/courses";
import FixedTop from "../common/FixedTop";
import CenteredLayout from "../common/CenterLayout";
import DashboardMaxWidth from "../common/DashboardMaxWidth";
import OngoingCourse from "../common/OngoingCourse";
import useStudent from "../../store/student";

const StudentDashboard = () => {
  // Hook
  const dispatch = useDispatch();

  // Store
  let [
    isLoadingDashboardData,
    dashboardData,
    isLoadingUpcomingClasses,
    upcomingClasses,
    // ongoingClass,
  ] = useSelector((store) => [
    store.entities.student.dashboardData.isLoading,
    store.entities.student.dashboardData.data,
    store.entities.student.upcomingClasses.isLoading,
    store.entities.student.upcomingClasses.data,
    // store.entities.student.ongoingClass.data,
  ]);

  let [isLoadingCategoriesData] = useSelector((store) => [
    store.entities.helpers.courseCategories.isLoading,
  ]);

  const { getStudentCoursesInfo } = useStudent();

  // Effects
  useEffect(() => {
    // if (!dashboardData) dispatch(getStudentDashboardData());
    dispatch(getStudentDashboardData());
    // dispatch(getCourseCategories());
    // dispatch(getFeaturedCourses());
    dispatch(getUpcomingClasses());
    dispatch(getOngoingClass());

    getStudentCoursesInfo();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderPage =
    isLoadingDashboardData &&
    isLoadingCategoriesData &&
    isLoadingUpcomingClasses;
  return (
    <main className="dashboard-main">
      <DashboardSideNav />
      <main className="dashboard__main">
        <DashboardTopNav>
          <h3 className="head-2">Dashboard</h3>
        </DashboardTopNav>

        <CenteredLayout>
          <DashboardMaxWidth>
            {renderPage ? (
              <StudentDashboardLoader />
            ) : (
              <section className="student-dashboard__layout">
                <section className="student-dashboard__left">
                  <div className="student-cards">
                    <div className="student-cards__total">
                      <div>
                        <h5 className="student-cards__title student-cards__title--total">
                          Total Courses Enrolled
                        </h5>
                        <h3 className="student-cards__value">
                          {dashboardData?.total_enrolment || 0}
                        </h3>
                      </div>
                      <Svg
                        cssClassName="student-cards__icon"
                        iconId="icon-enrolled-courses"
                      />
                    </div>
                    <div className="student-cards__active">
                      <div>
                        <h5 className="student-cards__title student-cards__title--active">
                          Active Enrolled
                        </h5>
                        <h3 className="student-cards__value">
                          {dashboardData?.active_enrolment || 0}
                        </h3>
                      </div>
                      <Svg
                        cssClassName="student-cards__icon"
                        iconId="icon-active-courses"
                      />
                    </div>
                    <div className="student-cards__completed">
                      <div>
                        <h5 className="student-cards__title student-cards__title--completed">
                          Course Completed
                        </h5>
                        <h3 className="student-cards__value">
                          {dashboardData?.courses_completed || 0}
                        </h3>
                      </div>
                      <Svg
                        cssClassName="student-cards__icon"
                        iconId="icon-completed-courses"
                      />
                    </div>
                  </div>
                  {/* <WhatToLearn
                    courseCategories={courseCategories?.course_categories}
                  /> */}

                  <OngoingCourse />

                  <CourseExplore cssClass="m-t-50px" />
                </section>
                <aside className="student-dashboard__right">
                  <FixedTop cssClass="top-74px">
                    {upcomingClasses?.all_student_live_classes?.length > 0 ? (
                      <UpcomingClasses
                        classes={upcomingClasses?.all_student_live_classes?.slice(
                          0,
                          3
                        )}
                      />
                    ) : (
                      <ContentCard title="Upcoming Classess">
                        <EmptyActivityBox cssClass="p-y-50px">
                          <EmptyActivityState
                            title="No upcoming classes"
                            subTitle="You do not have any upcoming classes"
                          />
                        </EmptyActivityBox>
                      </ContentCard>
                    )}
                  </FixedTop>
                </aside>
              </section>
            )}
          </DashboardMaxWidth>
        </CenteredLayout>
      </main>
    </main>
  );
};

export default StudentDashboard;
