import React, { useEffect, useCallback, useState } from "react";
import { useParams } from "react-router-dom";

import DashboardSideNav from "../common/DashboardSideNav";
import DashboardTopNav from "../common/DashboardTopNav";

// import InputSearch from "../common/InputSearch";
import LiveClasses from "../LiveClasses";
import { getCourse } from "./../../storee/slices/singleCourse";
import { useDispatch, useSelector } from "react-redux";
import CenteredLayout from "../common/CenterLayout";
import DashboardMaxWidth from "../common/DashboardMaxWidth";
import LiveClassesLoader from "../common/Loaders/LiveClassesLoader";
import EmptyActivityState from "../common/EmptyActivityState";
import EmptyActivityBox from "../common/EmptyActivityBox";
import { atcb_action } from "add-to-calendar-button";
import "add-to-calendar-button/assets/css/atcb.css";
import { formatLiveClassesCalenderData } from "../../utils/helpers";
import LiveClassModal from "../common/Modals/LiveClassModal";

const CreateLiveCourse = (props) => {
  // States
  const [showCreateClass, setShowCreateClass] = useState(false);

  // Hooks
  const dispatch = useDispatch();
  const { id } = useParams();

  // Store Selector
  let [course, courseData, isLoading] = useSelector((store) => [
    store.entities.singleCourse.data.courses,
    store.entities.singleCourse.data,
    store.entities.singleCourse.isLoading,
  ]);

  // Handlers
  const getACourse = useCallback(() => {
    dispatch(getCourse(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const addToCalenderAction = () => {
    const name = course?.course_name;
    const dates = [...formatLiveClassesCalenderData(courseData?.live_classes)];

    atcb_action({
      name,
      dates,
      options: [
        "Apple",
        "Google",
        "iCal",
        "Microsoft365",
        "Outlook.com",
        "Yahoo",
      ],
      iCalFileName: "Multi-Date-Event",
    });
  };

  // Effects
  useEffect(() => {
    getACourse();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <main className="dashboard-main">
        <DashboardSideNav />
        <main className="dashboard__main">
          <DashboardTopNav back>
            <h3 className="head-2">Live Classes</h3>
            {/* <InputSearch placeholder="Search Courses" /> */}
          </DashboardTopNav>

          <CenteredLayout>
            <DashboardMaxWidth>
              {isLoading ? (
                <LiveClassesLoader />
              ) : (
                <>
                  <section className="live_courses_list m-t-70px">
                    <div className="liveClassTop">
                      <h3 className="liveClassTopTitle head-35 max-width-550px">
                        {course?.course_name || "--"}
                      </h3>
                      <div className="liveClassTopBtn">
                        <button
                          className="btn btn-4"
                          onClick={addToCalenderAction}
                        >
                          Add Class to Calendar
                        </button>
                        <button
                          onClick={() => setShowCreateClass(true)}
                          className="btn btn-1"
                        >
                          Create Live Class
                        </button>
                      </div>
                    </div>

                    {courseData?.live_classes?.length ? (
                      <div className="liveClassList">
                        <>
                          {courseData?.live_classes?.map((liveClass) => (
                            <LiveClasses
                              key={liveClass.id}
                              data={liveClass}
                              handleReloadClasses={() => getACourse(id)}
                            />
                          ))}
                        </>
                      </div>
                    ) : (
                      <EmptyActivityBox cssClass="p-y-50px">
                        <EmptyActivityState title="No live classes" />
                      </EmptyActivityBox>
                    )}
                  </section>
                </>
              )}
            </DashboardMaxWidth>
          </CenteredLayout>
        </main>
      </main>

      {showCreateClass && (
        <LiveClassModal
          setShowModal={setShowCreateClass}
          handleReloadClasses={() => getACourse(id)}
          title="Create live class"
        />
      )}
    </>
  );
};

export default CreateLiveCourse;
