import React from "react";

const FileIcon = ({ width, height, cssClass, pathCssClass }) => {
  return (
    <svg
      width={width || "20"}
      height={height || "20"}
      className={`${cssClass ? cssClass : ""}`}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="10" fill="#647382" fillOpacity="0.02" />
      <path
        className={pathCssClass}
        d="M7 13.2381L12 13.2381L12 14L7 14L7 13.2381ZM9.5 6L11.6429 8.28571L11.1393 8.82286L9.85714 7.45905L9.85714 12.0952L9.14286 12.0952L9.14286 7.45905L7.86071 8.82286L7.35714 8.28571L9.5 6Z"
        fill="#2E3A59"
      />
    </svg>
  );
};

export default FileIcon;
