import React from "react";

const CurriculumIcon = ({ width, height, cssClass, pathCssClass1 }) => {
  return (
    <svg
      width={width || "19"}
      height={height || "19"}
      className={cssClass ? cssClass : ""}
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2_1970)">
        <path
          className={pathCssClass1 ? pathCssClass1 : ""}
          d="M11.5039 19H4.45312C1.99648 19 0 17.0035 0 14.5469V4.45312C0 1.99648 1.99648 0 4.45312 0H14.5469C17.0035 0 19 1.99648 19 4.45312V11.5596C19 11.9678 18.666 12.3018 18.2578 12.3018C17.8496 12.3018 17.5156 11.9678 17.5156 11.5596V4.45312C17.5156 2.8166 16.1834 1.48438 14.5469 1.48438H4.45312C2.8166 1.48438 1.48438 2.8166 1.48438 4.45312V14.5469C1.48438 16.1834 2.8166 17.5156 4.45312 17.5156H11.5039C11.9121 17.5156 12.2461 17.8496 12.2461 18.2578C12.2461 18.666 11.9121 19 11.5039 19ZM15.2891 4.67578C15.2891 4.26758 14.9551 3.93359 14.5469 3.93359H7.75586C7.34766 3.93359 7.01367 4.26758 7.01367 4.67578C7.01367 5.08398 7.34766 5.41797 7.75586 5.41797H14.5469C14.9551 5.41797 15.2891 5.08398 15.2891 4.67578ZM15.2891 7.64453C15.2891 7.23633 14.9551 6.90234 14.5469 6.90234H4.45312C4.04492 6.90234 3.71094 7.23633 3.71094 7.64453C3.71094 8.05273 4.04492 8.38672 4.45312 8.38672H14.5469C14.9551 8.38672 15.2891 8.05273 15.2891 7.64453ZM15.2422 10.6133C15.2422 10.2051 14.9082 9.87109 14.5 9.87109H4.45312C4.04492 9.87109 3.71094 10.2051 3.71094 10.6133C3.71094 11.0215 4.04492 11.3555 4.45312 11.3555H14.5C14.9082 11.3555 15.2422 11.0215 15.2422 10.6133ZM3.71094 4.60156C3.71094 5.11367 4.12656 5.5293 4.63867 5.5293C5.15078 5.5293 5.56641 5.11367 5.56641 4.60156C5.56641 4.08945 5.15078 3.67383 4.63867 3.67383C4.12656 3.67383 3.71094 4.08945 3.71094 4.60156Z"
          fill="black"
        />
        <path
          className={pathCssClass1 ? pathCssClass1 : ""}
          d="M14.5 12.7578C14.9082 12.7578 15.2422 13.0918 15.2422 13.5C15.2422 13.9082 14.9082 14.2422 14.5 14.2422H4.45312C4.04492 14.2422 3.71094 13.9082 3.71094 13.5C3.71094 13.0918 4.04492 12.7578 4.45312 12.7578H14.5Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_2_1970">
          <rect width="19" height="19" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CurriculumIcon;
