import React, { useState, useEffect } from "react";
import { useParams, useHistory, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import DashboardSideNav from "../common/DashboardSideNav";
import DashboardTopNav from "../common/DashboardTopNav";

import { getCourse } from "../../storee/slices/singleCourse";
import CenteredLayout from "../common/CenterLayout";
import EditBillingModal from "../common/Modals/EditBillingModal";
import usePayment from "../../store/payment";
import {
  paypalPayment,
  paystackPayment,
  stripePayment,
} from "../../services/paymentServices";
import { log, formatCurrency } from "../../utils/helpers";
import { getStudentBillingInfo } from "../../services/studentServices";
import SpinnerBox from "../common/SpinnerBox";
import authServices from "../../services/authServices";

const Checkout = () => {
  // State
  const [showModal, setShowModal] = useState(false);
  const [billingInfo, setBillingInfo] = useState({
    address: "",
    city: "",
    country: "",
  });
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();

  // Store
  const { paymentOption } = usePayment();
  const [course, isLoading] = useSelector((store) => [
    store.entities.singleCourse.courseById.data,
    store.entities.singleCourse.courseById.isLoading,
  ]);

  const student = authServices.getCurrentUser();

  // Methods
  const goBack = (e) => {
    e.preventDefault();
    history.goBack();
  };

  const getBillingInfo = async () => {
    try {
      const res = await getStudentBillingInfo();
      // if (res.data.student_bililng_info.billing_address) return;
      const data = res.data.student_bililng_info;
      console.log({ data });
      const info = {
        address: data.billing_address,
        city: data.billing_city,
        country: data.billing_country_name,
      };
      // let info = "";
      // info += data.billing_address;
      setBillingInfo(info);
    } catch (error) {}
  };

  const handlePayment = async () => {
    if (paymentOption === "paypal") {
      try {
        const paypalRes = await paypalPayment(id);
        log({ paypalRes: paypalRes.data });
        if (paypalRes.data.payment_link) {
          window.open(paypalRes.data.payment_link);
        }
      } catch (error) {
        log({ error });
      }
    } else if (paymentOption === "paystack") {
      try {
        const paystackRes = await paystackPayment(id);
        log({ paystackRes });
        log(paystackRes.data.payment_link_data.data.authorization_url);
        if (paystackRes.data) {
          window.open(
            paystackRes.data.payment_link_data.data.authorization_url
          );
        }
      } catch (error) {
        log({ error });
      }
    } else if (paymentOption === "stripe") {
      try {
        const stripeRes = await stripePayment(id);
        log({ stripeRes });
        log(stripeRes.data.payment_link);
        if (stripeRes.data.payment_link) {
          window.open(stripeRes.data.payment_link);
        }
      } catch (error) {
        log({ error });
      }
    }
  };

  useEffect(() => {
    getBillingInfo();
    dispatch(getCourse(id));
    // window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <main className="dashboard-main">
        <DashboardSideNav />
        <main className="dashboard__main">
          <DashboardTopNav>
            <button onClick={(e) => goBack(e)} className="btn btn-3">
              <i className="fas fa-long-arrow-alt-left m-r-6px"></i>
              Back
            </button>
          </DashboardTopNav>
          <>
            <CenteredLayout>
              <div className="checkout-out__wrapper">
                <div className="course-title m-b-30px">
                  <h2 className="head-8">Checkout</h2>
                </div>
                <div className="checkout-out__card">
                  {isLoading ? (
                    <SpinnerBox />
                  ) : (
                    <>
                      <div className="flex-r-jcbetween-aicenter m-b-7px">
                        <h5>Billing Address</h5>
                        <Link
                          to="#"
                          className="link link-5"
                          onClick={() => setShowModal(true)}
                        >
                          Edit
                        </Link>
                      </div>
                      <div className="checkout-out__billing m-b-40px">
                        <p className="para-19 m-b-5px">
                          {student?.name || "--"}
                        </p>
                        <h5 className="">
                          {billingInfo ? (
                            <>
                              {billingInfo.address}
                              <br />
                              {billingInfo.city}{', '}{billingInfo.country}
                            </>
                          ) : (
                            "No billing address provided"
                          )}
                          {/* 13 Salvation Street Olorunda Estate, Ketu Alapere, Lagos,
                      50080, Nigeria */}
                        </h5>
                      </div>
                      <h5 className="m-b-7px">Order Details</h5>
                      <p className="para-19 m-b-40px">
                        {course?.course_name}{" "}
                        {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Phasellus ac pellentesque odio, quis porttitor ex. Morbi est
                    justo, pulvinar vitae blandit non, molestie eu nulla. Orci
                    varius natoque penatibus et magnis dis parturient montes,
                    nascetur ridiculus mus. Integer euismod neque */}
                      </p>
                      <h5 className="m-b-7px">Payment Details</h5>
                      <div className="checkout-out__fees">
                        <div className="checkout-out__fee">
                          <h6>Original Price:</h6>
                          <p>NGN {formatCurrency(course?.price)}</p>
                        </div>
                        <div className="checkout-out__fee">
                          <h6>Coupon discount:</h6>
                          <p>NGN {formatCurrency(0)}</p>
                        </div>
                      </div>

                      <div className="checkout-out__total">
                        <h6>Total:</h6>
                        <h6>NGN {formatCurrency(course?.price)}</h6>
                      </div>

                      {/* <Link
                    to={`/user-course/${id}`}
                    className="display-block m-t-50px"
                  > */}
                      <button
                        className="btn btn-7 btn--full display-block m-t-50px"
                        onClick={() => handlePayment()}
                      >
                        PROCEED
                      </button>
                      {/* </Link> */}
                    </>
                  )}
                </div>
              </div>
            </CenteredLayout>
          </>
        </main>
      </main>

      {showModal && <EditBillingModal setShowModal={setShowModal} />}
    </>
  );
};

export default Checkout;
