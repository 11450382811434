import React from "react";
// import { Link } from "react-router-dom";

import ActivityAction from "./ActivityAction";

const Activities = ({ activities, children, activeTab }) => {
  return (
    <div className="activities">
      <div className="activities__actions">
        {activities.map((activity, id) => (
          // <Link to={activity.link} key={id}>
          <ActivityAction
            key={id}
            iconClass={activity?.iconClass}
            iconId={activity?.iconId}
            icon={activity?.icon}
            name={activity?.name}
            handleClick={() => activity?.handleActionClicked(id)}
            isActive={activeTab === id}
            iconType={activity?.iconType}
          />
          // </Link>
        ))}
      </div>
      <div className="activities__events">{children}</div>
    </div>
  );
};

export default Activities;
