import React from "react";

const Checkbox = (props) => {
  // Props
  const { name, label, error, value, type, cssClass, ...rest } = props;
  // Data to render
  return (
    <div className={cssClass ? cssClass : ""}>
      {error ? (
        <span className="input__error m-b-10px display-block"> {error} </span>
      ) : (
        ""
      )}
      <label htmlFor={label ? label : name} className="input__checkbox">
        <input
          type={type ? type : "checkbox"}
          name={name}
          id={label ? label : name}
          checked={value}
          value={value}
          {...rest}
        />

        <span className="check">
          <span className="check__label">{label}</span>
        </span>
      </label>
    </div>
  );
};

export default Checkbox;
