import { createSlice } from "@reduxjs/toolkit";

const notification = createSlice({
  name: "notification",
  initialState: {
    list: [],
  },
  reducers: {
    addNotification: (notification, action) => {
      notification.list.push(action.payload);
    },
    removeNotification: (notification, action) => {
      const currentNotifications = notification.list;
      currentNotifications.splice(action.payload.id, 1);
      notification.list = [...currentNotifications];
    },
    removeAllNotification: (notification, action) => {
      notification.list = [];
    },
  },
});

const {
  addNotification,
  removeNotification,
  removeAllNotification,
} = notification.actions;

// Action Creators
// Add Notification
export const addANotification = (data) => addNotification(data);
// Remove a Notification
export const removeANotification = (data) => removeNotification({ id: data });
// Remove all Notification
export const removeNotifications = () => removeAllNotification();

export default notification.reducer;
