import Form from "./../Forms/Form";
import Joi from "joi";
import ContentCard from "../ContentCard";
import GridView from "../GridView";
import EmptyActivityBox from "../EmptyActivityBox";
import EmptyActivityState from "../EmptyActivityState";
import EventCard from "./../EventCard";

import courseServices from "../../../services/courseServices";
import PlatformsLoader from "../Loaders/PlatformsLoader";
import {
  createLiveClass,
  editLiveClass,
} from "../../../storee/slices/createCourse";
// import Checkbox from "./../Input/Checkbox";
import { connect } from "react-redux";
import {
  getLiveClassTypes,
  getPreferedPlatform,
  getTimeZones,
} from "../../../storee/slices/helpers";
import Spinner from "../Spinner";
import { addANotification } from "../../../storee/slices/notification";
import ModalLayout from "./../ModalLayout";
import ModalChild from "../ModalChild";
import { images } from "../../../utils/images";
import Notiflix from "notiflix";

class LiveClass extends Form {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        liveClassName: "",
        startTime: "",
        endTime: "",
        preferredPlatform: "",
        date: "",
        timeZone: "",
        linkToLIveClass: "",
        liveClassType: "",
      },
      error: {
        liveClassName: "",
        startTime: "",
        endTime: "",
        preferredPlatform: "",
        date: "",
        timeZone: "",
        linkToLIveClass: "",
        liveClassType: "",
      },

      preferredLiveClassPlatform: this.props.preferedPlatforms,
      courseId: "",
      liveClasses: [],
      timeZonesList: [],
      curriculums: [],
      liveClassTypes: [],
      isLoading: false,
      isLoadingPlatforms: false,
      showModal: false,
      isEditing: false,
    };
  }

  schema = {
    liveClassName: Joi.string().required().label("*"),
    startTime: Joi.string().required().label("*"),
    endTime: Joi.string().required().label("*"),
    preferredPlatform: Joi.string().label("*"),
    date: Joi.string().label("*"),
    timeZone: Joi.string().label("*"),
    linkToLIveClass: Joi.string().label("*"),
    liveClassType: Joi.string().label("*"),
  };

  async componentDidMount() {
    this.setState({ isLoadingPlatforms: true });

    // Get course id
    const courseIdFromProps = this.props.getCourseId();
    const liveClassesResponse = await courseServices.getLiveClassesById({
      course_id: courseIdFromProps,
    });
    const liveClasses = liveClassesResponse.data.courses;
    this.setState({ liveClasses });

    // Load timezones
    await this.props.getAllTimeZones();
    // eslint-disable-next-line no-unused-vars
    const timeZonesData = this.props?.timeZones?.map((timeZone) => ({
      optionName: timeZone?.text,
      optionId: timeZone?.id,
    }));
    this.setState({
      timeZonesList: [...timeZonesData],
    });

    // Live class types
    await this.props.getLiveClassTypes();
    // eslint-disable-next-line no-unused-vars
    const liveClassTypesData = this.props?.liveClassTypes?.map(
      (liveClassType) => ({
        optionName: liveClassType?.name,
        optionId: liveClassType?.live_class_type_id,
      })
    );
    this.setState({
      liveClassTypes: [...liveClassTypesData],
    });

    // Fetch preffered live classes
    if (this.props.preferedPlatforms.length < 1) {
      this.setState({ isLoadingPlatforms: true });
      await this.props.getPreferedPlatforms();
      this.setState({
        preferredLiveClassPlatform: this.props.preferedPlatforms,
        isLoadingPlatforms: false,
      });
    } else {
      this.setState({ isLoadingPlatforms: false });
    }
    // Load curriculums
    const curriculumsData = this.props?.curriculums?.map((curriculum) => ({
      optionName: curriculum?.topic,
      optionId: curriculum?.curriculum_id,
    }));
    this.setState({
      curriculums: [...curriculumsData],
    });
  }

  doSubmit = async () => {
    this.setState({ isLoading: true });
    // const courseIdFromProps = this.props.getCourseId();
    const { isEditing, editingClassId } = this.state;

    const {
      liveClassName: live_class_name,
      startTime,
      endTime,
      preferredPlatform: preferred_platform,
      date,
      timeZone,
      linkToLIveClass: link_to_live_class,
      liveClassType,
      // curriculumId,
    } = this.state.data;
    const liveClassData = {
      course_id: this.props.courseId,
      live_class_name: "",
      date,
      start_time: startTime,
      end_time: endTime,
      time_zone_id: timeZone || "",
      preferred_platform,
      link_to_live_class,
      live_class_type: liveClassType,
      curriculum_id: live_class_name,
    };

    if (Date.parse(`${date} ${startTime}`) > Date.parse(`${date} ${endTime}`)) {
      this.setState({ isLoading: false });

      const errors = this.state.error;
      this.setState({
        error: {
          ...errors,
          endTime: "End time must be greater then start time",
        },
      });
      return;
    } else {
      const errors = this.state.error;
      this.setState({
        error: {
          ...errors,
          endTime: "",
        },
      });
    }
    if (!this.props.courseId) {
      // Notification
      // this.props.notifyActions({
      //   type: "bad",
      //   title: "Error",
      //   description: `Cannot create live class`,
      // });
      Notiflix.Notify.failure(`Cannot create live class`);

      this.setState({ isLoading: false });
      return;
    }

    if (isEditing) {
      delete liveClassData.course_id;
      liveClassData.live_class_id = editingClassId;

      try {
        await this.props.editLiveClass(liveClassData);
        Notiflix.Notify.success(
          `${isEditing ? "Updated" : "Created"} successfully`
        );
        this.handleModalClose();
      } catch (error) {
        Notiflix.Notify.failure("An error occurred");
      }
    } else {
      try {
        await this.props.saveLiveClass(liveClassData);
        Notiflix.Notify.success(
          `${isEditing ? "Updated" : "Created"} successfully`
        );
        this.handleModalClose();
      } catch (error) {
        Notiflix.Notify.failure("An error occurred");
      }
    }

    // Notification
    // this.props.notifyActions({
    //   type: "ok",
    //   title: "Success",
    //   description: `${isEditing ? "Updated" : "Created"} successfully`,
    // });
    // Notiflix.Notify.success(
    //   `${isEditing ? "Updated" : "Created"} successfully`
    // );

    this.setState(
      {
        isLoading: false,
      },
      () => {
        if (!isEditing) this.resetData();
      }
    );
  };

  resetData = () => {
    this.setState({
      data: {
        liveClassName: "",
        startTime: "",
        endTime: "",
        liveClassType: "",
        preferredPlatform: "",
        date: "",
        timeZone: "",
        linkToLIveClass: "",
      },
    });
  };

  handleLiveClassEdit = async (id) => {
    this.setState({ isEditing: true });
    const liveClass = this.props?.liveClasses.filter(
      (liveClass) => liveClass.live_class_id === id
    );

    console.log({ liveClass });
    await this.setState(
      {
        data: {
          liveClassName: liveClass[0]?.curriculum_id,
          startTime: liveClass[0]?.start_time,
          endTime: liveClass[0]?.end_time,
          preferredPlatform: liveClass[0]?.preferred_platform_id,
          date: liveClass[0]?.date,
          timeZone: liveClass[0]?.time_zone_id,
          linkToLIveClass: liveClass[0]?.link_to_live_class,
          liveClassType: liveClass[0]?.live_class_type,
        },
        editingClassId: liveClass[0]?.live_class_id,
      },
      () => this.setState({ showModal: true })
    );

    // await this.setState({

    // })
  };

  handleModalOpen = () => {
    this.setState({
      showModal: true,
    });
  };

  handleModalClose = () => {
    this.setState(
      {
        showModal: false,
        isEditing: false,
      },
      () => this.resetData()
    );
  };

  render() {
    const {
      preferredLiveClassPlatform,
      isLoading,
      isLoadingPlatforms,
      timeZonesList,
      curriculums,
      liveClassTypes,
      showModal,
    } = this.state;
    const { preferredPlatform } = this.state.data;
    const { liveClasses } = this.props;
    return (
      <>
        <div className="about-this-course animate-slideFromLeft">
          <h3 className="head-5 m-b-10px">Live Classes</h3>
          <GridView grid={2}>
            <EmptyActivityBox cssClass="p-y-50px align-self-start">
              <EmptyActivityState
                title="Add Live Class"
                subTitle="Upload the time for weekly live classes"
                handleClick={this.handleModalOpen}
                btnText={"Upload"}
              />
            </EmptyActivityBox>

            <div>
              <ContentCard title="Scheduled Links">
                {liveClasses.length <= 0 ? (
                  <EmptyActivityBox cssClass="p-y-100px">
                    <EmptyActivityState
                      iconType={"link"}
                      title={"No Scheduled links!"}
                      subTitle="You are yet to setup links"
                    />
                  </EmptyActivityBox>
                ) : (
                  liveClasses.map((classes, index) => (
                    <EventCard
                      key={index}
                      id={classes?.live_class_id}
                      name={classes?.curriculum_topic}
                      date={classes?.date}
                      time={classes?.start_time}
                      timeZone={classes?.time_zone_name}
                      handleEdit={this.handleLiveClassEdit}
                      type={classes?.live_class_type_name}
                      classes={classes}
                    />
                  ))
                )}
              </ContentCard>
            </div>
          </GridView>
        </div>

        {showModal && (
          <ModalLayout>
            <ModalChild>
              <ContentCard title="Links to live classes">
                <button
                  className="btn-close-modals"
                  onClick={() => this.handleModalClose()}
                >
                  <img src={images.pic9} alt="times" />
                </button>
                <form onSubmit={this.handleSubmit} id="courseLiveClassesForm">
                  {this.renderSelect(
                    "Select Live Class Type",
                    "liveClassType",
                    liveClassTypes,
                    "name",
                    "live_class_type_id",
                    "Select"
                  )}
                  {this.renderSelect(
                    "Select Course Content",
                    "liveClassName",
                    curriculums,
                    "curriculum_id",
                    "topic",
                    "Select"
                  )}
                  {/* {this.renderInput(
                    "Course Content Name",
                    "liveClassName",
                    "text",
                    "noIcon",
                    null
                  )} */}
                  {this.renderInput("Date", "date", "date", "noIcon")}
                  <GridView grid={2}>
                    {this.renderInput(
                      "Start Time",
                      "startTime",
                      "time",
                      "noIcon"
                    )}
                    {this.renderInput("End Time", "endTime", "time", "noIcon")}
                  </GridView>
                  {this.renderSelect(
                    "Time Zone",
                    "timeZone",
                    timeZonesList,
                    "text",
                    "id",
                    "Select"
                  )}
                  <p className="input__label m-b-7px">Preferred Platform</p>
                  {isLoadingPlatforms ? (
                    <PlatformsLoader />
                  ) : (
                    <GridView grid={4}>
                      {preferredLiveClassPlatform.map((platform, index) =>
                        this.renderRadio(
                          platform["name"],
                          "preferredPlatform",
                          platform["id"] || "",
                          index,
                          preferredPlatform
                        )
                      )}
                    </GridView>
                    // <GridView grid={2}>
                    //   {preferredLiveClassPlatform?.map((platform, index) => (
                    //     <Checkbox
                    //       key={index}
                    //       label={platform?.name}
                    //       // cssClass="m-t-20px"
                    //     />
                    //   ))}
                    // </GridView>
                  )}

                  <div className="m-t-20px"></div>

                  {this.renderInput(
                    "Link to Live class",
                    "linkToLIveClass",
                    "text",
                    "noIcon",
                    "Paste link to Live Class"
                  )}

                  <button
                    type="submit"
                    form="courseLiveClassesForm"
                    className="btn btn-2 btn-md m-t-25px"
                  >
                    {isLoading ? <Spinner color="white" /> : "Set"}
                  </button>

                  {/* {this.renderInput("Confirm Password", "password", "password")}

        {this.renderButton("Send Reset Link")} */}
                </form>
              </ContentCard>
            </ModalChild>
          </ModalLayout>
        )}
      </>
    );
  }
}

// Store
const mapStateToProps = (state) => ({
  courseId: state.entities.createCourse.courseId,
  liveClasses: state.entities.createCourse.liveClasses.lists,
  curriculums: state.entities.createCourse.curriculum.curriculums,
  timeZones: state.entities.helpers.timeZones.data,
  liveClassTypes: state.entities.helpers.liveClassTypes.data,
  preferedPlatforms: state.entities.helpers.preferedPlatform.data,
});

const mapDispatchToProps = (dispatch) => ({
  notifyActions: (data) => dispatch(addANotification(data)),
  saveLiveClass: (data) => dispatch(createLiveClass(data)),
  editLiveClass: (data) => dispatch(editLiveClass(data)),
  getAllTimeZones: () => dispatch(getTimeZones()),
  getPreferedPlatforms: () => dispatch(getPreferedPlatform()),
  getLiveClassTypes: () => dispatch(getLiveClassTypes()),
});

export default connect(mapStateToProps, mapDispatchToProps)(LiveClass);
