import { Link } from "react-router-dom";
import RegisterForm from "../common/Forms/RegisterForm";
import Onboarding from "../common/Onboarding";

const Register = ({ location, history }) => {
  return (
    <Onboarding location={location}>
      <h2 className="head-2">Create account</h2>
      <p className="para-2 m-t-20px m-b-40px">Create to start learning.</p>

      <RegisterForm history={history} />

      <div className="onboard-question">
        Already have an account?{" "}
        <Link to="/" className="link link-1">
          Login
        </Link>
      </div>

      <p className="m-t-20px para-20 text-center">
        by continuing, you agree to Steviapro’s Terms of Service. Read our
        Privacy Policy
      </p>
    </Onboarding>
  );
};

export default Register;
