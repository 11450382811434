import React from "react";
import { Link, NavLink } from "react-router-dom";
import SiteLogo from "./SvgIcons/SiteLogo";
import DashboardIcon from "./SvgIcons/DashboardIcon";
import CoursesIcon from "./SvgIcons/CoursesIcon";
// import MessageIcon from "./SvgIcons/MessageIcon";
import SettlementIcon from "./SvgIcons/SettlementIcon";
// import BellIcon from "./SvgIcons/BellIcon";
import SettingsIcon from "./SvgIcons/SettingsIcon";
import LogoutIcon from "./SvgIcons/LogoutIcon";
import authServices from "../../services/authServices";

const DashboardSideNav = () => {
  const user = authServices.getCurrentUser();

  const links = [
    {
      link: "/dashboard",
      component: DashboardIcon,
    },
    {
      link: "/courses",
      component: CoursesIcon,
    },
    // {
    //   link: "/messages",
    //   component: MessageIcon,
    // },
    {
      link: "/settlement",
      component: SettlementIcon,
      isInstructor: user?.role_id === 2,
    },
    // {
    //   link: "/notification",
    //   component: BellIcon,
    //   css: "m-t-150px display-block",
    // },
    {
      link: "/settings",
      component: SettingsIcon,
      css: "m-t-150px display-block",
    },
    {
      link: "/logout",
      component: LogoutIcon,
    },
  ];

  return (
    <nav className="dashboard__aside">
      <Link to="/dashboard">
        <SiteLogo />
      </Link>

      <div className="dashboard__aside-link m-t-60px m-b-50px">
        {user?.role_id &&
          links.map((link, index) =>
            user?.role_id === "1" && link?.link === "/settlement" ? (
              ""
            ) : (
              <NavLink
                to={link?.link}
                key={index}
                className={`link link-2 dashboard__side-nav-link ${
                  link?.css ? link.css : ""
                } `}
              >
                <link.component />
              </NavLink>
            )
          )}
      </div>
    </nav>
  );
};

export default DashboardSideNav;
