import Form from "./../Forms/Form";
import Joi from "joi";
import { uploadLiveClassVideo } from "../../../services/courseServices";
import Spinner from "../Spinner";
import withRouter from "../../../hoc/withRouter";
import withCourseData from "../../../hoc/withCourseData";
import moment from "moment";

class LiveClassVideoForm extends Form {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        link_to_recording: "",
      },
      error: {
        link_to_recording: "",
      },
      isLoading: false,
    };
  }

  schema = {
    link_to_recording: Joi.string().required().label("*"),
  };

  async componentDidMount() {}

  doSubmit = async () => {
    this.setState({ isLoading: true });

    const data = {
      link_to_recording: this.state.data.link_to_recording,
      live_class_id: this.props.classId,
    };

    try {
       await uploadLiveClassVideo(data);
      this.setState({
        isLoading: false,
      });

      // Reload live classes
      this.props.handleReloadClasses();

      // Close modal
      this.props.closeModal();
    } catch (error) {}
  };

  render() {
    const { isLoading } = this.state;
    const { data } = this.props;
    return (
      <>
        <form onSubmit={this.handleSubmit}>
          <div className="eventsModal">
            <div className="eventsModalInfo uploadModalInfo">
              <div className="eventsModalInfo2 uploadModalContent">
                <div className="eventsModalInfo1">
                  <h4 className="modalInfoTitle">{data?.curriculum_name}</h4>
                  <div className="eventsModalInfo1Time">
                    <p className="para-5">{moment(data?.date).format("LL")}</p>
                    <p className="para-5">
                      {moment(data?.start_time, "hh").format("LT")}
                    </p>
                    <p className="para-5">{data?.timezone_name_abbr || "--"}</p>
                  </div>
                </div>
                <div className="uploadLinkColumn">
                  {this.renderInput(
                    "Link",
                    "link_to_recording",
                    "text",
                    "noIcon",
                    "Paste link to Live Class"
                  )}
                  <button type="submit" className="btn btn-1">
                    {isLoading ? <Spinner color='white' /> : "Upload"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </>
    );
  }
}

export default withRouter(withCourseData(LiveClassVideoForm));
