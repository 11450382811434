import React from "react";
import CustomDropdown from "./CustomDropdown";
import EmptyActivityBox from "./EmptyActivityBox";
import EmptyActivityState from "./EmptyActivityState";

const UpcomingClasses = (props) => {
  const { classes } = props;
  return (
    <div className="upcoming-classes">
      <h4 className="head-21 m-b-20px">Upcoming Classess</h4>
      {!classes?.length ? (
        <EmptyActivityBox cssClass="p-y-50px">
          <EmptyActivityState
            title="No live classes"
            subTitle="Empty live Classes"
          />
        </EmptyActivityBox>
      ) : (
        classes?.map((classData, index) => (
          <CustomDropdown key={index} data={classData} {...props} />
        ))
      )}
    </div>
  );
};

export default UpcomingClasses;
