import React from "react";
import CenteredLayout from "../common/CenterLayout";
import DashboardMaxWidth from "../common/DashboardMaxWidth";
import MessagesContainer from "../common/messages/MessagesContainer";
import DashboardSideNav from "./../common/DashboardSideNav";
import DashboardTopNav from "./../common/DashboardTopNav";

const Messages = () => {
  return (
    <main className="dashboard-main">
      <DashboardSideNav />
      <main className="dashboard__main">
        <DashboardTopNav>
          <h3 className="head-2 m-b-20px">Messages</h3>
        </DashboardTopNav>

        <CenteredLayout>
          <DashboardMaxWidth>
            <MessagesContainer />
          </DashboardMaxWidth>
        </CenteredLayout>
      </main>
    </main>
  );
};

export default Messages;
