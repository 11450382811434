import React from "react";
import { Route, Redirect } from "react-router-dom";
import authServices from "../../services/authServices";

const ProtectedRoute = ({ path, component: Component, render, ...rest }) => {
  const user = authServices.getCurrentUser();

  return (
    <Route
      path={path}
      {...rest}
      render={(props) => {
        if (!user) return <Redirect to="/login" />;
        return Component ? (
          <Component userType={user?.role_id} {...props} />
        ) : (
          render(props)
        );
      }}
    />
  );
};

export default ProtectedRoute;
