const storageKey = "stevia__create-edit-course__key";

const save = (data) => {
  localStorage.setItem(storageKey, JSON.stringify(data));
};
const fetch = () => {
  return JSON.parse(localStorage.getItem(storageKey));
};
const clear = () => {
  localStorage.removeItem(storageKey);
};

//
export const storage = { save, fetch, clear };
