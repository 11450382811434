import { Link } from "react-router-dom";

import LoginForm from "../common/Forms/LoginForm";
import Onboarding from "../common/Onboarding";

const Login = ({ location, history }) => {
  console.log(document.referrer)
  console.log(history);
  return (
    <Onboarding location={location}>
      <h2 className="head-2">Log in</h2>
      <p className="para-2 m-t-20px m-b-40px">to continue to your dashboard</p>

      <LoginForm history={history} />

      <div className="onboard-question">
        Don`t have an account?{" "}
        <Link to="/register" className="link link-1">
          Sign Up
        </Link>
      </div>
    </Onboarding>
  );
};

export default Login;
