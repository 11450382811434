import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "./../actions/api";
import Notiflix from "notiflix";


const studentSlice = createSlice({
  name: "student",
  initialState: {
    data: {},
    error: {},
    fetchedCourses: [],
    isLoading: false,
    dashboardData: {
      isLoading: false,
      data: {},
      error: {},
    },
    upcomingClasses: {
      isLoading: false,
      data: {},
      error: {},
    },
    ongoingClass: {
      isLoading: false,
      data: {},
      error: {},
    },
    wishLists: {
      isLoading: false,
      data: null,
      error: null,
    },
    aboutStudent: {
      isLoading: false,
      data: {},
      error: {},
    },
    studentBilling: {
      isLoading: false,
      data: {},
      error: {},
    },
  },
  reducers: {
    //   Student Dashboard Data
    fetchingStudentDashboard: (student, action) => {
      student.dashboardData.isLoading = true;
    },
    fetchingStudentDashboardFailed: (student, action) => {
      student.dashboardData.error = action.payload;
      student.dashboardData.isLoading = false;
    },
    fetchingStudentDashboardSuccess: (student, action) => {
      student.dashboardData.data = action.payload;
      student.dashboardData.isLoading = false;
    },
    // Upcoming Classes
    upcomingClassesRequested: (student, action) => {
      student.upcomingClasses.isLoading = true;
    },
    upcomingClassesRequestFailed: (student, action) => {
      student.upcomingClasses.error = action.payload;
      student.upcomingClasses.isLoading = false;
    },
    upcomingClassesRequestSuccess: (student, action) => {
      student.upcomingClasses.data = action.payload;
      student.upcomingClasses.isLoading = false;
    },
    // Ongoing Class
    ongoingClassRequested: (student, action) => {
      student.ongoingClass.isLoading = true;
    },
    ongoingClassRequestFailed: (student, action) => {
      student.ongoingClass.error = action.payload;
      student.ongoingClass.isLoading = false;
    },
    ongoingClassRequestSuccess: (student, action) => {
      student.ongoingClass.data = action.payload;
      student.ongoingClass.isLoading = false;
    },
    // Add course to wishList
    addingCourseToWishlistRequested: (student, action) => {
      student.wishLists.isLoading = true;
    },
    addingCourseToWishlistFailed: (student, action) => {
      student.wishLists.error = action.payload;
      student.wishLists.isLoading = false;
    },
    addingCourseToWishlistSuccess: (student, action) => {
      student.wishLists.data = action.payload;
      Notiflix.Notify.success("Course added to wishlist");
      student.wishLists.isLoading = false;
    },
    // About student
    savingStudentData: (student, action) => {
      student.aboutStudent.isLoading = true;
    },
    savingStudentDataFailed: (student, action) => {
      student.aboutStudent.error = action.payload;
      student.aboutStudent.isLoading = false;
    },
    savingStudentDataSuccess: (student, action) => {
      student.aboutStudent.data = action.payload;
      student.aboutStudent.isLoading = false;
    },
    // Student Billing
    savingStudentBillingData: (student, action) => {
      student.studentBilling.isLoading = true;
    },
    savingStudentBillingDataFailed: (student, action) => {
      student.studentBilling.error = action.payload;
      student.studentBilling.isLoading = false;
    },
    savingStudentBillingDataSuccess: (student, action) => {
      student.studentBilling.data = action.payload;
      student.studentBilling.isLoading = false;
    },
  },
});

// Action Types
const {
  //   Student Dashboard Data
  fetchingStudentDashboard,
  fetchingStudentDashboardFailed,
  fetchingStudentDashboardSuccess,
  upcomingClassesRequested,
  upcomingClassesRequestFailed,
  upcomingClassesRequestSuccess,
  ongoingClassRequested,
  ongoingClassRequestFailed,
  ongoingClassRequestSuccess,
  addingCourseToWishlistRequested,
  addingCourseToWishlistFailed,
  addingCourseToWishlistSuccess,
  savingStudentData,
  savingStudentDataFailed,
  savingStudentDataSuccess,
  savingStudentBillingData,
  savingStudentBillingDataFailed,
  savingStudentBillingDataSuccess,
} = studentSlice.actions;

//   Action Creators
const service = "studentServices";

export const getStudentDashboardData = () =>
  apiCallBegan({
    service,
    endpoint: "getDashboardData",
    onStart: fetchingStudentDashboard.type,
    onSuccess: fetchingStudentDashboardSuccess.type,
    onError: fetchingStudentDashboardFailed.type,
  });

export const getUpcomingClasses = () =>
  apiCallBegan({
    service,
    endpoint: "getStudentUpcomingClasses",
    onStart: upcomingClassesRequested.type,
    onSuccess: upcomingClassesRequestSuccess.type,
    onError: upcomingClassesRequestFailed.type,
  });

export const getOngoingClass = () =>
  apiCallBegan({
    service,
    endpoint: "getStudentOngoingClass",
    onStart: ongoingClassRequested.type,
    onSuccess: ongoingClassRequestSuccess.type,
    onError: ongoingClassRequestFailed.type,
  });

export const addCourseToWishList = (data) =>
  apiCallBegan({
    service,
    data,
    endpoint: "saveCourseToWishlist",
    onStart: addingCourseToWishlistRequested.type,
    onSuccess: addingCourseToWishlistSuccess.type,
    onError: addingCourseToWishlistFailed.type,
  });

export const saveStudentData = (data) =>
  apiCallBegan({
    service,
    data,
    endpoint: "savePersonalProfile",
    onStart: savingStudentData.type,
    onSuccess: savingStudentDataSuccess.type,
    onError: savingStudentDataFailed.type,
  });

export const getStudentProfile = () =>
  apiCallBegan({
    service,
    endpoint: "getStudentProfile",
    onStart: savingStudentData.type,
    onSuccess: savingStudentDataSuccess.type,
    onError: savingStudentDataFailed.type,
  });

export const updateStudentBilling = (data) =>
  apiCallBegan({
    service,
    data,
    endpoint: "updateStudentBillingInfo",
    onStart: savingStudentBillingData.type,
    onSuccess: savingStudentBillingDataSuccess.type,
    onError: savingStudentBillingDataFailed.type,
  });

// getDashboardData

// *********************** End of Action Creators *****************************

export default studentSlice.reducer;
