import React from 'react'
import { images } from '../../../src/utils/images'
import ContentCard from './ContentCard'

const StudentCourses = ({courseImg, title, label, status, info, teach, teachName, teachRole, students}) => {
  return (
        <ContentCard cssClass="student-courses" cssClass2="student-courses-content">
            <div className="student-top-row">
                <img src={courseImg} alt="" className="student-top-img" />
                <h3 className="student-top-title">{title}</h3>
                <span className="student-top-label">{label}</span>
                <div className="student-top-status">
                    <img src={images.complete} alt="" className="top-status-icon" />
                    <span className="top-status-text">{status}</span>
                </div>
            </div>
            <div className="student-mid-row">
                <span className="mid-row-info">
                    {info}
                </span>
            </div>
            <div className="student-last-row">
                <img src={teach} alt="" className="last-row-img" />
                <div className="last-row-info">
                    <span className="last-row-title">{teachName}</span>
                    <span className="last-row-text">{teachRole}</span>
                </div>
            </div>
            <div className="students-mass">
                <img src={images.students} alt="" className="students-mass-img" />
                <span className="student-mass-number">{students}</span>
            </div>
            <button className="view-student-course">View Course</button>
        </ContentCard>
  )
}

export default StudentCourses