import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "./../actions/api";

const instructorSlice = createSlice({
  name: "instructor",
  initialState: {
    // Instructor settings =========
    // ==> General
    activeTab: 0,

    // ==> (About Me)
    info: {},
    error: "",
    isLoading: false,
    fetchFailed: false,
    lastFetched: null,
    isDataFetched: false, // To enhance caching
    // ==> (About Me)

    // Password and security
    passwordChange: {
      isLoading: false,
    },
    // Password and security

    // Bank account
    bankAccount: {
      isLoading: false,
      details: {},
      updateError: null,
      fetchFailed: false,
      isDataFetched: false, // To enhance caching
    },
    // End of Bank account
    // End of Instructor settings =========
    // Instructor Dashboard Data
    dashboardData: {
      data: {},
      isLoading: false,
    },
    // End of Instructor Dashboard Data
    // Instructor Dashboard Data
    courses: {
      data: [],
      isLoading: false,
    },

    deleteCourse: {
      data: {},
      error: {},
      isLoading: false,
    },
    // End of Instructor Dashboard Data
    // Instructor Bank Info status
    bankInfoStatus: {
      data: {},
      isLoading: false,
    },
    // End of Instructor Bank Info status

    // Instructor upcoming classes
    upcomingClasses: {
      data: [],
      isLoading: false,
    },
    // End of Instructor upcoming classes
  },
  reducers: {
    // Instructor Setting Start
    // ==> About me
    profileUpdating: (instructor, action) => {
      instructor.isLoading = true;
    },
    profileUpdateFailed: (instructor, action) => {
      instructor.error = action.payload;
      instructor.isLoading = false;
    },
    profileUpdated: (instructor, action) => {
      instructor.info = action.payload;
      instructor.isLoading = false;
      instructor.isDataFetched = false;
    },
    profileDataFetching: (instructor, action) => {
      instructor.fetchFailed = false;
      instructor.isLoading = true;
    },
    profileDataFetchFailed: (instructor, action) => {
      instructor.error = action.payload;
      instructor.isLoading = false;
      instructor.fetchFailed = true;
    },
    profileDataFetchSuccess: (instructor, action) => {
      instructor.info = action.payload;
      instructor.isLoading = false;
      instructor.isDataFetched = true;
    },
    // ==> About me
    // ==> Password and security
    updatePasswordStart: (instructor, action) => {
      // instructor.passwordChange.isLoading = action.payload;
      instructor.passwordChange.isLoading = true;
    },
    updatePasswordSuccess: (instructor, action) => {
      instructor.passwordChange.isLoading = false;
    },
    // ==> Password and security
    // ==> Bank account
    bankAccountUpdating: (instructor, action) => {
      instructor.bankAccount.isLoading = true;
    },
    bankAccountUpdateFailed: (instructor, action) => {
      instructor.bankAccount.updateError = action.payload;
      instructor.bankAccount.isLoading = false;
    },
    bankAccountUpdated: (instructor, action) => {
      instructor.bankAccount.details = action.payload;
      instructor.bankAccount.isLoading = false;
      instructor.bankAccount.isDataFetched = false;
    },

    bankAccountFetching: (instructor, action) => {
      instructor.bankAccount.fetchFailed = false;
      instructor.bankAccount.isLoading = true;
    },
    bankAccountFetchFailed: (instructor, action) => {
      instructor.bankAccount.updateError = action.payload;
      instructor.bankAccount.isLoading = false;
      instructor.bankAccount.fetchFailed = true;
    },
    bankAccountFetchSuccess: (instructor, action) => {
      instructor.bankAccount.details = action.payload;
      instructor.bankAccount.isLoading = false;
      instructor.bankAccount.isDataFetched = true;
    },
    // ==> Bank account
    // Instructor Setting End

    // Instructor Dashboard Data
    dashboardDataFetching: (instructor, action) => {
      instructor.dashboardData.isLoading = true;
    },
    dashboardDataFailed: (instructor, action) => {
      instructor.dashboardData.isLoading = false;
    },
    dashboardDataFetchSuccess: (instructor, action) => {
      instructor.dashboardData.data = action.payload;
      instructor.dashboardData.isLoading = false;
    },
    // Instructor Dashboard Data End

    // Instructor Courses
    coursesFetching: (instructor, action) => {
      instructor.courses.isLoading = true;
    },
    coursesFetchFail: (instructor, action) => {
      instructor.courses.isLoading = false;
    },
    coursesFetchSuccess: (instructor, action) => {
      instructor.courses.data = action.payload?.courses;
      instructor.courses.isLoading = false;
    },
    coursesShadowFetching: (instructor, action) => {
      // instructor.courses.isLoading = true;
    },
    coursesShadowFetchFail: (instructor, action) => {
      // instructor.courses.isLoading = false;
    },
    coursesShadowFetchSuccess: (instructor, action) => {
      instructor.courses.data = action.payload?.courses;
      // instructor.courses.isLoading = false;
    },

    // Delete course
    deletingCourse: (instructor, action) => {
      instructor.deleteCourse.isLoading = true;
    },
    deletingCourseFailed: (instructor, action) => {
      instructor.deleteCourse.error = action.payload;
      instructor.deleteCourse.isLoading = false;
    },
    deletingCourseSuccess: (instructor, action) => {
      instructor.deleteCourse.isLoading = false;
    },
    removeCourse: (instructor, action) => {
      const lists = [...instructor.courses.data];
      const newList = lists.filter((list) => list.id !== action.payload.id);
      instructor.courses.data = [...newList];
    },
    // Instructor Courses End
    // Instructor Bank Info status
    bankStatusInfoFetching: (instructor, action) => {
      instructor.bankInfoStatus.isLoading = true;
    },
    bankStatusInfoFail: (instructor, action) => {
      instructor.bankInfoStatus.isLoading = false;
    },
    bankStatusInfoSuccess: (instructor, action) => {
      instructor.bankInfoStatus.data = action.payload?.bankInfoStatus;
      instructor.courses.isLoading = false;
    },
    // Instructor Bank Info status End
    // Instructor Upcoming classes
    upcomingClassesFetching: (instructor, action) => {
      instructor.upcomingClasses.isLoading = true;
    },
    upcomingClassesFail: (instructor, action) => {
      instructor.upcomingClasses.isLoading = false;
    },
    upcomingClassesSuccess: (instructor, action) => {
      instructor.upcomingClasses.data = [
        ...action.payload.all_instructor_live_classes.splice(3),
      ];
      instructor.courses.isLoading = false;
    },
    // Instructor Upcoming classes End
  },
});

// Action types
const {
  // Instructor Settings
  // ==> About me
  profileUpdated,
  profileUpdating,
  profileUpdateFailed,
  profileDataFetching,
  profileDataFetchFailed,
  profileDataFetchSuccess,
  // ==> About me ******** End
  // ==> Password and Security
  updatePasswordStart,
  updatePasswordSuccess,
  // ==> Password and Security ******* End
  // ==> Password and Security
  bankAccountUpdating,
  bankAccountUpdateFailed,
  bankAccountUpdated,
  bankAccountFetching,
  bankAccountFetchFailed,
  bankAccountFetchSuccess,
  // ==> Password and Security ******* End

  // Instructor Settings ********* End

  // Instructoe Data Fetching
  dashboardDataFetching,
  dashboardDataFailed,
  dashboardDataFetchSuccess,
  // Instructoe Data Fetching ********* End

  // Instructor courses
  coursesFetching,
  coursesFetchFail,
  coursesFetchSuccess,
  coursesShadowFetching,
  coursesShadowFetchFail,
  coursesShadowFetchSuccess,
  deletingCourse,
  deletingCourseFailed,
  deletingCourseSuccess,
  removeCourse,
  // Instructor courses **************  End

  // Instructor upcoming classes
  upcomingClassesFetching,
  upcomingClassesFail,
  upcomingClassesSuccess,
  // Instructor upcoming classes **************  End
} = instructorSlice.actions;

// General Variables
const service = "instructorServices";
// *********************** Action Creators *****************************
// ==> Update Instructor profile
export const updateInstructorProfile = (instructorData) =>
  // Refetch profile data after updating
  apiCallBegan({
    service,
    endpoint: "updatePersonalProfile",
    data: instructorData,
    onStart: profileUpdating.type,
    onSuccess: profileUpdated.type,
    onError: profileUpdateFailed.type,
  });
// ==> Update Instructor profile

// ==> Get Instructor profile data
export const getInstructorProfile = () =>
  // Refetch profile data if state.dataFetched is false

  apiCallBegan({
    service,
    endpoint: "getPersonalProfile",
    onStart: profileDataFetching.type,
    onSuccess: profileDataFetchSuccess.type,
    onError: profileDataFetchFailed.type,
  });
// ==> Get Instructor profile data

// ==> Update Instructor password
export const updatePassword = (data) =>
  apiCallBegan({
    service,
    endpoint: "updatePassword",
    data,
    onStart: updatePasswordStart.type,
    onSuccess: updatePasswordSuccess.type,
  });

// ==> Update Instructor Bank account
export const updateBankAccount = (data) =>
  apiCallBegan({
    service,
    endpoint: "updateBankInfo",
    data,
    onStart: bankAccountUpdating.type,
    onSuccess: bankAccountUpdated.type,
    onError: bankAccountUpdateFailed.type,
  });

// ==> Get Bank account data
export const getBankAccountData = () =>
  // Refetch profile data if state.dataFetched is false
  apiCallBegan({
    service,
    endpoint: "getBankInfo",
    onStart: bankAccountFetching.type,
    onSuccess: bankAccountFetchSuccess.type,
    onError: bankAccountFetchFailed.type,
  });

// ==> Get Instructor Dashboard Data
export const getInstructorDashboardData = () =>
  apiCallBegan({
    service,
    endpoint: "getDashboardData",
    onStart: dashboardDataFetching.type,
    onSuccess: dashboardDataFetchSuccess.type,
    onError: dashboardDataFailed.type,
  });

// ==> Get Instructor Dashboard Data
export const getCourses = () =>
  apiCallBegan({
    service,
    endpoint: "getCourses",
    onStart: coursesFetching.type,
    onSuccess: coursesFetchSuccess.type,
    onError: coursesFetchFail.type,
  });

export const getShadowCourses = () =>
  apiCallBegan({
    service,
    endpoint: "getCourses",
    onStart: coursesShadowFetching.type,
    onSuccess: coursesShadowFetchSuccess.type,
    onError: coursesShadowFetchFail.type,
  });

export const deleteCourse = (data) =>
  apiCallBegan({
    service: "courseServices",
    endpoint: "deleteCourse",
    data,
    onStart: deletingCourse.type,
    onSuccess: deletingCourseSuccess.type,
    onError: deletingCourseFailed.type,
  });

export const getUpcomingClasses = () =>
  apiCallBegan({
    service,
    endpoint: "getUpcomingClasses",
    onStart: upcomingClassesFetching.type,
    onSuccess: upcomingClassesSuccess.type,
    onError: upcomingClassesFail.type,
  });

export const removeInstructorCourse = (data) => removeCourse(data);
// *********************** End of Action Creators *****************************

export default instructorSlice.reducer;
