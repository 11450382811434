import { useState } from "react";
import { images } from "../../utils/images";

const InputSearch = (props) => {
  // States
  const [searchValue, setSearchValue] = useState("");

  //Props
  const { getSearchValue, cssClass, placeholder } = props;

  // Data to render
  return (
    <div className={`input__search-course ${cssClass}`}>
      <svg className="input__search-icon">
        <use xlinkHref={`${images.spriteSVG}#icon-input-search`}></use>
      </svg>
      <input
        type="text"
        className="input__search-item"
        value={searchValue}
        placeholder={placeholder ? placeholder : "search"}
        onChange={(e) => {
          setSearchValue(e.target.value);
          getSearchValue(e.target.value);
        }}
      />
    </div>
  );
};

export default InputSearch;
