import React from "react";

const CalenderIcon = ({ width, height, cssClass, pathCssClass1 }) => {
  return (
    <svg
      width={width || "15"}
      height={height || "16"}
      className={`${cssClass ? cssClass : ""}`}
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={pathCssClass1}
        d="M12.8 16H1.6C0.716344 16 0 15.2837 0 14.4V3.2C0 2.31634 0.716344 1.6 1.6 1.6H3.2V0H4.8V1.6H9.6V0H11.2V1.6H12.8C13.6837 1.6 14.4 2.31634 14.4 3.2V14.4C14.4 15.2837 13.6837 16 12.8 16ZM1.6 6.4V14.4H12.8V6.4H1.6ZM1.6 3.2V4.8H12.8V3.2H1.6ZM11.2 12.8H9.6V11.2H11.2V12.8ZM8 12.8H6.4V11.2H8V12.8ZM4.8 12.8H3.2V11.2H4.8V12.8ZM11.2 9.6H9.6V8H11.2V9.6ZM8 9.6H6.4V8H8V9.6ZM4.8 9.6H3.2V8H4.8V9.6Z"
        fill="#647382"
      />
    </svg>
  );
};

export default CalenderIcon;
