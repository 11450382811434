import React from "react";
import useSWR from "swr";
import { fetcher } from "../../services/fetcher";
import EmptyActivityBox from "./EmptyActivityBox";
import EmptyActivityState from "./EmptyActivityState";
import FeaturedCourse from "./FeaturedCourse";
import SpinnerBox from "./SpinnerBox";

const UserCoursesEnrolled = () => {
  // Props
  // const { courses } = props;

  // Api
  const { data } = useSWR("/student/get-student-ongoing-courses", (url) =>
    fetcher(url, "post")
  );

  let courses = [];
  if (data) {
    courses = data.ongoing_courses;
  }

  // Data to render
  return (
    <>
      {!data ? (
        <SpinnerBox />
      ) : (
        <>
          {courses?.length ? (
            <div className="featured-courses__wrapper">
              <div className="featured-courses">
                {courses?.map((courses) => (
                  <FeaturedCourse key={courses.id} course={courses} />
                ))}
              </div>
            </div>
          ) : (
            <EmptyActivityBox cssClass="p-y-50px">
              <EmptyActivityState
                title="No Activity"
                subTitle="You are yet to enrol for any course"
              />
            </EmptyActivityBox>
          )}
        </>
      )}
    </>
  );
};

export default UserCoursesEnrolled;
