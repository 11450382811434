// rgba(r,g,b,a)
// red, green, blue, alpha

import { rgbaToHex } from "./helpers";

// fn to generate a number between 0 - 255
const number = () => {
  return Math.floor(Math.random() * 255) + 1;
};

const rgb = () => {
  const red = number();
  const green = number();
  const blue = number();
  const rgbValues = `${red},${green},${blue}`;

  const textColorHex = rgbaToHex(red, green, blue);
  return [rgbValues, textColorHex];
};

export const color = () => {
  const [rgbValues, textColorHex] = rgb();
  const alpha = 0.099;

  const bgColor = `rgba(${rgbValues},${alpha})`;
  const textColor = `rgba(${rgbValues})`;
  return [bgColor, textColor, textColorHex];
};
