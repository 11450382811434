import Skeleton from "react-loading-skeleton";

const CourseTableLoader = () => {
  const loaderBgColor = "#ffffff6e";
  const style = {
    main: {
      display: "grid",
      gridTemplateColumns: "1fr 33%",
      gridGap: "2.5rem",
      paddingTop: "5rem",
    },

    contentRightSideTop: {
      padding: "2rem",
      backgroundColor: loaderBgColor,
      borderRadius: "1rem",
      marginBottom: "25px",
    },

    table: {
      padding: "1.5rem",
      backgroundColor: loaderBgColor,
      borderRadius: "1rem",
    },

    tableItem: {
      borderTop: "1px solid $color-60",
      paddingTop: "1.5rem",
      paddingBottom: "1.5rem",
    },

    gap10: {
      gap: "10px",
    },
    gap5: {
      gap: "5px",
    },
  };

  return (
        <div style={style.table}>
          <div className="flex-r-jcbetween-aicenter m-b-20px">
            <Skeleton height={"20px"} width={"80px"} />
            <div className="m-r-25px">
              <Skeleton height={"20px"} width={"80px"} />
            </div>
            <div></div>
          </div>
          {/* Table Item */}
          <div className="flex-r-jcbetween-aicenter" style={style.tableItem}>
            <div className="flex-r-aicenter" style={style.gap10}>
              <Skeleton height={"47px"} width={"47px"} />
              <Skeleton height={"15px"} width={"200px"} />
            </div>
            <div className="flex-c-jccenter-aicenter " style={style.gap5}>
              <Skeleton height={"20px"} width={"50px"} />
              <Skeleton height={"8px"} width={"80px"} />
            </div>
            <Skeleton height={"35px"} width={"80px"} />
            <Skeleton height={"18px"} width={"4px"} />
          </div>
          {/* Table Item */}
          <div className="flex-r-jcbetween-aicenter" style={style.tableItem}>
            <div className="flex-r-aicenter" style={style.gap10}>
              <Skeleton height={"47px"} width={"47px"} />
              <Skeleton height={"15px"} width={"200px"} />
            </div>
            <div className="flex-c-jccenter-aicenter " style={style.gap5}>
              <Skeleton height={"20px"} width={"50px"} />
              <Skeleton height={"8px"} width={"80px"} />
            </div>
            <Skeleton height={"35px"} width={"80px"} />
            <Skeleton height={"18px"} width={"4px"} />
          </div>
          {/* Table Item */}
          <div className="flex-r-jcbetween-aicenter" style={style.tableItem}>
            <div className="flex-r-aicenter" style={style.gap10}>
              <Skeleton height={"47px"} width={"47px"} />
              <Skeleton height={"15px"} width={"200px"} />
            </div>
            <div className="flex-c-jccenter-aicenter " style={style.gap5}>
              <Skeleton height={"20px"} width={"50px"} />
              <Skeleton height={"8px"} width={"80px"} />
            </div>
            <Skeleton height={"35px"} width={"80px"} />
            <Skeleton height={"18px"} width={"4px"} />
          </div>
          {/* Table Item */}
          <div className="flex-r-jcbetween-aicenter" style={style.tableItem}>
            <div className="flex-r-aicenter" style={style.gap10}>
              <Skeleton height={"47px"} width={"47px"} />
              <Skeleton height={"15px"} width={"200px"} />
            </div>
            <div className="flex-c-jccenter-aicenter " style={style.gap5}>
              <Skeleton height={"20px"} width={"50px"} />
              <Skeleton height={"8px"} width={"80px"} />
            </div>
            <Skeleton height={"35px"} width={"80px"} />
            <Skeleton height={"18px"} width={"4px"} />
          </div>
          {/* Table Item */}
          <div className="flex-r-jcbetween-aicenter" style={style.tableItem}>
            <div className="flex-r-aicenter" style={style.gap10}>
              <Skeleton height={"47px"} width={"47px"} />
              <Skeleton height={"15px"} width={"200px"} />
            </div>
            <div className="flex-c-jccenter-aicenter " style={style.gap5}>
              <Skeleton height={"20px"} width={"50px"} />
              <Skeleton height={"8px"} width={"80px"} />
            </div>
            <Skeleton height={"35px"} width={"80px"} />
            <Skeleton height={"18px"} width={"4px"} />
          </div>
        </div>
  );
};

export default CourseTableLoader;
