import React from "react";
import InstructorCourses from "./InstructorCourses";
import StudentCourses from "./StudentCourses";

const MainCoursesPage = (props) => {
  const { userType } = props;

  return userType && userType === "1" ? (
    <StudentCourses />
  ) : (
    <InstructorCourses />
  );
};

export default MainCoursesPage;
