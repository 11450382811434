import React, { useState, useRef } from "react";
import IconPadlock from "./../SvgIcons/IconPadlock";
import IconEmail from "./../SvgIcons/IconEmail";
import Svg from "./../Svg";

const Input = ({
  label,
  error,
  labelIcon,
  type = "text",
  noicon,
  placeholder,
  ...rest
}) => {
  /**
   * Focus holder div when input is focused
   * Focus holder div when holder div is focused
   * Focus input when holder div is focused
   *
   */

  // Focus holder div when input is focused ✔
  const [focused, setFocused] = useState(false);
  const handleFocus = () => {
    setFocused(true);
  };
  const handleBlur = () => {
    setFocused(false);
  };

  // Focus holder div when holder div is focused ✔
  let inputHolderRef = useRef(null);
  const handleInputHolerClick = () => {
    const classExist = inputHolderRef.className.split(" ").includes("focused");
    if (!classExist) inputHolderRef.className += " focused";
    // Focus input when holder div is focused ✔
    inputHolderRef.children[0].focus();
  };

  // Change Password Type to text
  const [passwordToText, setPasswordToText] = useState(false);

  return (
    <div className="input__wrapper">
      <div className="input-top">
        {label ? (
          <label htmlFor="" className="input__label">
            {labelIcon ? (
              <Svg iconId={labelIcon} cssClassName={`${labelIcon} m-r-10px`} />
            ) : (
              ""
            )}
            {label}
          </label>
        ) : (
          <label htmlFor=""></label>
        )}

        {error ? <span className="input__error"> {error} </span> : ""}
      </div>
      <div
        ref={(ele) => (inputHolderRef = ele)}
        onClick={handleInputHolerClick}
        className={`input__holder ${noicon ? "input__holder--no-icon" : ""} ${
          focused ? "focused" : ""
        }`}
      >
        <input
          onFocus={handleFocus}
          onBlur={handleBlur}
          type={passwordToText ? "text" : type}
          placeholder={placeholder}
          {...rest}
          className={`input__item ${noicon ? "input__item--no-icon" : ""}`}
        />
        {noicon ? null : type === "password" ? (
          <IconPadlock
            handleClick={() => setPasswordToText((prevState) => !prevState)}
            isOpen={passwordToText}
            cssClass="icon-lock"
            pathCssClass1={`icon-transition ${focused ? "active-icon" : ""}`}
            pathCssClass2={`icon-transition ${focused ? "active-icon" : ""}`}
          />
        ) : (
          <IconEmail
            pathCssClass1={`icon-transition ${focused ? "active-icon" : ""}`}
            pathCssClass2={`icon-transition ${focused ? "active-icon" : ""}`}
          />
        )}
      </div>
    </div>
  );
};

export default Input;
