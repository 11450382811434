import Form from "./Form";
import Joi from "joi";
import { forgetPassword } from "../../../services/authServices";
import Notiflix from "notiflix";

class ForgetPasswordForm extends Form {
  constructor(props) {
    super(props);

    this.state = {
      data: { email: "" },
      error: { email: "" },
      isLoading: false,
    };
  }

  schema = {
    email: Joi.string().required().label("Email"),
  };

  doSubmit = async () => {
    this.setState({ isLoading: true });
    try {
      await forgetPassword({ email: this.state.data.email });
      Notiflix.Notify.success("Reset link sent successfully");
      this.setState({ isLoading: false });
    } catch (error) {
      Notiflix.Notify.failur("Error sending reset link");
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { isLoading } = this.state;
    return (
      <form onSubmit={this.handleSubmit}>
        {this.renderInput("Email address", "email")}
        {this.renderButton("Send Reset Link", isLoading)}
      </form>
    );
  }
}

export default ForgetPasswordForm;
