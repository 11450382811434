import React from "react";
import Svg from "./Svg";

const ActivityAction = ({
  iconClass,
  iconId,
  icon,
  name,
  handleClick,
  isActive: active,
  iconType,
}) => {
  return (
    <div
      className={`activities__action ${active ? "active" : ""} ${
        iconType ? "stroke" : "fill"
      }`}
      onClick={handleClick}
    >
      {icon ? icon : iconId && <Svg cssClassName={iconClass} iconId={iconId} />}
      <span className="activities__name"> {name} </span>
      <Svg
        cssClassName={`activities__icon-link m-l-auto`}
        iconId="icon-arrow-right-circle"
      />
    </div>
  );
};

export default ActivityAction;
