import React from "react";

const Spinner = (props) => {
  // Props
  const { color } = props;
  return (
    <i
      className={`fas fa-spinner animate-rotate ${
        color ? `color-${color}` : `color-primary`
      }`}
    ></i>
  );
};

export default Spinner;
