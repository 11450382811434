import React from "react";

const SettingsIcon = ({
  width,
  height,
  cssClass,
  pathCssClass1,
  pathCssClass2,
  title = "Settings",
}) => {
  return (
    <>
      <svg
        width={width || "16"}
        height={height || "18"}
        className={`${cssClass ? cssClass : ""}`}
        viewBox="0 0 16 18"
        fill="none"
      >
        <path
          className={pathCssClass1}
          d="M8.01043 11.3568C6.67337 11.3568 5.5918 10.3151 5.5918 9.00677C5.5918 7.69844 6.67337 6.64844 8.01043 6.64844C9.34749 6.64844 10.4035 7.69844 10.4035 9.00677C10.4035 10.3151 9.34749 11.3568 8.01043 11.3568Z"
          fill="#272D33"
        />
        <path
          className={pathCssClass2}
          opacity="0.4"
          d="M15.6924 10.9743C15.5306 10.7243 15.3007 10.4743 15.0026 10.316C14.7641 10.1993 14.6109 10.0077 14.4746 9.78268C14.0403 9.06602 14.2958 8.12435 15.0196 7.69935C15.8713 7.22435 16.1438 6.16602 15.6498 5.34102L15.0793 4.35768C14.5938 3.53268 13.5293 3.24102 12.6862 3.72435C11.9367 4.12435 10.9744 3.85768 10.5401 3.14935C10.4038 2.91602 10.3272 2.66602 10.3442 2.41602C10.3697 2.09102 10.2675 1.78268 10.1142 1.53268C9.79914 1.01602 9.22855 0.666016 8.59834 0.666016H7.39754C6.77585 0.682682 6.20526 1.01602 5.89015 1.53268C5.72834 1.78268 5.63466 2.09102 5.6517 2.41602C5.66873 2.66602 5.59208 2.91602 5.45582 3.14935C5.02149 3.85768 4.05915 4.12435 3.31823 3.72435C2.4666 3.24102 1.41057 3.53268 0.916626 4.35768L0.346034 5.34102C-0.139396 6.16602 0.133126 7.22435 0.976241 7.69935C1.70013 8.12435 1.95562 9.06602 1.5298 9.78268C1.38502 10.0077 1.23173 10.1993 0.993273 10.316C0.703719 10.4743 0.448229 10.7243 0.311968 10.9743C-0.00313529 11.491 0.0138973 12.141 0.329001 12.6827L0.916626 13.6827C1.23173 14.216 1.81936 14.5493 2.43253 14.5493C2.72208 14.5493 3.06274 14.466 3.33526 14.2993C3.54817 14.1577 3.80366 14.1077 4.08469 14.1077C4.92781 14.1077 5.63466 14.7993 5.6517 15.6243C5.6517 16.5827 6.4352 17.3327 7.42309 17.3327H8.58131C9.56068 17.3327 10.3442 16.5827 10.3442 15.6243C10.3697 14.7993 11.0766 14.1077 11.9197 14.1077C12.1922 14.1077 12.4477 14.1577 12.6691 14.2993C12.9417 14.466 13.2738 14.5493 13.5719 14.5493C14.1765 14.5493 14.7641 14.216 15.0793 13.6827L15.6754 12.6827C15.982 12.1243 16.0075 11.491 15.6924 10.9743Z"
          fill="#272D33"
        />
      </svg>
      <span>{title}</span>
    </>
  );
};

export default SettingsIcon;
