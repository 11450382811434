import React from "react";

const IconPadlock = ({
  width,
  height,
  cssClass,
  pathCssClass1,
  pathCssClass2,
  handleClick,
  isOpen,
}) => {
  return (
    <svg
      width={width || "24"}
      height={height || "20"}
      className={`${cssClass ? cssClass : ""}`}
      viewBox="0 0 11 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={handleClick}
    >
      <path
        className={pathCssClass1}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.7688 6.71289H13.2312C15.5886 6.71289 17.5 8.5821 17.5 10.8875V15.8244C17.5 18.1298 15.5886 19.9991 13.2312 19.9991H4.7688C2.41136 19.9991 0.5 18.1298 0.5 15.8244V10.8875C0.5 8.5821 2.41136 6.71289 4.7688 6.71289ZM8.99492 15.3285C9.49283 15.3285 9.88912 14.941 9.88912 14.454V12.248C9.88912 11.771 9.49283 11.3834 8.99492 11.3834C8.50717 11.3834 8.11088 11.771 8.11088 12.248V14.454C8.11088 14.941 8.50717 15.3285 8.99492 15.3285Z"
        fill="#272D33"
      />
      <path
        className={`${pathCssClass2} ${isOpen ? "open-lock" : ""}`}
        opacity="0.4"
        d="M14.522 5.39595V6.86667C14.1663 6.7673 13.7904 6.71761 13.4042 6.71761H12.7437V5.39595C12.7437 3.37868 11.0671 1.73903 9.00436 1.73903C6.9416 1.73903 5.26497 3.36874 5.25481 5.37608V6.71761H4.60448C4.20818 6.71761 3.83221 6.7673 3.47656 6.87661V5.39595C3.48672 2.41476 5.95594 0 8.98403 0C12.0528 0 14.522 2.41476 14.522 5.39595Z"
        fill="#272D33"
      />
    </svg>
  );
};

export default IconPadlock;
