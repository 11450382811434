import React from "react";
import { images } from "../../utils/images";

const Review = () => {
  return (
    <div className="review">
      <div className="review__by">
        <div className="review__user">
          <img
            src={images.pic8}
            alt="review user"
            className="review__user-img"
          />
        </div>
        <div>
          <h5 className="head-31">Samuel Oluwashina</h5>
          <p className="para-21 m-t-5px">01/08/2022</p>

          <p className="m-t-10px para-22">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus
            ac pellentesque odio, quis porttitor ex.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Review;
