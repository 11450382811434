import React, { useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import DashboardSideNav from "../common/DashboardSideNav";
import DashboardTopNav from "../common/DashboardTopNav";

import { getCourse } from "../../storee/slices/singleCourse";
import CenteredLayout from "../common/CenterLayout";
import NotificationItem from "../common/NotificationItem";

const Notification = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();

  const notifications = [
    {
      type: "info",
      time: " 8th Jan, 2021 10:25 am",
      details:
        "Your have a live class in 30 min, check course detail for live link and other information",
    },
    {
      type: "payment",
      time: " 8th Jan, 2021 10:25 am",
      details:
        "Your have a live class in 30 min, check course detail for live link and other information",
    },
    {
      type: "comment",
      time: " 8th Jan, 2021 10:25 am",
      details:
        "Your have a live class in 30 min, check course detail for live link and other information",
    },
  ];

  const goBack = (e) => {
    e.preventDefault();
    history.goBack();
  };

  useEffect(() => {
    dispatch(getCourse(id));
    // window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main className="dashboard-main">
      <DashboardSideNav />
      <main className="dashboard__main">
        <DashboardTopNav>
          <button onClick={(e) => goBack(e)} className="btn btn-3">
            <i className="fas fa-long-arrow-alt-left m-r-6px"></i>
            Back
          </button>
        </DashboardTopNav>
        <>
          <CenteredLayout>
            <div className="user-notification__wrapper">
              <div className=" m-b-20px">
                <h2 className="head-8">Notifiactions</h2>
              </div>
              <div className="user-notification__card">
                {notifications.map((notification, index) => (
                  <NotificationItem key={index} type={notification?.type} />
                ))}
              </div>
            </div>
          </CenteredLayout>
        </>
      </main>
    </main>
  );
};

export default Notification;
