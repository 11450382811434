import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import useStudent from "../../store/student";
import ProgressBarSingle from "./ProgressBarSingle";
import Book from "./SvgIcons/Book";

const OngoingCourse = (props) => {
  // Props
  const { cssClass } = props;

  // Store
  const { getRecentCourses, recentCourse } = useStudent();

  // Effects
  useEffect(
    () => {
      getRecentCourses();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // Data to render
  return (
    <>
      {recentCourse?.data?.course_name ? (
        <div className={cssClass ? cssClass : ""}>
          <h3 className="head-18 m-b-12px">Recent Course</h3>
          <div className="ongoing-course">
            <div className="flex-r-aicenter ">
              <Book />
              <div className="ongoing-course__content text-truncate m-l-30px">
                <p className="para-18 m-b-6px">Course you are taking</p>
                <h6 className="head-30 m-b-5px text-truncate ">
                  {recentCourse?.data?.course_name}
                </h6>
                <ProgressBarSingle
                  percentage={recentCourse?.data?.completed_percentage}
                />
                <p className="para-18 m-t-6px">
                  {recentCourse?.data?.completed_course_content} of{" "}
                  {recentCourse?.data?.total_course_content} course content
                </p>
              </div>
            </div>
            <Link
              to={`/user-course/${recentCourse?.data?.course_id}`}
              className=""
            >
              <button className="btn btn-7 btn-md m-l-auto">Continue</button>
            </Link>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default OngoingCourse;
