import React from "react";
import EmptyActivityBox from './EmptyActivityBox';
import EmptyActivityState from './EmptyActivityState';
import DiscussionForm from './Forms/DiscussionForm';
import Discussion from './Discussion';

const MyDiscussions = ({ course }) => {
  return (
    <>
      {!course?.discussions ? (
        <EmptyActivityBox cssClass="p-y-50px">
          <EmptyActivityState title="No Discussions" />
        </EmptyActivityBox>
      ) : (
        <>
          <DiscussionForm />
          <Discussion />
          <Discussion />
          <Discussion />
        </>
      )}
    </>
  );
};

export default MyDiscussions;
