import { images } from "./../../utils/images";

const MeetingCard = (props) => {
  const { details = {} } = props;
  return (
    <div className="meeting__card">
      {!details?.live_class_type_name && !details?.topic ? (
        <h1 className="head-6 text-center"> No meeting available </h1>
      ) : (
        <>
          <div className="meeting__card-left">
            <p className="para-3">
              Upcoming Meeting: {details?.live_class_type_name || "--"}
            </p>
            <h1 className="head-6 m-t-8px">{details?.topic || "--"}</h1>
          </div>

          <div>
            <span className="meeting__time">
              <svg className="meeting__time-icon">
                <use xlinkHref={`${images.spriteSVG}#icon-clock`}></use>
              </svg>
              <span className="meeting__time-text">
                {details?.start_time || "--"}
              </span>
            </span>
          </div>
        </>
      )}
    </div>
  );
};

export default MeetingCard;
