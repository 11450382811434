import React from "react";
import PaymentOption from "./PaymentOption";

const Payments = (props) => {
  const { paymentOptions, setSelectedOption } = props;
  return (
    <div className="payment-card__items m-t-30px">
      {paymentOptions?.map((option, index) => (
        <PaymentOption
          key={index}
          data={option}
          setSelectedOption={setSelectedOption}
        />
      ))}
    </div>
  );
};

export default Payments;
