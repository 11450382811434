import React from "react";
import { useHistory } from "react-router-dom";

import DashboardSideNav from "../common/DashboardSideNav";
import DashboardTopNav from "../common/DashboardTopNav";

import CenteredLayout from "../common/CenterLayout";
import usePayment from "../../store/payment";

const PaymentSuccesful = () => {
  const history = useHistory();

  //   Store
  const { newPaidCourseId } = usePayment();

  // Methods
  const goBack = (e) => {
    e.preventDefault();
    history.goBack();
  };

  return (
    <>
      <main className="dashboard-main">
        <DashboardSideNav />
        <main className="dashboard__main">
          <DashboardTopNav>
            <button onClick={(e) => goBack(e)} className="btn btn-3">
              <i className="fas fa-long-arrow-alt-left m-r-6px"></i>
              Back
            </button>
          </DashboardTopNav>
          <>
            <CenteredLayout>
              <div className="payments__wrapper">
                <div className="payments__card">
                  <img
                    src={"/assets/images/succes-payment-icon.png"}
                    alt=""
                    style={{ width: "10rem", height: "10rem" }}
                  />
                  <h3 className="m-t-20px">Payment Successful</h3>
                  <p className="m-t-30px text-center para-24">
                    Thank you for your payment. Your payment has been processed.
                    You will receive details of this transaction in your email
                    shortly
                  </p>

                  <button
                    disabled={!newPaidCourseId}
                    className="btn btn-7 btn--full display-block m-t-70px"
                    onClick={() =>
                      history.push(`/user-course/${newPaidCourseId}`)
                    }
                  >
                    PROCEED
                  </button>
                </div>
              </div>
            </CenteredLayout>
          </>
        </main>
      </main>
    </>
  );
};

export default PaymentSuccesful;
