import React from "react";
import useStudent from "../store/student";

const withStudentStore = (WrappedComponent) => (props) => {
  // Store
  const store = useStudent();

  return <WrappedComponent {...props} store={{ ...store }} />;
};

export default withStudentStore;
