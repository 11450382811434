import pic1 from "../assets/img/pic-1.png";
import pic2 from "../assets/img/pic-2.png";
import pic3 from "../assets/img/pic-3.png";
import pic4 from "../assets/img/pic-4.png";
import pic5 from "../assets/img/pic-5.png";
import pic6 from "../assets/img/pic-6.png";
import pic7 from "../assets/img/pic-7.png";
import pic8 from "../assets/img/pic-8.png";
import pic9 from "../assets/img/pic-9.png";
import pic10 from "../assets/img/pic-10.png";
import pic12 from "../assets/img/pic-12.png";
import pic13 from "../assets/img/pic-13.png";
import pic14 from "../assets/img/pic-14.png";
import pic16 from "../assets/img/pic-16.png";
import pic17 from "../assets/img/pic-17.png";
import pic18 from "../assets/img/pic-18.png";
import pic19 from "../assets/img/pic-19.png";
import pic20 from "../assets/img/pic-20.png";
import pic21 from "../assets/img/pic-21.png";
import pic22 from "../assets/img/pic-22.png";
import pic23 from "../assets/img/pic-23.webp";
import warn from "../assets/img/warn.png";
import spriteSVG from "../assets/img/sprite.svg";
import close from "../assets/img/close.png";
import edit from "../assets/img/edit.svg";
import trash from "../assets/img/trash.svg";
import copy from "../assets/img/copy.svg";
import arrowDown from "../assets/img/arrowDown.png";
import complete from "../assets/img/complete.png";
import course from "../assets/img/course.png";
import students from "../assets/img/students.png";
import media from "../assets/img/media.svg";

export const images = {
  pic1,
  pic2,
  pic3,
  pic4,
  pic5,
  pic6,
  pic7,
  pic8,
  pic9,
  pic10,
  pic12,
  pic13,
  pic14,
  pic16,
  pic17,
  pic18,
  pic19,
  pic20,
  pic21,
  pic22,
  pic23,
  warn,
  spriteSVG,
  close,
  edit,
  trash,
  copy,
  arrowDown,
  complete,
  course,
  students,
  media,
};
