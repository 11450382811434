import create from "zustand";
import courseServices from "../services/courseServices";
import { getCourseContents } from "../utils/helpers";

const useSingleCourse = create((set, get) => ({
  allContents: [],
  activeContentId: "",
  activeCurriculumId: "",

  course: {},
  courseData: [],
  curriculumId: "",
  contentId: "",
  isLoading: false,
  firstCurriculumId: "",
  lastCurriculumId: "",
  firstContentId: "",
  lastContentId: "",
  isFirstContent: false,
  isLastContent: false,

  // Methods
  fecthCourse: async (id) => {
    set(() => ({ isLoading: true }));

    try {
      const res = await courseServices.getCourseContents(id);
      if (res.data) {
        let courseData, curriculumId, contentId;
        courseData = res?.data?.all_course_curriculums_and_course_contents;

        // Get All course data length
        const dataLength = courseData.length;
        // Get first data Id
        const firstCurriculumId = courseData[0].curriculum_id;
        const firstContentId = courseData[0].course_contents[0].id;
        // Get last data Id
        const lastCurriculumId = courseData[dataLength - 1].curriculum_id;
        const lastContentsLength =
          courseData[dataLength - 1].course_contents.length;
        const lastContentId =
          courseData[dataLength - 1].course_contents[lastContentsLength - 1].id;

        curriculumId = courseData[0].curriculum_id;
        contentId = courseData[0].course_contents[0].id;

        get().getContentPosition(curriculumId, contentId);

        set(() => ({
          courseData,
          allContents: getCourseContents(courseData),
          curriculumId,
          contentId,
          firstCurriculumId,
          lastCurriculumId,
          isLoading: false,
          firstContentId,
          lastContentId,
        }));
      } else {
        set(() => ({ isLoading: false }));
      }
    } catch (error) {
      set(() => ({ isLoading: false }));
    }

    // await courseServices.getCourseById(id).then(({ data }) => {
    //   const { course: courseData } = data;

    //   set((store) => ({ course: { ...courseData }, isLoading: false }));
    // });
  },
  getContentPosition: (curriculumId, contentId) => {
    const {
      firstCurriculumId,
      lastCurriculumId,
      firstContentId,
      lastContentId,
    } = get();
    const isFirstContent =
      curriculumId === firstCurriculumId && contentId === firstContentId;
    const isLastContent =
      curriculumId === lastCurriculumId && contentId === lastContentId;

    set(() => ({
      isFirstContent,
      isLastContent,
    }));
  },

  setCourseInfo: (curriculumId, contentId) => {
    get().getContentPosition(curriculumId, contentId);
    set(() => ({ curriculumId, contentId }));
  },

  getPrevious: async () => {

  },
  getNext: async () => {
  },
}));

export default useSingleCourse;
