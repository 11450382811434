import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import SpinnerBox from "../../common/SpinnerBox";
// import useSWR from "swr";
// import { fetcher } from "../../../services/fetcher";
import VideoPlayer from "./VideoPlayer";
import DocumentView from "./DocumentView";
import DocumentIcon from "../../common/SvgIcons/DocumentIcon";
import {
  getCourseContentTracker,
  markContent,
} from "../../../services/courseServices";
import Check from "../../common/SvgIcons/Check";
import useSingleCourse from "../../../store/singleCourse";
import { log } from "../../../utils/helpers";
import Spinner from "../../common/Spinner";

const Content = (props) => {
  // States
  // const [marked, setMarked] = useState("");
  const [isMarking, setIsMarking] = useState("");
  // const [isCompleted, setIsCompleted] = useState(false);
  // Hooks
  let history = useHistory();

  // Props
  const { contents, updateRender } = props;
  // const [courseContent, setCourseContent] = useState({});
  // const { contentId } = useParams();
  const { id, curriculumId, contentId } = useParams();

  // Store
  const { setCourseInfo, isFirstContent, isLastContent, allContents } =
    useSingleCourse();

  let course = contents.filter(
    (content) => content.curriculum_id === curriculumId
  );

  const courseContent = course[0]?.course_contents.filter(
    (courseData) => courseData.id === contentId
  )[0];

  const getPrevContent = () => {
    // Get current content Index
    const currentContentId = allContents.findIndex(
      (ele) => ele.id === contentId
    );
    // return if first content
    if (currentContentId <= 0) return;
    // Move to previous content
    const prevContent = allContents[currentContentId - 1];
    // log({ prevContent });
    setCourseInfo(prevContent.curriculum_id, prevContent.id);

    history.push(
      `/user-course/${id}/contents/${prevContent.curriculum_id}/${prevContent.id}`
    );
  };
  const getNextContent = () => {
    // Get current content Index
    const currentContentId = allContents.findIndex(
      (ele) => ele.id === contentId
    );
    // return if last content
    if (currentContentId >= allContents.length) return;
    // Move to previous content
    const nextContent = allContents[currentContentId + 1];
    setCourseInfo(nextContent.curriculum_id, nextContent.id);
    history.push(
      `/user-course/${id}/contents/${nextContent.curriculum_id}/${nextContent.id}`
    );
  };



  const markComplete = async (id) => {
    setIsMarking(true);
    try {
      // const response = await markContent(id);
      await markContent(id);

      courseContent.is_completed = true;
      // setIsCompleted(true);
      updateRender(`${id}`);
      setIsMarking(false);

    } catch (error) {
      setIsMarking(false);
    }
  };

  const trackContent = async (id) => {
    try {
      const res = await getCourseContentTracker(id);
      log({ res });
    } catch (error) {}
  };

  // Effects
  useEffect(() => {
    setCourseInfo(curriculumId, contentId);
    trackContent({ course_id: id, course_content_id: contentId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curriculumId, contentId]);

  // useEffect(() => {}, [courseContent.is_completed]);

  if (!courseContent) return <SpinnerBox />;
  return (
    <div className="all-course-content__right">
      {courseContent?.resource_type === "2" ? (
        <VideoPlayer link={courseContent?.attachment_link} />
      ) : (
        <DocumentView
          link={courseContent?.attachment_link}
          title={courseContent?.resource_name}
          description={courseContent?.description}
        />
      )}

      <div className="all-course-content__video-controls m-t-30px">
        <div className="all-course-content__doctype">
          <DocumentIcon />
          <h5>{courseContent?.resource_type_name}</h5>
        </div>
        <div>{/* <h5>Play Speed</h5> */}</div>
        <div className="all-course-content__video-directions">
          {!isFirstContent && (
            <button
              className="btn btn-8 btn--video-control"
              onClick={getPrevContent}
            >
              Previous
            </button>
          )}
          {!isLastContent && (
            <button
              className="btn btn-8 btn--video-control"
              onClick={getNextContent}
            >
              Next
            </button>
          )}
        </div>
      </div>
      <div className="all-course-content__details m-t-50px">
        {courseContent?.resource_type === "2" && (
          <h3 className="head-29">{courseContent?.resource_name || "--"}</h3>
        )}
        <div className="all-course-content__details-card m-t-25px">
          <div className="all-course-content__detail">
            <div className="all-course-content__detail-title">
              <h5 className="text-truncate">Description</h5>
            </div>
            <div className="all-course-content__detail-desc">
              <p className="para-17">{courseContent?.description || "--"}</p>
            </div>
          </div>
          <div className="all-course-content__mark">
            <button
              disabled={isMarking || courseContent?.is_completed}
              onClick={() => markComplete(courseContent.id)}
              className="btn btn-7 btn-md m-t-30px m-b-25px"
            >
              {isMarking ? (
                <Spinner color={"color"} />
              ) : courseContent?.is_completed ? (
                "Completed"
              ) : (
                "Mark as completed"
              )}
            </button>
            {courseContent?.is_completed && <Check width={"20"} height="20" />}
          </div>

          {/*  */}
          {/* <div className="all-course-content__detail">
            <div className="all-course-content__detail-title">
              <h5 className="text-truncate">Instructor</h5>
            </div>
            <div className="all-course-content__detail-desc">
              <p className="para-17">{contents?.about_instructor || "--"}</p>
              <p className="para-17">See you inside the courses!</p>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Content;
