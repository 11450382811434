import React from "react";
import EditLightIcon from "./SvgIcons/EditLightIcon";

const CourseContentPlain = (props) => {
  const { topic } = props;
  return (
    <div className="course-content-plain">
      <EditLightIcon />
      <h5 className="course-content-plain__text">{topic}</h5>
    </div>
  );
};

export default CourseContentPlain;
