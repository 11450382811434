import http from "./httpServices";

const loginApi = "/login";
const verifyAccountApi = "/verify";
const forgetPasswordApi = "/forgot-password";
const setPasswordApi = "/set-password";
const resendVerificationLinkApi = "/resend-activation-link";
const tokenKey = "userData";

http.setToken(getToken());

export function userLogin(email, password) {
  return http.post(`${loginApi}?email=${email}&password=${password}`);
}

export function storeUserData(data) {
  const { userData, token } = data;
  const user = { userData, token };
  localStorage.setItem(tokenKey, JSON.stringify(user));
}

export function userLogout() {
  localStorage.removeItem(tokenKey);
}

export function getToken() {
  let userData = localStorage.getItem(tokenKey);

  userData = JSON.parse(userData);
  if (userData) return userData.token;
  return null;
}

export function getCurrentUser() {
  let user = localStorage.getItem(tokenKey);
  if (user) {
    user = JSON.parse(user);
    return user[tokenKey];
  } else return null;
}

export function verifyAccount(token) {
  return http.get(`${verifyAccountApi}/${token}`);
}

export function resendVerificationLink(data) {
  return http.post(resendVerificationLinkApi, data);
}

export function forgetPassword(data) {
  return http.post(forgetPasswordApi, data);
}

export function setPassword(token, password) {
  return http.post(`${setPasswordApi}/${token}`, { password });
}

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  userLogin,
  userLogout,
  getCurrentUser,
  getToken,
  storeUserData,
  verifyAccount,
  resendVerificationLink,
  forgetPassword,
  setPassword,
};
