import React from "react";
import Form from "./../Forms/Form";
import Joi from "joi";
import ContentCard from "../ContentCard";
import GridView from "../GridView";
import { connect } from "react-redux";

import ExitIcon from "./ExitIcon";
import {
  getStudentProfile,
  saveStudentData,
} from "../../../storee/slices/student";
import { getAllCountries } from "../../../storee/slices/helpers";
import SpinnerBox from "../SpinnerBox";

class AboutStudent extends Form {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        fullName: "",
        professionalTitle: "",
        emailAddress: "",
        phoneNumber: "",
        aboutYou: "",
        country: "",
        profilePictureUrl: "",
      },
      error: {
        fullName: "",
        professionalTitle: "",
        emailAddress: "",
        phoneNumber: "",
        aboutYou: "",
        country: "",
        profilePictureUrl: "",
      },
      countries: [],
    };
  }

  schema = {
    fullName: Joi.string().required().label("Full Name"),
    professionalTitle: Joi.string().required().label("Professional title"),
    emailAddress: Joi.string().required().label("Email"),
    phoneNumber: Joi.string().required().label("Phone number"),
    aboutYou: Joi.string().required().label("About you"),
    country: Joi.string().required().label("Country"),
    profilePictureUrl: Joi.any().label("Profile picture"),
  };

  async componentDidMount() {
    // Fetch the user data from the redux state
    if (!Object.keys(this.props.studentData).length) {
      await this.props.getStudentProfile();
    }

    if (this.props.studentData.student_personal_profile) {
      const {
        name,
        email,
        phone_number,
        country,
        profile_picture_url,
        about_me,
        professional_title,
      } = this.props.studentData.student_personal_profile;

      const data = {
        fullName: name,
        professionalTitle: professional_title,
        emailAddress: email,
        phoneNumber: phone_number,
        aboutYou: about_me,
        country,
        profilePictureUrl: profile_picture_url,
      };

      this.setState({ data });
    }

    // Country list
    await this.props.getAllCountries();
    const countryData = this.props.countryData?.countries?.map((country) => ({
      optionName: country?.country_name,
      optionId: country?.country_id,
    }));
    this.setState({ countries: countryData });
  }

  doSubmit = async () => {
    const {
      fullName,
      professionalTitle,
      emailAddress,
      phoneNumber,
      aboutYou,
      country,
      profilePictureUrl,
    } = this.state.data;

    const data = {
      name: fullName,
      professional_title: professionalTitle,
      email: emailAddress,
      phone_number: phoneNumber,
      about_me: aboutYou,
      country,
      profile_picture_file: profilePictureUrl,
    };

    let formData = new FormData();
    for (let key in data) {
      formData.append(key, data[key]);
    }

    await this.props.saveStudentData(formData);
  };

  render() {
    const { isLoading, isLoadingCountry } = this.props;
    const { countries } = this.state;
    return (
      <div className="animate-slideFromLeft">
        <h3 className="head-5 m-b-10px">About me</h3>
        <form onSubmit={this.handleSubmit}>
          <ContentCard>
            {isLoading && isLoadingCountry ? (
              <SpinnerBox />
            ) : (
              <>
                <div className="user-profile-image__wrapper">
                  <div className="user-profile-image">
                    {/* {this.state.userImage ? (
                  
                ) : (
                  <div className="user-profile__avatar">
                    <span>Upload Image</span>
                  </div>
                )} */}
                    <img
                      src={
                        this.state.userImage ||
                        this.state.data.profilePictureUrl ||
                        "https://via.placeholder.com/200?text=120px x 120px"
                      }
                      alt=""
                      className="user-profile__img"
                    />
                  </div>
                  <div className="select-profile-image">
                    <input
                      type="file"
                      name="profilePictureUrl"
                      id=""
                      accept="image/*"
                      className="select-profile-image__input"
                      onChange={(e) => {
                        this.setState({
                          userImage: URL.createObjectURL(e.target.files[0]),
                          userImageFile: e.target.files[0],
                        });
                        this.handleChange(e);
                      }}
                    />
                    <button className="btn select-profile-image__btn">
                      <ExitIcon />
                      Change Image
                    </button>
                  </div>
                </div>

                <GridView grid={2}>
                  {this.renderInput("Full Name", "fullName", "text", "noicon")}
                  {this.renderInput(
                    "Professional Title",
                    "professionalTitle",
                    "text",
                    "noicon"
                  )}
                </GridView>
                <GridView grid={2}>
                  {this.renderInput(
                    "Email Address",
                    "emailAddress",
                    "text",
                    "noicon"
                  )}
                  {this.renderInput(
                    "Phone Number",
                    "phoneNumber",
                    "text",
                    "noicon"
                  )}
                </GridView>
                <GridView grid={2}>
                  {this.renderSelect(
                    "Country",
                    "country",
                    countries,
                    "country_id",
                    "country_name",
                    "Select Country"
                  )}{" "}
                  <div></div>
                </GridView>
                {this.renderTextArea(
                  "aboutYou",
                  "4",
                  "Tell us something about you",
                  "About you"
                )}

                {this.renderButton(
                  "Save Changes",
                  isLoading,
                  "btn btn-5 m-t-25px m-l-auto"
                )}
                {/* <button type="submit" className="btn btn-5 m-t-25px m-l-auto">
              {this.props.isLoading ? <Spinner /> : "Save Changes"}
            </button> */}
              </>
            )}
          </ContentCard>

          {/* {this.renderInput("Confirm Password", "password", "password")}

        {this.renderButton("Send Reset Link")} */}
        </form>
      </div>
    );
  }
}

// Store
const mapStateToProps = (state) => ({
  isLoading: state.entities.student.aboutStudent.isLoading,
  studentData: state.entities.student.aboutStudent.data,
  countryData: state.entities.helpers.countries.data,
  isLoadingCountry: state.entities.helpers.countries.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  getAllCountries: () => dispatch(getAllCountries()),
  saveStudentData: (data) => dispatch(saveStudentData(data)),
  getStudentProfile: () => dispatch(getStudentProfile()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AboutStudent);
