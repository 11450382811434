import React from "react";

const SiteLogo = ({ width, height, cssClass }) => {
  return (
    <svg
      id="site-logo"
      width={width || "100"}
      height={height || "24"}
      className={`${cssClass ? cssClass : ""}`}
      viewBox="0 0 100 24"
      fill="none"
    >
      <path
        d="M99.75 12.9478C99.5928 10.1583 98.3355 7.63199 96.3447 5.84252C94.511 4.21094 92.1535 3.21094 89.534 3.21094C83.8759 3.21094 79.2656 7.84251 79.2656 13.4741C79.2656 19.1583 83.8759 23.7899 89.534 23.7899C92.1535 23.7899 94.5634 22.7899 96.3447 21.1583C98.2831 19.3688 99.5928 16.8951 99.75 14.053C99.75 13.8425 99.75 13.6846 99.75 13.4741C99.75 13.3162 99.75 13.1057 99.75 12.9478ZM89.4292 20.4214C85.6572 20.4214 82.5662 17.3162 82.5662 13.5267C82.5662 9.73725 85.6572 6.63199 89.4292 6.63199C93.0441 6.63199 96.0303 9.47409 96.2399 13.1057V13.9478C96.0827 17.5794 93.0965 20.4214 89.4292 20.4214Z"
        fill="#5C4FFF"
      />
      <path
        d="M39.1356 4.79102C34.4729 4.79102 30.7008 8.52786 30.6484 13.2121H47.6227C47.5703 8.58049 43.8506 4.79102 39.1356 4.79102ZM39.1356 6.31733C42.4361 6.31733 45.2128 8.63311 45.9462 11.7384H32.3249C33.1108 8.63311 35.835 6.31733 39.1356 6.31733Z"
        fill="#5C4FFF"
      />
      <path
        d="M49.089 13.2112C49.0366 7.73747 44.5311 3.31641 39.135 3.31641C33.6865 3.31641 29.1286 7.73747 29.0762 13.2112V14.7375H49.089V13.2112ZM32.3243 11.7375C33.0578 8.63219 35.8344 6.31641 39.135 6.31641C42.4355 6.31641 45.2122 8.63219 45.9457 11.7375H32.3243Z"
        fill="#5C4FFF"
      />
      <path
        d="M47.4646 17.2637C46.1025 20.2637 42.9067 22.3689 39.1346 22.3689C35.415 22.3689 32.1668 20.2637 30.8047 17.2637H32.5859C33.8433 19.4216 36.3056 20.8953 39.1346 20.8953C41.9637 20.8953 44.426 19.4216 45.6834 17.2637H47.4646Z"
        fill="#0D0282"
      />
      <path
        d="M45.684 17.2637C44.4267 19.4216 41.9644 20.8953 39.1353 20.8953C36.3063 20.8953 33.844 19.4216 32.5866 17.2637H29.1289C30.5958 21.0531 34.525 23.79 39.1353 23.79C43.7456 23.79 47.6749 21.0531 49.1418 17.2637H45.684Z"
        fill="#0D0282"
      />
      <path
        d="M9.27298 12.053L7.125 11.3688C6.07721 11.053 5.34375 10.6846 4.92463 10.2109C4.50551 9.73725 4.29595 9.21094 4.29595 8.52673C4.29595 7.78989 4.61029 7.26356 5.23897 6.78988C5.86765 6.36882 6.65349 6.15831 7.54412 6.15831C9.22059 6.15831 10.5827 6.89514 11.6305 8.42146L14.1452 6.68462C12.7831 4.36884 10.6351 3.21094 7.6489 3.21094C5.71048 3.21094 4.13878 3.68462 2.82904 4.68461C1.5193 5.68461 0.890624 7.0004 0.890624 8.63198C0.890624 11.5267 2.51471 13.4741 5.81526 14.4215L7.80606 15.053C9.06342 15.4741 9.95405 15.8951 10.4779 16.3688C11.0018 16.8425 11.2638 17.4215 11.2638 18.1583C11.2638 19.0004 10.9495 19.632 10.2684 20.1057C9.58732 20.5794 8.80147 20.8425 7.80606 20.8425C6.65349 20.8425 5.65809 20.5793 4.71507 20.0004C3.77206 19.4215 3.14338 18.6846 2.77666 17.6846L0 19.2109C0.628677 20.7372 1.57169 21.8425 2.93383 22.632C4.29596 23.4215 5.86765 23.7899 7.70129 23.7899C9.74448 23.7899 11.3686 23.2636 12.6783 22.2109C13.9357 21.1583 14.6167 19.6846 14.6167 17.8425C14.5643 15.1057 12.7831 13.2109 9.27298 12.053Z"
        fill="#5C4FFF"
      />
      <path
        d="M22.7363 6.47368H26.7179V3.36842H22.7363V0C22.7363 0 22.7363 3.21159e-06 22.7363 0.0526348C22.6315 0.315793 21.8457 2.10526 20.1168 3.36842C19.9073 3.52631 19.6453 3.6842 19.3834 3.8421C18.5975 4.26315 17.6545 4.52632 16.5543 4.52632H16.502V6.57895H19.3834V17.9474C19.3834 19.9474 19.9597 21.4211 21.0599 22.4737C22.16 23.5263 23.627 24 25.4606 24C25.9321 24 26.3512 23.9474 26.8227 23.8947V20.7895C26.6132 20.7895 26.3512 20.7895 26.0893 20.7895C24.9367 20.7895 24.1509 20.5263 23.627 20.0526C23.1031 19.5789 22.8411 18.6842 22.8411 17.4211V6.47368H22.7363Z"
        fill="#5C4FFF"
      />
      <path
        d="M67.3733 3.31641L60.1959 19.1585L53.0709 3.42168H49.2988L58.729 23.948H61.558L71.093 3.31641H67.3733Z"
        fill="#5C4FFF"
      />
      <path d="M76.96 3.31641H73.5547V23.948H76.96V3.31641Z" fill="#5C4FFF" />
      <path
        d="M99.7491 4.10547H96.3438V23.9476H99.7491V4.10547Z"
        fill="#5C4FFF"
      />
      <path
        d="M26.7196 3.31641H16.4512V6.4743H26.7196V3.31641Z"
        fill="#5C4FFF"
      />
      <path d="M22.7374 0H19.332V7.36842H22.7374V0Z" fill="#5C4FFF" />
    </svg>
  );
};

export default SiteLogo;
