import React from "react";
import ModalLayout from "./../ModalLayout";
import ModalChild from "./../ModalChild";
import ContentCard from "./../ContentCard";
import { images } from "../../../utils/images";
import PromoVideoForm from "../Forms/PromoVideoForm";

const UploadPromoVideoModal = (props) => {
  const { setShowModal } = props;

  return (
    <ModalLayout>
      <ModalChild>
        <ContentCard title="Provide Promo Video Link">
          <button
            className="btn-close-modals"
            onClick={() => setShowModal(false)}
          >
            <img src={images?.pic9} alt="times" />
          </button>

          <PromoVideoForm />
        </ContentCard>
      </ModalChild>
    </ModalLayout>
  );
};

export default UploadPromoVideoModal;
