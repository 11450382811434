import http from "./httpServices";

const getStudentDashboardDataApi = "/student/get-student-dashboard-stats";
const getStudentUpcomingClassesApi = "/student/get-student-upcoming-classes";
const getStudentOngoingClassApi = "/student/get-student-ongoing-courses";
const saveCourseToWishlistApi = "/student/save-course-to-wishlist";
const savePersonalProfileApi = "/student/update-student-personal-profile";
const getStudentProfileApi = "/student/get-student-personal-profile";
const getStudentBillingInfoApi = "/student/get-student-billing-info";
const updateStudentBillingInfoApi = "/student/update-student-billing-info";
const getStudentRecentCourseApi = "/student/get-recent-courses";
const getCompletedCoursesApi = "/student/get-student-completed-courses";
const getOngoingCoursesApi = "/student/get-student-ongoing-courses";
const getWishlistCoursesApi = "/student/get-student-course-wishlist";

export function getDashboardData() {
  return http.get(getStudentDashboardDataApi);
}

export function getStudentUpcomingClasses() {
  return http.post(getStudentUpcomingClassesApi);
}

export function getStudentOngoingClass() {
  return http.post(getStudentOngoingClassApi);
}

export function saveCourseToWishlist(data) {
  return http.post(saveCourseToWishlistApi, data);
}

export function savePersonalProfile(data) {
  return http.post(savePersonalProfileApi, data);
}

export function getStudentProfile() {
  return http.post(getStudentProfileApi);
}

export function getStudentBillingInfo() {
  return http.post(getStudentBillingInfoApi);
}

export function updateStudentBillingInfo(data) {
  return http.post(updateStudentBillingInfoApi, data);
}

export function getStudentRecentCourse() {
  return http.post(getStudentRecentCourseApi);
}

export function getCompletedCourses() {
  return http.post(getCompletedCoursesApi);
}

export function getOngoingCourses() {
  return http.post(getOngoingCoursesApi);
}

export function getWishlistCourses() {
  return http.post(getWishlistCoursesApi);
}

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  getDashboardData,
  getStudentUpcomingClasses,
  getStudentOngoingClass,
  saveCourseToWishlist,
  savePersonalProfile,
  getStudentProfile,
  getStudentBillingInfo,
  updateStudentBillingInfo,
  getStudentRecentCourse,
  getCompletedCourses,
  getOngoingCourses,
  getWishlistCourses,
};
