import React from "react";

const Radio = ({
  name,
  label,
  error,
  value,
  // selectedValue,
  handleClick,
  ...rest
}) => {
  return (
    <div className="m-b-20px">
      {error ? <span className="input__error"> {error} </span> : ""}
      <label htmlFor={label} className="input__radio">
        <input
          type="radio"
          name={name}
          id={label}
          value={value}
          // checked={selectedValue === value}
          {...rest}
        />
        <span className="input__radio-btn" onClick={handleClick}></span>
        <span className="input__radio-label" onClick={handleClick}></span>
      </label>
      <p className="m-t-5px input__radio-label--2">{label}</p>
    </div>
  );
};

export default Radio;
