import styled from "styled-components";

export const FilterStyle = styled.div`
  position: relative;

  button {
    height: 100%;
  }

  .filter {
    position: absolute;
    right: 0;
    top: 130%;
    width: 31.2rem;
    padding-bottom: 2rem;
    background-color: #ffffff;
    border-radius: 0.6rem;
    box-shadow: 0 1rem 2.5rem rgba(0, 0, 0, 0.13);
    z-index: 2;
    animation: name duration timing-function delay iteration-count direction
      fill-mode;

    &::before {
      position: absolute;
      content: "";
      bottom: 100%;
      right: 15%;
      /* background-color: #a29f9f; */
      border-width: 1rem;
      border-style: solid;
      border-color: transparent transparent #ffffff transparent;
    }
  }

  .filter-top {
    border-bottom: 1px solid rgba(100, 115, 130, 0.1);
    padding: 1.5rem 1.5rem;
  }

  .filter-bottom {
    /* border-bottom: 1px solid rgba(100, 115, 130, 0.1); */
    padding: 1.5rem 1.5rem;
  }

  .heading {
    font-weight: 600;
    font-size: 1.8rem;
    line-height: 1;
    color: #191c1f;
  }
`;

export const Filters = styled.div`
  padding: 1.5rem;
  &:not(:last-child) {
    border-bottom: 1px solid rgba(100, 115, 130, 0.1);
  }

  h5 {
    font-size: 1.4rem;
    font-weight: 500;
    color: #191c1f;
  }
`;
