import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { verifyAccount } from "../../services/authServices";
import OnboardingNav from "../common/OnboardingNav";
import SpinnerBox from "../common/SpinnerBox";
import VerifyAcc from "../common/SvgIcons/VerifyAcc";

const AccountVerification = ({ location }) => {
  // State
  const [isVerifying, setIsVerifying] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  // Hooks
  const { token } = useParams();

  // Methods
  const handleVerification = async () => {
    if (token) {
      setIsVerifying(true);
      try {
        await verifyAccount(token);
        setIsVerified(true);
        setIsVerifying(false);
      } catch (error) {
        setIsVerifying(false);
      }
    }
  };

  // Effect
  useEffect(() => {
    handleVerification();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Data to display
  return (
    <>
      <OnboardingNav location={location} />
      {isVerified && !isVerifying ? (
        <div className="verify-email__container">
          <div className="verify-email__details">
            <VerifyAcc />
            <h3 className="m-b-20px">Thank you</h3>

            <p className="m-b-5px">
              Your Account has been successfully verified, click the{" "}
            </p>

            <p className="m-b-30px">link below to proceed to login</p>
            <Link to="/login" className="link link-3">
              Login
            </Link>
          </div>
        </div>
      ) : (
        <>
          <SpinnerBox />
          <h2 className="m-b-30px text-center">Verifying account</h2>
        </>
      )}
    </>
  );
};

export default AccountVerification;
