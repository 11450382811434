import React, { useState, useRef, useEffect } from "react";
import FilterIcon from "../SvgIcons/FilterIcon";
import { FilterStyle, Filters } from "./Style";
import Checkbox from "./../Input/Checkbox";
import useOnClickOutside from "../../../utils/Hooks/useOnClickOutside";
import { useSelector, useDispatch } from "react-redux";
import {
  getAllDurations,
  getLevelOfCompetence,
} from "../../../storee/slices/helpers";
import SpinnerBox from "../SpinnerBox";

const Filter = (props) => {
  // State
  const [showFilter, setShowFilter] = useState(false);
  // Props
  const { inputChange, searchCourse, resetFilter } = props;
  // Refs
  const dropdownRef = useRef();
  // Hooks
  useOnClickOutside(dropdownRef, () => setShowFilter(false));
  const dispatch = useDispatch();

  // Store
  let [isLaodingLevels, levelOfCompetences, isLoadingDurations, durations] =
    useSelector((store) => [
      store.entities.helpers.levelOfCompetence.isLoading,
      store.entities.helpers.levelOfCompetence.data,
      store.entities.helpers.durations.isLoading,
      store.entities.helpers.durations.data,
    ]);

  // Effect
  useEffect(() => {
    dispatch(getLevelOfCompetence());
    dispatch(getAllDurations());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Data to render
  return (
    <FilterStyle ref={dropdownRef}>
      <button
        className="btn course-explore__search-btn"
        onClick={() => setShowFilter((prevState) => !prevState)}
      >
        <FilterIcon /> Filter
      </button>
      {showFilter && (
        <div className="filter animate-fadeIn">
          <div className="filter-top">
            <h4 className="heading">Filter</h4>
          </div>

          {isLaodingLevels && isLoadingDurations ? (
            <SpinnerBox />
          ) : (
            <>
              <Filters>
                <h5>Level of competency </h5>
                {levelOfCompetences.length ? (
                  <>
                    {levelOfCompetences?.map((level) => (
                      <Checkbox
                        key={level?.level_of_competence_id}
                        label={level?.name}
                        name="level_of_competence"
                        type="radio"
                        cssClass="m-t-20px"
                        onChange={() =>
                          inputChange(
                            "level_of_competence",
                            level?.level_of_competence_id
                          )
                        }
                      />
                    ))}
                  </>
                ) : (
                  ""
                )}
              </Filters>
              <Filters>
                <h5>Price </h5>

                <Checkbox
                  name="price"
                  label="Lowest - Highest"
                  cssClass="m-t-20px"
                  type="radio"
                  onChange={() => inputChange("price", "asc")}
                />
                <Checkbox
                  name="price"
                  label="Highest - Lowest"
                  cssClass="m-t-20px"
                  type="radio"
                  onChange={() => inputChange("price", "desc")}
                />
              </Filters>
              <Filters>
                <h5>Duration </h5>

                {durations.length ? (
                  <>
                    {durations.map((duration) => (
                      <Checkbox
                        key={duration?.duration_id}
                        label={duration?.name}
                        name="duration"
                        type="radio"
                        cssClass="m-t-20px"
                        onChange={() =>
                          inputChange("duration", duration?.duration_id)
                        }
                      />
                    ))}
                  </>
                ) : (
                  ""
                )}
              </Filters>

              <div className="filter-bottom">
                <button
                  className="btn btn-7 btn--full m-t-20px"
                  onClick={() => {
                    searchCourse();
                    setShowFilter(false);
                    resetFilter();
                  }}
                >
                  Show Result
                </button>
              </div>
            </>
          )}
        </div>
      )}
    </FilterStyle>
  );
};

export default Filter;
