import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.interceptors.response.use(null, (error) => {
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status <= 500;

  if (!expectedError) console.log(error);

  return Promise.reject(error);
});

function setToken(token) {
  axios.defaults.headers.common["AuthToken"] = token;
}

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  post: axios.post,
  get: axios.get,
  put: axios.put,
  delete: axios.delete,
  patch: axios.patch,
  setToken,
};
