import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
// import { addANotification } from "../../storee/slices/notification";
import { publishCourse } from "../../storee/slices/singleCourse";
import Notiflix from "notiflix";
// import { getShadowCourses } from "../../storee/slices/instructor";

const CoursePublish = ({ status, courseId }) => {
  const [courseStatus, setCourseStatus] = useState(status);
  const hasMounted = useRef(false);
  const dispatch = useDispatch();

  // const [hasError, changedCourseId] = useSelector((store) => [
  //   store.entities.singleCourse.publishCourse.hasError,
  //   store.entities.singleCourse.publishCourse.data.course_id,
  // ]);

  // Methods
  // const getAllCoursesAfterStatusChange = async () => {
  //   dispatch(getShadowCourses());
  // };

  // Effects
  useEffect(() => {
    if (!hasMounted.current) {
      hasMounted.current = true;
    } else {
      try {
        dispatch(
          publishCourse({
            is_published: Number(courseStatus),
            course_id: Number(courseId),
          })
        );
        // getAllCoursesAfterStatusChange();
        // setCourseStatus((prevState) => (prevState === "0" ? "1" : "0"));
        if (courseStatus === "1") {
          Notiflix.Notify.success("Course has been published");
        } else if (courseStatus === "0") {
          Notiflix.Notify.success("Course has been unpublished");
        }
      } catch (error) {}
    }

    // Set hasChanged,current to true if hasChange.current == false
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseStatus]);

  // Data to display
  return (
    <div className="text-center">
      <button
        className="data__action-toggle"
        onClick={() =>
          setCourseStatus((prevState) => (prevState === "0" ? "1" : "0"))
        }
      >
        <span
          className={`data__action-toggler ${
            courseStatus === "1" ? "data__action-toggler__active" : ""
          }`}
        ></span>
      </button>

      {courseStatus === "1" ? (
        <p className="data__published animate-fadeIn">Published</p>
      ) : (
        <p className="data__published animate-fadeIn">Not Published</p>
      )}
    </div>
  );
};

export default CoursePublish;
