import React from "react";
import CircleComment from "./SvgIcons/CircleComment";
import CircleInfo from "./SvgIcons/CircleInfo";
import CircleWallet from "./SvgIcons/CircleWallet";

const NotificationItem = (props) => {
  const { type } = props;

  return (
    <div className="user-notification__item">
      {type === "payment" ? (
        <CircleWallet />
      ) : type === "info" ? (
        <CircleInfo />
      ) : (
        <CircleComment />
      )}

      <div>
        <h6 className="user-notification__detail">
          Your have a live class in 30 min, check course detail for live link
          and other information.
        </h6>

        <span className="user-notification__date">8th Jan, 2021 10:25 am</span>
      </div>
    </div>
  );
};

export default NotificationItem;
