import React from "react";

const CameraIcon = ({
  width,
  height,
  cssClass,
  pathCssClass1,
  pathCssClass2,
}) => {
  return (
    <svg
      width={width || "18"}
      height={height || "14"}
      className={`${cssClass ? cssClass : ""}`}
      viewBox="0 0 18 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={pathCssClass1}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1714 9.73264C12.1714 11.411 11.0278 12.5966 9.19874 12.5966H3.96337C2.136 12.5966 1 11.411 1 9.73264V3.87158C1 2.19411 2.136 1 3.97263 1H9.19874C11.0278 1 12.1714 2.19411 12.1714 3.87158V9.73264Z"
        stroke="#647382"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className={pathCssClass2}
        d="M12.1714 6.07475L15.6215 3.25033C16.1689 2.7998 17 3.19138 17 3.90465V9.6916C17 10.4057 16.1689 10.7956 15.6215 10.3459L12.1714 7.52149"
        stroke="#647382"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CameraIcon;
