import React, { useState } from "react";
import { color } from "./../../utils/randomColors";
import moment from "moment";
import EditIcon from "./SvgIcons/EditIcon";
import TrashIcon from "./SvgIcons/TrashIcon";
import ConfirmActionModal from "./Modals/ConfirmActionModal";
import { useDispatch, useSelector } from "react-redux";
import { deleteLiveClass } from "../../storee/slices/createCourse";
// import { addANotification } from "../../storee/slices/notification";
import Notiflix from "notiflix";

// import ModalChild from "./ModalChild";
// import ModalLayout from "./ModalLayout";
// import { images } from "../../utils/images";
// import ContentCard from "./ContentCard";

const EventCard = (props) => {
  // States
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [, setShowModal] = useState(false);

  // Hooks
  const [bgColor, textColor] = color();

  // Props
  const { date, name, time, timeZone, id, handleEdit, type } = props;

  // Variables
  const bgStyles = {
    backgroundColor: bgColor,
  };
  const textStyles = {
    color: textColor,
  };

  // Selectors
  const [isDeleting] = useSelector((store) => [
    store.entities.createCourse.liveClasses.isDeleting,
  ]);

  const dispatch = useDispatch();
  // Handlers
  const handleLiveClassDelete = async () => {
    try {
      await dispatch(deleteLiveClass({ live_class_id: id }));
      Notiflix.Notify.success(`Deleted successfully`);
    } catch (error) {
      Notiflix.Notify.success(`An error occurred`);
    }
    // dispatch(
    //   addANotification({
    //     type: "ok",
    //     title: "Success",
    //     description: `Deleted successfully`,
    //   })
    // );
    setShowDeleteModal(false);
  };
  const displayEventModal = () => setShowModal(true);

  return (
    <>
      <div
        onClick={displayEventModal}
        className="event-card event-card--1"
        style={bgStyles}
      >
        <div className="eventCardBlock">
          <div className="eventCardDate">
            <h3 className="dateTitle">{moment(date).format("MMM")}</h3>
            <span className="dateNum">{moment(date).format("Do")}</span>
          </div>
          <div className="event-card__top">
            <div className="eventInfoTop">
              <p className="para-5 eventType">Meetup: {type ? type : "--"} </p>
              <h3
                className="head-7 text-truncate width-200px event-name"
                style={textStyles}
                title={name ? name : null}
              >
                {name ? name : "--"}
              </h3>
            </div>
            <div className="event-card-left">
              <p className="para-5">
                {date ? moment(date).format("LL") : "--"}
              </p>
              <p className="para-5 event-card-left-dot">{time ? time : "--"}</p>
              <p className="para-5">{timeZone ? timeZone : "--"}</p>
            </div>
            {/* <span className="eventStartTime">
              Starts in 17 hours and 45 minutes
            </span> */}
          </div>
        </div>

        {handleEdit && (
          <div className="event-card__bottom">
            <button className="btn btn-naked" onClick={() => handleEdit(id)}>
              <EditIcon width={"16"} height="16" />
            </button>
            <button
              className="btn btn-naked"
              onClick={() => setShowDeleteModal(true)}
            >
              <TrashIcon width={"16"} height="16" />
            </button>
          </div>
        )}
      </div>

      {showDeleteModal && (
        <ConfirmActionModal
          setShowModal={setShowDeleteModal}
          handleYesAction={handleLiveClassDelete}
          title="Are you sure you wan to delete live class ?"
          isLoading={isDeleting}
        />
      )}

      {/* {showModal && 
        <ModalLayout>
        <ModalChild>
        <ContentCard title="Live Class">
          <button
            className="btn-close-modals"
            onClick={() => setShowModal(false)}
          >
            <img src={images?.pic9} alt="times" />
          </button>
          <div className="eventsModal">
            <div className="eventsModalInfo">
              <div className="eventsModalInfo1">
                <h4 className="modalInfoTitle">{name ? name : "--"}</h4>
                <div className="eventsModalInfo1Time">
                  <p className="para-5">{date ? moment(date).format("LL") : "--"}</p>
                  <p className="para-5">{time ? time : "--"}</p>
                  <p className="para-5">{timeZone ? timeZone : "--"}</p>
                </div>
                <div className="eventsModalLinkBox">
                  <span className="eventsModalLink">https://illinois.zoom.us/j/83163267333?pwd</span>
                  <button className="eventsModalLinkCopy">
                    <img src={images.copy} alt="" className="copyIcon" />
                  </button>
                </div>
              </div>
              <div className="eventsModalInfo2">
                <button className="btn editEventBtn">
                  <img src={images.edit} alt="" className="editEventIcon" />
                  <span className="editEventText">Edit Live Class</span>
                </button>
                <button className="btn delEventBtn">
                  <img src={images.trash} alt="" className="editEventIcon" />
                  <span className="editEventText">Delete</span>
                </button>
              </div>
            </div>
            <div className="eventsModalCalendarInfo">
              <div className="eventCalBlock eventCal1">
                <span className="eventCal1Title">Starts in...</span>
                <div className="eventCalSubBlock">
                  <div className="eventBox">
                    <h4 className="eventBoxTitle">17</h4>
                    <span className="eventBoxTimeText">Hours</span>
                  </div>
                  <div className="eventBox">
                    <h4 className="eventBoxTitle">45</h4>
                    <span className="eventBoxTimeText">Minutes</span>
                  </div>
                </div>
                <div className="button eventBtn startClassBtn">Start Class</div>
              </div>
              <div className="eventCalBlock eventCal2">
                <h4 className="eventCal2Title">Add this event to your calendar</h4>
                <button className="eventBtn addToCalBtn">Add to Calendar</button>
              </div>
            </div>
          </div>
        </ContentCard>
      </ModalChild>
      </ModalLayout>
      } */}
    </>
  );
};

export default React.memo(EventCard);
