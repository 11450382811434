import React from "react";
import { Link, useLocation } from "react-router-dom";
import ProgressBarWithUser from "./ProgressBarWithUser";

const FeaturedCourse = (props) => {
  // Hooks
  const location = useLocation();

  // Props
  const { course, wishlist } = props;

  // Data to render
  return (
    <Link
      to={
        wishlist
          ? {
              pathname: `/course/${course?.id}`,
              state: { fromUrl: location.pathname },
            }
          : `/user-course/${course?.id}`
      }
      className="link featured-course__link"
    >
      <div className="featured-course__card">
        <p className="featured-course__category">
          {course?.course_category_name}
        </p>
        <h4
          className="featured-course__title m-t-10px m-b-12px text-truncate"
          title={course?.course_name}
        >
          {course?.course_name}
        </h4>
        <div className="featured-course__avatar">
          <img src={course?.thumbnail_file_url} alt="" />
        </div>
        {!wishlist && (
          <ProgressBarWithUser className="m-y-8px" classPercentage={0} />
        )}
      </div>
    </Link>
  );
};

export default FeaturedCourse;
