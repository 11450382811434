import React from "react";
import NotificationItem from "./NotificationItem";

import { useDispatch, useSelector } from "react-redux";
import { removeNotifications } from "../../../storee/slices/notification";

const Notification = () => {
  const dispatch = useDispatch();

  let [notifications] = useSelector((store) => [
    store.entities.notification.list,
  ]);
  const notificationExist = !!notifications && !!notifications?.length;

  return (
    <div className="alert-notification">
      {notificationExist && notifications.length > 1 && (
        <button
          className="btn alert-notification__close-btn m-b-5px"
          onClick={() => dispatch(removeNotifications())}
        >
          Close All
        </button>
      )}

      {notificationExist &&
        notifications?.map((notification, index) => (
          <NotificationItem
            key={index}
            type={notification?.type}
            title={notification?.title}
            description={notification?.description}
            id={index}
          />
        ))}
    </div>
  );
};

export default Notification;
