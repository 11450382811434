import React, { useState } from "react";
import usePayment from "../../../store/payment";
import MasterCardIcon from "../SvgIcons/MasterCardIcon";
import PaypalIcon from "../SvgIcons/PaypalIcon";
import StripeIcon from "../SvgIcons/StripeIcon";
import PaypalForm from "./PaypalForm";

const PaymentOption = (props) => {
  // State
  const [show, setShow] = useState(false);

  // Props
  const { data, setSelectedOption } = props;

  // Store
  const { setPaymentOption } = usePayment();

  // Methods
  const handleFormShow = (type) => {
    setShow((prevState) => !prevState);
    setSelectedOption(type);
    setPaymentOption(type);
  };
  return (
    <>
      <div className="payment-card__item">
        <input type="radio" id={data?.id} name={data?.name}></input>
        <label htmlFor={data?.id} onClick={() => handleFormShow(data?.type)}>
          <div className="payment-card__item-radio"></div>
          <div className="payment-card__item-detail">
            <div>
              <h4 className="head-27">{data?.title}</h4>
              <p className="para-14 m-t-5">{data?.label} </p>
            </div>
            <div className="payment-card__logos m-l-auto">
              {data?.type === "paystack" ? (
                <MasterCardIcon />
              ) : data?.type === "paypal" ? (
                <PaypalIcon />
              ) : (
                <StripeIcon />
              )}
            </div>
          </div>
        </label>
      </div>
      {show && data.type === "card" && (
        <div className="payment-card__form">
          <PaypalForm />
        </div>
      )}
    </>
  );
};

export default PaymentOption;
