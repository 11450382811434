import { createSlice } from "@reduxjs/toolkit";
// import { apiCallBegan } from "../actions/api";
import { apiCallBegan } from "./../actions/api";

const createCourseSlice = createSlice({
  name: "createCourse",
  initialState: {
    courseId: null,
    isEditing: false,
    aboutCourse: {
      data: {
        courseName: "",
        classSize: "",
        courseCategory: "",
        levelOfCompetence: "",
        thumbnailFileUrl: "",
        whatToLearn: "",
        aboutCourse: "",
        startDate: "",
        endDate: "",
      },
      apiResponse: null,
      error: {},
      isLoading: false,
    },
    curriculum: {
      curriculums: [],
      weekNumber: 1,
      isLoading: false,
      isDeleting: false,
      error: null,
    },
    courseContent: {
      contents: [],
      weekNumber: 1,
      isLoading: false,
      isDeleting: false,
      error: null,
    },
    whoIsCourseFor: {
      lists: [],
      isLoading: false,
      isDeleting: false,
      error: null,
    },
    price: {
      data: "0.00",
      isLoading: false,
      error: null,
    },
    liveClasses: {
      lists: [],
      isLoading: false,
      isEditing: false,
      isDeleting: false,
      error: null,
    },
    publishCourse: {
      data: {},
      isLoading: false,
      error: null,
    },
  },
  reducers: {
    // Reset create course data
    resetCreateCourse: (createCourse, action) => {
      createCourse.aboutCourse = {
        data: {
          courseName: "",
          classSize: "",
          courseCategory: "",
          levelOfCompetence: "",
          thumbnailFileUrl: "",
          whatToLearn: "",
          aboutCourse: "",
          startDate: "",
          endDate: "",
        },
        apiResponse: null,
        error: {},
        isLoading: false,
      };
      createCourse.curriculum = {
        curriculums: [],
        weekNumber: 1,
        isLoading: false,
        isDeleting: false,
        error: null,
      };
      createCourse.courseContent = {
        contents: [],
        weekNumber: 1,
        isLoading: false,
        isDeleting: false,
        error: null,
      }(
        (createCourse.whoIsCourseFor = {
          lists: [],
          isLoading: false,
          isDeleting: false,
          error: null,
        })
      );
      createCourse.price = {
        data: "0.00",
        isLoading: false,
        error: null,
      };
      createCourse.liveClasses = {
        lists: [],
        isLoading: false,
        isEditing: false,
        isDeleting: false,
        error: null,
      };
      createCourse.publishCourse = {
        data: {},
        isLoading: false,
        error: null,
      };
    },
    //  Create About course
    createCourseStarted: (createCourse, action) => {
      createCourse.aboutCourse.isLoading = true;
    },
    createCourseFail: (createCourse, action) => {
      createCourse.aboutCourse.isLoading = false;
      createCourse.aboutCourse.error = action.payload;
    },
    createCourseSuccess: (createCourse, action) => {
      createCourse.courseId = action.payload["course_id"];
      createCourse.aboutCourse.apiResponse = action.payload;
      createCourse.aboutCourse.data = {
        ...createCourse.aboutCourse.data,
        ...action.payload,
      };
      createCourse.aboutCourse.isLoading = false;
    },
    persistCreateCourse: (createCourse, action) => {
      createCourse.aboutCourse.data = { ...action.payload };
    },
    //  Update About course
    updateCourseStarted: (createCourse, action) => {
      createCourse.aboutCourse.isLoading = true;
    },
    updateCourseFail: (createCourse, action) => {
      createCourse.aboutCourse.isLoading = false;
      createCourse.aboutCourse.error = action.payload;
    },
    updateCourseSuccess: (createCourse, action) => {
      console.log(action.payload);
      createCourse.courseId =
        action.payload["course_id"] || createCourse.courseId;
      createCourse.aboutCourse.apiResponse = action.payload;
      createCourse.aboutCourse.data = {
        ...action.payload,
        thumbnailFileUrl: createCourse.aboutCourse.data.thumbnailFileUrl,
      };
      createCourse.aboutCourse.isLoading = false;
    },

    // Curriculum
    addingCurriculum: (createCourse, action) => {
      createCourse.curriculum.isLoading = true;
    },
    addingCurriculumFail: (createCourse, action) => {
      createCourse.curriculum.error = action?.payload;
      createCourse.curriculum.isLoading = false;
    },
    addingCurriculumSuccess: (createCourse, action) => {
      delete action.payload.message;
      createCourse.curriculum.curriculums.push(action.payload);
      createCourse.curriculum.weekNumber += 1;
      createCourse.curriculum.isLoading = false;
    },

    // update Curriculum
    updatingCurriculum: (createCourse, action) => {
      createCourse.curriculum.isLoading = true;
    },
    updatingCurriculumFail: (createCourse, action) => {
      createCourse.curriculum.error = action?.payload;
      createCourse.curriculum.isLoading = false;
    },
    updatingCurriculumSuccess: (createCourse, action) => {
      delete action.payload.message;
      const curricumlums = createCourse.curriculum.curriculums;
      const newCurr = curricumlums.map((curriculum) => {
        if (curriculum.curriculum_id === action.payload.curriculum_id) {
          curriculum.topic = action.payload.topic;
          curriculum.description = action.payload.description;
          return curriculum;
        }
        return curriculum;
      });
      createCourse.curriculum.curriculums = [...newCurr];
      createCourse.curriculum.isLoading = false;
    },

    // Delete Curriculum
    deletingCurriculum: (createCourse, action) => {
      createCourse.curriculum.isDeleting = true;
    },
    deletingCurriculumFail: (createCourse, action) => {
      createCourse.curriculum.error = action?.payload;
      createCourse.curriculum.isDeleting = false;
    },
    deletingCurriculumSuccess: (createCourse, action) => {
      const curricumlums = [...createCourse.curriculum.curriculums];
      const newCurr = curricumlums.filter(
        (curriculum) =>
          curriculum.curriculum_id !== action.payload.curriculum_id
      );
      createCourse.curriculum.curriculums = [...newCurr];
      createCourse.curriculum.isDeleting = false;
    },

    // Course content
    addingCourseContent: (createCourse, action) => {
      createCourse.courseContent.isLoading = true;
    },
    addingCourseContentFail: (createCourse, action) => {
      createCourse.courseContent.error = action?.payload;
      createCourse.courseContent.isLoading = false;
    },
    addingCourseContentSuccess: (createCourse, action) => {
      delete action.payload.message;
      createCourse.courseContent.contents.push(action.payload);
      createCourse.courseContent.isLoading = false;
    },

    // Update content
    updatingCourseContent: (createCourse, action) => {
      createCourse.courseContent.isLoading = true;
    },
    updatingCourseContentFail: (createCourse, action) => {
      createCourse.courseContent.error = action?.payload;
      createCourse.courseContent.isLoading = false;
    },
    updatingCourseContentSuccess: (createCourse, action) => {
      delete action.payload.message;
      console.log("Here here here 1");
      const contents = createCourse.courseContent.contents.map((content) => {
        console.log("Here here here 2");
        console.log(content, action.payload.course_content_id);
        if (content.id === action.payload.course_content_id) {
          console.log("Here here here 3");
          content.resource_name = action.payload.resource_name;
          content.estimated_time = action.payload.estimated_time;
          content.attachment_link = action.payload.attachment_link;
          content.resource_type = action.payload.resource_type;
          content.description = action.payload.description;
          return content;
        }
        return content;
      });
      createCourse.courseContent.contents = [...contents];
      createCourse.courseContent.isLoading = false;
    },

    // Delete course content
    deletingCourseContent: (createCourse, action) => {
      createCourse.courseContent.isDeleting = true;
    },
    deletingCourseContentFail: (createCourse, action) => {
      createCourse.courseContent.error = action?.payload;
      createCourse.courseContent.isDeleting = false;
    },
    deletingCourseContentSuccess: (createCourse, action) => {
      const contents = [...createCourse.courseContent.contents];
      const newContents = contents.filter((content) => {
        const id = content.id || content.course_content_id;

        return id.toString() !== action.payload.course_content_id.toString();
      });
      createCourse.courseContent.contents = [...newContents];
      createCourse.courseContent.isDeleting = false;
    },

    // Who is this course for
    addingWhoIsCourseFor: (createCourse, action) => {
      createCourse.whoIsCourseFor.isLoading = true;
    },
    addingWhoIsCourseForFail: (createCourse, action) => {
      createCourse.whoIsCourseFor.error = action?.payload;
      createCourse.whoIsCourseFor.isLoading = false;
    },
    addingWhoIsCourseForSuccess: (createCourse, action) => {
      createCourse.whoIsCourseFor.lists.push(action.payload);
      createCourse.whoIsCourseFor.isLoading = false;
    },

    // update Who is this course for
    updatingWhoIsCourseFor: (createCourse, action) => {
      createCourse.whoIsCourseFor.isLoading = true;
    },
    updatingWhoIsCourseForFail: (createCourse, action) => {
      createCourse.whoIsCourseFor.error = action?.payload;
      createCourse.whoIsCourseFor.isLoading = false;
    },
    updatingWhoIsCourseForSuccess: (createCourse, action) => {
      delete action.payload.message;
      const lists = createCourse.whoIsCourseFor.lists.map((list) => {
        const id = list.who_is_this_course_for_id || list.id;
        if (
          id.toString() === action.payload.who_is_this_course_for_id.toString()
        ) {
          list.category_id = action.payload.category_id;
          list.category_name = action.payload?.category_name;
          list.description = action.payload.description;
          return list;
        }
        return list;
      });
      createCourse.whoIsCourseFor.lists = [...lists];
      createCourse.whoIsCourseFor.isLoading = false;
    },

    // Delete Who is this course for
    deletingWhoIsCourseFor: (createCourse, action) => {
      createCourse.whoIsCourseFor.isDeleting = true;
    },
    deletingWhoIsCourseForFail: (createCourse, action) => {
      createCourse.whoIsCourseFor.error = action?.payload;
      createCourse.whoIsCourseFor.isDeleting = false;
    },
    deletingWhoIsCourseForSuccess: (createCourse, action) => {
      const lists = [...createCourse.whoIsCourseFor.lists];
      const newList = lists.filter((list) => {
        const id = list.id || list.who_is_this_course_for_id;

        return (
          id.toString() !== action.payload.who_is_this_course_for_id.toString()
        );
      });
      createCourse.whoIsCourseFor.lists = [...newList];
      createCourse.whoIsCourseFor.isDeleting = false;
    },

    // Who is this course for
    addingPrice: (createCourse, action) => {
      createCourse.price.isLoading = true;
    },
    addingPriceFail: (createCourse, action) => {
      createCourse.price.error = action?.payload;
      createCourse.price.isLoading = false;
    },
    addingPriceSuccess: (createCourse, action) => {
      createCourse.price.data = action.payload?.price;
      createCourse.price.isLoading = false;
    },

    // Live classes
    addingLiveClass: (createCourse, action) => {
      createCourse.liveClasses.isLoading = true;
    },
    addingLiveClassFail: (createCourse, action) => {
      createCourse.liveClasses.error = action?.payload;
      createCourse.liveClasses.isLoading = false;
    },
    addingLiveClassSuccess: (createCourse, action) => {
      delete action.payload.message;
      createCourse.liveClasses.lists.push(action.payload);
      createCourse.price.isLoading = false;
    },

    // Edit live class
    editingLiveClass: (createCourse, action) => {
      createCourse.liveClasses.isEditing = true;
    },
    editingLiveClassFail: (createCourse, action) => {
      createCourse.liveClasses.error = action.payload;
      createCourse.liveClasses.isEditing = false;
    },
    editingLiveClassSuccess: (createCourse, action) => {
      delete action.payload.message;
      const liveClassList = createCourse.liveClasses.lists;
      const newLiveClassList = liveClassList.map((liveClass) => {
        if (liveClass.live_class_id === action.payload.live_class_id) {
          liveClass.live_class_id = action.payload.live_class_id;
          liveClass.live_class_name = action.payload.live_class_name;
          liveClass.live_class_type = action.payload.live_class_type;
          liveClass.live_class_type_name = action.payload.live_class_type_name;
          liveClass.curriculum_topic = action.payload.curriculum_topic;
          liveClass.curriculum_description =
            action.payload.curriculum_description;
          liveClass.date = action.payload.date;
          liveClass.start_time = action.payload.start_time;
          liveClass.end_time = action.payload.end_time;
          liveClass.time_zone_id = action.payload.time_zone_id;
          liveClass.time_zone_name = action.payload.time_zone_name;
          liveClass.preferred_platform_id = action.payload.preferred_platform;
          liveClass.link_to_live_class = action.payload.link_to_live_class;
          return liveClass;
        }
        return liveClass;
      });
      createCourse.liveClasses.lists = [...newLiveClassList];
      createCourse.liveClasses.isEditing = false;
    },

    // Delete live class
    deletingLiveClass: (createCourse, action) => {
      createCourse.liveClasses.isDeleting = true;
    },
    deletingLiveClassFail: (createCourse, action) => {
      createCourse.liveClasses.error = action?.payload;
      createCourse.liveClasses.isDeleting = false;
    },
    deletingLiveClassSuccess: (createCourse, action) => {
      delete action.payload.message;
      const lists = [...createCourse.liveClasses.lists];
      const newList = lists.filter(
        (list) => list.live_class_id !== action.payload.live_class_id
      );
      createCourse.liveClasses.lists = [...newList];
      createCourse.liveClasses.isDeleting = false;
    },

    // Publish course
    publishingCourse: (createCourse, action) => {
      createCourse.publishCourse.isLoading = true;
    },
    publishingCourseFail: (createCourse, action) => {
      createCourse.publishCourse.error = action?.payload;
      createCourse.publishCourse.isLoading = false;
    },
    publishingCourseSuccess: (createCourse, action) => {
      createCourse.publishCourse.data = action.payload;
      createCourse.publishCourse.isLoading = false;
    },

    // Get course data for edit
    gettingCourseData: (createCourse, action) => {
      // createCourse.publishCourse.data = action.payload;
      // createCourse.publishCourse.isLoading = false;
    },
    gettingCourseDataFail: (createCourse, action) => {
      // createCourse.publishCourse.data = action.payload;
      // createCourse.publishCourse.isLoading = false;
    },
    gettingCourseDataSuccess: (createCourse, action) => {
      delete action.payload.message;
      const courseData = action.payload;

      // Set course id
      createCourse.courseId = courseData?.courses?.id;
      createCourse.isEditing = true;

      // Set About Course
      const aboutCourse = courseData?.courses;
      createCourse.aboutCourse.data = {
        courseName: aboutCourse?.course_name,
        classSize: aboutCourse?.class_size,
        courseCategory: aboutCourse?.course_category,
        levelOfCompetence: aboutCourse?.level_of_competence,
        thumbnailFileUrl: aboutCourse?.thumbnail_file_url,
        whatToLearn: aboutCourse?.outcome,
        aboutCourse: aboutCourse?.about_course,
        startDate: aboutCourse?.start_date,
        endDate: aboutCourse?.end_date,
        sub_title: aboutCourse?.sub_title,
        outcomes: JSON.parse(aboutCourse?.outcomes).filter(
          (item) => item !== ""
        ),
        course_requirements: JSON.parse(
          aboutCourse?.course_requirements
        ).filter((item) => item !== ""),
      };

      // Set Course curriculum
      const curriculums = courseData?.curriculums;
      createCourse.curriculum.curriculums = curriculums ? [...curriculums] : [];

      // Set couurse contents
      const courseContents = courseData?.course_contents;
      createCourse.courseContent.contents = courseContents
        ? [...courseContents]
        : [];

      // Set who is this course for
      const whoGetCourse = courseData?.who_is_this_course_for;
      createCourse.whoIsCourseFor.lists = whoGetCourse ? [...whoGetCourse] : [];

      // Set Course Price
      const coursePrice = aboutCourse?.price;
      createCourse.price.data = coursePrice;

      // Set live classes
      const liveClasses = courseData?.live_classes;
      createCourse.liveClasses.lists = liveClasses ? [...liveClasses] : [];

      //  Set course publish status
      const coursePublished = aboutCourse?.is_published;
      createCourse.publishCourse.data = { is_published: coursePublished };
    },

    // Reset
    resetCreateCourseData: (createCourse, action) => {
      // Set course id
      createCourse.courseId = null;
      createCourse.isEditing = false;

      // Set About Course
      createCourse.aboutCourse.data = {
        courseName: "",
        classSize: "",
        courseCategory: "",
        levelOfCompetence: "",
        thumbnailFileUrl: "",
        whatToLearn: "",
        aboutCourse: "",
        startDate: "",
        endDate: "",
      };

      // Set Course curriculum
      createCourse.curriculum.curriculums = [];

      // Set couurse contents
      createCourse.courseContent.contents = [];

      // Set who is this course for
      createCourse.whoIsCourseFor.lists = [];

      // Set Course Price
      createCourse.price.data = "0.00";

      // Set live classes
      createCourse.liveClasses.lists = [];

      //  Set course publish status
      createCourse.publishCourse.data = {};
    },
  },
});

// Action Types
const {
  //   Course categories
  addingCurriculum,
  addingCurriculumFail,
  addingCurriculumSuccess,
  updatingCurriculum,
  updatingCurriculumFail,
  updatingCurriculumSuccess,
  deletingCurriculum,
  deletingCurriculumFail,
  deletingCurriculumSuccess,
  createCourseStarted,
  createCourseFail,
  createCourseSuccess,
  persistCreateCourse,
  updateCourseStarted,
  updateCourseFail,
  updateCourseSuccess,
  addingCourseContent,
  addingCourseContentFail,
  addingCourseContentSuccess,
  updatingCourseContent,
  updatingCourseContentFail,
  updatingCourseContentSuccess,
  deletingCourseContent,
  deletingCourseContentFail,
  deletingCourseContentSuccess,
  addingWhoIsCourseFor,
  addingWhoIsCourseForFail,
  addingWhoIsCourseForSuccess,
  updatingWhoIsCourseFor,
  updatingWhoIsCourseForFail,
  updatingWhoIsCourseForSuccess,
  deletingWhoIsCourseFor,
  deletingWhoIsCourseForFail,
  deletingWhoIsCourseForSuccess,
  addingPrice,
  addingPriceFail,
  addingPriceSuccess,
  addingLiveClass,
  addingLiveClassFail,
  addingLiveClassSuccess,
  editingLiveClass,
  editingLiveClassFail,
  editingLiveClassSuccess,
  deletingLiveClass,
  deletingLiveClassFail,
  deletingLiveClassSuccess,
  publishingCourse,
  publishingCourseFail,
  publishingCourseSuccess,
  gettingCourseData,
  gettingCourseDataFail,
  gettingCourseDataSuccess,
  resetCreateCourseData,
} = createCourseSlice.actions;

//   Action Creators
const service = "courseServices";

export const addCourseCurriculum = (data) => {
  return apiCallBegan({
    service,
    endpoint: "createCurriculum",
    data,
    onStart: addingCurriculum.type,
    onSuccess: addingCurriculumSuccess.type,
    onError: addingCurriculumFail.type,
  });
};

export const updateCourseCurriculum = (data) => {
  return apiCallBegan({
    service,
    endpoint: "updateCurriculum",
    data,
    onStart: updatingCurriculum.type,
    onSuccess: updatingCurriculumSuccess.type,
    onError: updatingCurriculumFail.type,
  });
};

export const deleteCourseCurriculum = (data) => {
  return apiCallBegan({
    service,
    endpoint: "deleteCurriculum",
    data,
    onStart: deletingCurriculum.type,
    onSuccess: deletingCurriculumSuccess.type,
    onError: deletingCurriculumFail.type,
  });
};

export const createAboutCourse = (data) =>
  apiCallBegan({
    service,
    endpoint: "createCourse",
    data,
    onStart: createCourseStarted.type,
    onSuccess: createCourseSuccess.type,
    onError: createCourseFail.type,
  });

export const updateAboutCourse = (data) =>
  apiCallBegan({
    service,
    endpoint: "updateCourse",
    data,
    onStart: updateCourseStarted.type,
    onSuccess: updateCourseSuccess.type,
    onError: updateCourseFail.type,
  });

export const saveAboutcourseData = (data) => persistCreateCourse(data);

export const createCourseContent = (data) =>
  apiCallBegan({
    service,
    endpoint: "createCourseContent",
    data,
    onStart: addingCourseContent.type,
    onSuccess: addingCourseContentSuccess.type,
    onError: addingCourseContentFail.type,
  });

export const updateCourseContent = (data) =>
  apiCallBegan({
    service,
    endpoint: "updateCourseContent",
    data,
    onStart: updatingCourseContent.type,
    onSuccess: updatingCourseContentSuccess.type,
    onError: updatingCourseContentFail.type,
  });

export const deleteCourseContent = (data) =>
  apiCallBegan({
    service,
    endpoint: "deleteCourseContent",
    data,
    onStart: deletingCourseContent.type,
    onSuccess: deletingCourseContentSuccess.type,
    onError: deletingCourseContentFail.type,
  });

export const createWhoIsCourseFor = (data) =>
  apiCallBegan({
    service,
    endpoint: "createWhoIsCourseFor",
    data,
    onStart: addingWhoIsCourseFor.type,
    onSuccess: addingWhoIsCourseForSuccess.type,
    onError: addingWhoIsCourseForFail.type,
  });

export const updateWhoIsCourseFor = (data) =>
  apiCallBegan({
    service,
    endpoint: "updateWhoIsCourseFor",
    data,
    onStart: updatingWhoIsCourseFor.type,
    onSuccess: updatingWhoIsCourseForSuccess.type,
    onError: updatingWhoIsCourseForFail.type,
  });

export const deleteWhoIsCourseFor = (data) =>
  apiCallBegan({
    service,
    endpoint: "deleteWhoIsCourseFor",
    data,
    onStart: deletingWhoIsCourseFor.type,
    onSuccess: deletingWhoIsCourseForSuccess.type,
    onError: deletingWhoIsCourseForFail.type,
  });

export const createCoursePrice = (data) =>
  apiCallBegan({
    service,
    endpoint: "createCoursePrice",
    data,
    onStart: addingPrice.type,
    onSuccess: addingPriceSuccess.type,
    onError: addingPriceFail.type,
  });

export const createLiveClass = (data) =>
  apiCallBegan({
    service,
    endpoint: "createCourseLiveClasses",
    data,
    onStart: addingLiveClass.type,
    onSuccess: addingLiveClassSuccess.type,
    onError: addingLiveClassFail.type,
  });

export const editLiveClass = (data) =>
  apiCallBegan({
    service,
    endpoint: "editLiveClasses",
    data,
    onStart: editingLiveClass.type,
    onSuccess: editingLiveClassSuccess.type,
    onError: editingLiveClassFail.type,
  });

export const deleteLiveClass = (data) =>
  apiCallBegan({
    service,
    endpoint: "deleteLiveClasses",
    data,
    onStart: deletingLiveClass.type,
    onSuccess: deletingLiveClassSuccess.type,
    onError: deletingLiveClassFail.type,
  });

export const updateCourses = (data) =>
  apiCallBegan({
    service,
    endpoint: "publishCourse",
    data,
    onStart: publishingCourse.type,
    onSuccess: publishingCourseSuccess.type,
    onError: publishingCourseFail.type,
  });

export const getCourseData = (data) =>
  apiCallBegan({
    service,
    endpoint: "getCourseDataForEdit",
    data,
    onStart: gettingCourseData.type,
    onSuccess: gettingCourseDataSuccess.type,
    onError: gettingCourseDataFail.type,
  });

export const resetData = () => resetCreateCourseData();
// *********************** End of Action Creators *****************************

export default createCourseSlice.reducer;
