import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
// import { useDispatch } from "react-redux";

import DashboardSideNav from "../common/DashboardSideNav";
import DashboardTopNav from "../common/DashboardTopNav";

// import { getCourse } from "../../storee/slices/singleCourse";
import CenteredLayout from "../common/CenterLayout";
import EditBillingModal from "../common/Modals/EditBillingModal";
import usePayment from "../../store/payment";
import {
  paypalVerifyTransaction,
  paystackVerifyTransaction,
  stripeVerifyTransaction,
} from "../../services/paymentServices";
import queryString from "query-string";
import { log } from "../../utils/helpers";
import Spinner from "../common/Spinner";

const ConfirmPayment = () => {
  const [showModal, setShowModal] = useState(false);
  //   const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const parsed = queryString.parse(location.search);
  const { paymentId, token, PayerID, channel, reference, session_id } = parsed;

  log({ parsed, paymentId, token, PayerID, channel });

  //   Store
  const { setNewPaidCourseId } = usePayment();

  // Methods
  const goBack = (e) => {
    e.preventDefault();
    history.goBack();
  };

  const confirmCoursePayment = async () => {
    if (channel === "paypal") {
      try {
        const res = await paypalVerifyTransaction({
          paymentId,
          token,
          PayerID,
          channel,
        });
        if (res.data.message === "Payment successful") {
          const {
            //   message,
            //   transaction_reference,
            //   transaction_state,
            course_id,
          } = res.data;
          setNewPaidCourseId(course_id);
          history.push(`/user-course/payment-successful`);
        }
      } catch (error) {}

      return;
    }
    if (channel === "paystack") {
      try {
        const res = await paystackVerifyTransaction({
          reference,
        });

        if (res.data.message === "Payment successful") {
          const {
            //   message,
            //   transaction_reference,
            //   transaction_state,
            course_id,
          } = res.data;
          setNewPaidCourseId(course_id);
          history.push(`/user-course/payment-successful`);
        }
      } catch (error) {}

      return;
    }

    if (channel === "stripe") {
      try {
        const res = await stripeVerifyTransaction({
          session_id,
        });
        if (res.data.message === "Payment successful") {
          const {
            //   message,
            //   transaction_reference,
            //   transaction_state,
            course_id,
          } = res.data;
          setNewPaidCourseId(course_id);
          history.push(`/user-course/payment-successful`);
        }
      } catch (error) {}

      return;
    }
  };

  useEffect(() => {
    confirmCoursePayment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <main className="dashboard-main">
        <DashboardSideNav />
        <main className="dashboard__main">
          <DashboardTopNav>
            <button onClick={(e) => goBack(e)} className="btn btn-3">
              <i className="fas fa-long-arrow-alt-left m-r-6px"></i>
              Back
            </button>
          </DashboardTopNav>
          <>
            <CenteredLayout>
              <div className="payments__wrapper">
                <div className="payments__card">
                  <Spinner />
                  <h4>Verifying payment....</h4>
                </div>
              </div>
            </CenteredLayout>
          </>
        </main>
      </main>

      {showModal && <EditBillingModal setShowModal={setShowModal} />}
    </>
  );
};

export default ConfirmPayment;
