import React, { useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import DashboardSideNav from "../common/DashboardSideNav";
import DashboardTopNav from "../common/DashboardTopNav";
import ContentCard from "../common/ContentCard";
import TabNavigator from "../common/TabNavigator";
import AboutCourse from "../common/AboutCourse";

import moment from "moment";

import { getCoursesById } from "../../storee/slices/singleCourse";
import EmptyActivityBox from "../common/EmptyActivityBox";
import EmptyActivityState from "../common/EmptyActivityState";
import VideoAvatar from "../common/VideoAvatar";
import CourseEnrollCard from "../common/CourseEnrollCard";
import CenteredLayout from "../common/CenterLayout";
import DashboardMaxWidth from "../common/DashboardMaxWidth";
import FixedTop from "../common/FixedTop";
import useTabs from "./../../utils/Hooks/useTab";
import CourseReviewByUser from "../common/CourseReviewByUser";
// import { removeTags } from "../../utils/helpers";
import CourseContentPlain from "../common/CourseContentPlain";
// import { log } from "../../utils/helpers";
import SpinnerBox from "../common/SpinnerBox";
import { isValidJson } from "../../utils/helpers";

const CourseEnroll = () => {
  // const whatToLearnRef = useRef();

  // Hooks
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const [activeTabId, setTabId, renderTabComponent, setAllTabContent] =
    useTabs(0);

  // Variables
  const tabLabels = ["About", "Reviews"];

  // Store
  const [course, isLoading] = useSelector((store) => [
    store.entities.singleCourse.courseById.data,
    store.entities.singleCourse.courseById.isLoading,
  ]);

  // Handlers
  const goBack = (e) => {
    e.preventDefault();
    history.goBack();
  };

  // Effects
  // useEffect(() => {
  //   if (Boolean(isUserEnrolled))
  //     return history.push({
  //       pathname: `/user-course/${id}`,
  //       state: { userEnrolled: true, fromUrl: history.location.state.fromUrl },
  //     });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [course]);

  useEffect(() => {
    if (String(course?.id) !== String(id)) dispatch(getCoursesById(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // window.scrollTo(0, 0);
    setAllTabContent([
      <AboutCourse
        course={course}
        allCourseInfo={course}
        type="student"
        progressBarType="user"
      />,
      <CourseReviewByUser />,
    ]);

    // if (course) whatToLearnRef.current.innerHTML = course?.outcomes;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [course]);

  // Data to render
  return (
    <main className="dashboard-main">
      <DashboardSideNav />
      <main className="dashboard__main">
        <DashboardTopNav>
          <button
            onClick={(e) => goBack(e)}
            className="btn btn-3 dashboard__top-nav--back-btn"
          >
            <i className="fas fa-long-arrow-alt-left m-r-6px"></i>
            Back
          </button>
        </DashboardTopNav>
        <>
          <CenteredLayout>
            <DashboardMaxWidth>
              {isLoading ? (
                <SpinnerBox />
              ) : (
                <>
                  <div className="course-title max-width-650px">
                    <h2 className="head-8">{course?.course_name}</h2>
                  </div>

                  <div className="course__info-n-action">
                    <span className="course__infos course__created-date">
                      Created {moment(course?.created_at).format("LL")}
                    </span>
                  </div>

                  <div className="course-view__blocks">
                    <div className=" course-view__block-1">
                      <VideoAvatar
                        cssClass="size-md"
                        pic="pic21"
                        url={course?.promo_video_url}
                      />

                      <ContentCard>
                        <TabNavigator
                          tabTitles={tabLabels}
                          withLine={true}
                          activeId={activeTabId}
                          handleClick={setTabId}
                        />
                        {renderTabComponent(activeTabId)}
                      </ContentCard>
                    </div>

                    <div className=" course-view__block-2">
                      {course &&
                      course?.outcomes &&
                      isValidJson(course?.outcomes) ? (
                        <>
                          <ContentCard title="What you’ll learn">
                            {JSON.parse(course?.outcomes)?.map(
                              (outcome, index) => {
                                return outcome ? (
                                  <p key={index} className="para-11">
                                    {outcome}
                                  </p>
                                ) : null;
                              }
                            )}
                          </ContentCard>
                        </>
                      ) : null}

                      <ContentCard title="Course Content">
                        {course?.curriculums?.length < 1 ? (
                          <EmptyActivityBox cssClass="p-y-50px">
                            <EmptyActivityState
                              title="No Contents Available"
                              subTitle="Contents for this course are not currently availbale!"
                            />
                          </EmptyActivityBox>
                        ) : (
                          <>
                            {course?.curriculums?.map((curriculum) => (
                              <CourseContentPlain
                                key={curriculum?.curriculum_id}
                                topic={curriculum?.topic || "--"}
                              />
                            ))}
                          </>
                        )}
                      </ContentCard>
                    </div>

                    <div className="course-view__block-3">
                      <FixedTop cssClass="fixed-top-74px">
                        <ContentCard cssClass2="no-padding">
                          <CourseEnrollCard
                            pic={course?.thumbnail_file_url}
                            price={course?.price}
                            id={course?.id}
                            instructor={course?.instructor_details}
                          />
                        </ContentCard>
                      </FixedTop>
                    </div>
                  </div>
                </>
              )}
            </DashboardMaxWidth>
          </CenteredLayout>
        </>
      </main>
    </main>
  );
};

// Store
// const mapStateToProps = (state) => ({
//   isLoading: state.entities.singleCourse.isLoading,
//   data: state.entities.singleCourse.data,
//   isDataFetched: state.entities.instructor.isDataFetched,
// });

// const mapDispatchToProps = (dispatch) => ({
//   getCourseData: (id) => dispatch(getCourse(id)),
//   // getInstructorProfile: (data) => dispatch(getInstructorProfile()),
// });

export default CourseEnroll;
