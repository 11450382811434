import Form from "./Form";
import Joi from "joi";
import { registerInstructor } from "../../../services/userServices";

import { connect } from "react-redux";
import { getAllCountries } from "./../../../storee/slices/helpers";
import Checkbox from "../Input/Checkbox";
import { saveNewUserData } from "../../../storee/slices/user";

class RegisterForm extends Form {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        fullName: "",
        email: "",
        password: "",
        confirmPassword: "",
        phoneNumber: "",
        location: "Country",
      },
      error: {
        fullName: "",
        email: "",
        password: "",
        confirmPassword: "",
        phoneNumber: "",
        location: "",
      },
      isLoading: false,
      countries: [],
      terms: false,
    };
  }

  schema = {
    fullName: Joi.string().required().label("Full name"),
    email: Joi.string().required().label("Email"),
    phoneNumber: Joi.string().required().label("Phone number"),
    location: Joi.string().required().label("Location"),
    password: Joi.string().min(6).required().label("Password"),
    confirmPassword: Joi.string().min(6).required().label("Password"),
  };

  async componentDidMount() {
    await this.props.getAllCountries();
    const countryData = this.props.countryData.countries.map((country) => ({
      optionName: country?.country_name,
      optionId: country?.country_code,
    }));
    this.setState({ countries: [...countryData] });
  }

  doSubmit = async () => {
    this.setState({ isLoading: true });
    const data = { ...this.state.data };

    const { password, confirmPassword } = data;

    if (password !== confirmPassword) {
      this.setState(
        {
          error: {
            ...this.state.error,
            confirmPassword: "Password does not match",
          },
        },
        () => {
          this.setState({ isLoading: false });
        }
      );
      return;
    } else {
      this.setState({
        error: {
          ...this.state.error,
          confirmPassword: "",
        },
      });
    }

    const userData = {};
    userData.name = data.fullName;
    userData.email = data.email;
    userData.password = data.password;
    userData.phone_number = data.phoneNumber;
    userData.country_id = data.location;

    try {
      await registerInstructor(userData).then(() => {
        this.props.saveUserEmail(userData?.email);
        this.setState({ isLoading: false });
        this.props.history.push("/account-success");
      });
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const error = { ...this.state.error };
        error.email = ex.response.data;
        this.setState({ error });
      }
      this.setState({ isLoading: false });
    }
  };

  handleTerms = () => {
    this.setState((state) => ({ terms: !state.terms }));
  };

  render() {
    const { isLoading, countries } = this.state;
    return (
      <form onSubmit={this.handleSubmit}>
        {this.renderInput("Your full name", "fullName", "text", "noicon")}
        {this.renderInput("Your email address", "email", "text", "noicon")}

        {this.renderInput("Your phone number", "phoneNumber", "text", "noicon")}
        {this.renderSelect(
          "Your location",
          "location",
          countries,
          "country_id",
          "country_name",
          "Select Country"
        )}

        {this.renderInput("Password", "password", "password")}
        {this.renderInput("Confirm Password", "confirmPassword", "password")}
        <Checkbox
          label={"Get notified of any changes to your account settings"}
          onChange={() => this.handleTerms()}
          name={"terms"}
          cssClass="m-t-40px"
        />

        {this.renderButton("Sign Up", isLoading, "m-t-30px")}
      </form>
    );
  }
}

// Store
const mapStateToProps = (state) => ({
  countryData: state.entities.helpers.countries.data,
  countryDataLoading: state.entities.helpers.countries.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  getAllCountries: () => dispatch(getAllCountries()),
  saveUserEmail: (email) => dispatch(saveNewUserData(email)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RegisterForm);
