import { Link } from "react-router-dom";
import ForgetPasswordForm from "../common/Forms/ForgetPasswordForm";
import Onboarding from "../common/Onboarding";

const ForgetPassword = ({location}) => {
  return (
    <Onboarding location={location}>
      <Link to="/login" className="link link-1 m-b-40px">
        Back to Login
      </Link>
      <h2 className="head-2">Forgot Password?</h2>
      <p className="para-2 m-t-20px m-b-40px">
        Sent a link to your email to reset your password
      </p>

      <ForgetPasswordForm />
    </Onboarding>
  );
};

export default ForgetPassword;
