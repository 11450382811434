import React from "react";

const DashboardIcon = ({
  width,
  height,
  cssClass,
  pathCssClass1,
  title = "Dashboard",
}) => {
  return (
    <>
      <svg
        width={width || "16"}
        height={height || "18"}
        className={`${cssClass ? cssClass : ""}`}
        viewBox="0 0 16 18"
        fill="none"
      >
        <path
          fill="#272D33"
          className={pathCssClass1}
          opacity="0.4"
          d="M5.62043 16.3178V13.7621C5.62042 13.1144 6.14704 12.5883 6.79935 12.5842H9.19449C9.84971 12.5842 10.3809 13.1115 10.3809 13.7621V16.3104C10.3809 16.8721 10.8373 17.3286 11.4031 17.3327H13.0372C13.8003 17.3346 14.5329 17.035 15.0733 16.4999C15.6136 15.9649 15.9173 15.2383 15.9173 14.4806V7.22089C15.9173 6.60884 15.644 6.02828 15.1712 5.6356L9.6198 1.22791C8.64941 0.456951 7.26346 0.481856 6.32181 1.28717L0.889828 5.6356C0.394602 6.0167 0.0986113 6.59898 0.0839844 7.22089V14.4732C0.0839844 16.0524 1.37347 17.3327 2.96413 17.3327H4.56089C4.83329 17.3346 5.09522 17.2286 5.28855 17.038C5.48187 16.8475 5.59059 16.5882 5.59058 16.3178H5.62043Z"
        />
      </svg>
      <span>{title}</span>
    </>
  );
};

export default DashboardIcon;
