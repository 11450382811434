import http from "./httpServices";

const instructorApi = "/instructor";
const updateProfileApi = "/instructor/update-instructor-personal-profile";
const getProfileApi = "/instructor/get-instructor-personal-profile";
const updatePasswordApi = "/instructor/update-instructor-password";
const updateBankApi = "/instructor/update-instructor-bank-information";
const getBankInfoApi = "/instructor/get-instructor-bank-information";
const getBankInfoStatusApi = "/instructor/check-instructor-bank-information";
const getUpcomingClassesApi = "/instructor/get-all-instructor-upcoming-classes";
const updateProfilePictureApi = "/instructor/upload-profile-picture";

export function getDashboardData() {
  return http.get(`${instructorApi}/get-instructor-dashboard-stats`);
}

export function getCourses() {
  return http.get(`/get-instructor-courses`);
}

export function updatePersonalProfile(data) {
  return http.post(updateProfileApi, data);
}

export function getPersonalProfile() {
  return http.post(getProfileApi);
}

export function updatePassword(data) {
  return http.post(updatePasswordApi, data);
}

export function updateBankInfo(data) {
  return http.post(updateBankApi, data);
}

export function getBankInfo() {
  return http.post(getBankInfoApi);
}

export function getBankInfoStatus() {
  return http.post(getBankInfoStatusApi);
}

export function getUpcomingClasses() {
  return http.post(getUpcomingClassesApi);
}

export function updateInstructorProfilePicture(data) {
  return http.post(updateProfilePictureApi, data);
}

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  getDashboardData,
  getCourses,
  updatePersonalProfile,
  getPersonalProfile,
  updatePassword,
  updateBankInfo,
  getBankInfo,
  getBankInfoStatus,
  getUpcomingClasses,
  updateInstructorProfilePicture,
};