import React from "react";
import DiscussionForm from "./Forms/DiscussionForm";
import Rating from "./Rating";

const CourseReviews = () => {
  return (
    <div className="course-review">
      <h4 className="head-13 m-b-30px">Rate this course</h4>

      <p className="m-b-10px">Tell us more about your experience</p>
      <Rating cssClass="m-b-20px" />
      <DiscussionForm placeholder={"Write a review"} />
    </div>
  );
};

export default CourseReviews;
