import React from "react";

const TrashIcon = ({ width, height, cssClass, pathCssClass }) => {
  return (
    <svg
      width={width || "19"}
      height={height || "19"}
      className={`${cssClass ? cssClass : ""}`}
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={pathCssClass}
        d="M16.625 4.73415C13.9888 4.4729 11.3367 4.33832 8.6925 4.33832C7.125 4.33832 5.5575 4.41748 3.99 4.57582L2.375 4.73415"
        stroke="#292D32"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className={pathCssClass}
        d="M6.72913 3.93456L6.90329 2.89748C7.02996 2.1454 7.12496 1.58331 8.46288 1.58331H10.537C11.875 1.58331 11.9779 2.17706 12.0966 2.9054L12.2708 3.93456"
        stroke="#292D32"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className={pathCssClass}
        d="M14.9229 7.23584L14.4083 15.2079C14.3212 16.4508 14.2499 17.4167 12.0412 17.4167H6.95869C4.74994 17.4167 4.67869 16.4508 4.59161 15.2079L4.07703 7.23584"
        stroke="#292D32"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className={pathCssClass}
        d="M8.17786 13.0625H10.8141"
        stroke="#292D32"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className={pathCssClass}
        d="M7.52087 9.89581H11.4792"
        stroke="#292D32"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TrashIcon;
