import React from "react";
import Joi from "joi";
import { images } from "../../../utils/images";
import ContentCard from "../ContentCard";
import EmptyActivityBox from "../EmptyActivityBox";
import EmptyActivityState from "../EmptyActivityState";
import Form from "../Forms/Form";
import GridView from "../GridView";
import ModalChild from "../ModalChild";
import ModalLayout from "./../ModalLayout";

import CourseContentSingle from "../CourseContentSingle";
import { connect } from "react-redux";
import { getResourceTypes } from "./../../../storee/slices/helpers";
import {
  createCourseContent,
  deleteCourseContent,
  saveAboutcourseData,
  updateCourseContent,
} from "../../../storee/slices/createCourse";
// import { addANotification } from "../../../storee/slices/notification";
import ConfirmActionModal from "../Modals/ConfirmActionModal";
import { _isVideoLink } from "../../../utils/helpers";
import Notiflix from "notiflix";

class CourseContent extends Form {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        resourceTitle: "",
        resourceType: "",
        estimatedTime: "",
        link: "",
        description: "",
      },
      error: {
        resourceTitle: "",
        resourceType: "",
        estimatedTime: "",
        link: "",
        description: "",
      },
      contentArray: [],
      showModal: false,
      showDeleteModal: false,
      isLoading: false,
      resourceTypesList: [],
    };
  }

  schema = {
    resourceTitle: Joi.string().required().label("Course content name"),
    estimatedTime: Joi.number().required().label("Course estimated time"),
    resourceType: Joi.string().required().label("Course links"),
    link: Joi.string().required().label("Course links"),
    description: Joi.string().required().label("Course links"),
  };

  async componentDidMount() {
    await this.props.getAllResourceTypes();
    const resourceTypesData = this.props?.resourseTypes.map((resource) => ({
      optionName: resource?.name,
      optionId: resource?.resource_type_id,
    }));
    this.setState({
      resourceTypesList: [...resourceTypesData],
    });
  }

  doSubmit = async () => {
    const { isEditing, courseContentIdToEdit } = this.state;

    const { resourceTitle, resourceType, estimatedTime, link, description } =
      this.state.data;

    const data = {
      course_id: this.props.courseId,
      resource_name: resourceTitle,
      estimated_time: estimatedTime,
      resource_type: resourceType,
      attachment_link: link,
      description: description,
      curriculum_id: this.state.currentCurriculumId,
    };

    if (data.resource_type === "2" && !_isVideoLink(data.attachment_link)) {
      this.setState({
        error: { ...this.state.error, link: "This has to be a video link" },
      });
      return;
    } else if (
      data.resource_type !== "2" &&
      _isVideoLink(data.attachment_link)
    ) {
      this.setState({
        error: { ...this.state.error, link: "This is not a document link" },
      });
      return;
    } else {
      this.setState({
        error: { ...this.state.error, link: "" },
      });
    }
    this.setState({
      error: { ...this.state.error, link: "" },
    });
    if (!this.props.courseId) {
      // Check if course has been created
      Notiflix.Notify.failure("Error while creating course content!");

      return;
    }

    // Post to database
    if (isEditing) {
      delete data.course_id;
      data.course_content_id = courseContentIdToEdit;

      try {
        await this.props.updateSingleCourseContent(data);
        Notiflix.Notify.success(
          `Course content ${isEditing ? "updated" : "created"} successfully`
        );
      } catch (error) {}
    } else {
      try {
        await this.props.saveCourseContent(data);
        Notiflix.Notify.success(
          `Course content ${isEditing ? "updated" : "created"} successfully`
        );
      } catch (error) {}
    }

    // reset Data
    if (!isEditing) {
      this.resetData();
    } else {
      this.handleModalClose();
    }
  };

  resetData = () => {
    this.setState({
      data: {
        resourceTitle: "",
        resourceType: "",
        estimatedTime: "",
        link: "",
        description: "",
      },
    });
  };

  handleModalOpen = (id) => {
    this.setState({
      showModal: true,
      currentCurriculumId: id,
    });
  };

  handleModalClose = () => {
    this.setState(
      {
        showModal: false,
        isEditing: false,
        showDeleteModal: false,
      },
      () => this.resetData()
    );
  };

  handleContentUpload = () => {};

  getContentForCurriculum = (allContents, curriculumId) => {
    const contents = allContents.filter(
      (content) => content.curriculum_id === curriculumId
    );

    return contents;
  };

  handleContentEdit = async (content) => {
    this.setState({ isEditing: true });
    const contentToEdit = { ...content };

    this.setState(
      {
        data: {
          resourceTitle: contentToEdit?.resource_name,
          resourceType: contentToEdit?.resource_type,
          estimatedTime: contentToEdit?.estimated_time,
          link: contentToEdit?.attachment_link,
          description: contentToEdit?.description,
        },
        courseContentIdToEdit: contentToEdit?.id,
      },
      () => this.setState({ showModal: true })
    );
  };

  handleContentDelete = async () => {
    const { deletingContentId } = this.state;

    try {
      await this.props.deleteContent({ course_content_id: deletingContentId });
      Notiflix.Notify.success(`Course content deleted successfully`);
    } catch (error) {
      Notiflix.Notify.failure(`Error deleting content`);
    }
    this.setState({ showDeleteModal: false });
  };

  handleActionModalOpen = (id) => {
    this.setState({
      showDeleteModal: true,
      deletingContentId: id,
    });
  };

  render() {
    const { showModal, resourceTypesList, showDeleteModal } = this.state;
    const { curriculums, isCreatingContent, courseContents, isDeleting } =
      this.props;
    return (
      <>
        <div className="animate-slideFromLeft">
          <h3 className="head-5 m-b-10px">Course Content</h3>

          <ContentCard title="Course Outline">
            {curriculums?.length < 1 ? (
              <EmptyActivityBox cssClass="p-y-50px">
                <EmptyActivityState title="Please add curriculums in the curriculum section" />
              </EmptyActivityBox>
            ) : (
              curriculums?.map((curriculum, index) => (
                <CourseContentSingle
                  key={index}
                  contents={this.getContentForCurriculum(
                    courseContents,
                    curriculum?.curriculum_id
                  )}
                  id={curriculum?.curriculum_id}
                  contentName={`Week ${curriculum?.week_number} - ${curriculum?.topic}`}
                  estimatedTime={""}
                  handleModalOpen={this.handleModalOpen}
                  handleEdit={this.handleContentEdit}
                  handleDelete={this.handleActionModalOpen}
                />
              ))
            )}

            {/* <button
              className="btn-upload-content m-t-50px"
              onClick={() => this.handleModalOpen()}
            >
              <Svg
                cssClassName="btn-upload-content__icon"
                iconId="icon-plus-circle"
              />
              <span>Upload Resource</span>
            </button> */}
          </ContentCard>
        </div>
        {showModal && (
          <ModalLayout>
            <ModalChild>
              <ContentCard title="Add Course Content">
                <button
                  className="btn-close-modals"
                  onClick={() => this.handleModalClose()}
                >
                  <img src={images.pic9} alt="times" />
                </button>
                <form onSubmit={this.handleSubmit} id="courseContentForm">
                  {this.renderInput(
                    "Resource Title",
                    "resourceTitle",
                    "text",
                    "noIcon",
                    null
                  )}
                  <GridView cssClass="grid-view__minMax">
                    {this.renderSelect(
                      "Resources Type",
                      "resourceType",
                      resourceTypesList,
                      "resource_type_id",
                      "name",
                      "Select resource type"
                    )}
                    {this.renderInput(
                      "Estimated Time (Mins)",
                      "estimatedTime",
                      "number",
                      "noIcon",
                      null
                    )}
                  </GridView>
                  {this.renderInput(
                    "Link",
                    "link",
                    "text",
                    "noIcon",
                    null,
                    "icon-link-small"
                  )}
                  {this.renderTextArea("description", 5, null, "Description")}

                  <button
                    className="btn btn-5 m-t-25px"
                    form="courseContentForm"
                  >
                    {isCreatingContent ? (
                      <i className="fas fa-spinner animate-rotate"></i>
                    ) : (
                      "Upload"
                    )}
                  </button>
                </form>
              </ContentCard>
            </ModalChild>
          </ModalLayout>
        )}

        {showDeleteModal && (
          <ConfirmActionModal
            setShowModal={this.handleModalClose}
            handleYesAction={this.handleContentDelete}
            title="Are you sure you want to delete content ?"
            isLoading={isDeleting}
          />
        )}
      </>
    );
  }
}

// Store
const mapStateToProps = (state) => ({
  curriculums: state.entities.createCourse.curriculum.curriculums,
  resourseTypes: state.entities.helpers.resourceTypes.data,
  courseId: state.entities.createCourse.courseId,
  isCreatingContent: state.entities.createCourse.courseContent.isLoading,
  courseContents: state.entities.createCourse.courseContent.contents,
  isDeleting: state.entities.createCourse.courseContent.isDeleting,

  isEditingCourse: state.entities.createCourse.isEditing,
  isEditingCourseId: state.entities.createCourse.courseId,
});

const mapDispatchToProps = (dispatch) => ({
  saveAboutcourseData: (data) => dispatch(saveAboutcourseData(data)),
  getAllResourceTypes: () => dispatch(getResourceTypes()),
  // notifyActions: (data) => dispatch(addANotification(data)),
  saveCourseContent: (data) => dispatch(createCourseContent(data)),
  updateSingleCourseContent: (data) => dispatch(updateCourseContent(data)),
  deleteContent: (data) => dispatch(deleteCourseContent(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CourseContent);
