import React, { useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import DashboardSideNav from "../common/DashboardSideNav";
import DashboardTopNav from "../common/DashboardTopNav";
import CourseContentSingle from "../common/CourseContentSingle";
import ContentCard from "../common/ContentCard";
import Discussion from "../common/Discussion";
import DiscussionForm from "../common/Forms/DiscussionForm";
import TabNavigator from "../common/TabNavigator";
import AboutCourse from "../common/AboutCourse";

import moment from "moment";

import { getCourse } from "../../storee/slices/singleCourse";
import EmptyActivityBox from "../common/EmptyActivityBox";
import EmptyActivityState from "../common/EmptyActivityState";
import VideoAvatar from "../common/VideoAvatar";
import UpcomingClasses from "../common/UpcomingClasses";
import CenteredLayout from "../common/CenterLayout";
import DashboardMaxWidth from "../common/DashboardMaxWidth";
import useTabs from "../../utils/Hooks/useTab";
import CourseReviews from "../common/CourseReviews";
// import OfficeHours from "../OfficeHours";
import { getContentForCurriculum } from "../../utils/helpers";
import usePayment from "../../store/payment";
import SpinnerBox from "../common/SpinnerBox";
import useUserCourseStatus from "../../utils/Hooks/useUserCourseStatus";

const CourseEnrolled = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const [activeTabId, setTabId, renderTabComponent, setAllTabContent] =
    useTabs(0);
  // const tabLabels = ["About", "Reviews", "Office Hours"];
  const tabLabels = ["About", "Reviews"];

  // Store
  const { setNewPaidCourseId } = usePayment();

  const [course, courseData, isLoading] = useSelector((store) => [
    store.entities.singleCourse.data.courses,
    store.entities.singleCourse.data,
    store.entities.singleCourse.isLoading,
  ]);

  // Hooks
  const [isEnrolled] = useUserCourseStatus(id);

  useEffect(() => {
    if (!isEnrolled) {
      history.goBack();
    }
    // if (!course?.id) dispatch(getCourse(id));
    dispatch(getCourse(id));
    setNewPaidCourseId("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setAllTabContent([
      <AboutCourse
        course={course}
        allCourseInfo={courseData}
        type="student"
        progressBarType="user"
      />,
      <CourseReviews />,
      // <OfficeHours />,
    ]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [course, courseData]);

  const goBack = (e) => {
    e.preventDefault();
    // if (history.location?.state?.userEnrolled) {
    //   let fromUrl = history.location?.state?.fromUrl;
    //   history.push(fromUrl);
    //   return;
    // }
    history.goBack();
  };

  // data to render
  return (
    <main className="dashboard-main">
      <DashboardSideNav />
      <main className="dashboard__main">
        <DashboardTopNav>
          <button
            onClick={(e) => goBack(e)}
            className="btn btn-3 dashboard__top-nav--back-btn"
          >
            <i className="fas fa-long-arrow-alt-left m-r-6px"></i>
            Back
          </button>
        </DashboardTopNav>
        <>
          <CenteredLayout>
            <DashboardMaxWidth>
              {isLoading ? (
                <SpinnerBox />
              ) : (
                <>
                  <div className="course-title max-width-650px">
                    <h2 className="head-8">{course?.course_name || "--"}</h2>
                  </div>

                  <div className="course__info-n-action">
                    <span className="course__infos course__created-date">
                      Created {moment(course?.created_at).format("LL")}
                    </span>
                  </div>

                  <div className="course-view__blocks">
                    <div className=" course-view__block-1">
                      <VideoAvatar url={course?.promo_video_url} />
                      <ContentCard>
                        <TabNavigator
                          tabTitles={tabLabels}
                          withLine={true}
                          activeId={activeTabId}
                          handleClick={setTabId}
                        />
                        {renderTabComponent(activeTabId)}
                      </ContentCard>
                    </div>

                    <div className=" course-view__block-2">
                      <ContentCard
                        title="Course Content"
                        titleLink={{
                          label: "View All",
                          link: `/user-course/${id}/contents`,
                        }}
                      >
                        {courseData?.course_contents?.length < 1 ? (
                          <EmptyActivityBox cssClass="p-y-50px">
                            <EmptyActivityState
                              title="No Contents Available"
                              subTitle="Contents for this course are not currently availbale!"
                            />
                          </EmptyActivityBox>
                        ) : (
                          <>
                            {courseData?.curriculums?.map(
                              (curriculum, index) => (
                                <CourseContentSingle
                                  key={curriculum?.curriculum_id}
                                  opened={index === 0 ? true : false}
                                  contentName={curriculum?.topic}
                                  contents={getContentForCurriculum(
                                    courseData?.course_contents,
                                    curriculum?.curriculum_id
                                  )}
                                  hideActions={true}
                                />
                              )
                            )}
                          </>
                        )}
                      </ContentCard>

                      <UpcomingClasses
                        hostName={course?.instructor_name}
                        classes={courseData?.live_classes}
                      />
                    </div>

                    <div className=" course-view__block-3">
                      <ContentCard title="Discussions">
                        {!course?.discussions ? (
                          <EmptyActivityBox cssClass="p-y-50px">
                            <EmptyActivityState
                              title="No Discussions"
                              subTitle="No discussions so far for this course"
                            />
                          </EmptyActivityBox>
                        ) : (
                          <>
                            <DiscussionForm />
                            <Discussion />
                            <Discussion />
                            <Discussion />
                          </>
                        )}
                      </ContentCard>
                    </div>
                  </div>
                </>
              )}
            </DashboardMaxWidth>
          </CenteredLayout>
        </>
      </main>
    </main>
  );
};

// Store
// const mapStateToProps = (state) => ({
//   isLoading: state.entities.singleCourse.isLoading,
//   data: state.entities.singleCourse.data,
//   isDataFetched: state.entities.instructor.isDataFetched,
// });

// const mapDispatchToProps = (dispatch) => ({
//   getCourseData: (id) => dispatch(getCourse(id)),
//   // getInstructorProfile: (data) => dispatch(getInstructorProfile()),
// });

export default CourseEnrolled;
