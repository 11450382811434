import React from "react";
import { Link } from "react-router-dom";
import Svg from "./Svg";
import EditIcon from "./SvgIcons/EditIcon";
import TrashIcon from "./SvgIcons/TrashIcon";

const CourseVideo = ({
  label,
  link,
  id,
  handleEdit,
  handleDelete,
  estimatedTime,
  allContent,
  hideActions,
}) => {
  return (
    <>
      {label && (
        <div className="course-content__files">
          <Svg cssClassName="icon-file" iconId="icon-video" />
          <div className="course-content__file-description">
            <div>
              <Link
                to={{ pathname: `${link}` }}
                target="_blank"
                className="course-content__file-name"
              >
                <h5 className="course-content__file-name">{label}</h5>
              </Link>
              <span className="course-content__file-duration">
                {`( ${estimatedTime} min )`}
              </span>
            </div>
            {!hideActions && (
              <div className="course-content__action">
                <button
                  className="btn btn-naked"
                  onClick={() => handleDelete(id)}
                >
                  <TrashIcon
                    width="17"
                    height="19"
                    pathCssClass={"course-content__action-icon"}
                  />
                </button>
                <button
                  className="btn btn-naked"
                  onClick={() => handleEdit(allContent)}
                >
                  <EditIcon
                    width="17"
                    height="19"
                    pathCssClass={"course-content__action-icon"}
                  />
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default CourseVideo;
