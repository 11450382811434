import React from 'react';

const ChatTextMe = () => {
    return (
      <div className="chat-texts__me">
        <div className="chat-texts__me-text">
          <article>
            <span className="chat-texts__message">
              I have some stuff to still finish, but in a couple of hours it
              will be emailed to you, so we can talk about what i have done?
            </span>
          </article>

          <span className="chat-texts__time">12:24</span>
        </div>
      </div>
    );
}
 
export default ChatTextMe;